import _mapValues from 'lodash/mapValues';
import { createTheme } from '@mui/material/styles';
import { colors } from 'components/Colors';

const theme = createTheme({
  customPalette: {
    beige: {
      primary: colors.beige.primary.value,
      alternative: colors.beige.alternative.value
    },
    cobalt: {
      primary: colors.cobalt.primary.value,
      alternative: colors.cobalt.alternative.value
    },
    blue: {
      primary: colors.blue.primary.value,
      alternative: colors.blue.alternative.value
    },
    turquoise: {
      primary: colors.turquoise.primary.value,
      alternative: colors.turquoise.alternative.value
    },
    olive: {
      primary: colors.olive.primary.value,
      alternative: colors.olive.alternative.value
    },
    yellow: {
      primary: colors.yellow.primary.value,
      alternative: colors.yellow.alternative.value
    }
  },
  palette: {
    common: { ..._mapValues(colors.common, 'value') },
    primary: {
      main: colors.green.primary.value,
      light: colors.green.alternative.value,
      contrastText: colors.common.white.value
    },
    secondary: {
      main: colors.common.darkGrey.value,
      light: colors.common.lightGrey.value
    },
    error: {
      main: colors.red.primary.value,
      light: colors.red.alternative.value,
      contrastText: colors.common.white.value
    },
    warning: {
      main: colors.orange.primary.value,
      light: colors.orange.alternative.value
    },
    info: {
      main: colors.green.teal.value,
      light: colors.green.midTeal.value
    },
    success: {
      main: colors.green.alternative.value
    },
    text: {
      primary: colors.common.text.value,
      secondary: colors.common.darkGrey.value,
      disabled: colors.common.lightGrey.value
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'Mulish, sans-serif',
      fontSize: '16px',
      lineHeight: '125%',
      color: colors.common.text.value
    },
    h1: {
      fontSize: '49px',
      lineHeight: '120%',
      fontWeight: 900,
      margin: '20px 0'
    },
    h2: {
      fontSize: '39px',
      lineHeight: '125%',
      fontWeight: 900,
      margin: '20px 0'
    },
    h3: {
      fontSize: '31px',
      lineHeight: '125%',
      fontWeight: 900,
      margin: '20px 0'
    },
    h4: {
      fontSize: '22px',
      lineHeight: '125%',
      fontWeight: 900,
      margin: '20px 0'
    },
    h5: {
      fontSize: '16px',
      lineHeight: '125%',
      fontWeight: 600,
      textTransform: 'uppercase',
      margin: '20px 0'
    },
    // body
    body1: {
      fontSize: '16px',
      lineHeight: '140%',
      fontWeight: 400
    },
    // small text
    body2: {
      fontSize: '13px',
      lineHeight: '140%',
      fontWeight: 600
    },
    // grey links
    subtitle1: {
      fontSize: '13px',
      lineHeight: '140%',
      fontWeight: 700
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1200,
      xl: 1440
    },
    unit: 'px'
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: colors.common.text.value
        }
      },
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: colors.common.text.value,
          padding: '13px 16px',
          fontWeight: 700,
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${colors.common.white.value} inset`
          }
        },
        multiline: {
          padding: '13px 14px 13px 16px'
        },
        notchedOutline: {
          borderColor: colors.common.text.value,
          borderRadius: '4px',
          top: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.common.text.value,
          fontSize: '16px',
          lineHeight: '140%',
          fontWeight: 700
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          fontWeight: 600,
          margin: '3px 0 0 0'
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: false
      },
      styleOverrides: {
        root: {
          transform: 'none',
          fontSize: '13px',
          marginBottom: '4px'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: colors.green.alternative.value,
          fontWeight: 700,
          '&:hover': {
            color: colors.green.primary.value
          }
        },
        button: {
          verticalAlign: 'top',
          fontFamily: 'Mulish,sans-serif'
        }
      },
      variants: [
        {
          props: { variant: 'subtitle1' },
          style: {
            fontWeight: 400,
            color: colors.common.darkGrey.value,
            '&:hover': {
              color: colors.common.grey.value
            }
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: '12px',
          textTransform: 'none'
        },
        sizeSmall: {
          padding: '8px 18px',
          fontSize: '12px'
        },
        sizeMedium: {
          padding: '12px 24px',
          fontSize: '13px'
        },
        sizeLarge: {
          padding: '16px 40px',
          fontSize: '16px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '14px 20px',
          color: colors.common.text.value
        },
        filled: {
          color: colors.common.white.value
        },
        standardWarning: {
          backgroundColor: colors.orange.light.value
        },
        standardError: {
          backgroundColor: colors.red.light.value
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: colors.common.text.value,
          fontSize: 16,
          fontWeight: 700
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 1303
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: 'none',
          minWidth: 250,
          borderRadius: 0
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0 10px 15px 5px',
          fontSize: 22,
          fontWeight: 900
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '5px 10px 15px 5px',
          fontSize: '13px',
          fontWeight: 400
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
          padding: '5px'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '8px'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.green.alternative.value
        },
        flexContainer: {
          justifyContent: 'space-between'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          top: '66px',
          '& > .MuiBackdrop-root': {
            top: '66px'
          }
        },
        paper: {
          top: '64px',
          height: '100%',
          boxShadow: 'none'
        }
      }
    }
  }
});

if (theme.components) {
  theme.components.MuiCssBaseline = {
    styleOverrides: {
      html: {
        height: '100%',
        overflowY: 'overlay',
        '*::-webkit-scrollbar': {
          width: '2px',
          height: '2px'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: colors.common.grey.value,
          borderRadius: '8px'
        },
        scrollbarColor: `${colors.common.grey.value} transparent`,
        scrollbarWidth: 'thin',
        overscrollBehaviorY: 'contain'
      },
      body: {
        minHeight: '100%'
      },
      input: {
        '::-ms-reveal, ::-ms-clear': {
          display: 'none'
        },
        '&[type="number"]': {
          MozAppearance: 'textfield'
        }
      },
      '#root': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      },
      '.MuiButtonBase-root.MuiTab-root': {
        padding: '12px 0',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '140%',
        color: colors.common.text.value,
        textTransform: 'none'
      },
      '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
        color: colors.green.alternative.value
      },
      '.rpv-core__menu-divider': {
        borderBottom: 'none',
        margin: 0
      },
      '.rpv-core__menu-item': {
        padding: '7px 0',
        borderBottom: 'none'
      },
      [theme.breakpoints.up('lg')]: {
        html: {
          overflowY: 'scroll',
          '*::-webkit-scrollbar': {
            width: '6px',
            height: '6px'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: colors.common.grey.value,
            borderRadius: '8px'
          },
          scrollbarColor: `${colors.common.grey.value} transparent`,
          scrollbarWidth: 'auto'
        },
        '.MuiButtonBase-root.MuiTab-root': {
          fontSize: '22px',
          fontWeight: 900,
          lineHeight: '125%'
        }
      }
    }
  };
}

export default theme;
