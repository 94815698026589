const { NODE_ENV, REACT_APP_API_URL } = process.env;

export const getBrokerUrl = () => {
  if (
    !window.location.hostname.includes('localhost') &&
    NODE_ENV !== 'development'
  ) {
    return `wss://${window.location.hostname}${REACT_APP_API_URL}/events`;
  }

  return `${REACT_APP_API_URL?.replace('https', 'ws')}/events`;
};
