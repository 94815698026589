import { SvgIcon } from '@mui/material';

const Error = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_3687)">
      <path d="M12 0C9.62665 0 7.30654 0.703823 5.33315 2.0224C3.35976 3.34098 1.82179 5.21509 0.913536 7.40781C0.00528422 9.60052 -0.23247 12.0133 0.230552 14.3411C0.693575 16.6689 1.8365 18.8071 3.51473 20.4853C5.19296 22.1635 7.33125 23.3064 9.65902 23.7694C11.9868 24.2324 14.3996 23.9948 16.5923 23.0866C18.785 22.1783 20.659 20.6403 21.9776 18.6669C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7358 5.76515 20.4853 3.51471C18.2349 1.26427 15.1826 0 12 0ZM12 21C10.22 21 8.48 20.4722 6.99996 19.4832C5.51992 18.4943 4.36621 17.0887 3.68502 15.4441C3.00383 13.7996 2.82561 11.99 3.17287 10.2442C3.52014 8.49837 4.37734 6.89475 5.63601 5.63608C6.89468 4.37741 8.4984 3.52021 10.2442 3.17294C11.9901 2.82568 13.7997 3.0039 15.4442 3.68509C17.0888 4.36628 18.4942 5.5198 19.4832 6.99985C20.4721 8.47989 21 10.22 21 12C21 14.3869 20.0519 16.6761 18.364 18.3639C16.6762 20.0518 14.387 21 12 21Z" />
      <path d="M10.5 12H13.5V6H10.5V12Z" />
      <path d="M10.5 16.5H13.5V13.5H10.5V16.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_3687">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Error;
