import { SvgIcon } from '@mui/material';

const QuestionMark = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3261_16951)">
      <path d="M8.02637 0C5.90464 0 3.86981 0.842849 2.36952 2.34314C0.869226 3.84343 0.0263672 5.87827 0.0263672 8C0.0263672 10.1217 0.869226 12.1565 2.36952 13.6568C3.86981 15.1571 5.90464 16 8.02637 16C10.1481 16 12.1829 15.1571 13.6832 13.6568C15.1835 12.1565 16.0264 10.1217 16.0264 8C16.0264 5.87827 15.1835 3.84343 13.6832 2.34314C12.1829 0.842849 10.1481 0 8.02637 0V0ZM8.02637 14.6667C6.25826 14.6667 4.56256 13.9643 3.31232 12.714C2.06207 11.4638 1.3597 9.76811 1.3597 8C1.3597 6.23189 2.06207 4.53617 3.31232 3.28593C4.56256 2.03569 6.25826 1.33333 8.02637 1.33333C9.79448 1.33333 11.4902 2.03569 12.7404 3.28593C13.9907 4.53617 14.693 6.23189 14.693 8C14.693 9.76811 13.9907 11.4638 12.7404 12.714C11.4902 13.9643 9.79448 14.6667 8.02637 14.6667V14.6667Z" />
      <path d="M6.39312 4.5199C6.84819 4.24349 7.37439 4.10675 7.90646 4.12659C8.60077 4.09896 9.28557 4.29527 9.85978 4.68656C10.1194 4.87642 10.327 5.12865 10.4633 5.41996C10.5996 5.71127 10.6603 6.03225 10.6398 6.35323C10.6529 6.75903 10.5361 7.15843 10.3064 7.49324C10.0853 7.76509 9.82984 8.00708 9.54645 8.21321L9.17312 8.49992C8.98687 8.6368 8.84722 8.82761 8.77311 9.04655C8.72585 9.24749 8.70347 9.45351 8.70645 9.65991H7.29979C7.29132 9.24923 7.34756 8.83976 7.46645 8.44657C7.61978 8.13661 7.84913 7.87053 8.13312 7.67322L8.51311 7.37325C8.63091 7.2901 8.73257 7.18617 8.81311 7.06657C8.95625 6.87454 9.0292 6.63922 9.01979 6.3999C9.0222 6.14094 8.94029 5.88821 8.78645 5.67989C8.68314 5.56176 8.5528 5.47037 8.40654 5.41349C8.26027 5.35661 8.10243 5.33593 7.94645 5.35323C7.78073 5.33747 7.61384 5.36698 7.46356 5.4386C7.31329 5.51021 7.18526 5.62124 7.09312 5.75989C6.92738 6.00614 6.83914 6.29638 6.83978 6.59322H5.35978C5.34614 6.18897 5.43347 5.78771 5.61389 5.4257C5.79432 5.06369 6.06213 4.75238 6.39312 4.5199ZM7.25979 10.3732H8.81979V11.8799H7.25979V10.3732Z" />
    </g>
    <defs>
      <clipPath id="clip0_3261_16951">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default QuestionMark;
