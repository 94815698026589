export const LanternGreyFemale = ({ ...props }) => (
  <svg
    {...props}
    width="163"
    height="139"
    viewBox="0 0 163 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4764_25199)">
      <path
        d="M13.3352 130.192C17.9771 139.041 24.8759 139.379 31.3745 135.984C37.8732 132.589 41.5329 126.748 36.8935 117.899C32.2542 109.05 14.5688 103.938 14.5688 103.938C14.5688 103.938 11.7478 112.33 11.5605 120.422L19.8339 125.497L11.6272 123.297C11.7965 125.83 12.3094 128.222 13.3352 130.192Z"
        fill="#F2F2F2"
      />
      <path
        d="M18.7798 119.276C18.7182 119.127 18.6567 118.974 18.5977 118.82C18.3994 118.326 18.2045 117.822 18.013 117.311C17.2436 115.264 16.5896 113.284 16.0383 111.491C15.9895 111.335 15.9434 111.176 15.8972 111.025C14.8714 107.656 14.2661 105.018 14.0507 104.043C13.9917 103.788 13.9635 103.639 13.9609 103.632L14.1994 103.58L14.4354 103.532C14.4354 103.532 14.4354 103.585 14.4636 103.66C14.5995 104.286 15.233 107.101 16.364 110.848C16.4101 111.005 16.4563 111.161 16.505 111.317C17.1846 113.527 18.0438 116.039 19.0516 118.6C19.1106 118.751 19.1722 118.905 19.2337 119.053C20.0159 120.99 20.893 122.944 21.8701 124.819C21.9419 124.965 22.0214 125.108 22.0984 125.249C24.4398 129.66 27.3301 133.566 30.7744 135.6L30.6538 135.807L30.5256 136.02L30.3871 135.938C27.2455 134.029 24.3142 130.519 21.6291 125.464L21.4008 125.027C20.4981 123.287 19.6244 121.37 18.7798 119.276Z"
        fill="white"
      />
      <path
        d="M115.697 111.03L115.228 110.854C112.972 109.957 111.007 108.461 109.544 106.528L109.088 106.73C110.317 108.393 111.909 109.754 113.745 110.71C114.181 110.939 114.629 111.143 115.086 111.322C115.24 111.383 115.397 111.442 115.553 111.496C116.84 111.952 118.189 112.211 119.554 112.263C119.523 112.097 119.492 111.933 119.464 111.772C118.179 111.71 116.909 111.46 115.697 111.03Z"
        fill="white"
      />
      <path
        d="M11.1953 119.887C11.1953 120.053 11.1953 120.217 11.1953 120.381C13.7813 120.747 16.4181 120.363 18.7916 119.276C18.9455 119.204 19.0968 119.13 19.2481 119.053L19.4328 118.961C21.3569 117.957 23.0135 116.51 24.265 114.739C25.5164 112.969 26.3269 110.927 26.629 108.781C26.4777 108.697 26.3238 108.615 26.1699 108.526C25.903 110.629 25.1302 112.636 23.9173 114.376C22.7044 116.117 21.0875 117.539 19.2045 118.521C19.1609 118.546 19.1122 118.57 19.066 118.593C18.9173 118.669 18.7634 118.743 18.6095 118.813C16.296 119.884 13.7189 120.258 11.1953 119.887Z"
        fill="white"
      />
      <path
        d="M11.5039 125.188C11.5347 125.374 11.5655 125.561 11.6039 125.743C14.8609 126.922 18.4454 126.823 21.6315 125.464C21.7879 125.395 21.9443 125.326 22.1008 125.249C22.2572 125.172 22.429 125.093 22.5906 125.006C25.1417 123.672 27.2061 121.569 28.4903 118.997C29.7744 116.425 30.2129 113.515 29.7433 110.68C29.5586 110.554 29.3714 110.424 29.1816 110.309C29.7467 113.104 29.3877 116.007 28.1587 118.582C26.9296 121.157 24.8967 123.264 22.3649 124.589C22.2008 124.673 22.0367 124.753 21.8725 124.832C21.7084 124.911 21.5597 124.975 21.4032 125.039C18.2486 126.372 14.6972 126.426 11.5039 125.188Z"
        fill="white"
      />
      <path
        d="M117.777 130.959C113.138 139.808 106.236 140.146 99.7376 136.751C93.2389 133.356 89.5793 127.516 94.2212 118.667C98.8631 109.818 116.533 104.706 116.533 104.706C116.533 104.706 119.354 113.097 119.544 121.189L111.278 126.265L119.485 124.065C119.321 126.597 118.81 128.989 117.777 130.959Z"
        fill="#F2F2F2"
      />
      <path
        d="M112.329 120.043C112.39 119.895 112.449 119.741 112.508 119.588C112.705 119.093 112.9 118.59 113.093 118.078C113.862 116.032 114.519 114.052 115.068 112.258C115.116 112.102 115.162 111.944 115.211 111.793C116.237 108.423 116.84 105.786 117.058 104.811C117.117 104.555 117.145 104.407 117.147 104.399L116.909 104.348L116.67 104.299C116.663 104.342 116.654 104.385 116.642 104.427C116.509 105.054 115.873 107.868 114.742 111.616C114.698 111.772 114.65 111.928 114.601 112.084C113.921 114.295 113.062 116.807 112.057 119.368L111.872 119.821C111.103 121.757 110.213 123.712 109.238 125.587C109.164 125.733 109.087 125.876 109.01 126.017C106.666 130.427 103.776 134.334 100.334 136.368L100.455 136.575L100.583 136.787L100.719 136.705C103.863 134.797 106.794 131.287 109.479 126.232L109.708 125.794C110.612 124.054 111.486 122.137 112.329 120.043Z"
        fill="white"
      />
      <path
        d="M115.214 111.798C115.058 111.741 114.902 111.685 114.743 111.621C114.315 111.451 113.896 111.258 113.488 111.045C111.75 110.142 110.237 108.861 109.062 107.295L108.605 107.497C110.134 109.558 112.211 111.149 114.601 112.089C114.758 112.151 114.914 112.209 115.071 112.263C116.358 112.72 117.707 112.978 119.072 113.031C119.041 112.864 119.01 112.701 118.982 112.539C117.696 112.478 116.427 112.228 115.214 111.798Z"
        fill="white"
      />
      <path
        d="M112.512 119.588C112.358 119.519 112.206 119.444 112.058 119.368L111.917 119.296C110.034 118.313 108.417 116.891 107.205 115.151C105.992 113.411 105.219 111.403 104.951 109.301L104.492 109.557C104.797 111.7 105.608 113.741 106.86 115.51C108.112 117.279 109.768 118.725 111.691 119.728C111.753 119.759 111.812 119.792 111.873 119.82C112.027 119.897 112.178 119.971 112.332 120.043C114.705 121.13 117.341 121.514 119.926 121.148V120.654C117.403 121.028 114.826 120.657 112.512 119.588Z"
        fill="white"
      />
      <path
        d="M109.707 125.794C109.548 125.73 109.392 125.658 109.238 125.587C109.084 125.515 108.907 125.428 108.745 125.344C106.217 124.02 104.186 121.915 102.957 119.344C101.727 116.773 101.366 113.874 101.926 111.081C101.739 111.204 101.549 111.337 101.364 111.452C100.895 114.287 101.334 117.198 102.619 119.77C103.904 122.342 105.968 124.445 108.52 125.779C108.681 125.866 108.845 125.948 109.009 126.022C109.174 126.096 109.322 126.168 109.479 126.237C112.665 127.595 116.249 127.695 119.506 126.516C119.545 126.334 119.576 126.147 119.606 125.96C116.411 127.193 112.859 127.133 109.707 125.794Z"
        fill="white"
      />
      <path
        d="M79.9591 134.367C77.1945 139.635 73.0911 139.837 69.2186 137.813C65.3461 135.789 63.1713 132.315 65.9334 127.048C68.6954 121.78 79.223 118.736 79.223 118.736C79.223 118.736 80.9105 123.732 81.0183 128.549L76.0917 131.571L80.9823 130.261C80.8772 131.768 80.5746 133.193 79.9591 134.367Z"
        fill="#F2F2F2"
      />
      <path
        d="M76.714 127.866C76.7525 127.779 76.7884 127.687 76.8243 127.595C76.9423 127.304 77.0577 127.002 77.1705 126.697C77.6296 125.482 78.0194 124.3 78.3477 123.233L78.4323 122.954C79.035 120.949 79.4017 119.373 79.5299 118.8C79.5658 118.644 79.5838 118.559 79.5838 118.544L79.4427 118.513L79.3017 118.485C79.3017 118.485 79.3017 118.516 79.2863 118.562C79.2042 118.933 78.8298 120.608 78.1553 122.839L78.0707 123.118C77.6655 124.436 77.1603 125.932 76.555 127.455C76.5191 127.544 76.4832 127.636 76.4447 127.726C75.9805 128.877 75.4574 130.041 74.8778 131.156C74.8342 131.243 74.788 131.33 74.7419 131.412C73.3467 134.055 71.6233 136.375 69.5742 137.585L69.646 137.708L69.7204 137.836L69.8025 137.785C71.6746 136.652 73.4185 134.561 75.0188 131.55L75.1548 131.295C75.6933 130.256 76.2131 129.113 76.714 127.866Z"
        fill="white"
      />
      <path
        d="M78.4321 122.954C78.3398 122.921 78.2449 122.888 78.1526 122.849C77.8978 122.75 77.6487 122.636 77.4063 122.509C76.3707 121.97 75.4697 121.206 74.7699 120.273L74.498 120.393C75.2282 121.385 76.1766 122.196 77.2704 122.765C77.5296 122.9 77.7958 123.021 78.068 123.128C78.1603 123.167 78.2526 123.2 78.3475 123.233C79.1133 123.503 79.9158 123.655 80.7274 123.683L80.6736 123.392C79.9089 123.357 79.1536 123.209 78.4321 122.954Z"
        fill="white"
      />
      <path
        d="M76.8247 127.595C76.7324 127.554 76.6426 127.511 76.5529 127.465L76.4682 127.424C75.3465 126.839 74.3834 125.992 73.6613 124.955C72.9391 123.919 72.4793 122.723 72.3213 121.47L72.0469 121.621C72.2277 122.898 72.7108 124.113 73.456 125.167C74.2012 126.22 75.1872 127.082 76.3323 127.679L76.44 127.736L76.7119 127.866C78.1248 128.516 79.6958 128.744 81.2358 128.524C81.2358 128.426 81.2358 128.329 81.2358 128.232C79.7346 128.453 78.2013 128.232 76.8247 127.595Z"
        fill="white"
      />
      <path
        d="M75.1554 131.292C75.0605 131.253 74.9682 131.21 74.8759 131.166L74.581 131.023C73.0785 130.235 71.8718 128.983 71.141 127.455C70.4103 125.926 70.1946 124.203 70.5264 122.542L70.1904 122.762C69.9118 124.45 70.1735 126.182 70.9382 127.713C71.7029 129.244 72.9318 130.495 74.4502 131.289C74.5476 131.34 74.6425 131.389 74.7425 131.433C74.8426 131.476 74.9272 131.522 75.0221 131.56C76.9177 132.371 79.0518 132.431 80.9899 131.727C81.0129 131.619 81.0309 131.509 81.0514 131.397C79.1473 132.129 77.0321 132.091 75.1554 131.292Z"
        fill="white"
      />
      <path
        d="M4.48797 92.5439H2.94922V93.8231H4.48797V92.5439Z"
        fill="#F0F0F0"
      />
      <path
        d="M150.028 93.8231H147.005V92.5439H150.028V93.8231ZM143.963 93.8231H140.94V92.5439H143.971L143.963 93.8231ZM137.901 93.8231H134.874V92.5439H137.908L137.901 93.8231ZM131.835 93.8231H128.809V92.5439H131.843L131.835 93.8231ZM125.77 93.8231H122.746V92.5439H125.778L125.77 93.8231ZM119.705 93.8231H116.689V92.5439H119.72L119.705 93.8231ZM113.642 93.8231H110.616V92.5439H113.65L113.642 93.8231ZM107.577 93.8231H104.553V92.5439H107.585L107.577 93.8231ZM101.512 93.8231H98.4803V92.5439H101.512V93.8231ZM95.4489 93.8231H92.4227V92.5439H95.4566L95.4489 93.8231ZM89.3837 93.8231H86.3601V92.5439H89.3914L89.3837 93.8231ZM83.3185 93.8231H80.2948V92.5439H83.3261L83.3185 93.8231ZM77.2558 93.8231H74.2296V92.5439H77.2635L77.2558 93.8231ZM71.1905 93.8231H68.1643V92.5439H71.1982L71.1905 93.8231ZM65.1253 93.8231H62.1016V92.5439H65.1407L65.1253 93.8231ZM59.06 93.8231H56.0364V92.5439H59.0677L59.06 93.8231ZM52.9973 93.8231H49.9711V92.5439H53.005L52.9973 93.8231ZM46.9321 93.8231H43.9084V92.5439H46.9321V93.8231ZM40.8669 93.8231H37.8432V92.5439H40.8745L40.8669 93.8231ZM34.8042 93.8231H31.778V92.5439H34.8119L34.8042 93.8231ZM28.7389 93.8231H25.7153V92.5439H28.7466L28.7389 93.8231ZM22.6737 93.8231H19.65V92.5439H22.6814L22.6737 93.8231ZM16.6084 93.8231H13.5925V92.5439H16.6238L16.6084 93.8231ZM10.5457 93.8231H7.51953V92.5439H10.5534L10.5457 93.8231Z"
        fill="#F0F0F0"
      />
      <path
        d="M4.48797 60.0535H2.94922V61.3326H4.48797V60.0535Z"
        fill="#F0F0F0"
      />
      <path
        d="M150.028 61.3326H147.005V60.0535H150.028V61.3326ZM143.963 61.3326H140.94V60.0535H143.971L143.963 61.3326ZM137.901 61.3326H134.874V60.0535H137.908L137.901 61.3326ZM131.835 61.3326H128.809V60.0535H131.843L131.835 61.3326ZM125.77 61.3326H122.746V60.0535H125.778L125.77 61.3326ZM119.705 61.3326H116.689V60.0535H119.72L119.705 61.3326ZM113.642 61.3326H110.616V60.0535H113.65L113.642 61.3326ZM107.577 61.3326H104.553V60.0535H107.585L107.577 61.3326ZM101.512 61.3326H98.4803V60.0535H101.512V61.3326ZM95.4489 61.3326H92.4227V60.0535H95.4566L95.4489 61.3326ZM89.3837 61.3326H86.3601V60.0535H89.3914L89.3837 61.3326ZM83.3185 61.3326H80.2948V60.0535H83.3261L83.3185 61.3326ZM77.2558 61.3326H74.2296V60.0535H77.2635L77.2558 61.3326ZM71.1905 61.3326H68.1643V60.0535H71.1982L71.1905 61.3326ZM65.1253 61.3326H62.1016V60.0535H65.1407L65.1253 61.3326ZM59.06 61.3326H56.0364V60.0535H59.0677L59.06 61.3326ZM52.9973 61.3326H49.9711V60.0535H53.005L52.9973 61.3326ZM46.9321 61.3326H43.9084V60.0535H46.9321V61.3326ZM40.8669 61.3326H37.8432V60.0535H40.8745L40.8669 61.3326ZM34.8042 61.3326H31.778V60.0535H34.8119L34.8042 61.3326ZM28.7389 61.3326H25.7153V60.0535H28.7466L28.7389 61.3326ZM22.6737 61.3326H19.65V60.0535H22.6814L22.6737 61.3326ZM16.6084 61.3326H13.5925V60.0535H16.6238L16.6084 61.3326ZM10.5457 61.3326H7.51953V60.0535H10.5534L10.5457 61.3326Z"
        fill="#F0F0F0"
      />
      <path
        d="M4.48797 27.563H2.94922V28.8421H4.48797V27.563Z"
        fill="#F0F0F0"
      />
      <path
        d="M150.028 28.8421H147.005V27.563H150.028V28.8421ZM143.963 28.8421H140.94V27.563H143.971L143.963 28.8421ZM137.901 28.8421H134.874V27.563H137.908L137.901 28.8421ZM131.835 28.8421H128.809V27.563H131.843L131.835 28.8421ZM125.77 28.8421H122.746V27.563H125.778L125.77 28.8421ZM119.705 28.8421H116.689V27.563H119.72L119.705 28.8421ZM113.642 28.8421H110.616V27.563H113.65L113.642 28.8421ZM107.577 28.8421H104.553V27.563H107.585L107.577 28.8421ZM101.512 28.8421H98.4803V27.563H101.512V28.8421ZM95.4489 28.8421H92.4227V27.563H95.4566L95.4489 28.8421ZM89.3837 28.8421H86.3601V27.563H89.3914L89.3837 28.8421ZM83.3185 28.8421H80.2948V27.563H83.3261L83.3185 28.8421ZM77.2558 28.8421H74.2296V27.563H77.2635L77.2558 28.8421ZM71.1905 28.8421H68.1643V27.563H71.1982L71.1905 28.8421ZM65.1253 28.8421H62.1016V27.563H65.1407L65.1253 28.8421ZM59.06 28.8421H56.0364V27.563H59.0677L59.06 28.8421ZM52.9973 28.8421H49.9711V27.563H53.005L52.9973 28.8421ZM46.9321 28.8421H43.9084V27.563H46.9321V28.8421ZM40.8669 28.8421H37.8432V27.563H40.8745L40.8669 28.8421ZM34.8042 28.8421H31.778V27.563H34.8119L34.8042 28.8421ZM28.7389 28.8421H25.7153V27.563H28.7466L28.7389 28.8421ZM22.6737 28.8421H19.65V27.563H22.6814L22.6737 28.8421ZM16.6084 28.8421H13.5925V27.563H16.6238L16.6084 28.8421ZM10.5457 28.8421H7.51953V27.563H10.5534L10.5457 28.8421Z"
        fill="#F0F0F0"
      />
      <path
        d="M101.332 92.9275H88.7656V138.977H101.332V92.9275Z"
        fill="#3F3D56"
      />
      <path
        d="M99.0235 92.9275H86.457V138.977H99.0235V92.9275Z"
        fill="#D4E0DF"
      />
      <path
        d="M71.5821 60.4373H59.0156V138.977H71.5821V60.4373Z"
        fill="#3F3D56"
      />
      <path
        d="M69.2755 60.4373H56.709V138.977H69.2755V60.4373Z"
        fill="#D4E0DF"
      />
      <path
        d="M41.8341 27.9468H29.2676V138.977H41.8341V27.9468Z"
        fill="#3F3D56"
      />
      <path
        d="M39.5255 27.9468H26.959V138.977H39.5255V27.9468Z"
        fill="#D4E0DF"
      />
      <path
        d="M3.0775 31.2727C4.77716 31.2727 6.15501 29.8983 6.15501 28.2028C6.15501 26.5073 4.77716 25.1328 3.0775 25.1328C1.37785 25.1328 0 26.5073 0 28.2028C0 29.8983 1.37785 31.2727 3.0775 31.2727Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.0775 63.763C4.77716 63.763 6.15501 62.3885 6.15501 60.693C6.15501 58.9975 4.77716 57.623 3.0775 57.623C1.37785 57.623 0 58.9975 0 60.693C0 62.3885 1.37785 63.763 3.0775 63.763Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.0775 96.2534C4.77716 96.2534 6.15501 94.879 6.15501 93.1835C6.15501 91.488 4.77716 90.1135 3.0775 90.1135C1.37785 90.1135 0 91.488 0 93.1835C0 94.879 1.37785 96.2534 3.0775 96.2534Z"
        fill="#F0F0F0"
      />
      <path
        d="M122.648 85.9743H122.625C118.737 85.3347 114.418 85.3347 109.418 85.9743C109.293 85.9763 109.17 85.9538 109.054 85.908C108.939 85.8622 108.834 85.794 108.745 85.7074C108.656 85.6209 108.585 85.5176 108.536 85.4035C108.488 85.2895 108.462 85.167 108.461 85.043V80.5174C108.461 80.2684 108.56 80.0296 108.737 79.8535C108.913 79.6774 109.153 79.5785 109.402 79.5785C113.805 79.0054 118.264 79.0054 122.666 79.5785C122.913 79.5818 123.149 79.6823 123.323 79.8581C123.496 80.0339 123.593 80.2708 123.592 80.5174V85.0328C123.591 85.2823 123.492 85.5214 123.315 85.6978C123.138 85.8742 122.898 85.9736 122.648 85.9743Z"
        fill="white"
      />
      <path
        d="M122.648 85.9743H122.625C118.737 85.3347 114.418 85.3347 109.418 85.9743C109.293 85.9763 109.17 85.9538 109.054 85.908C108.939 85.8622 108.834 85.794 108.745 85.7074C108.656 85.6209 108.585 85.5176 108.536 85.4035C108.488 85.2895 108.462 85.167 108.461 85.043V80.5174C108.461 80.2684 108.56 80.0296 108.737 79.8535C108.913 79.6774 109.153 79.5785 109.402 79.5785C113.805 79.0054 118.264 79.0054 122.666 79.5785C122.913 79.5818 123.149 79.6823 123.323 79.8581C123.496 80.0339 123.593 80.2708 123.592 80.5174V85.0328C123.591 85.2823 123.492 85.5214 123.315 85.6978C123.138 85.8742 122.898 85.9736 122.648 85.9743ZM115.855 79.4045C113.702 79.401 111.551 79.5437 109.418 79.8317C109.327 79.8304 109.236 79.8469 109.152 79.8804C109.067 79.9139 108.99 79.9636 108.925 80.0268C108.86 80.09 108.807 80.1654 108.771 80.2487C108.735 80.332 108.716 80.4215 108.715 80.5123V85.0328C108.715 85.2142 108.787 85.3882 108.915 85.5167C109.044 85.6452 109.218 85.7177 109.4 85.7184C114.403 85.0737 118.745 85.0737 122.659 85.7184C122.839 85.7151 123.01 85.6414 123.136 85.5131C123.262 85.3849 123.333 85.2124 123.333 85.0328V80.5174C123.333 80.3362 123.261 80.1625 123.132 80.0344C123.004 79.9063 122.83 79.8343 122.648 79.8343C120.395 79.5517 118.126 79.4082 115.855 79.4045Z"
        fill="#D4E0DF"
      />
      <path
        d="M116.024 84.3497C117.015 84.3497 117.819 83.5479 117.819 82.5589C117.819 81.5698 117.015 80.7681 116.024 80.7681C115.032 80.7681 114.229 81.5698 114.229 82.5589C114.229 83.5479 115.032 84.3497 116.024 84.3497Z"
        fill="#D4E0DF"
      />
      <path
        d="M111.408 81.6251H109.869C109.767 81.6251 109.669 81.5847 109.597 81.5127C109.525 81.4407 109.484 81.3431 109.484 81.2413V80.7297C109.484 80.6279 109.525 80.5303 109.597 80.4583C109.669 80.3864 109.767 80.3459 109.869 80.3459H111.408C111.51 80.3459 111.608 80.3864 111.68 80.4583C111.752 80.5303 111.793 80.6279 111.793 80.7297V81.2413C111.793 81.3431 111.752 81.4407 111.68 81.5127C111.608 81.5847 111.51 81.6251 111.408 81.6251Z"
        fill="#D4E0DF"
      />
      <path
        d="M122.179 85.2066H120.641C120.539 85.2066 120.441 85.1662 120.369 85.0942C120.296 85.0223 120.256 84.9247 120.256 84.8229V84.3112C120.256 84.2095 120.296 84.1118 120.369 84.0399C120.441 83.9679 120.539 83.9275 120.641 83.9275H122.179C122.281 83.9275 122.379 83.9679 122.451 84.0399C122.523 84.1118 122.564 84.2095 122.564 84.3112V84.8229C122.564 84.9247 122.523 85.0223 122.451 85.0942C122.379 85.1662 122.281 85.2066 122.179 85.2066Z"
        fill="#D4E0DF"
      />
      <path
        d="M120.459 84.1066H120.441C117.107 82.0062 113.127 80.3305 108.269 78.99C108.038 78.8943 107.854 78.711 107.758 78.4804C107.662 78.2498 107.661 77.9905 107.756 77.7594L109.508 73.5741C109.556 73.4605 109.626 73.3575 109.714 73.271C109.802 73.1845 109.907 73.1161 110.021 73.0698C110.136 73.0234 110.258 73.0001 110.382 73.001C110.506 73.0019 110.628 73.0271 110.742 73.0752C115.021 74.2547 119.129 75.9841 122.962 78.2199C123.188 78.3194 123.366 78.5037 123.457 78.733C123.549 78.9623 123.546 79.2182 123.449 79.4453L121.687 83.6051C121.59 83.8332 121.406 84.0138 121.176 84.1077C120.946 84.2015 120.689 84.2011 120.459 84.1066Z"
        fill="white"
      />
      <path
        d="M120.459 84.1066H120.441C117.107 82.0062 113.127 80.3305 108.269 78.99C108.038 78.8943 107.854 78.711 107.758 78.4804C107.662 78.2498 107.661 77.9905 107.756 77.7594L109.508 73.5741C109.556 73.4605 109.626 73.3575 109.714 73.271C109.802 73.1845 109.907 73.1161 110.021 73.0698C110.136 73.0234 110.258 73.0001 110.382 73.001C110.506 73.0019 110.628 73.0271 110.742 73.0752C115.021 74.2547 119.129 75.9841 122.962 78.2199C123.188 78.3194 123.366 78.5037 123.457 78.733C123.549 78.9623 123.546 79.2182 123.449 79.4453L121.687 83.6051C121.59 83.8332 121.406 84.0138 121.176 84.1077C120.946 84.2015 120.689 84.2011 120.459 84.1066ZM116.763 75.4084C114.781 74.5702 112.743 73.8676 110.665 73.3054C110.496 73.2374 110.306 73.2385 110.138 73.3083C109.97 73.3782 109.835 73.5113 109.765 73.6789L108.005 77.8387C107.934 78.006 107.933 78.1945 108.001 78.3628C108.069 78.5312 108.202 78.6656 108.369 78.7367C113.242 80.0849 117.23 81.7683 120.582 83.8789C120.749 83.9468 120.936 83.9462 121.103 83.8772C121.269 83.8082 121.402 83.6764 121.472 83.5105L123.234 79.3507C123.304 79.183 123.305 78.9945 123.236 78.8263C123.167 78.6581 123.035 78.5238 122.867 78.4527C120.902 77.3196 118.868 76.308 116.779 75.4237L116.763 75.4084Z"
        fill="#D4E0DF"
      />
      <path
        d="M115.686 80.1822C116.677 80.1822 117.481 79.3804 117.481 78.3914C117.481 77.4024 116.677 76.6006 115.686 76.6006C114.694 76.6006 113.891 77.4024 113.891 78.3914C113.891 79.3804 114.694 80.1822 115.686 80.1822Z"
        fill="#D4E0DF"
      />
      <path
        d="M111.798 75.7383L110.382 75.1422C110.335 75.1224 110.292 75.0934 110.256 75.0569C110.22 75.0203 110.191 74.9769 110.172 74.9293C110.153 74.8817 110.144 74.8307 110.145 74.7794C110.145 74.7282 110.157 74.6775 110.177 74.6306L110.375 74.1599C110.395 74.1125 110.424 74.0696 110.46 74.0336C110.497 73.9976 110.54 73.9693 110.588 73.9502C110.636 73.9312 110.687 73.9218 110.738 73.9227C110.79 73.9235 110.84 73.9346 110.888 73.9552L112.306 74.5513C112.353 74.5711 112.396 74.6001 112.432 74.6366C112.468 74.6732 112.497 74.7166 112.516 74.7642C112.535 74.8118 112.544 74.8628 112.544 74.9141C112.543 74.9653 112.532 75.016 112.511 75.0629L112.311 75.5337C112.291 75.581 112.262 75.6239 112.225 75.6599C112.189 75.6959 112.145 75.7242 112.097 75.7433C112.05 75.7623 111.999 75.7717 111.947 75.7708C111.896 75.77 111.845 75.7589 111.798 75.7383Z"
        fill="#D4E0DF"
      />
      <path
        d="M120.326 83.2162L118.911 82.6329C118.863 82.6134 118.82 82.5846 118.783 82.5481C118.747 82.5116 118.719 82.4682 118.699 82.4204C118.68 82.3727 118.671 82.3217 118.672 82.2703C118.673 82.2189 118.684 82.1682 118.705 82.1213L118.903 81.6506C118.923 81.6032 118.952 81.5603 118.988 81.5243C119.025 81.4884 119.069 81.46 119.116 81.441C119.164 81.4219 119.215 81.4125 119.267 81.4134C119.318 81.4142 119.369 81.4253 119.416 81.4459L120.834 82.042C120.881 82.0618 120.924 82.0908 120.961 82.1274C120.997 82.1639 121.025 82.2073 121.044 82.2549C121.063 82.3025 121.073 82.3535 121.072 82.4048C121.071 82.4561 121.06 82.5067 121.039 82.5536L120.839 83.0244C120.818 83.0708 120.789 83.1126 120.751 83.1475C120.714 83.1823 120.671 83.2094 120.623 83.2273C120.575 83.2452 120.524 83.2534 120.473 83.2515C120.423 83.2496 120.372 83.2376 120.326 83.2162Z"
        fill="#D4E0DF"
      />
      <path
        d="M123.989 81.6327C124.281 81.4598 124.535 81.231 124.737 80.9594C124.94 80.6878 125.086 80.3789 125.168 80.0506C125.25 79.7223 125.265 79.381 125.214 79.0466C125.163 78.7121 125.045 78.3911 124.869 78.1022C124.795 77.9724 124.709 77.8499 124.613 77.7364L129.154 69.8057L124.382 70.0973L120.791 77.5906C120.343 78.0381 120.075 78.6339 120.038 79.2653C120 79.8966 120.196 80.5198 120.588 81.017C120.98 81.5141 121.541 81.8508 122.165 81.9633C122.789 82.0758 123.432 81.9564 123.974 81.6276L123.989 81.6327Z"
        fill="#FFB6B6"
      />
      <path
        d="M158.952 135.764H155.808L154.311 123.668H158.952V135.764Z"
        fill="#FFB6B6"
      />
      <path
        d="M159.753 138.806H149.615V138.678C149.615 137.633 150.031 136.632 150.771 135.894C151.511 135.155 152.515 134.74 153.562 134.74H159.753V138.806Z"
        fill="#2F2E41"
      />
      <path
        d="M137.411 135.764H134.267L132.77 123.668H137.411V135.764Z"
        fill="#FFB6B6"
      />
      <path
        d="M138.21 138.806H128.072V138.678C128.072 137.633 128.488 136.632 129.228 135.894C129.968 135.155 130.972 134.74 132.019 134.74H138.21V138.806Z"
        fill="#2F2E41"
      />
      <path
        d="M129.325 70.1357C129.325 70.1357 128.461 82.4156 130.163 88.6092C131.866 94.8028 132.171 132.213 132.171 132.213H137.88L142.827 81.2797L154.237 132.392L159.674 132.018L156.697 71.791L129.325 70.1357Z"
        fill="#2F2E41"
      />
      <path
        d="M154.333 28.7245L149.973 25.5471L142.661 27.1358L140.681 31.8277L137.265 33.5137C136.143 34.0687 135.173 34.8871 134.438 35.8987C133.704 36.9102 133.226 38.0846 133.047 39.321L128.174 72.891L156.692 71.7909L160.144 32.255C160.18 31.8498 160.135 31.4415 160.013 31.0534C159.891 30.6654 159.693 30.3052 159.431 29.9935C159.169 29.6817 158.848 29.4246 158.486 29.2367C158.124 29.0488 157.729 28.9339 157.323 28.8985H157.233L154.333 28.7245Z"
        fill="#E4E4E4"
      />
      <path
        d="M135.459 36.136L132.826 39.6792L121.352 74.5846L126.73 76.7336L135.341 60.468L135.459 36.136Z"
        fill="#E4E4E4"
      />
      <path
        d="M144.888 23.4955C149.709 23.4955 153.618 19.5966 153.618 14.7871C153.618 9.97752 149.709 6.07861 144.888 6.07861C140.067 6.07861 136.158 9.97752 136.158 14.7871C136.158 19.5966 140.067 23.4955 144.888 23.4955Z"
        fill="#2F2E41"
      />
      <path
        d="M145.007 23.8869C148.525 23.8869 151.378 21.0417 151.378 17.5321C151.378 14.0224 148.525 11.1772 145.007 11.1772C141.489 11.1772 138.637 14.0224 138.637 17.5321C138.637 21.0417 141.489 23.8869 145.007 23.8869Z"
        fill="#FFB9B9"
      />
      <path
        d="M149.844 16.7082C145.787 15.25 144.415 14.3546 139.935 16.7082L138.283 10.1206H151.496L149.844 16.7082Z"
        fill="#2F2E41"
      />
      <path
        d="M144.181 11.062C146.918 11.062 149.136 8.84913 149.136 6.11939C149.136 3.38965 146.918 1.17676 144.181 1.17676C141.445 1.17676 139.227 3.38965 139.227 6.11939C139.227 8.84913 141.445 11.062 144.181 11.062Z"
        fill="#2F2E41"
      />
      <path
        d="M138.282 4.94254C138.283 3.67329 138.774 2.45301 139.652 1.53503C140.531 0.617053 141.73 0.071883 143.001 0.0126953C142.922 0.0126953 142.845 0.0126953 142.765 0.0126953C142.115 0.0126953 141.47 0.14054 140.869 0.388931C140.268 0.637321 139.722 1.00139 139.262 1.46036C138.802 1.91932 138.437 2.4642 138.188 3.06386C137.939 3.66353 137.811 4.30625 137.811 4.95533C137.811 5.6044 137.939 6.24712 138.188 6.84679C138.437 7.44646 138.802 7.99133 139.262 8.4503C139.722 8.90926 140.268 9.27333 140.869 9.52172C141.47 9.77011 142.115 9.89796 142.765 9.89796C142.845 9.89796 142.922 9.89796 143.001 9.89796C141.726 9.83796 140.523 9.28902 139.644 8.3657C138.765 7.44238 138.277 6.21602 138.282 4.94254Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.1"
        d="M156.464 38.1467L154.686 55.822L148.408 68.1607L156.464 38.1467Z"
        fill="black"
      />
      <path
        d="M149.064 80.3458H149.041C145.153 79.7063 140.835 79.7063 135.834 80.3458C135.709 80.3479 135.586 80.3253 135.47 80.2795C135.355 80.2337 135.25 80.1656 135.161 80.079C135.072 79.9924 135.001 79.8892 134.952 79.7751C134.904 79.6611 134.878 79.5386 134.877 79.4146V74.889C134.877 74.64 134.976 74.4011 135.153 74.2251C135.329 74.049 135.569 73.9501 135.818 73.9501C140.221 73.377 144.68 73.377 149.082 73.9501C149.329 73.9534 149.565 74.0539 149.739 74.2297C149.912 74.4055 150.009 74.6424 150.008 74.889V79.4044C150.007 79.6539 149.908 79.8929 149.731 80.0693C149.554 80.2458 149.314 80.3452 149.064 80.3458Z"
        fill="white"
      />
      <path
        d="M149.064 80.3458H149.041C145.153 79.7063 140.835 79.7063 135.834 80.3458C135.709 80.3479 135.586 80.3253 135.47 80.2795C135.355 80.2337 135.25 80.1656 135.161 80.079C135.072 79.9924 135.001 79.8892 134.952 79.7751C134.904 79.6611 134.878 79.5386 134.877 79.4146V74.889C134.877 74.64 134.976 74.4011 135.153 74.2251C135.329 74.049 135.569 73.9501 135.818 73.9501C140.221 73.377 144.68 73.377 149.082 73.9501C149.329 73.9534 149.565 74.0539 149.739 74.2297C149.912 74.4055 150.009 74.6424 150.008 74.889V79.4044C150.007 79.6539 149.908 79.8929 149.731 80.0693C149.554 80.2458 149.314 80.3452 149.064 80.3458ZM142.271 73.7761C140.118 73.7726 137.967 73.9153 135.834 74.2033C135.743 74.202 135.652 74.2185 135.568 74.252C135.483 74.2854 135.406 74.3352 135.341 74.3984C135.276 74.4616 135.224 74.537 135.187 74.6203C135.151 74.7036 135.132 74.7931 135.131 74.8838V79.4044C135.131 79.5858 135.203 79.7598 135.331 79.8883C135.46 80.0168 135.634 80.0893 135.816 80.09C140.819 79.4453 145.161 79.4453 149.075 80.09C149.255 80.0867 149.426 80.0129 149.552 79.8847C149.678 79.7565 149.749 79.584 149.749 79.4044V74.889C149.749 74.7078 149.677 74.5341 149.548 74.406C149.42 74.2779 149.246 74.2059 149.064 74.2059C146.811 73.9233 144.542 73.7798 142.271 73.7761Z"
        fill="#D4E0DF"
      />
      <path
        d="M142.44 78.7213C143.431 78.7213 144.235 77.9195 144.235 76.9305C144.235 75.9414 143.431 75.1396 142.44 75.1396C141.448 75.1396 140.645 75.9414 140.645 76.9305C140.645 77.9195 141.448 78.7213 142.44 78.7213Z"
        fill="#D4E0DF"
      />
      <path
        d="M137.824 75.9969H136.285C136.183 75.9969 136.085 75.9565 136.013 75.8845C135.941 75.8126 135.9 75.7149 135.9 75.6132V75.1015C135.9 74.9997 135.941 74.9021 136.013 74.8302C136.085 74.7582 136.183 74.7178 136.285 74.7178H137.824C137.926 74.7178 138.024 74.7582 138.096 74.8302C138.168 74.9021 138.209 74.9997 138.209 75.1015V75.6132C138.209 75.7149 138.168 75.8126 138.096 75.8845C138.024 75.9565 137.926 75.9969 137.824 75.9969Z"
        fill="#D4E0DF"
      />
      <path
        d="M148.595 79.5785H147.057C146.955 79.5785 146.857 79.538 146.785 79.4661C146.712 79.3941 146.672 79.2965 146.672 79.1947V78.6831C146.672 78.5813 146.712 78.4837 146.785 78.4117C146.857 78.3397 146.955 78.2993 147.057 78.2993H148.595C148.697 78.2993 148.795 78.3397 148.867 78.4117C148.939 78.4837 148.98 78.5813 148.98 78.6831V79.1947C148.98 79.2965 148.939 79.3941 148.867 79.4661C148.795 79.538 148.697 79.5785 148.595 79.5785Z"
        fill="#D4E0DF"
      />
      <path
        d="M146.182 74.4259C146.135 73.7452 146.36 73.0736 146.809 72.5585C147.257 72.0434 147.892 71.7268 148.575 71.6783C148.726 71.6655 148.878 71.6655 149.029 71.6783L152.937 63.4099L155.414 67.475L151.311 74.7022C151.192 75.3154 150.854 75.8648 150.36 76.2489C149.866 76.633 149.25 76.8259 148.625 76.7919C148 76.7579 147.408 76.4994 146.959 76.064C146.51 75.6286 146.234 75.0459 146.182 74.4234V74.4259Z"
        fill="#FFB6B6"
      />
      <path
        d="M156.804 31.1702L158.414 29.0775C158.414 29.0775 162.335 28.2793 163 33.6518L162.594 57.5386L154 71.0285L148.42 69.6086L155.716 53.5809L156.804 31.1702Z"
        fill="#E4E4E4"
      />
    </g>
    <defs>
      <clipPath id="clip0_4764_25199">
        <rect width="163" height="139" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LanternGreyFemale;
