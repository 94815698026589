import { Models } from '@rematch/core';
import { userModel } from './user';
import { modalsModel } from './modals';
import { alertsModel } from './alerts';
import { statementModel } from './statements';
import { contactModel } from './contact';
import { taxOfficeModel } from './taxOffice';
import { productModel } from './product';
import { agreementsModel } from './agreements';
import { notificationsModel } from './notifications';
import { paymentModel } from './payment';
import { applicationModel } from './application';
import { documentsModel } from './documents';
import { pwaModel } from './pwa';
import { enteredEmailsModel } from './enteredEmails';
import { processStepModel } from './processStep';
import { liveChatModel } from './liveChat';

export interface RootModel extends Models<RootModel> {
  user: typeof userModel;
  modals: typeof modalsModel;
  alerts: typeof alertsModel;
  contact: typeof contactModel;
  statements: typeof statementModel;
  product: typeof productModel;
  taxOffice: typeof taxOfficeModel;
  agreements: typeof agreementsModel;
  notifications: typeof notificationsModel;
  payment: typeof paymentModel;
  application: typeof applicationModel;
  documents: typeof documentsModel;
  pwa: typeof pwaModel;
  enteredEmails: typeof enteredEmailsModel;
  processStep: typeof processStepModel;
  liveChat: typeof liveChatModel;
}

export const models: RootModel = {
  user: userModel,
  modals: modalsModel,
  alerts: alertsModel,
  contact: contactModel,
  statements: statementModel,
  product: productModel,
  taxOffice: taxOfficeModel,
  agreements: agreementsModel,
  notifications: notificationsModel,
  payment: paymentModel,
  application: applicationModel,
  documents: documentsModel,
  pwa: pwaModel,
  enteredEmails: enteredEmailsModel,
  processStep: processStepModel,
  liveChat: liveChatModel
};
