import { SvgIcon } from '@mui/material';

const ChevronDown = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.759051 2.20453L8.1313 9.83276L15.5036 2.20453C15.7038 1.99661 15.8133 1.71766 15.8079 1.42902C15.8025 1.14039 15.6827 0.865721 15.4748 0.665444C15.2668 0.465168 14.9879 0.355687 14.6993 0.361088C14.4106 0.366488 14.136 0.486328 13.9357 0.694242L8.1313 6.7034L2.32054 0.694242C2.12026 0.486328 1.84559 0.366488 1.55696 0.361087C1.26832 0.355687 0.989363 0.465167 0.781449 0.665444C0.573534 0.86572 0.453696 1.14039 0.448295 1.42902C0.442894 1.71766 0.552374 1.99661 0.752652 2.20453L0.759051 2.20453Z" />
  </SvgIcon>
);

export default ChevronDown;
