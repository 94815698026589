const AccountActivation = ({ ...props }) => (
  <svg
    {...props}
    viewBox="0 0 443 317"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M429.907 92.8913H187.486C184.074 92.8954 180.803 94.2519 178.391 96.6632C175.978 99.0745 174.621 102.344 174.617 105.754V145.02C174.619 148.431 175.975 151.702 178.388 154.113C180.801 156.525 184.074 157.881 187.486 157.883H429.907C433.319 157.879 436.59 156.522 439.002 154.111C441.415 151.7 442.772 148.43 442.776 145.02V105.754C442.77 102.344 441.412 99.0763 439 96.6654C436.588 94.2546 433.318 92.8974 429.907 92.8913Z"
      fill="#F2F2F2"
    />
    <path
      d="M97.2151 317H0.771549C0.566921 317 0.370675 316.919 0.225981 316.774C0.0812879 316.63 0 316.433 0 316.229C0 316.024 0.0812879 315.828 0.225981 315.684C0.370675 315.539 0.566921 315.458 0.771549 315.458H97.2151C97.4197 315.458 97.616 315.539 97.7607 315.684C97.9054 315.828 97.9867 316.024 97.9867 316.229C97.9867 316.433 97.9054 316.63 97.7607 316.774C97.616 316.919 97.4197 317 97.2151 317Z"
      fill="#3F3D56"
    />
    <path
      d="M55.0251 307.676L63.2397 307.669L67.1494 275.976H55.0251V307.676Z"
      fill="#FFB8B8"
    />
    <path
      d="M79.2736 316.068H53.1599V305.812H69.1857C70.5107 305.811 71.823 306.075 73.0473 306.59C74.2717 307.106 75.3841 307.861 76.3211 308.813C77.258 309.766 78.001 310.897 78.5077 312.142C79.0143 313.386 79.2746 314.721 79.2736 316.068Z"
      fill="#2F2E41"
    />
    <path
      d="M29.8514 307.676L38.0611 307.669L41.9684 275.976H29.8442L29.8514 307.676Z"
      fill="#FFB8B8"
    />
    <path
      d="M54.0782 316.068H27.979V305.812H44.0031C45.3278 305.811 46.6396 306.075 47.8637 306.59C49.0878 307.105 50.2001 307.861 51.1371 308.813C52.0741 309.765 52.8174 310.896 53.3245 312.141C53.8317 313.386 54.0927 314.72 54.0927 316.068H54.0782Z"
      fill="#2F2E41"
    />
    <path
      d="M54.0926 83.9118C63.879 83.9118 71.8126 75.9806 71.8126 66.1971C71.8126 56.4135 63.879 48.4823 54.0926 48.4823C44.3061 48.4823 36.3726 56.4135 36.3726 66.1971C36.3726 75.9806 44.3061 83.9118 54.0926 83.9118Z"
      fill="#FFB8B8"
    />
    <path
      d="M62.7905 113.621C62.6438 109.041 61.3476 104.571 59.0198 100.618C56.692 96.6639 53.4066 93.3527 49.4631 90.9854C44.8336 88.2729 39.7739 87.214 35.7421 91.2175C33.3237 93.6862 31.3879 96.5809 30.0335 99.7538C26.303 108.393 26.0576 118.129 29.3482 126.944L38.9354 153.293L61.1282 155.686C61.5093 155.727 61.8949 155.693 62.2628 155.586C62.6307 155.479 62.9738 155.301 63.2724 155.062C63.571 154.823 63.8193 154.527 64.0031 154.193C64.1869 153.858 64.3025 153.49 64.3434 153.111C64.3544 152.969 64.3544 152.826 64.3434 152.683L62.7905 113.621Z"
      fill="#2F2E41"
    />
    <path
      d="M40.827 150.109C40.827 150.109 24.4068 156.308 28.6925 182.551C32.5446 206.107 29.4803 284.288 28.8154 300.111C28.7848 300.863 29.0429 301.599 29.5357 302.163C30.0285 302.728 30.7181 303.078 31.4605 303.142L41.0293 303.938C41.7805 303.999 42.5257 303.762 43.1062 303.276C43.6867 302.79 44.0566 302.095 44.137 301.338L49.0948 255.336C49.1047 255.241 49.1332 255.148 49.1785 255.064C49.2238 254.98 49.2852 254.905 49.359 254.845C49.4328 254.784 49.5177 254.739 49.6088 254.712C49.6999 254.685 49.7954 254.676 49.8898 254.686C50.0567 254.704 50.2122 254.781 50.3296 254.902C50.4469 255.023 50.5189 255.182 50.533 255.351L54.255 299.615C54.3176 300.36 54.6604 301.052 55.213 301.549C55.7656 302.046 56.4858 302.311 57.2254 302.287L65.2837 302.032C65.6634 302.019 66.037 301.932 66.3831 301.773C66.7292 301.615 67.041 301.39 67.3008 301.11C67.5605 300.829 67.7631 300.5 67.8969 300.141C68.0307 299.782 68.0931 299.4 68.0806 299.016L63.3323 153.168L40.827 150.109Z"
      fill="#2F2E41"
    />
    <path
      d="M57.8215 65.1041C63.7857 67.5765 71.4063 64.6859 74.2903 58.8607C77.1743 53.0355 74.8932 45.1487 69.3576 41.8032C63.822 38.4578 55.8601 40.1819 52.1551 45.5229C49.1404 40.0278 41.2583 38.6265 35.8026 41.6932C30.3469 44.7599 27.4193 51.1793 27.0851 57.4447C26.7509 63.7101 28.6252 69.9168 30.9281 75.7274C34.6257 85.1401 46.6123 90.2317 55.8891 86.3066C51.6466 79.9165 52.4821 70.5698 57.8215 65.1041Z"
      fill="#2F2E41"
    />
    <path
      d="M406.13 0H163.717C160.305 0.00204298 157.032 1.35786 154.619 3.76963C152.206 6.18139 150.85 9.45186 150.848 12.8626V52.129C150.85 55.5398 152.206 58.8102 154.619 61.222C157.032 63.6338 160.305 64.9896 163.717 64.9916H406.13C409.542 64.9876 412.813 63.6311 415.225 61.2198C417.638 58.8084 418.995 55.5392 418.999 52.129V12.8626C418.995 9.45248 417.638 6.1832 415.225 3.77188C412.813 1.36056 409.542 0.00408154 406.13 0V0ZM417.641 52.129C417.639 55.1798 416.426 58.1051 414.268 60.2623C412.109 62.4196 409.182 63.6324 406.13 63.6344H163.717C160.664 63.6324 157.736 62.4198 155.577 60.2628C153.417 58.1058 152.202 55.1805 152.198 52.129V12.8626C152.202 9.8111 153.417 6.88586 155.577 4.72885C157.736 2.57183 160.664 1.35925 163.717 1.35721H406.13C409.182 1.35925 412.109 2.57208 414.268 4.72932C416.426 6.88655 417.639 9.81181 417.641 12.8626V52.129Z"
      fill="#3F3D56"
    />
    <path
      d="M213.051 54.8824C208.625 54.8824 204.299 53.5708 200.62 51.1135C196.94 48.6562 194.072 45.1636 192.379 41.0773C190.685 36.991 190.242 32.4945 191.106 28.1565C191.969 23.8185 194.1 19.8338 197.229 16.7062C200.358 13.5787 204.345 11.4488 208.686 10.586C213.026 9.72308 217.525 10.1659 221.613 11.8585C225.702 13.5512 229.196 16.4175 231.655 20.0951C234.113 23.7726 235.426 28.0963 235.426 32.5193C235.411 38.446 233.049 44.1258 228.856 48.3166C224.663 52.5074 218.981 54.8681 213.051 54.8824Z"
      fill="#58A765"
    />
    <path
      d="M373.054 50.7725H256.627C255.195 50.7725 253.821 50.2038 252.808 49.1915C251.796 48.1792 251.227 46.8062 251.227 45.3745C251.227 43.9429 251.796 42.5699 252.808 41.5576C253.821 40.5453 255.195 39.9766 256.627 39.9766H373.054C374.486 39.9766 375.86 40.5453 376.873 41.5576C377.886 42.5699 378.455 43.9429 378.455 45.3745C378.455 46.8062 377.886 48.1792 376.873 49.1915C375.86 50.2038 374.486 50.7725 373.054 50.7725Z"
      fill="#CCCCCC"
    />
    <path
      d="M295.159 27.7608H256.627C255.195 27.7608 253.821 27.1921 252.808 26.1798C251.796 25.1675 251.227 23.7945 251.227 22.3628C251.227 20.9312 251.796 19.5582 252.808 18.5459C253.821 17.5336 255.195 16.9648 256.627 16.9648H295.159C295.868 16.9648 296.57 17.1045 297.225 17.3757C297.881 17.647 298.476 18.0446 298.978 18.5459C299.479 19.0471 299.877 19.6422 300.148 20.2971C300.42 20.952 300.559 21.6539 300.559 22.3628C300.559 23.0717 300.42 23.7736 300.148 24.4285C299.877 25.0834 299.479 25.6785 298.978 26.1798C298.476 26.681 297.881 27.0786 297.225 27.3499C296.57 27.6212 295.868 27.7608 295.159 27.7608Z"
      fill="#CCCCCC"
    />
    <path
      d="M211.9 40.3701C211.344 40.3734 210.802 40.1947 210.357 39.8611L204.548 35.4193C204.004 35.0042 203.648 34.3903 203.558 33.7128C203.467 33.0352 203.65 32.3496 204.065 31.8066C204.481 31.2636 205.095 30.9077 205.773 30.8173C206.451 30.727 207.137 30.9094 207.68 31.3246L211.445 34.2009L220.326 22.6339C220.743 22.0917 221.358 21.7369 222.036 21.6473C222.714 21.5577 223.401 21.7405 223.944 22.1557L223.89 22.2329L223.944 22.1557C224.487 22.5725 224.842 23.1873 224.931 23.8653C225.021 24.5432 224.838 25.229 224.423 25.7724L213.96 39.329C213.719 39.6435 213.408 39.8982 213.052 40.0734C212.696 40.2486 212.305 40.3395 211.908 40.3392L211.9 40.3701Z"
      fill="white"
    />
    <path
      d="M325.971 105.646H83.5584C80.1458 105.648 76.8736 107.004 74.4606 109.416C72.0475 111.828 70.691 115.098 70.689 118.509V157.775C70.691 161.186 72.0475 164.456 74.4606 166.868C76.8736 169.28 80.1458 170.636 83.5584 170.638H325.971C329.383 170.634 332.654 169.277 335.067 166.866C337.479 164.455 338.837 161.185 338.841 157.775V118.509C338.837 115.099 337.479 111.829 335.067 109.418C332.654 107.007 329.383 105.65 325.971 105.646Z"
      fill="white"
    />
    <path
      d="M325.971 105.646H83.5584C80.1458 105.648 76.8736 107.004 74.4606 109.416C72.0475 111.828 70.691 115.098 70.689 118.509V157.775C70.691 161.186 72.0475 164.456 74.4606 166.868C76.8736 169.28 80.1458 170.636 83.5584 170.638H325.971C329.383 170.634 332.654 169.277 335.067 166.866C337.479 164.455 338.837 161.185 338.841 157.775V118.509C338.837 115.099 337.479 111.829 335.067 109.418C332.654 107.007 329.383 105.65 325.971 105.646ZM337.483 157.775C337.481 160.826 336.267 163.751 334.109 165.908C331.95 168.066 329.024 169.279 325.971 169.281H83.5584C80.5053 169.279 77.5777 168.066 75.4181 165.909C73.2584 163.752 72.0433 160.827 72.0392 157.775V118.509C72.0433 115.457 73.2584 112.532 75.4181 110.375C77.5777 108.218 80.5053 107.005 83.5584 107.003H325.971C329.024 107.005 331.95 108.218 334.109 110.375C336.267 112.533 337.481 115.458 337.483 118.509V157.775Z"
      fill="#3F3D56"
    />
    <path
      d="M132.892 160.528C128.466 160.528 124.14 159.217 120.461 156.759C116.781 154.302 113.914 150.809 112.22 146.723C110.527 142.637 110.083 138.14 110.947 133.802C111.81 129.464 113.941 125.48 117.07 122.352C120.199 119.225 124.186 117.095 128.527 116.232C132.867 115.369 137.366 115.812 141.454 117.504C145.543 119.197 149.037 122.063 151.496 125.741C153.954 129.418 155.267 133.742 155.267 138.165C155.252 144.092 152.89 149.772 148.697 153.962C144.504 158.153 138.822 160.514 132.892 160.528Z"
      fill="#58A765"
    />
    <path
      d="M292.895 156.418H176.469C175.036 156.418 173.663 155.849 172.65 154.837C171.637 153.825 171.068 152.452 171.068 151.02C171.068 149.588 171.637 148.215 172.65 147.203C173.663 146.191 175.036 145.622 176.469 145.622H292.895C294.328 145.622 295.701 146.191 296.714 147.203C297.727 148.215 298.296 149.588 298.296 151.02C298.296 152.452 297.727 153.825 296.714 154.837C295.701 155.849 294.328 156.418 292.895 156.418Z"
      fill="#CCCCCC"
    />
    <path
      d="M215 133.407H176.469C175.036 133.407 173.663 132.838 172.65 131.826C171.637 130.814 171.068 129.441 171.068 128.009C171.068 126.577 171.637 125.204 172.65 124.192C173.663 123.18 175.036 122.611 176.469 122.611H215C216.432 122.611 217.806 123.18 218.819 124.192C219.832 125.204 220.401 126.577 220.401 128.009C220.401 129.441 219.832 130.814 218.819 131.826C217.806 132.838 216.432 133.407 215 133.407Z"
      fill="#CCCCCC"
    />
    <path
      d="M131.742 146.015C131.186 146.019 130.644 145.84 130.199 145.506L124.389 141.065C123.847 140.648 123.492 140.034 123.403 139.356C123.314 138.679 123.498 137.994 123.915 137.452C124.331 136.91 124.946 136.555 125.624 136.466C126.302 136.377 126.987 136.561 127.529 136.977L131.295 139.854L140.183 128.287C140.388 128.018 140.644 127.792 140.937 127.622C141.23 127.452 141.553 127.342 141.889 127.298C142.224 127.253 142.565 127.275 142.892 127.363C143.219 127.451 143.525 127.602 143.794 127.809L143.74 127.886L143.794 127.809C144.336 128.225 144.691 128.84 144.781 129.518C144.87 130.196 144.687 130.882 144.272 131.425L133.802 144.974C133.56 145.289 133.25 145.543 132.894 145.719C132.538 145.894 132.146 145.985 131.75 145.984L131.742 146.015Z"
      fill="white"
    />
    <path
      d="M75.9536 125.476C75.12 125.126 74.2251 124.942 73.3201 124.935C72.415 124.929 71.5176 125.101 70.6793 125.44C69.8409 125.779 69.0781 126.28 68.4345 126.913C67.7909 127.546 67.2792 128.299 66.9286 129.129C66.7676 129.498 66.6422 129.882 66.5541 130.276L51.045 132.92L50.3621 142.65L72.2011 138.632C73.8734 138.897 75.5853 138.543 77.0124 137.636C78.4395 136.729 79.4827 135.333 79.9443 133.713C80.4059 132.092 80.2539 130.359 79.517 128.843C78.7801 127.326 77.5096 126.131 75.9462 125.484L75.9536 125.476Z"
      fill="#FFB8B8"
    />
    <path
      d="M49.5333 97.7783C51.7773 98.7763 53.5483 100.604 54.4753 102.879C55.4024 105.154 55.4137 107.7 54.5067 109.983L45.5546 132.819L56.796 130.748C57.4977 130.619 58.2226 130.75 58.8341 131.118C59.4457 131.486 59.9017 132.065 60.1165 132.746L62.3545 139.872C62.5839 140.611 62.5119 141.41 62.1543 142.095C61.7966 142.781 61.1823 143.297 60.4456 143.531H60.3724L45.7448 147.753C44.1744 148.208 42.5297 148.349 40.9048 148.167C39.2799 147.986 37.7068 147.486 36.2754 146.695C34.844 145.905 33.5825 144.84 32.563 143.561C31.5436 142.282 30.7863 140.815 30.3345 139.243C30.0148 138.144 29.8498 137.007 29.8445 135.863C29.8392 134.963 29.9299 134.065 30.1151 133.185L36.2587 104.495C36.7933 102.022 38.2876 99.8616 40.4133 98.4897C42.539 97.1177 45.1221 96.6461 47.5952 97.1783C48.2611 97.3073 48.911 97.5084 49.5333 97.7783Z"
      fill="#CCCCCC"
    />
  </svg>
);

export default AccountActivation;
