import { SvgIcon } from '@mui/material';

const Close = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="-2 -2 14 14"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 8.5398 0.05 L 4.9998 3.59 L 1.4598 0.05 L 0.0498 1.46 L 3.5898 5 L 0.0498 8.54 L 1.4598 9.95 L 4.9998 6.41 L 8.5398 9.95 L 9.9498 8.54 L 6.4098 5 L 9.9498 1.46 L 8.5398 0.05 Z" />
  </SvgIcon>
);

export default Close;
