import { Typography } from '@mui/material';
import { List } from '../styled/List';
import { ListItem } from '../styled/ListItem';

const PeriodOfDataProcessing = () => (
  <>
    <Typography variant="h5" color="text" fontWeight="700">
      OKRES PRZECHOWYWANIA DANYCH OSOBOWYCH
    </Typography>
    <Typography variant="body1" marginBottom="10px">
      Twoje dane będą przetwarzane przez okres niezbędny dla realizacji celów
      określonych powyżej oraz zależny od podstawy prawnej ich przetwarzania:
    </Typography>
    <List>
      <ListItem dense sx={{ fontWeight: '400' }}>
        Dane osobowe przetwarzane w celu zawarcia lub wykonania umowy oraz
        wypełnienia naszych obowiązków prawnych będą przechowywane przez okres
        obowiązywania tej umowy, a po jego upływie przez okres niezbędny do:{' '}
        <List sx={{ paddingBottom: '0' }}>
          <ListItem dense $listType="lower-latin">
            Zabezpieczenia lub dochodzenia ewentualnych roszczeń, do czasu
            upływu ustawowych terminów przedawnienia ewentualnych roszczeń
            związanych z wykonywaniem umowy, które to terminy wynikają z
            powszechnie obowiązujących przepisów prawa;
          </ListItem>{' '}
          <ListItem dense $listType="lower-latin">
            Wynikający z powszechnie obowiązującego prawa.
          </ListItem>
        </List>
      </ListItem>
      <ListItem dense>
        Dane osobowe przetwarzane na potrzeby marketingu produktów lub usług
        własnych, wynikającego z naszych prawnie uzasadnionych interesów, będą
        przechowywane do czasu zgłoszenia ewentualnego sprzeciwu;
      </ListItem>
      <ListItem dense>
        Dane osobowe przetwarzane na podstawie odrębnej zgody będą przechowywane
        do czasu ewentualnego odwołania tej zgody. Wyrażoną zgodę możesz cofnąć
        w dowolnym momencie i bez podania przyczyny, lecz bez wpływu na zgodność
        z prawem przetwarzania przed jej cofnięciem.
      </ListItem>
    </List>
  </>
);

export default PeriodOfDataProcessing;
