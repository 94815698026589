import styled from 'styled-components/macro';
import { Grid } from '@mui/material';

export const BackgroundWrapper = styled(Grid)`
  height: 100%;
  background: linear-gradient(
    323.61deg,
    rgba(222, 227, 223, 0.4) 4.23%,
    rgba(189, 198, 190, 0) 77.14%
  );
`;
