const eventName = 'c_event';
const errorName = 'c_error';

export const menuClickEvent = {
  name: eventName,
  category: 'menu_interaction',
  action: 'link_click'
};

export const footerClickEvent = {
  name: eventName,
  category: 'footer_interaction',
  action: 'link_click'
};

export const dashboardViewEvent = {
  name: eventName,
  category: 'dashboard_interaction',
  action: 'case_tiles_view'
};

export const dashboardClickEvent = {
  name: eventName,
  category: 'dashboard_interaction',
  action: 'case_tiles_interaction'
};

export const offerViewEvent = {
  name: eventName,
  category: 'offer_interaction',
  action: 'offer_view'
};

export const offerClickEvent = {
  name: eventName,
  category: 'offer_interaction',
  action: 'offer_click'
};

export const registerClickEvent = {
  name: eventName,
  category: 'registration_interaction',
  action: 'click'
};

export const accountClickEvent = {
  name: eventName,
  category: 'account_interaction',
  action: 'click'
};

export const errorFormEvent = {
  name: errorName,
  category: 'form_error',
  action: 'form_validation'
};

export const errorInputEvent = {
  name: errorName,
  category: 'form_error',
  action: 'input_validation'
};
