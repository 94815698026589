import { SvgIcon } from '@mui/material';

const Chat = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4170)">
      <path d="M9.91656 16.3857L12.6329 14.193L15.3384 12.0003L12.6329 9.80753L9.91656 7.62572L9.91656 12.0003L9.91656 16.3857Z" />
      <path d="M0.021974 12C0.0219739 14.3734 0.725745 16.6934 2.04432 18.6668C3.3629 20.6402 5.23704 22.1783 7.42975 23.0865C9.62247 23.9948 12.0353 24.2324 14.3631 23.7694C16.6908 23.3064 18.8291 22.1635 20.5073 20.4853C22.1855 18.8071 23.3284 16.6689 23.7914 14.3411C24.2544 12.0133 24.0168 9.6005 23.1085 7.40778C22.2003 5.21507 20.6622 3.34093 18.6888 2.02235C16.7154 0.703773 14.3953 -4.20793e-07 12.022 -5.24537e-07C8.83938 -6.63652e-07 5.7871 1.26426 3.53667 3.51469C1.28623 5.76513 0.0219742 8.8174 0.021974 12ZM21.8402 12C21.8402 13.9419 21.2643 15.8401 20.1855 17.4547C19.1066 19.0693 17.5733 20.3277 15.7792 21.0708C13.9852 21.814 12.0111 22.0084 10.1066 21.6296C8.20201 21.2507 6.45258 20.3156 5.07948 18.9425C3.70638 17.5694 2.77126 15.82 2.39242 13.9154C2.01359 12.0109 2.20801 10.0368 2.95113 8.24274C3.69424 6.4487 4.9527 4.91533 6.56729 3.83649C8.18189 2.75766 10.0801 2.18182 12.022 2.18182C14.6259 2.18182 17.1232 3.21624 18.9645 5.05751C20.8057 6.89877 21.8402 9.39606 21.8402 12Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4170">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24) rotate(90)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Chat;
