import { IIllustration } from '../Illustration.model';

const UsedPassword = ({ width, height }: IIllustration) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 260 255"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1202_6351)">
      <path
        d="M128.402 18.6178C131.296 15.3574 132.849 11.1274 132.753 6.77324C132.753 6.34356 133.416 6.34921 133.421 6.77324C133.518 11.301 131.9 15.6986 128.89 19.0871C128.584 19.4206 128.119 18.9401 128.402 18.6178Z"
        fill="white"
      />
      <path
        d="M147.979 23.0842C152.258 22.2179 156.053 19.7731 158.606 16.2376C158.86 15.8871 159.393 16.2998 159.138 16.6447C156.477 20.3196 152.524 22.8559 148.07 23.7457C147.645 23.8305 147.56 23.1804 147.979 23.0842Z"
        fill="white"
      />
      <path
        d="M25.4902 233.934C39.568 233.934 50.9804 222.544 50.9804 208.492C50.9804 194.441 39.568 183.051 25.4902 183.051C11.4123 183.051 0 194.441 0 208.492C0 222.544 11.4123 233.934 25.4902 233.934Z"
        fill="#F0F0F0"
      />
      <path
        d="M250.738 120.21L66.0136 204.857C63.3844 206.061 60.3838 206.174 57.6712 205.171C54.9586 204.168 52.7557 202.132 51.5465 199.509L5.97007 100.41C4.7691 97.7869 4.65872 94.7955 5.6631 92.0911C6.66748 89.3868 8.70475 87.19 11.3287 85.9819L196.053 1.32296C198.682 0.122498 201.681 0.0113761 204.392 1.01395C207.102 2.01653 209.304 4.051 210.515 6.67139L256.103 105.77C257.307 108.395 257.418 111.39 256.412 114.097C255.407 116.804 253.366 119.002 250.738 120.21Z"
        fill="white"
      />
      <path
        d="M14.3994 92.6533C13.547 93.0457 12.8853 93.7594 12.5593 94.638C12.2333 95.5166 12.2697 96.4883 12.6604 97.3402L58.2482 196.439C58.4418 196.861 58.7168 197.241 59.0577 197.557C59.3985 197.873 59.7984 198.119 60.2345 198.281C60.6706 198.443 61.1344 198.517 61.5993 198.5C62.0642 198.482 62.5212 198.374 62.944 198.18L247.669 113.532C248.521 113.139 249.183 112.424 249.509 111.545C249.835 110.665 249.799 109.693 249.408 108.84L203.826 9.74135C203.431 8.89023 202.715 8.22942 201.834 7.90317C200.954 7.57692 199.979 7.61173 199.124 8L14.3994 92.6533Z"
        fill="#E4E4E4"
      />
      <path
        d="M250.738 120.21L66.0136 204.857C63.3844 206.061 60.3838 206.174 57.6712 205.171C54.9586 204.168 52.7557 202.132 51.5465 199.509L5.97007 100.41C4.7691 97.7869 4.65872 94.7955 5.6631 92.0911C6.66748 89.3868 8.70475 87.19 11.3287 85.9819L196.053 1.32296C198.682 0.122498 201.681 0.0113761 204.392 1.01395C207.102 2.01653 209.304 4.051 210.515 6.67139L256.103 105.77C257.307 108.395 257.418 111.39 256.412 114.097C255.407 116.804 253.366 119.002 250.738 120.21ZM11.7988 86.9995C9.44591 88.0822 7.61902 90.0517 6.7186 92.4765C5.81819 94.9014 5.91771 97.5836 6.99534 99.9352L52.5831 199.056C53.6656 201.407 55.6385 203.233 58.0684 204.132C60.4984 205.032 63.1868 204.933 65.5435 203.856L250.268 119.181C252.621 118.098 254.448 116.128 255.348 113.704C256.249 111.279 256.149 108.596 255.072 106.245L209.484 7.1463C208.4 4.7968 206.427 2.9724 203.997 2.07354C201.567 1.17468 198.879 1.2748 196.523 2.35194L11.7988 86.9995Z"
        fill="#CACACA"
      />
      <path
        d="M204.585 75.2397L57.829 142.519C56.6468 143.057 55.3272 143.218 54.05 142.981C52.7727 142.743 51.6 142.118 50.6917 141.19L7.75489 97.244C7.04535 96.523 6.52026 95.642 6.22404 94.6755C5.92782 93.7091 5.86919 92.6856 6.05312 91.6917C6.23704 90.6979 6.65811 89.7629 7.28069 88.9658C7.90327 88.1687 8.70904 87.5331 9.62984 87.1126L197.691 0.610562C198.442 0.264517 199.255 0.0697212 200.082 0.0373107C200.909 0.00490024 201.735 0.135512 202.511 0.421676C203.288 0.70784 204 1.14395 204.607 1.70506C205.215 2.26618 205.706 2.94131 206.052 3.69184C206.342 4.29228 206.533 4.9357 206.618 5.59715L208.249 69.3938C208.277 70.6189 207.945 71.8254 207.294 72.8642C206.642 73.9031 205.701 74.7289 204.585 75.2397ZM198.178 1.60562L10.117 88.1415C8.8732 88.7145 7.90834 89.757 7.43457 91.0399C6.96081 92.3228 7.01694 93.741 7.59062 94.9826C7.83298 95.5179 8.16861 96.006 8.58191 96.4243L51.5187 140.376C52.2651 141.136 53.2277 141.647 54.2756 141.842C55.3236 142.036 56.406 141.903 57.3758 141.462L204.109 74.2108C205.028 73.7904 205.804 73.11 206.34 72.2537C206.875 71.3974 207.147 70.403 207.122 69.3938L205.502 6.16252C205.481 5.30874 205.248 4.47356 204.823 3.732C204.399 2.99043 203.797 2.3657 203.071 1.91392C202.344 1.46213 201.517 1.19745 200.663 1.14364C199.809 1.08983 198.955 1.24857 198.178 1.60562Z"
        fill="#CACACA"
      />
      <path
        d="M112.553 166.214L66.8406 187.183C66.1738 187.47 65.4211 187.485 64.7433 187.226C64.0655 186.966 63.5164 186.452 63.2132 185.793C62.9099 185.135 62.8767 184.384 63.1205 183.702C63.3644 183.019 63.8659 182.459 64.5181 182.14L110.236 161.176C110.57 161.008 110.935 160.909 111.308 160.884C111.681 160.86 112.056 160.911 112.409 161.034C112.762 161.157 113.086 161.35 113.363 161.602C113.64 161.853 113.862 162.158 114.018 162.497C114.174 162.837 114.26 163.204 114.27 163.577C114.281 163.95 114.216 164.322 114.079 164.669C113.942 165.017 113.736 165.333 113.474 165.6C113.212 165.866 112.899 166.077 112.553 166.219V166.214Z"
        fill="white"
      />
      <path
        d="M146.002 101.462C147.505 104.884 147.603 108.758 146.273 112.251C144.944 115.744 142.293 118.576 138.893 120.139C135.492 121.702 131.613 121.869 128.09 120.606C124.566 119.343 121.681 116.75 120.053 113.385V113.357"
        fill="#CACACA"
      />
      <path
        d="M249.09 255.028H45.8312C44.397 255.027 42.9772 254.743 41.6527 254.194C40.3283 253.645 39.1252 252.841 38.1121 251.828C37.099 250.814 36.2958 249.612 35.7484 248.289C35.2009 246.966 34.9199 245.548 34.9214 244.117V135.068C34.9259 132.182 36.0769 129.415 38.1221 127.375C40.1673 125.335 42.9396 124.187 45.8312 124.184H249.09C251.982 124.186 254.755 125.333 256.801 127.373C258.846 129.414 259.997 132.181 260 135.068V244.117C260.002 245.548 259.722 246.966 259.174 248.29C258.627 249.613 257.824 250.816 256.811 251.829C255.798 252.843 254.594 253.647 253.269 254.196C251.945 254.745 250.524 255.028 249.09 255.028Z"
        fill="white"
      />
      <path
        d="M45.8312 131.534C44.8922 131.535 43.9919 131.908 43.3274 132.57C42.6629 133.232 42.2882 134.13 42.2852 135.068V244.117C42.283 244.583 42.373 245.045 42.5501 245.477C42.7272 245.909 42.988 246.301 43.3174 246.632C43.6468 246.963 44.0385 247.226 44.4698 247.405C44.9012 247.585 45.3638 247.678 45.8312 247.678H249.09C249.558 247.679 250.021 247.587 250.453 247.408C250.885 247.229 251.277 246.967 251.607 246.636C251.937 246.304 252.197 245.911 252.374 245.479C252.551 245.046 252.64 244.584 252.636 244.117V135.068C252.636 134.603 252.544 134.143 252.366 133.714C252.188 133.285 251.926 132.895 251.597 132.567C251.268 132.239 250.877 131.979 250.447 131.801C250.016 131.624 249.555 131.533 249.09 131.534H45.8312Z"
        fill="#E73F0D"
      />
      <path
        d="M249.09 255.028H45.8312C44.397 255.027 42.9772 254.743 41.6527 254.194C40.3283 253.645 39.1252 252.841 38.1121 251.828C37.099 250.814 36.2958 249.612 35.7484 248.289C35.2009 246.966 34.9199 245.548 34.9214 244.117V135.068C34.9259 132.182 36.0769 129.415 38.1221 127.375C40.1673 125.335 42.9396 124.187 45.8312 124.184H249.09C251.982 124.186 254.755 125.333 256.801 127.373C258.846 129.414 259.997 132.181 260 135.068V244.117C260.002 245.548 259.722 246.966 259.174 248.29C258.627 249.613 257.824 250.816 256.811 251.829C255.798 252.843 254.594 253.647 253.269 254.196C251.945 254.745 250.524 255.028 249.09 255.028ZM45.8312 125.315C43.2396 125.316 40.7546 126.344 38.9215 128.173C37.0885 130.001 36.0573 132.481 36.0543 135.068V244.117C36.0521 245.4 36.3033 246.671 36.7935 247.857C37.2838 249.043 38.0035 250.121 38.9115 251.03C39.8195 251.938 40.898 252.659 42.0854 253.151C43.2727 253.643 44.5456 253.897 45.8312 253.898H249.09C250.376 253.897 251.649 253.643 252.836 253.151C254.023 252.659 255.102 251.938 256.01 251.03C256.918 250.121 257.637 249.043 258.128 247.857C258.618 246.671 258.869 245.4 258.867 244.117V135.068C258.864 132.481 257.833 130.001 256 128.173C254.167 126.344 251.682 125.316 249.09 125.315H45.8312Z"
        fill="#3F3D56"
      />
      <path
        d="M225.945 194.912H64.462C63.1626 194.911 61.8954 194.508 60.8338 193.76C59.7721 193.013 58.968 191.956 58.5313 190.734L37.8786 132.908C37.6008 132.13 37.4794 131.305 37.5212 130.48C37.5631 129.655 37.7674 128.846 38.1225 128.1C38.4775 127.354 38.9765 126.685 39.5907 126.131C40.2049 125.578 40.9224 125.15 41.7021 124.874C42.3781 124.631 43.091 124.507 43.8093 124.506L250.897 124.19C251.725 124.188 252.545 124.35 253.31 124.664C254.075 124.979 254.771 125.442 255.357 126.025C255.943 126.608 256.408 127.301 256.725 128.064C257.043 128.827 257.207 129.645 257.208 130.471C257.208 131.135 257.105 131.795 256.902 132.427L231.734 191.101C231.249 192.233 230.442 193.197 229.413 193.874C228.384 194.552 227.178 194.913 225.945 194.912ZM250.937 125.326L43.8376 125.643C43.0105 125.645 42.1959 125.845 41.4621 126.226C40.7284 126.607 40.097 127.158 39.6209 127.833C39.1448 128.509 38.8379 129.288 38.726 130.106C38.614 130.924 38.7003 131.757 38.9775 132.535L59.6246 190.355C59.9844 191.356 60.6446 192.222 61.5153 192.835C62.386 193.448 63.4248 193.779 64.4904 193.781H225.945C226.956 193.782 227.945 193.486 228.789 192.93C229.633 192.374 230.295 191.583 230.692 190.655L255.656 132.518L255.865 132.602L255.656 132.518C255.991 131.735 256.127 130.883 256.052 130.035C255.977 129.188 255.694 128.372 255.227 127.66C254.76 126.948 254.124 126.363 253.376 125.955C252.627 125.548 251.789 125.332 250.937 125.326Z"
        fill="#3F3D56"
      />
      <path
        d="M104.278 239.266H53.9714C53.2338 239.266 52.5264 238.973 52.0048 238.453C51.4832 237.932 51.1902 237.226 51.1902 236.49C51.1902 235.753 51.4832 235.047 52.0048 234.527C52.5264 234.006 53.2338 233.714 53.9714 233.714H104.278C105.015 233.714 105.723 234.006 106.244 234.527C106.766 235.047 107.059 235.753 107.059 236.49C107.059 237.226 106.766 237.932 106.244 238.453C105.723 238.973 105.015 239.266 104.278 239.266Z"
        fill="white"
      />
      <path
        d="M161.75 194.347C161.75 198.128 160.245 201.755 157.565 204.429C154.886 207.103 151.253 208.605 147.464 208.605C143.675 208.605 140.041 207.103 137.362 204.429C134.683 201.755 133.178 198.128 133.178 194.347"
        fill="#3F3D56"
      />
      <path
        d="M71.3725 30.4001C74.188 30.4001 76.4705 28.122 76.4705 25.3117C76.4705 22.5015 74.188 20.2234 71.3725 20.2234C68.5569 20.2234 66.2744 22.5015 66.2744 25.3117C66.2744 28.122 68.5569 30.4001 71.3725 30.4001Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.09804 152.521C7.91361 152.521 10.1961 150.242 10.1961 147.432C10.1961 144.622 7.91361 142.344 5.09804 142.344C2.28247 142.344 0 144.622 0 147.432C0 150.242 2.28247 152.521 5.09804 152.521Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1202_6351">
        <rect width="260" height="255" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UsedPassword;
