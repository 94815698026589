import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import ModalsState from './modals.model';
import reducers from './modals.reducers';

const initialState: ModalsState = {
  isRegulationOpen: false,
  isPrivacyPolicyOpen: false
};

const modalsModel = createModel<RootModel>()({
  state: initialState,
  reducers
});

export default modalsModel;
