import styled from 'styled-components/macro';
import { Container } from '@mui/material';

export const FormWrapper = styled(Container)`
  &&& {
    width: 400px;
    max-width: calc(100vw - 48px);
  }
`;

export default FormWrapper;
