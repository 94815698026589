import { Typography, Link } from '@mui/material';
import { List } from '../styled/List';
import { ListItem } from '../styled/ListItem';
import { linkFbPolicyCookies } from '../utils/urls';

const OtherTechnology = () => (
  <>
    <Typography variant="h5" fontWeight="700" marginBottom="10px">
      INNE TECHNOLOGIE WYKORZYSTYWANE PRZEZ SERWIS
    </Typography>
    <List>
      <ListItem dense>
        Local Storage (LS) – technologia zbliżona do Cookies jednak posiadająca
        inne właściwości. Dla Zapewnienia sprawnego korzystanie z naszego
        Serwisu używamy technologii LS, która służy do przechowywania danych
        zapisywanych podczas korzystania z Serwisu w wydzielonej części pamięci
        przeglądarki;
      </ListItem>
      <ListItem dense>
        Pixel – korzystamy z narzędzi marketingowych, takich jak Facebook Pixel,
        by kierować do Ciebie spersonalizowane reklamy w serwisie Facebook.
        Więcej informacji na temat wykorzystywania plików Cookies przez
        Facebooka (Meta Platforms Ireland Limited) znajdziesz klikając w{' '}
        <Link
          sx={{ fontWeight: 700, lineHeight: '125%' }}
          color="primary.light"
          href={linkFbPolicyCookies}
          target="_blank"
          rel="nofollow"
          data-link-no-alert
        >
          link
        </Link>
        .
      </ListItem>
    </List>
  </>
);

export default OtherTechnology;
