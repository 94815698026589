import { Constant } from 'utils/consts';

export const blockBodyPosition = () => {
  const scrollPositionY = window.scrollY;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollPositionY}px`;
  document.body.style.left = '0';
  document.body.style.right = '0';
};

export const resetBodyPosition = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.left = '';
  document.body.style.right = '';
  window.scrollTo(
    Constant.zero,
    parseInt(scrollY || '0', Constant.ten) * Constant.minusOne
  );
};
