import { SvgIcon } from '@mui/material';

const Mail = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4148)">
      <path d="M22.2899 0.29999C21.8771 0.0923212 21.422 -0.0171996 20.96 -0.0200174H2.95996C2.56499 -0.0221903 2.17369 0.0560458 1.80994 0.209963L1.71997 0.31C1.20466 0.543764 0.766013 0.918489 0.45459 1.39093C0.143167 1.86338 -0.0283391 2.41425 -0.0400391 2.97998V14.98C-0.0400391 15.7756 0.276014 16.5387 0.838623 17.1013C1.40123 17.6639 2.16431 17.98 2.95996 17.98H20.96C21.7556 17.98 22.5187 17.6639 23.0813 17.1013C23.6439 16.5387 23.96 15.7756 23.96 14.98V2.97998C23.9583 2.42218 23.8011 1.87588 23.5061 1.40247C23.2111 0.929054 22.7899 0.547281 22.2899 0.29999ZM20.51 2L12.85 9.46997C12.6414 9.66182 12.3683 9.76831 12.085 9.76831C11.8016 9.76831 11.5285 9.66182 11.3199 9.46997L3.65991 1.97998L20.51 2ZM21 16H3C2.73478 16 2.48038 15.8946 2.29285 15.7071C2.10531 15.5196 2 15.2652 2 15V3.17999L9.91992 10.9C10.4995 11.4596 11.2743 11.7717 12.08 11.77C12.8865 11.776 13.6628 11.4633 14.24 10.9L22 3.33997V14.98C22.0001 15.1147 21.9729 15.2481 21.9202 15.3721C21.8674 15.4961 21.7903 15.6082 21.6931 15.7016C21.596 15.795 21.481 15.8678 21.355 15.9157C21.229 15.9635 21.0946 15.9854 20.96 15.98L21 16Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4148">
        <rect width="24" height="18" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Mail;
