import { SvgIcon } from '@mui/material';

const ChevronLeft = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.513 22.861 1.071 11.803 12.513.744C12.825.444 13.243.28 13.676.288 14.109.296 14.521.476 14.822.788 15.122 1.1 15.286 1.518 15.278 1.951 15.27 2.384 15.09 2.796 14.779 3.096L5.765 11.803 14.779 20.519C15.09 20.819 15.27 21.231 15.278 21.664 15.286 22.097 15.122 22.516 14.822 22.828 14.521 23.14 14.109 23.319 13.676 23.327 13.243 23.336 12.825 23.171 12.513 22.871V22.861Z" />
  </SvgIcon>
);

export default ChevronLeft;
