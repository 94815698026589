import jwtDecode from 'jwt-decode';
import { StatusCode, TimeInMs } from 'utils/consts';
import { DecodedToken, RefreshTokenResponse, TokenData } from './Token.model';

const { REACT_APP_API_URL } = process.env;

const getToken = (): string => localStorage.getItem('token') || '';

const getBearer = (): string => {
  const token = localStorage.getItem('token');
  const ACPToken = sessionStorage.getItem('tokenACP');

  return (
    (token && `Bearer ${token}`) || (ACPToken && `Bearer ${ACPToken}`) || ''
  );
};

const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

const getTokenData = (): TokenData => {
  const token = localStorage.getItem('token');

  if (!token) {
    return { username: '', id: 0 };
  }

  const { sub, iat }: DecodedToken = jwtDecode(token);

  return { username: sub, id: iat };
};

const isTokenNotExpired = () => {
  const decodedToken = jwtDecode<{ exp: number }>(getToken());
  return Date.now() <= decodedToken.exp * TimeInMs.oneSecond;
};

const getTimeToRefresh = (): number => {
  const decodedToken = jwtDecode<{ exp: number }>(getToken());
  const timeInMs =
    (decodedToken.exp - Math.round(new Date().getTime() / TimeInMs.oneSecond)) *
    TimeInMs.oneSecond;
  return timeInMs - TimeInMs.tenMinutes;
};

const refreshToken = async () => {
  const token = getBearer();
  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    ...(token && { Authorization: `${token}` })
  };

  const response: RefreshTokenResponse = await fetch(
    `${REACT_APP_API_URL}/auth/token/regenerate`,
    {
      headers,
      method: 'GET'
    }
  )
    .then((res) => {
      if (res.status === StatusCode.ok) {
        return (res.json() as Promise<RefreshTokenResponse>).then((data) => ({
          ...data,
          status: res.status
        }));
      }

      return {
        success: false,
        status: res.status
      };
    })
    .catch((error) => {
      throw error;
    });

  if (response.success && response.data) {
    setToken(response.data.token);
  }
};

export default {
  getToken,
  getBearer,
  getTokenData,
  refreshToken,
  setToken,
  getTimeToRefresh,
  isTokenNotExpired
};
