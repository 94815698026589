import TagManager from 'react-gtm-module';

interface IEventElements {
  name: string;
  category: string;
  labelText: string;
  action?: string;
  secondDimension?: string;
  thirdDimension?: string;
}

const sendEvent = (eventElements: IEventElements) => {
  const { name, category, action, labelText, secondDimension, thirdDimension } =
    eventElements;
  const lowerCaseLabel = labelText.toLowerCase();
  const lowerCaseThirdDimension = thirdDimension?.toLowerCase();
  const clickAction = {
    dataLayer: {
      event: name,
      eventCategory: category,
      eventAction: action,
      eventLabel: lowerCaseLabel,
      dimension2: secondDimension || '',
      dimension3: lowerCaseThirdDimension || ''
    }
  };
  TagManager.dataLayer(clickAction);
};

export default sendEvent;
