import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Link, Container } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import appTheme from 'App.theme';
import { useImageResized, useWindowSize } from 'hooks';
import { RootDispatch, RootState } from 'storage/store';
import { SMALL_DEVICE_HEIGHT, TimeInMs } from 'utils/consts';
import { Url } from 'utils/urls';
import { Button } from 'components';
import { PageTemplate } from 'components/PageTemplate';
import ContactUsModal from 'components/Modal/modals/ContactUs';

const TechnicalBreak = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<RootDispatch>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const laptopBreakpoint = useMediaQuery(
    appTheme.breakpoints.between('lg', 'xl')
  );
  const tabletBreakpoint = useMediaQuery(
    appTheme.breakpoints.between('md', 'lg')
  );
  const mobileBreakpoint = useMediaQuery(appTheme.breakpoints.down('md'));
  const desktopBreakpoint = useMediaQuery(appTheme.breakpoints.up('lg'));
  const { sigmaAvailable } = useSelector(
    (store: RootState) => store.application
  );
  const { isImageResized } = useImageResized();
  const { windowSize } = useWindowSize();
  const isSmallDevice = windowSize.height <= SMALL_DEVICE_HEIGHT;

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await dispatch.application.getSigmaAvailability();
      if (response && response.sigmaAvailable) {
        navigate(Url.login);
      }
    }, TimeInMs.oneMinute);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (sigmaAvailable) {
      navigate(Url.login);
    }
  }, []);

  const button = (
    <Container
      disableGutters
      sx={{
        width: '216px',
        marginTop: isImageResized
          ? isSmallDevice
            ? '-30px'
            : '-10px'
          : {
              sm: '-50px',
              md: '-35px',
              lg: '30px'
            },
        marginBottom: { xs: '30px' },
        marginLeft: {
          lg: 0
        }
      }}
    >
      <Button
        onClick={() => {
          navigate(Url.login);
        }}
        size="large"
        sx={{
          minWidth: 'max-content',
          marginTop: {
            sm: '15px',
            md: '60px',
            lg: '2px'
          },
          marginLeft: {
            md: '-53px',
            lg: 0
          }
        }}
      >
        <Trans i18nKey="PAGE_TECHNICAL_BREAK_LOGIN_BUTTON" />
      </Button>
    </Container>
  );

  const resizedImageParams = {
    height: isSmallDevice ? '100px' : '150px',
    svgHeight: isSmallDevice ? '120px' : '180px',
    marginTop: isSmallDevice ? '-20px' : 'auto'
  };

  return (
    <>
      <Helmet>
        <title>{t('ROUTING_TECHNICAL_BREAK_TITLE')}</title>
        <meta
          name="description"
          content={t('ROUTING_TECHNICAL_BREAK_DESCRIPTION')}
        />
      </Helmet>
      <ContactUsModal
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
      <PageTemplate
        heading={<Trans i18nKey="PAGE_TECHNICAL_BREAK_TITLE" />}
        textWidth={
          tabletBreakpoint ? '367px' : desktopBreakpoint ? '388px' : undefined
        }
        message={
          <Trans i18nKey="PAGE_TECHNICAL_BREAK_MESSAGE">
            Przepraszamy, aktualnie trwają prace techniczne, przez co aplikacja
            jest chwilowo niedostępna. Jeśli wymaga tego sytuacja, koniecznie
            <Link
              component="button"
              type="button"
              onClick={() => setIsModalVisible(true)}
              variant="body1"
              sx={{ fontWeight: 700, cursor: 'pointer' }}
            >
              skontaktuj się z nami
            </Link>
            . Chętnie Ci pomożemy.
          </Trans>
        }
        buttons={button}
        image="error"
        imageWidth={
          mobileBreakpoint
            ? '400px'
            : tabletBreakpoint
            ? '550px'
            : laptopBreakpoint
            ? '650px'
            : '700px'
        }
        withBackgroundSection={false}
        resizedImageParams={resizedImageParams}
      />
    </>
  );
};

export default TechnicalBreak;
