import { IIllustration } from '../Illustration.model';

const OfferSuccess = ({ width, height }: IIllustration) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 500 373"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1183_3177)">
      <path
        d="M32.6446 342.274C35.1497 343.155 37.8392 343.375 40.4543 342.915C43.0693 342.454 45.5216 341.328 47.575 339.645C52.8043 335.256 54.4442 328.029 55.7778 321.334L59.7237 301.533L51.4627 307.221C45.5215 311.31 39.4469 315.532 35.3336 321.455C31.2204 327.379 29.4259 335.466 32.7298 341.876"
        fill="#E6E6E6"
      />
      <path
        d="M33.9197 367.624C32.8799 360.05 31.8101 352.379 32.5412 344.724C33.1891 337.925 35.2637 331.286 39.4873 325.84C41.7291 322.956 44.457 320.484 47.5483 318.537C48.3542 318.028 49.0962 319.305 48.2937 319.812C42.9447 323.191 38.8074 328.182 36.4782 334.064C33.9059 340.605 33.4929 347.736 33.9359 354.685C34.2038 358.887 34.7729 363.061 35.3445 367.231C35.3896 367.419 35.3614 367.618 35.2656 367.787C35.1699 367.955 35.0136 368.081 34.8285 368.139C34.6397 368.19 34.4384 368.164 34.2684 368.068C34.0983 367.971 33.9732 367.811 33.9202 367.623L33.9197 367.624Z"
        fill="#F2F2F2"
      />
      <path
        d="M41.4038 355.472C42.478 357.105 43.9547 358.434 45.6917 359.33C47.4287 360.227 49.3674 360.661 51.3209 360.591C56.3425 360.353 60.5271 356.85 64.2938 353.524L75.4373 343.687L68.0624 343.334C62.7588 343.08 57.3183 342.842 52.2667 344.479C47.2151 346.116 42.5563 350.05 41.6327 355.278"
        fill="#E6E6E6"
      />
      <path
        d="M30.9809 371.979C35.9867 363.124 41.7926 353.282 52.1675 350.136C55.052 349.264 58.0766 348.952 61.0784 349.216C62.0246 349.298 61.7883 350.756 60.844 350.675C55.8122 350.257 50.7901 351.585 46.6231 354.436C42.6133 357.165 39.491 360.959 36.8488 364.982C35.2301 367.446 33.7802 370.015 32.3304 372.581C31.8671 373.401 30.5122 372.808 30.9809 371.979Z"
        fill="#F2F2F2"
      />
      <path
        d="M479.009 307.233L478.51 307.233L156.459 307.233C155.598 307.232 154.772 306.889 154.163 306.278C153.554 305.668 153.211 304.84 153.209 303.976V3.75777C153.211 2.89378 153.554 2.06572 154.163 1.45512C154.772 0.844549 155.598 0.50121 156.459 0.5H479.009C479.87 0.50121 480.696 0.844551 481.305 1.45512C481.915 2.0658 482.258 2.89397 482.259 3.75808V303.975C482.258 304.839 481.915 305.667 481.305 306.278C480.696 306.888 479.87 307.232 479.009 307.233ZM156.458 1.00001L156.457 1.00001C155.729 1.0016 155.031 1.29226 154.517 1.80803C154.002 2.32375 153.713 3.02254 153.711 3.75141V3.75249L153.711 303.975L153.711 303.976C153.713 304.705 154.002 305.404 154.517 305.92C155.031 306.436 155.729 306.726 156.457 306.728H156.458H479.01H479.011C479.739 306.726 480.437 306.436 480.952 305.92C481.466 305.404 481.756 304.705 481.757 303.976V303.975V3.75742V3.75634C481.756 3.02747 481.466 2.32868 480.951 1.81296C480.437 1.29719 479.739 1.00653 479.011 1.00494H479.01L156.458 1.00001Z"
        fill="#CACACA"
        stroke="black"
      />
      <path
        d="M316.72 82.4728C316.008 82.4739 315.327 82.7338 314.824 83.1953C314.321 83.6569 314.039 84.2824 314.039 84.9346C314.039 85.5868 314.321 86.2124 314.824 86.674C315.327 87.1355 316.008 87.3954 316.72 87.3965H464.068C464.779 87.3954 465.461 87.1355 465.964 86.674C466.466 86.2124 466.749 85.5868 466.749 84.9346C466.749 84.2824 466.466 83.6569 465.964 83.1953C465.461 82.7338 464.779 82.4739 464.068 82.4728H316.72Z"
        fill="#E4E4E4"
      />
      <path
        d="M316.741 98.4749C316.024 98.4764 315.337 98.7365 314.83 99.198C314.324 99.6595 314.039 100.285 314.039 100.937C314.039 101.589 314.324 102.214 314.83 102.675C315.337 103.137 316.024 103.397 316.741 103.399H392.618C393.335 103.397 394.022 103.137 394.529 102.675C395.035 102.214 395.32 101.589 395.32 100.937C395.32 100.285 395.035 99.6595 394.529 99.198C394.022 98.7365 393.335 98.4764 392.618 98.4749H316.741Z"
        fill="#E4E4E4"
      />
      <path
        d="M188.632 184.64C187.923 184.641 187.243 184.902 186.743 185.363C186.242 185.825 185.961 186.45 185.961 187.102C185.961 187.754 186.242 188.379 186.743 188.84C187.243 189.302 187.923 189.562 188.632 189.564H464.078C464.787 189.562 465.467 189.302 465.967 188.84C466.468 188.379 466.749 187.754 466.749 187.102C466.749 186.45 466.468 185.825 465.967 185.363C465.467 184.902 464.787 184.641 464.078 184.64H188.632Z"
        fill="#E4E4E4"
      />
      <path
        d="M188.638 200.642C187.927 200.644 187.246 200.969 186.745 201.546C186.243 202.123 185.961 202.904 185.961 203.719C185.961 204.534 186.243 205.316 186.745 205.893C187.246 206.47 187.927 206.795 188.638 206.797H392.644C393.354 206.795 394.035 206.47 394.537 205.893C395.039 205.316 395.321 204.534 395.321 203.719C395.321 202.904 395.039 202.123 394.537 201.546C394.035 200.969 393.354 200.644 392.644 200.642H188.638Z"
        fill="#E4E4E4"
      />
      <path
        d="M188.632 216.644C187.923 216.646 187.243 216.906 186.743 217.367C186.242 217.829 185.961 218.454 185.961 219.106C185.961 219.758 186.242 220.383 186.743 220.845C187.243 221.306 187.923 221.566 188.632 221.568H464.078C464.787 221.566 465.467 221.306 465.967 220.845C466.468 220.383 466.749 219.758 466.749 219.106C466.749 218.454 466.468 217.829 465.967 217.367C465.467 216.906 464.787 216.646 464.078 216.644H188.632Z"
        fill="#E4E4E4"
      />
      <path
        d="M188.638 232.647C187.927 232.648 187.246 232.908 186.745 233.37C186.243 233.831 185.961 234.457 185.961 235.109C185.961 235.761 186.243 236.386 186.745 236.847C187.246 237.309 187.927 237.569 188.638 237.571H392.644C393.354 237.569 394.035 237.309 394.537 236.847C395.039 236.386 395.321 235.761 395.321 235.109C395.321 234.457 395.039 233.831 394.537 233.37C394.035 232.908 393.354 232.648 392.644 232.647H188.638Z"
        fill="#E4E4E4"
      />
      <path
        d="M188.632 248.649C187.923 248.651 187.243 248.976 186.743 249.553C186.242 250.13 185.961 250.911 185.961 251.726C185.961 252.541 186.242 253.323 186.743 253.9C187.243 254.476 187.923 254.802 188.632 254.804H464.078C464.787 254.802 465.467 254.476 465.967 253.9C466.468 253.323 466.749 252.541 466.749 251.726C466.749 250.911 466.468 250.13 465.967 249.553C465.467 248.976 464.787 248.651 464.078 248.649H188.632Z"
        fill="#E4E4E4"
      />
      <path
        d="M188.638 264.651C187.927 264.653 187.246 264.978 186.745 265.555C186.243 266.132 185.961 266.913 185.961 267.728C185.961 268.543 186.243 269.325 186.745 269.902C187.246 270.479 187.927 270.804 188.638 270.806H392.644C393.354 270.804 394.035 270.479 394.537 269.902C395.039 269.325 395.321 268.543 395.321 267.728C395.321 266.913 395.039 266.132 394.537 265.555C394.035 264.978 393.354 264.653 392.644 264.651H188.638Z"
        fill="#E4E4E4"
      />
      <path
        d="M232.143 131.71C224.471 131.71 216.97 129.436 210.591 125.175C204.211 120.915 199.239 114.859 196.303 107.774C193.366 100.689 192.598 92.8927 194.095 85.3711C195.592 77.8496 199.286 70.9407 204.712 65.5179C210.137 60.0952 217.049 56.4022 224.574 54.9061C232.1 53.4099 239.9 54.1778 246.988 57.1125C254.077 60.0472 260.135 65.0171 264.398 71.3935C268.661 77.7699 270.936 85.2666 270.936 92.9354C270.924 103.215 266.833 113.071 259.561 120.34C252.288 127.609 242.428 131.698 232.143 131.71V131.71Z"
        fill="#58A765"
      />
      <path
        d="M122.02 224.855C121.966 223.713 121.662 222.597 121.13 221.585C120.598 220.574 119.851 219.691 118.94 218.999C118.03 218.308 116.979 217.824 115.862 217.583C114.744 217.341 113.587 217.347 112.473 217.601L102.131 203.03L91.7412 207.153L106.635 227.526C107.185 229.37 108.393 230.948 110.03 231.961C111.666 232.975 113.618 233.352 115.514 233.023C117.411 232.694 119.121 231.68 120.32 230.175C121.519 228.669 122.124 226.777 122.02 224.855H122.02Z"
        fill="#A0616A"
      />
      <path
        d="M101.864 223.774L70.5713 183.186L82.2963 146.343C83.1555 137.081 88.9529 134.494 89.1995 134.388L89.5758 134.227L99.7787 161.43L92.2874 181.403L110.675 212.322L101.864 223.774Z"
        fill="#3F3D56"
      />
      <path
        d="M210.537 98.6549C209.403 98.7971 208.314 99.1862 207.346 99.7949C206.378 100.404 205.556 101.217 204.936 102.178C204.317 103.139 203.916 104.224 203.762 105.356C203.607 106.489 203.703 107.642 204.042 108.733L190.311 120.169L195.226 130.206L214.391 113.785C216.187 113.094 217.667 111.767 218.551 110.058C219.435 108.348 219.661 106.374 219.186 104.509C218.711 102.644 217.568 101.018 215.974 99.9388C214.38 98.8599 212.446 98.4031 210.537 98.6549V98.6549Z"
        fill="#A0616A"
      />
      <path
        d="M211.019 118.83L172.964 153.161L135.319 144.322C126.015 144.182 122.988 138.603 122.863 138.365L122.673 138.002L149.012 125.729L169.508 131.652L198.918 110.933L211.019 118.83Z"
        fill="#3F3D56"
      />
      <path
        d="M145.103 362.89L155.327 362.889L160.191 323.459L145.101 323.46L145.103 362.89Z"
        fill="#A0616A"
      />
      <path
        d="M175.046 372.38L142.912 372.382L142.911 359.969L166.03 359.968C168.421 359.968 170.714 360.917 172.405 362.607C174.096 364.298 175.046 366.59 175.046 368.981L175.046 372.38L175.046 372.38Z"
        fill="#2F2E41"
      />
      <path
        d="M104.236 362.89L114.46 362.889L119.325 323.459L104.234 323.46L104.236 362.89Z"
        fill="#A0616A"
      />
      <path
        d="M134.181 372.38L102.047 372.382L102.046 359.969L125.165 359.968C126.349 359.968 127.521 360.201 128.615 360.654C129.709 361.107 130.703 361.77 131.54 362.607C132.377 363.444 133.041 364.438 133.495 365.532C133.948 366.625 134.181 367.797 134.181 368.981L134.181 372.38V372.38Z"
        fill="#2F2E41"
      />
      <path
        d="M100.601 218.965L101.434 277.333L102.269 354.045L120.616 352.378L129.791 246.481L141.467 352.378H160.408L162.316 245.648L155.644 222.3L100.601 218.965Z"
        fill="#2F2E41"
      />
      <path
        d="M136.512 226.803C116.548 226.804 98.1722 217.77 97.9242 217.646L97.7181 217.543L96.0428 177.346C95.557 175.925 85.9908 147.896 84.3706 138.987C82.7291 129.961 106.52 122.039 109.409 121.113L110.065 113.851L136.725 110.979L140.104 120.27L149.669 123.856C150.753 124.263 151.651 125.053 152.191 126.077C152.731 127.101 152.877 128.288 152.601 129.413L147.285 151.026L160.268 222.535L157.473 222.656C150.773 225.681 143.542 226.802 136.512 226.803Z"
        fill="#3F3D56"
      />
      <path
        d="M133.645 104.029C142.948 98.9448 146.366 87.2837 141.281 77.9832C136.196 68.6827 124.533 65.2646 115.23 70.3488C105.928 75.4329 102.51 87.094 107.595 96.3945C112.68 105.695 124.343 109.113 133.645 104.029Z"
        fill="#A0616A"
      />
      <path
        d="M108.891 105.768C112.549 109.664 119.343 107.573 119.82 102.25C119.857 101.837 119.854 101.421 119.812 101.008C119.565 98.6507 118.203 96.5102 118.529 94.0208C118.603 93.4012 118.834 92.8108 119.2 92.3054C122.115 88.4025 128.958 94.0511 131.71 90.5179C133.397 88.3514 131.414 84.9404 132.708 82.5191C134.417 79.3232 139.478 80.8997 142.651 79.1495C146.183 77.2022 145.971 71.7855 143.647 68.4908C140.812 64.4727 135.842 62.3287 130.934 62.0197C126.025 61.7107 121.151 63.0372 116.569 64.8227C111.362 66.8513 106.199 69.6549 102.995 74.232C99.0989 79.7983 98.724 87.2817 100.673 93.7905C101.858 97.7499 105.904 102.587 108.891 105.768Z"
        fill="#2F2E41"
      />
      <path
        d="M243.915 372.875H0.63852C0.469174 372.875 0.306754 372.808 0.187008 372.688C0.0672622 372.568 0 372.406 0 372.237C0 372.067 0.0672622 371.905 0.187008 371.785C0.306754 371.666 0.469174 371.598 0.63852 371.598H243.915C244.084 371.598 244.246 371.666 244.366 371.785C244.486 371.905 244.553 372.067 244.553 372.237C244.553 372.406 244.486 372.568 244.366 372.688C244.246 372.808 244.084 372.875 243.915 372.875Z"
        fill="#CACACA"
      />
      <path
        d="M229.924 107.091C229.22 107.092 228.543 106.832 228.029 106.365L215.173 94.7151C214.633 94.2245 214.314 93.5447 214.287 92.8251C214.261 92.1054 214.528 91.4048 215.03 90.8772C215.532 90.3496 216.229 90.0381 216.966 90.0111C217.704 89.9842 218.422 90.2441 218.963 90.7336L229.852 100.602L251.396 79.5829C251.654 79.329 251.961 79.1274 252.3 78.9895C252.638 78.8517 253.001 78.7805 253.368 78.7798C253.734 78.7791 254.097 78.8491 254.436 78.9857C254.775 79.1223 255.083 79.3228 255.342 79.5758C255.601 79.8287 255.807 80.1291 255.947 80.4597C256.087 80.7903 256.158 81.1446 256.158 81.5023C256.157 81.86 256.084 82.214 255.942 82.5441C255.801 82.8741 255.594 83.1737 255.334 83.4256L231.893 106.296C231.635 106.548 231.328 106.748 230.99 106.885C230.652 107.022 230.289 107.092 229.924 107.091V107.091Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1183_3177">
        <rect width="500" height="372.973" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default OfferSuccess;
