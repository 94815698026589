import { useState } from 'react';
import { useMediaQuery, Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import appTheme from 'App.theme';
import { useImageResized, useWindowSize } from 'hooks';
import { IsLoggedIn } from 'utils/user';
import { Url } from 'utils/urls';
import { SMALL_DEVICE_HEIGHT } from 'utils/consts';
import { Button } from 'components';
import { PageTemplate } from 'components/PageTemplate';
import ContactUsModal from 'components/Modal/modals/ContactUs';

const Error = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const laptopBreakpoint = useMediaQuery(
    appTheme.breakpoints.between('lg', 'xl')
  );
  const tabletBreakpoint = useMediaQuery(appTheme.breakpoints.down('lg'));
  const mobileBreakpoint = useMediaQuery(appTheme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { isImageResized } = useImageResized();
  const { windowSize } = useWindowSize();
  const isSmallDevice = windowSize.height <= SMALL_DEVICE_HEIGHT;

  const buttons = IsLoggedIn() ? (
    <Button
      onClick={() => navigate(Url.dashboard)}
      size="large"
      variant="contained"
      sx={{
        padding: '16px 36px',
        marginTop: isImageResized ? '0' : '25px'
      }}
    >
      <Trans i18nKey="PAGE_ERROR_BUTTON_GOTO_DASHBOARD" />
    </Button>
  ) : (
    <Button
      onClick={() => navigate(Url.main)}
      size="large"
      variant="contained"
      sx={{
        padding: '16px 36px',
        marginTop: isImageResized ? '0' : '25px'
      }}
    >
      <Trans i18nKey="PAGE_ERROR_BUTTON_GOTO_MAINPAGE" />
    </Button>
  );

  const resizedImageParams = {
    height: isSmallDevice ? '100px' : '150px',
    svgHeight: isSmallDevice ? '120px' : '180px',
    marginTop: isSmallDevice ? '-20px' : 'auto'
  };

  return (
    <>
      <Helmet>
        <title>{t('ROUTING_ERROR_TITLE')}</title>
        <meta name="description" content={t('ROUTING_ERROR_DESCRIPTION')} />
      </Helmet>
      <ContactUsModal
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
      <PageTemplate
        heading={<Trans i18nKey="PAGE_ERROR_TITLE" />}
        message={
          <Trans i18nKey="PAGE_ERROR_MESSAGE">
            Wystąpił nieoczekiwany błąd. Spróbuj ponownie, a jeśli sytuacja
            będzie się powtarzała, koniecznie
            <Link
              onClick={() => setIsModalVisible(true)}
              variant="body1"
              sx={{ fontWeight: 700, cursor: 'pointer' }}
            >
              skontaktuj się z nami
            </Link>
            . Chętnie Ci pomożemy.
          </Trans>
        }
        buttons={buttons}
        image="error"
        imageWidth={
          mobileBreakpoint
            ? '420px'
            : tabletBreakpoint
            ? '550px'
            : laptopBreakpoint
            ? '650px'
            : '700px'
        }
        withBackgroundSection={false}
        resizedImageParams={resizedImageParams}
      />
    </>
  );
};

export default Error;
