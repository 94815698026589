import { API } from 'utils';
import {
  DocumentResponse,
  RejectResponse,
  AcceptSettlementResponse,
  AvailableDocumentsResponse,
  DMSDocumentResponse,
  AvailableDocuments
} from './documents.model';
import { RootDispatch } from '../store';

const endpoint = '/document';

const effects = (dispatch: RootDispatch) => ({
  async getDocument(agreementId: number): Promise<any | undefined> {
    return API.get<void, DocumentResponse>(`${endpoint}/${agreementId}`)
      .then((response) => {
        if (response.success) {
          return response.data.file;
        }
        return null;
      })
      .catch(() => undefined);
  },
  async rejectDocument(agreementId: number): Promise<any | undefined> {
    return API.delete<any, RejectResponse>(
      `${endpoint}/reject/${agreementId}`,
      []
    )
      .then((response) => response)
      .catch((error) => error);
  },
  async acceptDocument(agreementId: number): Promise<any | undefined> {
    return API.post<any, AcceptSettlementResponse>(
      `${endpoint}/accept/${agreementId}`
    )
      .then((response) => response)
      .catch((error) => error);
  },
  async getAvailableDocuments(
    agreementId: number
  ): Promise<AvailableDocuments[] | undefined> {
    return API.get<void, AvailableDocumentsResponse>(
      `${endpoint}/downloadable/${agreementId}`
    )
      .then((response) => {
        dispatch.documents.storeAvailableDocuments(response.data);
        return response.data;
      })
      .catch(() => undefined);
  },
  async getDMSDocument({
    agreementId,
    barcode
  }: {
    agreementId: number;
    barcode: string;
  }): Promise<string | undefined> {
    return API.get<void, DMSDocumentResponse>(
      `${endpoint}/${agreementId}/${barcode}`
    )
      .then((response) => response.data.file)
      .catch(() => undefined);
  }
});

export default effects;
