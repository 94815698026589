import { SvgIcon } from '@mui/material';

const Menu = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25 0H1V2.52632H25V0Z" />
    <path d="M25 7.57895H1V10.1053H25V7.57895Z" />
    <path d="M1.06737 22V15.796H2.06177L4.34097 20.9264H4.06817L6.34737 15.796H7.33297V22H6.30337V17.3976H6.62017L4.53457 22H3.85697L1.77137 17.3976H2.09697V22H1.06737ZM8.4516 22V15.796H12.6316V16.6848H9.5428V18.4008H12.4292V19.2984H9.5428V21.1024H12.6316V22H8.4516ZM13.4212 22V15.796H14.2748L17.874 20.4512H17.6452V15.796H18.7012V22H17.8476L14.2572 17.3448H14.4772V22H13.4212ZM22.3874 22.088C21.5015 22.088 20.8415 21.868 20.4074 21.428C19.9791 20.9821 19.765 20.3251 19.765 19.4568V15.796H20.9002V19.448C20.9002 19.9995 21.0175 20.416 21.2522 20.6976C21.4927 20.9792 21.8711 21.12 22.3874 21.12C22.8802 21.12 23.2498 20.9792 23.4962 20.6976C23.7484 20.416 23.8746 19.9995 23.8746 19.448V15.796H24.9922V19.4568C24.9922 20.3251 24.7722 20.9821 24.3322 21.428C23.898 21.868 23.2498 22.088 22.3874 22.088Z" />
  </SvgIcon>
);

export default Menu;
