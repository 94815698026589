import { SvgIcon } from '@mui/material';

const Doc = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4126)">
      <path d="M0 5.0073V24H17.4545V0H5.00724L0 5.0073ZM5.45455 2.62906V5.45455H2.62913L5.45455 2.62906ZM15.2727 2.18182V21.8182H2.18182V7.63636H7.63636V2.18182H15.2727Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4126">
        <rect width="17.4545" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Doc;
