export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export interface SendRequestParams<T> {
  endpoint: string;
  payload?: T | Partial<T>;
  method: RequestMethod;
}
