import { MouseEvent } from 'react';
import { Typography } from '@mui/material';
import theme from 'App.theme';
import { Icon } from 'components/Icon';
import { IconBox } from './styled/IconBox';
import { StyledButton } from './styled/StyledButton';
import { Bar } from './styled/Bar';
import { ButtonContainer } from './styled/ButtonContainer';

interface IBottomButtonBar {
  handleScroll: (event: MouseEvent) => void;
  buttonDescription: string;
  width: number;
}

export const BottomButtonBar = ({
  handleScroll,
  buttonDescription,
  width
}: IBottomButtonBar) => (
  <ButtonContainer theme={theme} disableGutters>
    <StyledButton onClick={handleScroll}>
      <IconBox>
        <Icon
          icon="chevronDown"
          color="primary"
          sx={{
            width: '16px'
          }}
        />
      </IconBox>
      <Bar $width={width} />
      <Typography
        variant="body2"
        align="center"
        sx={{
          fontWeight: 700,
          paddingTop: '37px',
          maxWidth: '329px'
        }}
        color="primary"
      >
        {buttonDescription}
      </Typography>
    </StyledButton>
  </ButtonContainer>
);
