const SettlementYellow = ({ ...props }) => (
  <svg
    width="194"
    height="194"
    {...props}
    viewBox="0 0 194 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2512_13982)">
      <path
        opacity="0.1"
        d="M153.994 147.988C207.562 147.988 250.988 104.562 250.988 50.9941C250.988 -2.57426 207.562 -46 153.994 -46C100.426 -46 57 -2.57426 57 50.9941C57 104.562 100.426 147.988 153.994 147.988Z"
        fill="#FBB13C"
      />
      <g clipPath="url(#clip1_2512_13982)">
        <path
          d="M141 13.5143C140.953 11.8701 141.236 10.2335 141.833 8.70084C142.431 7.16816 143.329 5.77113 144.477 4.59246C145.712 3.38328 147.182 2.44006 148.797 1.82218C150.411 1.2043 152.136 0.924237 153.863 0.99966C155.592 0.902803 157.322 1.1734 158.939 1.79248C160.557 2.41156 162.026 3.3658 163.248 4.59246C164.451 5.73574 165.394 7.12332 166.014 8.66265C166.634 10.202 166.915 11.8566 166.84 13.5143C166.915 15.172 166.634 16.8267 166.014 18.366C165.394 19.9054 164.451 21.2936 163.248 22.4369C162.009 23.6405 160.538 24.5798 158.924 25.1973C157.311 25.8148 155.589 26.0976 153.863 26.029C152.139 26.0779 150.422 25.7855 148.811 25.169C147.201 24.5526 145.728 23.6243 144.477 22.4369C142.181 20.0413 140.93 16.8317 141 13.5143ZM165.682 99.7253L161.163 100.42C159.175 100.788 157.159 100.982 155.137 101C153.324 101.134 151.504 101.134 149.691 101C148.191 100.799 146.761 100.243 145.52 99.378C144.395 98.4483 143.519 97.2532 142.97 95.9012C142.303 94.125 141.988 92.236 142.043 90.3394V33.6765L146.562 32.9812C148.551 32.6161 150.566 32.4224 152.588 32.402C154.439 32.2678 156.298 32.2678 158.15 32.402C159.619 32.5836 161.016 33.1426 162.206 34.0244C163.366 34.9193 164.25 36.1244 164.755 37.5005C165.479 39.261 165.796 41.1621 165.682 43.0623V99.7253Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2512_13982">
        <rect width="194" height="194" fill="white" />
      </clipPath>
      <clipPath id="clip1_2512_13982">
        <rect
          width="25.8401"
          height="100"
          fill="white"
          transform="translate(141 1)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SettlementYellow;
