import { SvgIcon } from '@mui/material';

const ChevronUp = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.2409 7.79547L7.8687 0.167236L0.496442 7.79547C0.296165 8.00339 0.186685 8.28234 0.192086 8.57098C0.197486 8.85961 0.317325 9.13428 0.52524 9.33456C0.733154 9.53483 1.01211 9.64431 1.30075 9.63891C1.58938 9.63351 1.86405 9.51367 2.06433 9.30576L7.8687 3.2966L13.6795 9.30576C13.8797 9.51367 14.1544 9.63351 14.443 9.63891C14.7317 9.64431 15.0106 9.53483 15.2186 9.33455C15.4265 9.13428 15.5463 8.85961 15.5517 8.57098C15.5571 8.28234 15.4476 8.00338 15.2473 7.79547L15.2409 7.79547Z" />
  </SvgIcon>
);

export default ChevronUp;
