import { ProductParametersKey } from 'storage/agreements/agreements.model';
import {
  InstallmentsPaymentValueChanged,
  PrerequisitePaymentValueChanged,
  SettlementZeroWithPrerequisiteValueChanged
} from 'storage/product/product.model';
import { formatCurrency } from './formatCurrency';

const setPayloadForRecalculateZeroWithPrerequisite = (
  prerequisitePaymentDate: string,
  valueChanged:
    | PrerequisitePaymentValueChanged
    | InstallmentsPaymentValueChanged,
  firstInstallmentDate?: string,
  installmentsCount?: number,
  installmentsAmount?: number
) => {
  if (firstInstallmentDate && installmentsCount && installmentsAmount) {
    return [
      {
        key: ProductParametersKey.PREREQUISITE_PAYMENT_DATE,
        value: prerequisitePaymentDate,
        valueChanged:
          valueChanged ===
          SettlementZeroWithPrerequisiteValueChanged.prerequisiteDate
      },
      {
        key: ProductParametersKey.INSTALLMENTS_COUNT,
        value: installmentsCount.toString(),
        valueChanged:
          valueChanged ===
          SettlementZeroWithPrerequisiteValueChanged.installmentsCount
      },
      {
        key: ProductParametersKey.INSTALLMENTS_AMOUNT,
        value: formatCurrency(
          'plain',
          installmentsAmount,
          undefined,
          false,
          false,
          false
        ) as string,
        valueChanged:
          valueChanged ===
          SettlementZeroWithPrerequisiteValueChanged.installmentsAmount
      },
      {
        key: ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
        value: firstInstallmentDate,
        valueChanged: false
      }
    ];
  }

  return [
    {
      key: ProductParametersKey.PREREQUISITE_PAYMENT_DATE,
      value: prerequisitePaymentDate,
      valueChanged:
        valueChanged ===
        SettlementZeroWithPrerequisiteValueChanged.prerequisiteDate
    }
  ];
};

export default setPayloadForRecalculateZeroWithPrerequisite;
