import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import effects from './payment.effects';

const paymentModel = createModel<RootModel>()({
  state: {},
  effects
});

export default paymentModel;
