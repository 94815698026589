import { LiveChatState, LiveChatTypes } from './liveChat.model';

const reducers = {
  setVisibility: (state: LiveChatState, visibility: LiveChatTypes) => ({
    ...state,
    visibility
  })
};

export default reducers;
