import { TFunction } from 'react-i18next';
import lazyWithRetry from 'utils/lazyWithRetry';
import { Login } from './pages';
import TechnicalBreak from './pages/TechnicalBreak/TechnicalBreak';
import Dashboard from './pages/Dashboard';

const InformationObligation = lazyWithRetry(
  () => import('./pages/InformationObligation/InformationObligation')
);
const MarketingConsent = lazyWithRetry(
  () => import('./pages/MarketingConsent/MarketingConsent')
);
const UnblockAccount = lazyWithRetry(
  () => import('./pages/account/UnblockAccount')
);
const ResetPassword = lazyWithRetry(
  () => import('./pages/account/ResetPassword')
);
const Register = lazyWithRetry(() => import('./pages/account/Register'));
const RegisterFromACP = lazyWithRetry(
  () => import('./pages/account/Register/RegisterFromACP/Main/Main')
);
const Impersonation = lazyWithRetry(
  () => import('./pages/Impersonation/Impersonation')
);
const Error = lazyWithRetry(() => import('./pages/Error/Error'));
const Faq = lazyWithRetry(() => import('./pages/Faq/Faq'));
const Contact = lazyWithRetry(() => import('./pages/Contact/Contact'));
const NotFound = lazyWithRetry(() => import('./pages/NotFound/NotFound'));

export interface AppSubRoute {
  key: string;
  path: string;
  element: JSX.Element | null;
  desktopHideMenuBar?: boolean;
  authenticatedOnly?: boolean;
  unauthenticatedOnly?: boolean;
  authorizedOnly?: boolean;
}

export interface AppRoute extends AppSubRoute {
  title: string;
  menuTitle?: string;
  description: string;
}

const routes = (t: TFunction<'translation'>): AppRoute[] => [
  {
    key: 'main-route',
    path: '/',
    title: '',
    description: '',
    element: null,
    authenticatedOnly: true,
    desktopHideMenuBar: true
  },
  {
    key: 'route-contact',
    path: '/contact',
    title: t('ROUTING_CONTACT_TITLE'),
    description: t('ROUTING_CONTACT_DESCRIPTION'),
    element: <Contact />
  },
  {
    key: 'error',
    path: '/error',
    title: t('ROUTING_ERROR_TITLE'),
    description: t('ROUTING_ERROR_DESCRIPTION'),
    element: <Error />,
    desktopHideMenuBar: true
  },
  {
    key: 'not-found',
    path: '/not-found',
    title: t('ROUTING_404_TITLE'),
    description: t('ROUTING_404_DESCRIPTION'),
    element: <NotFound />,
    desktopHideMenuBar: true
  },
  {
    key: 'route-login',
    path: '/login',
    title: t('ROUTING_LOGIN_TITLE'),
    description: t('ROUTING_LOGIN_DESCRIPTION'),
    element: <Login />,
    desktopHideMenuBar: true
  },
  {
    key: 'route-impersonation',
    path: '/impersonation/:tempPassword',
    title: '',
    description: '',
    element: <Impersonation />
  },
  {
    key: 'route-register',
    path: '/register/*',
    title: t('ROUTING_REGISTER_TITLE'),
    description: t('ROUTING_REGISTER_DESCRIPTION'),
    element: <Register />,
    desktopHideMenuBar: true,
    unauthenticatedOnly: true
  },
  {
    key: 'route-register',
    path: '/register-from-acp',
    title: t('ROUTING_REGISTER_TITLE'),
    description: t('ROUTING_REGISTER_DESCRIPTION'),
    element: <RegisterFromACP />,
    desktopHideMenuBar: true,
    unauthenticatedOnly: true
  },
  {
    key: 'route-reset-password',
    path: '/reset-password/*',
    title: t('ROUTING_RESET_PASSWORD_TITLE'),
    description: t('ROUTING_RESET_PASSWORD_DESCRIPTION'),
    element: <ResetPassword />,
    desktopHideMenuBar: true,
    unauthenticatedOnly: true
  },
  {
    key: 'route-unblock-account',
    path: '/unblock-account/*',
    title: t('ROUTING_UNBLOCK_ACCOUNT_TITLE'),
    description: t('ROUTING_UNBLOCK_ACCOUNT_DESCRIPTION'),
    element: <UnblockAccount />,
    desktopHideMenuBar: true,
    unauthenticatedOnly: true
  },
  {
    key: 'route-dashboard',
    path: '/dashboard/*',
    title: t('ROUTING_DASHBOARD_TITLE'),
    description: t('ROUTING_DASHBOARD_DESCRIPTION'),
    element: <Dashboard />,
    authenticatedOnly: true,
    desktopHideMenuBar: false
  },
  {
    key: 'information-obligation',
    path: '/information-obligation',
    title: t('ROUTING_INFORMATION_OBLIGATION_TITLE'),
    description: t('ROUTING_INFORMATION_OBLIGATION_DESCRIPTION'),
    element: <InformationObligation />,
    authenticatedOnly: true,
    desktopHideMenuBar: false
  },
  {
    key: 'marketing-consent',
    path: '/marketing-consents',
    title: t('ROUTING_MARKETING_CONSENT_TITLE'),
    description: t('ROUTING_MARKETING_CONSENT_DESCRIPTION'),
    element: <MarketingConsent />,
    authenticatedOnly: true,
    desktopHideMenuBar: false
  },
  {
    key: 'route-faq',
    path: '/faq',
    title: t('ROUTING_FAQ_TITLE'),
    description: t('ROUTING_FAQ_DESCRIPTION'),
    element: <Faq />,
    desktopHideMenuBar: false
  },
  {
    key: 'route-technical-break',
    path: '/technical-break',
    title: t('ROUTING_LOGIN_TITLE'),
    description: t('ROUTING_LOGIN_DESCRIPTION'),
    element: <TechnicalBreak />
  },
  {
    key: 'faces-route',
    path: '/faces/*',
    title: '',
    description: '',
    element: null,
    desktopHideMenuBar: true
  },
  {
    key: 'rs-route',
    path: '/rs/*',
    title: '',
    description: '',
    element: null
  },
  {
    key: 'not-found-route',
    path: '*',
    title: '',
    description: '',
    element: null
  }
];

export default routes;
