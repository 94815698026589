const SettlementRed = ({ ...props }) => (
  <svg
    width="194"
    height="194"
    {...props}
    viewBox="0 0 194 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2782_14539)">
      <path
        opacity="0.07"
        d="M153.994 148.269C207.562 148.269 250.988 104.844 250.988 51.2754C250.988 -2.29301 207.562 -45.7188 153.994 -45.7188C100.426 -45.7188 57 -2.29301 57 51.2754C57 104.844 100.426 148.269 153.994 148.269Z"
        fill="#E73F0D"
      />
      <path
        d="M148.15 111.939C155.556 114.735 163.826 110.997 166.621 103.591C169.417 96.1856 165.68 87.9158 158.274 85.1203C150.868 82.3248 142.598 86.0622 139.803 93.468C137.007 100.874 140.744 109.144 148.15 111.939Z"
        fill="white"
      />
      <path
        d="M151.056 73.7802C149.197 73.0788 147.582 71.8537 146.405 70.253C145.228 68.6524 144.541 66.7449 144.425 64.7616L141.232 8.82071C141.126 6.96725 141.401 5.11173 142.038 3.36821C142.676 1.62468 143.664 0.0300089 144.94 -1.31779C146.217 -2.66558 147.756 -3.73802 149.462 -4.46916C151.169 -5.20031 153.007 -5.57471 154.863 -5.56937C156.72 -5.56402 158.555 -5.17902 160.258 -4.43806C161.96 -3.69709 163.493 -2.61581 164.761 -1.26068C166.03 0.0944545 167.009 1.69479 167.636 3.44196C168.264 5.18913 168.528 7.0462 168.411 8.89902L164.903 64.8299C164.802 66.4301 164.328 67.9845 163.519 69.3684C162.709 70.7522 161.586 71.927 160.24 72.7984C158.894 73.6699 157.363 74.2137 155.769 74.3862C154.175 74.5588 152.563 74.3552 151.061 73.792L151.056 73.7802Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2782_14539">
        <rect width="194" height="194" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SettlementRed;
