import { AlertsState, IAlert, AlertTypes } from './alerts.model';

const alertsReducer = {
  setAlert: (state: AlertsState, alert: IAlert) => ({
    ...state,
    [alert.variant]: [...state[alert.variant], alert]
  }),
  clearAlert: (state: AlertsState, alert: AlertTypes) => ({
    ...state,
    [alert]: []
  }),
  clearAlerts: (state: AlertsState) => ({
    ...state,
    success: [],
    error: [],
    warning: []
  })
};

export default alertsReducer;
