export const IsLoggedIn = () => {
  if (!isLocalStorageEnabled()) {
    return false;
  }
  const token = localStorage.getItem('token');
  const minTokenLength = 3;
  return token && token.length > minTokenLength;
};

function isLocalStorageEnabled() {
  try {
    const mod = '__storage_test__';
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}
