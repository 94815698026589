import { SvgIcon } from '@mui/material';

const SecureShield = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 27 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3261_16801)">
      <path d="M26.9994 20.8208V0H16.3161V2.87382H17.6515V0H9.63896V2.87382V0H0.291016V20.8208L13.6452 32L26.9994 20.8208ZM12.3098 2.87382H24.3286V19.4127L13.6452 28.3503L2.96186 19.4127V2.87382H13.6452" />
      <path d="M19.6548 10.5047L17.7517 8.5332L11.9614 14.5317L10.2068 12.728L8.30371 14.6995L11.9614 18.4888L19.6548 10.5047Z" />
    </g>
    <defs>
      <clipPath id="clip0_3261_16801">
        <rect
          width="26.7084"
          height="32"
          fill="white"
          transform="translate(0.291016)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default SecureShield;
