import { Typography, Link } from '@mui/material';
import { List } from '../styled/List';
import { ListItem } from '../styled/ListItem';
import {
  linkChrome,
  linkOpera,
  linkEdge,
  linkSafari,
  linkGoogleSettings,
  linkFirefox
} from '../utils/urls';

const Cookies = () => (
  <>
    <Typography variant="h5" color="text" fontWeight="700">
      COOKIES (CIASTECZKA)
    </Typography>
    <Typography variant="body1" color="text">
      Ciasteczka to niewielkie pliki tekstowe, które są wysyłane przez Serwis do
      przeglądarki internetowej zainstalowanej w urządzeniu Użytkownika, a
      następnie wysyłane są przez przeglądarkę internetową tego Użytkownika
      podczas każdorazowego wejścia do Serwisu.{' '}
    </Typography>{' '}
    <Typography variant="body1" color="text" sx={{ marginTop: '10px' }}>
      Każdy plik Cookie zawiera informacje dotyczące w szczególności: nazwy,
      wartości, domeny, czasu wygaśnięcia, daty ostatniego dostępu, atrybutów
      oraz ścieżki. Zapisany plik Cookie może być następnie odczytywany i
      modyfikowany przez Serwis pod warunkiem, że pochodzi z tej samej domeny
      skąd plik Cookie został przesłany.{' '}
    </Typography>
    <Typography variant="body1" color="text" sx={{ marginTop: '10px' }}>
      Ciasteczka nie są szkodliwe dla urządzenia Użytkownika, nie utrudniają
      pracy urządzenia lub oprogramowania zainstalowanego w tym urządzeniu, ani
      nie powodują zmian konfiguracyjnych w urządzeniu Użytkownika. Dzięki
      Ciasteczkom korzystanie z Serwisu jest łatwiejsze i bardziej komfortowe
      dla Użytkownika.
    </Typography>
    <Typography variant="h6" fontWeight="700" sx={{ margin: '20px 0' }}>
      W jakim celu stosujemy Ciasteczka?
    </Typography>
    <Typography variant="h5" sx={{ marginTop: '0' }} fontWeight="700">
      STOSOWANIE COOKIES W SERWISIE
    </Typography>
    <Typography variant="body1" color="text">
      Na stronach internetowych Serwisu wykorzystywane są następujące
      Ciasteczka:
    </Typography>
    <List>
      <ListItem dense>
        Niezbędne – umożliwiające korzystanie z Serwisu;
      </ListItem>
      <ListItem dense>
        Sesyjne – używane podczas tworzenia sesji Użytkownika, czy podczas
        logowania do Serwisu. Pliki te są tymczasowe i niezbędne, aby dostosować
        zawartość stron internetowych Serwisu do preferencji Użytkownika oraz
        rozpoznać urządzenie Użytkownika Serwisu i dostosować wyświetlenia
        strony internetowej do jego indywidualnych potrzeb;
      </ListItem>
      <ListItem dense>
        Statystyczne – do zbierania danych statycznych za pośrednictwem narzędzi
        analitycznych;
      </ListItem>
      <ListItem dense>
        Umożliwiające dostęp do kanałów komunikacji dostosowanych do potrzeb
        Użytkowników;
      </ListItem>
      <ListItem dense>Pliki służące do zapewnienia bezpieczeństwa;</ListItem>
      <ListItem>
        Pliki służące do poprawy funkcjonowania Serwisu – służące do
        optymalizacji działań Serwisu;
      </ListItem>
      <ListItem dense>
        Marketingowe – dostosowywanie reklam do preferencji Użytkowników;
      </ListItem>
      <ListItem dense>Do prezentowania treści multimedialnych;</ListItem>
      <ListItem dense>
        Dotyczące polityki w zakresie Cookies – oznaczenie, że poinformowano
        Użytkownika o stosowaniu mechanizmu Ciasteczek przez Serwis.
      </ListItem>
    </List>
    <Typography variant="body1" color="text" marginBottom="2px">
      Na stronach internetowych Serwisu wykorzystywane są Ciasteczka
      Zewnętrznych dostawców w następujących celach:
    </Typography>
    <List>
      <ListItem dense sx={{ display: 'block', fontWeight: '400' }}>
        Zbierania danych statycznych za pośrednictwem narzędzi analitycznych:
        <List>
          <ListItem dense $listType="lower-latin">
            Google Analytics (administrator Cookies: Google LLC z siedzibą w
            USA),
          </ListItem>
        </List>
      </ListItem>
      <ListItem dense sx={{ display: 'block', fontWeight: '400' }}>
        Prezentowania reklam dostosowanych do potrzeb Użytkownika z
        wykorzystaniem aplikacji pozwalającej na efektywne zarządzanie strategią
        marketingową:{' '}
        <List>
          <ListItem dense $listType="lower-latin">
            Google Marketing Platform (administrator Cookies: Google LLC z
            siedzibą w USA),
          </ListItem>
        </List>
      </ListItem>
      <ListItem dense sx={{ display: 'block', fontWeight: '400' }}>
        Wykorzystania funkcji serwisów społecznościowych do promocji naszych
        serwisów:{' '}
        <List>
          <ListItem dense $listType="lower-latin">
            Facebook.com (administrator Cookies: Meta Platforms, Inc. z siedzibą
            w USA lub Meta Platforms Ireland Limited z siedzibą w Irlandii).
          </ListItem>
        </List>
      </ListItem>
    </List>
    <Typography variant="body1">
      Cookies sesyjne pozostają na Twoim urządzeniu jedynie podczas korzystania
      z Serwisu, pozostałe Cookies pozostają na Twoim urządzeniu tak długo jak
      długo mają ustawiony czas istnienia lub do momentu, kiedy je usuniesz.
    </Typography>
    <Typography variant="h5" fontWeight="700" margin="27px 0 10px">
      ZARZĄDZANIE COOKIES
    </Typography>
    <List>
      <ListItem dense>
        Wyrażenie zgody przez Użytkownika na stosowanie Cookies oznacza
        akceptację stosowania Cookies na stronach internetowych Serwisu;
      </ListItem>
      <ListItem dense>
        W każdym czasie Użytkownik może dokonywać zmian ustawień dotyczących
        plików Cookies. Ustawienia te mogą zostać zmienione w ustawieniach
        przeglądarki internetowej,
      </ListItem>
      <ListItem dense>
        Wyłączenie bądź ograniczenie stosowania Cookies może utrudnić bądź
        uniemożliwić korzystanie z niektórych funkcjonalności Serwisu.
      </ListItem>
    </List>
    <Typography variant="body1" sx={{ margin: '10px 0' }}>
      Poniżej przedstawiamy linki do instrukcji najpopularniejszych przeglądarek
      informujące o tym jak dokonać zmiany w ustawieniach dotyczących plików
      Cookies:
    </Typography>
    <List>
      <ListItem dense>
        {' '}
        <Link
          sx={{ fontWeight: 700, lineHeight: '125%' }}
          color="primary.light"
          href={linkChrome}
          target="_blank"
          rel="nofollow"
          data-link-no-alert
        >
          {' '}
          Google Chrome
        </Link>
      </ListItem>
      <ListItem dense>
        <Link
          sx={{ fontWeight: 700, lineHeight: '125%' }}
          color="primary.light"
          href={linkFirefox}
          target="_blank"
          rel="nofollow"
          data-link-no-alert
        >
          {' '}
          Firefox
        </Link>
      </ListItem>
      <ListItem dense>
        {' '}
        <Link
          sx={{ fontWeight: 700, lineHeight: '125%' }}
          color="primary.light"
          href={linkEdge}
          target="_blank"
          rel="nofollow"
          data-link-no-alert
        >
          Microsoft Edge
        </Link>
      </ListItem>
      <ListItem dense>
        {' '}
        <Link
          sx={{ fontWeight: 700, lineHeight: '125%' }}
          color="primary.light"
          href={linkOpera}
          target="_blank"
          rel="nofollow"
          data-link-no-alert
        >
          Opera
        </Link>
      </ListItem>
      <ListItem dense>
        {' '}
        <Link
          sx={{ fontWeight: 700, lineHeight: '125%' }}
          color="primary.light"
          href={linkSafari}
          target="_blank"
          rel="nofollow"
          data-link-no-alert
        >
          Safari
        </Link>
      </ListItem>
    </List>
    <Typography variant="body1" sx={{ margin: '10px 0' }}>
      Użytkownik może również zrezygnować z Cookies Google w ustawieniach reklam
      (
      <Link
        sx={{ fontWeight: 700, lineHeight: '125%' }}
        color="primary.light"
        href={linkGoogleSettings}
        target="_blank"
        rel="nofollow"
        data-link-no-alert
      >
        adssettings.google.pl
      </Link>
      ).
    </Typography>
  </>
);

export default Cookies;
