import { ReactElement } from 'react';
import {
  LocalizationProvider as Localization,
  plPL
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import enLocale from 'date-fns/locale/en-US';
import { Locale } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'storage/store';

const locales: Record<string, Locale> = {
  pl: plLocale,
  en: enLocale
};

export interface ILocalizationProvider {
  children?: ReactElement | ReactElement[];
}

const LocalizationProvider = ({ children }: ILocalizationProvider) => {
  const language = useSelector((state: RootState) => state.user.language);

  return (
    <Localization
      dateAdapter={AdapterDateFns}
      adapterLocale={locales[language]}
      localeText={
        plPL.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      {children}
    </Localization>
  );
};

export default LocalizationProvider;
