import {
  DynamicSimulationData,
  Offer,
  PartialSimulationData,
  ProductState,
  TotalSimulationData,
  ZeroSimulationData,
  ZeroWithPrerequisiteSimulationData,
  PlusSimulationData
} from './product.model';

const reducers = {
  storeAllOffers: (state: ProductState, offers: Offer[]) => ({
    ...state,
    userOffers: offers
  }),
  storeDynamicSimulation: (
    state: ProductState,
    dynamicSimulation: DynamicSimulationData
  ) => ({
    ...state,
    dynamicSimulation
  }),
  storePitRequired: (state: ProductState, pitRequired: boolean) => ({
    ...state,
    pitRequired
  }),
  storeInitialAnnexSimulation: (
    state: ProductState,
    zeroSimulation: ZeroSimulationData
  ) => ({
    ...state,
    initialAnnexSimulation: zeroSimulation
  }),
  storePartialSimulation: (
    state: ProductState,
    partialSimulation: PartialSimulationData
  ) => ({
    ...state,
    partialSimulation
  }),
  storeZeroSimulation: (
    state: ProductState,
    zeroSimulation: ZeroSimulationData
  ) => ({
    ...state,
    zeroSimulation
  }),
  storeZeroWithPrerequisiteSimulation: (
    state: ProductState,
    zeroWithPrerequisiteSimulation: ZeroWithPrerequisiteSimulationData
  ) => ({
    ...state,
    zeroWithPrerequisiteSimulation
  }),
  storeTotalSimulation: (
    state: ProductState,
    totalSimulation: TotalSimulationData
  ) => ({
    ...state,
    totalSimulation
  }),
  storePlusSimulation: (
    state: ProductState,
    plusSimulation: PlusSimulationData
  ) => ({
    ...state,
    plusSimulation
  })
};

export default reducers;
