import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const MenuItemNavLink = styled(NavLink)`
  && {
    margin: 8px 0;
    padding: 10px 0;
    width: 100%;
    text-decoration: none;
  }
`;

export default MenuItemNavLink;
