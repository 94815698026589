import styled from 'styled-components/macro';
import { LoadingButton } from '@mui/lab';

import theme from 'App.theme';
import { colors } from 'components/Colors';
import { Color, Variant, Status, IButton } from '../Button.model';

const defaultColors = {
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
  success: theme.palette.success.main,
  warning: theme.palette.warning.main,
  error: theme.palette.error.main,
  info: theme.palette.info.main,
  inherit: 'inherit'
};
const hoverColors = {
  primary: theme.palette.primary.light,
  secondary: colors.common.grey.value,
  success: theme.palette.success.light,
  warning: theme.palette.warning.light,
  error: theme.palette.error.light,
  info: theme.palette.info.light,
  inherit: 'inherit'
};

const disabledColors = {
  primary: theme.palette.info.light,
  secondary: theme.palette.secondary.light,
  success: theme.palette.info.light,
  warning: colors.orange.grey.value,
  error: colors.red.grey.value,
  info: theme.palette.info.light,
  inherit: 'inherit'
};

const getCurrentColor = (status: Status, color?: Color) => {
  switch (status) {
    case 'hover':
      return hoverColors[color ?? 'primary'];
    case 'loading':
      return defaultColors[color ?? 'primary'];
    default:
      return disabledColors[color ?? 'primary'];
  }
};

const getStyles = (status: Status, variant?: Variant, color?: Color) => {
  switch (variant) {
    case 'contained':
      return `
        background-color: ${getCurrentColor(status, color)};
        `;
    case 'outlined':
      return `
            background-color: transparent;
            border-color: ${getCurrentColor(status, color)};
            color: ${
              status === 'loading'
                ? 'transparent'
                : getCurrentColor(status, color)
            };
          `;
    case 'text':
      return `
          color: ${
            status === 'loading'
              ? 'transparent'
              : getCurrentColor(status, color)
          };
          background-color: transparent;
        `;
    default:
      return null;
  }
};

export const ButtonContainer = styled(LoadingButton)<IButton>`
  &.MuiButton-contained {
    color: ${({ loading }) =>
      loading ? 'transparent' : theme.palette.common.white};
  }

  &.MuiLoadingButton-root.Mui-disabled {
    ${({ color, variant, loading }) =>
      getStyles(loading ? 'loading' : 'disabled', variant, color)};

    .MuiLoadingButton-loadingIndicator {
      height: ${({ size }) => (size === 'small' ? '20px' : '24px')};
      color: ${({ color, variant }) =>
        variant === 'contained'
          ? theme.palette.common.white
          : defaultColors[color ?? 'primary']};
    }
  }

  &.MuiLoadingButton-root {
    &:hover {
      ${({ color, variant }) => getStyles('hover', variant, color)};
    }
  }
`;
