import { IIllustration } from '../Illustration.model';

const ResetPassword = ({ width, height }: IIllustration) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 302 271"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M299.351 103.636C299.242 103.636 299.137 103.603 299.048 103.541L152.521 1.53175C152.075 1.22276 151.545 1.05763 151.002 1.05856C150.46 1.05948 149.93 1.22642 149.485 1.53692L4.01318 103.54C3.89812 103.621 3.75572 103.652 3.61729 103.628C3.47886 103.604 3.35574 103.525 3.27504 103.41C3.19433 103.295 3.16264 103.153 3.18693 103.015C3.21123 102.876 3.28952 102.753 3.40458 102.673L148.877 0.669546C149.5 0.234902 150.241 0.00126054 151 1.26543e-05C151.76 -0.00123523 152.502 0.229969 153.126 0.662563L299.654 102.672C299.746 102.736 299.816 102.828 299.852 102.935C299.889 103.042 299.89 103.157 299.856 103.265C299.823 103.373 299.755 103.467 299.664 103.533C299.573 103.6 299.463 103.636 299.351 103.636L299.351 103.636Z"
      fill="#3F3D56"
    />
    <path
      d="M12.3259 107.252L151.146 4.40609L291.02 114.57L158.299 193.22L86.2428 176.802L12.3259 107.252Z"
      fill="#E6E6E6"
    />
    <path
      d="M92.325 241.799H23.7443C23.3232 241.8 22.9061 241.717 22.5169 241.557C22.1277 241.396 21.774 241.16 21.476 240.863C21.1781 240.565 20.9417 240.212 20.7804 239.823C20.6192 239.434 20.5361 239.017 20.5361 238.596C20.5361 238.176 20.6192 237.759 20.7804 237.37C20.9417 236.981 21.1781 236.628 21.476 236.33C21.774 236.033 22.1277 235.797 22.5169 235.636C22.9061 235.475 23.3232 235.393 23.7443 235.394H92.325C92.7461 235.393 93.1631 235.475 93.5524 235.636C93.9416 235.797 94.2953 236.033 94.5932 236.33C94.8912 236.628 95.1276 236.981 95.2888 237.37C95.4501 237.759 95.5331 238.176 95.5331 238.596C95.5331 239.017 95.4501 239.434 95.2888 239.823C95.1276 240.212 94.8912 240.565 94.5932 240.863C94.2953 241.16 93.9416 241.396 93.5524 241.557C93.1631 241.717 92.7461 241.8 92.325 241.799Z"
      fill="#006D48"
    />
    <path
      d="M48.3495 228.029H23.7443C23.3232 228.029 22.9061 227.947 22.5169 227.786C22.1277 227.626 21.774 227.39 21.476 227.092C21.1781 226.795 20.9417 226.442 20.7804 226.053C20.6192 225.664 20.5361 225.247 20.5361 224.826C20.5361 224.405 20.6192 223.988 20.7804 223.599C20.9417 223.21 21.1781 222.857 21.476 222.56C21.774 222.262 22.1277 222.026 22.5169 221.866C22.9061 221.705 23.3232 221.623 23.7443 221.623H48.3495C48.7706 221.623 49.1877 221.705 49.5769 221.866C49.9661 222.026 50.3198 222.262 50.6178 222.56C50.9157 222.857 51.1521 223.21 51.3134 223.599C51.4747 223.988 51.5577 224.405 51.5577 224.826C51.5577 225.247 51.4747 225.664 51.3134 226.053C51.1521 226.442 50.9157 226.795 50.6178 227.092C50.3198 227.39 49.9661 227.626 49.5769 227.786C49.1877 227.947 48.7706 228.029 48.3495 228.029Z"
      fill="#006D48"
    />
    <path
      d="M153.098 164.536C152.577 164.536 152.061 164.435 151.578 164.238L65.9634 128.727V17.5703C65.9645 16.5874 66.3556 15.6451 67.0509 14.95C67.7462 14.255 68.6889 13.864 69.6722 13.8629H233.918C234.901 13.864 235.844 14.255 236.539 14.95C237.234 15.6451 237.625 16.5874 237.626 17.5703V128.804L237.466 128.873L154.664 164.221C154.169 164.429 153.636 164.536 153.098 164.536Z"
      fill="white"
    />
    <path
      d="M153.098 164.8C152.542 164.801 151.992 164.693 151.477 164.483L65.6982 128.903V17.5703C65.6994 16.5171 66.1185 15.5075 66.8634 14.7628C67.6084 14.0181 68.6184 13.5992 69.6719 13.598H233.918C234.971 13.5992 235.981 14.0181 236.726 14.7628C237.471 15.5075 237.89 16.5171 237.891 17.5703V128.979L154.768 164.464C154.24 164.686 153.672 164.801 153.098 164.8ZM66.7579 128.196L151.881 163.504C152.675 163.826 153.564 163.82 154.354 163.489L236.832 128.28V17.5703C236.831 16.798 236.523 16.0576 235.977 15.5115C235.431 14.9654 234.69 14.6582 233.918 14.6573H69.6719C68.8994 14.6582 68.1587 14.9654 67.6124 15.5115C67.0661 16.0576 66.7588 16.798 66.7579 17.5703L66.7579 128.196Z"
      fill="#3F3D56"
    />
    <path
      d="M298.291 102.577H298.185L237.361 128.539L154.142 164.062C153.817 164.198 153.468 164.27 153.115 164.272C152.762 164.273 152.413 164.206 152.086 164.072L66.2281 128.465L3.91004 102.619L3.81484 102.577H3.70877C2.72548 102.578 1.78277 102.969 1.08748 103.664C0.392183 104.359 0.00109104 105.301 0 106.284V267.293C0.00109244 268.275 0.392183 269.218 1.08748 269.913C1.78277 270.608 2.72548 270.999 3.70877 271H298.291C299.275 270.999 300.217 270.608 300.913 269.913C301.608 269.218 301.999 268.275 302 267.293V106.284C301.999 105.301 301.608 104.359 300.913 103.664C300.217 102.969 299.275 102.578 298.291 102.577ZM300.94 267.293C300.94 267.995 300.661 268.668 300.164 269.165C299.667 269.661 298.994 269.94 298.291 269.941H3.70877C3.00627 269.94 2.33262 269.661 1.83587 269.165C1.33913 268.668 1.05993 267.995 1.05965 267.293V106.284C1.06061 105.599 1.32634 104.941 1.80129 104.448C2.27623 103.954 2.92362 103.663 3.60814 103.636L66.2281 129.609L151.678 165.052C152.603 165.428 153.64 165.423 154.561 165.036L237.361 129.689L298.397 103.636C299.081 103.665 299.726 103.957 300.2 104.45C300.674 104.944 300.939 105.6 300.94 106.284V267.293Z"
      fill="#3F3D56"
    />
    <path
      d="M152.242 133.016C149.082 133.022 146.005 132 143.476 130.105L143.319 129.987L110.305 104.72C108.776 103.549 107.493 102.088 106.529 100.421C105.565 98.7545 104.938 96.9142 104.685 95.0054C104.433 93.0966 104.558 91.1567 105.056 89.2965C105.553 87.4364 106.412 85.6923 107.583 84.1639C108.755 82.6355 110.216 81.3527 111.883 80.3888C113.551 79.4249 115.392 78.7988 117.301 78.5461C119.211 78.2934 121.151 78.4192 123.012 78.9162C124.873 79.4132 126.618 80.2718 128.147 81.4428L149.531 97.8338L200.064 31.9545C201.236 30.4265 202.698 29.1442 204.365 28.1809C206.033 27.2175 207.874 26.592 209.784 26.34C211.694 26.0881 213.634 26.2145 215.495 26.7123C217.355 27.21 219.1 28.0692 220.628 29.2409L220.314 29.6673L220.636 29.2471C223.72 31.6159 225.737 35.1106 226.246 38.9644C226.755 42.8183 225.714 46.7165 223.351 49.804L163.911 127.294C162.537 129.08 160.769 130.525 158.745 131.517C156.722 132.509 154.496 133.022 152.242 133.016Z"
      fill="#006D48"
    />
  </svg>
);

export default ResetPassword;
