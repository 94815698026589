import { API } from 'utils';

const endpoint = '/dataSurveyor/recordDownloadAppButtonClick/';

const effects = () => ({
  recordButtonClick(buttonType: string) {
    return API.post(`${endpoint}${buttonType}`);
  }
});

export default effects;
