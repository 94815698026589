import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../storage/store';

const useStepDispatch = (formStep: number) => {
  const dispatch = useDispatch<RootDispatch>();

  useEffect(() => {
    dispatch.processStep.storeStep(formStep);

    return () => {
      dispatch.processStep.storeStep(null);
    };
  }, [formStep]);

  return null;
};

export default useStepDispatch;
