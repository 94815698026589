import { API } from 'utils';
import { RootDispatch } from '../store';
import {
  Statement,
  StatementResponse,
  ConfirmedStatement,
  ConfirmStatementsPayload,
  Consent,
  ConsentResponse,
  UpdateConsentRequest,
  UpdateConsentResponse
} from './statement.model';

const endpoint = '/statement';

const effects = (dispatch: RootDispatch) => ({
  async getStatements(): Promise<Statement[]> {
    return API.get<undefined, StatementResponse>(
      `${endpoint}/informationObligation`
    )
      .then((response) => {
        dispatch.statements.storeAllStatements(response.data.statements);
        return response.data.statements;
      })
      .catch((error) => error);
  },
  turnOffStatements(payload: ConfirmedStatement[]): any {
    return API.put<ConfirmStatementsPayload, any>(
      `${endpoint}/informationObligation`,
      {
        confirmedStatements: payload
      }
    )
      .then((response) => {
        dispatch.statements.storeAllStatements([]);
        return response;
      })
      .catch((error) => error);
  },
  async getConsents(): Promise<Consent[]> {
    return API.get<undefined, ConsentResponse>(`${endpoint}/marketingConsent`)
      .then((response) => {
        dispatch.statements.storeAllConsents(response.data.consents);
        return response.data.consents;
      })
      .catch((error) => error);
  },
  async updateConsents(data: UpdateConsentRequest): Promise<number> {
    return API.put<UpdateConsentRequest, UpdateConsentResponse>(
      `${endpoint}/marketingConsent`,
      data
    )
      .then((response) => response.status)
      .catch((error) => error);
  }
});

export default effects;
