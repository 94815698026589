import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import type { User } from './user.model';
import reducers from './user.reducers';
import effects from './user.effects';

const initialState: User = {
  id: null,
  language: 'pl',
  firstName: '',
  firstNameVocative: '',
  lastName: '',
  email: '',
  peselGender: 'M',
  token: null,
  userStatus: null,
  testGroup: null,
  showContactDetailsAcceptance: false,
  impersonation: false
};

const userModel = createModel<RootModel>()({
  state: initialState,
  reducers,
  effects
});

export default userModel;
