import { Typography } from '@mui/material';

const TransferOfPersonalData = () => (
  <>
    <Typography variant="h5" color="text" fontWeight="700">
      PRZEKAZYWANIE DANYCH OSOBOWYCH
    </Typography>
    <Typography variant="body1">
      Dane osobowe Użytkowników Serwisu w zakresie imienia, nazwiska, adresu
      poczty elektronicznej oraz adresu IP Administrator udostępnia Autopay S.A.
      z siedzibą w Sopocie (dalej: Autopay) przy ul. Powstańców Warszawy 6,
      81-718 Sopot, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru
      Sądowego prowadzonego przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VIII
      Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS:
      0000320590, NIP: 5851351185, REGON 191781561, wpisana do rejestru
      krajowych instytucji płatniczych prowadzonego przez Komisję Nadzoru
      Finansowego pod numerem podmiotu w UKNF IP17/2013, jako krajowa instytucja
      płatnicza. Stosownie do art. 13 ust. 1 lit. e) RODO Autopay będzie
      przetwarzała powyższe dane w celach związanych ze świadczeniem usług
      płatniczych, w szczególności w zakresie niezbędnym do zapobiegania
      oszustwom związanym z wykonywanymi usługami płatniczymi oraz dochodzenia i
      wykrywania tego rodzaju oszustw, a także identyfikacji Płatnika w zakresie
      wynikającym z przepisów prawa.
    </Typography>
  </>
);

export default TransferOfPersonalData;
