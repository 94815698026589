import React from 'react';
import { Grid, Typography } from '@mui/material';
import appTheme from 'App.theme';
import { Illustration } from 'components/Illustration';
import { IPageTemplate } from './PageTemplate.model';
import { PageWrapper } from './styled/PageWrapper';
import { BackgroundWrapper } from './styled/BackgroundColumn';
import { IllustrationWrapper } from './styled/IllustrationWrapper';
import { TextWrapper } from './styled/TextWrapper';

const DesktopView = ({
  heading,
  message,
  helpText,
  textWidth,
  image,
  buttons,
  withBackgroundSection,
  imageWidth,
  imagePositionTop,
  imagePositionRight
}: IPageTemplate) => {
  const gridWithBackground = 7;
  const gridWithoutBackground = 5;

  return (
    <PageWrapper
      container
      flexWrap="wrap"
      justifyContent="center"
      overflow="hidden"
      theme={appTheme}
    >
      {withBackgroundSection ? (
        <>
          <BackgroundWrapper item lg={5} />
          <IllustrationWrapper
            theme={appTheme}
            imageWidth={imageWidth}
            imagePositionTop={imagePositionTop}
            imagePositionRight={imagePositionRight}
            withBackgroundSection
          >
            <Illustration component={image} />
          </IllustrationWrapper>
        </>
      ) : (
        <Grid item lg={7} paddingTop="130px">
          <Grid
            container
            height="100%"
            justifyContent="flex-end"
            alignContent="center"
          >
            <Grid item>
              <IllustrationWrapper
                theme={appTheme}
                imageWidth={imageWidth}
                imagePositionTop={imagePositionTop}
                imagePositionRight={imagePositionRight}
                withBackgroundSection={false}
              >
                <Illustration component={image} />
              </IllustrationWrapper>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        lg={withBackgroundSection ? gridWithBackground : gridWithoutBackground}
        paddingTop="130px"
      >
        <Grid
          container
          direction="column"
          justifyContent={withBackgroundSection ? 'flex-start' : 'center'}
          alignContent={withBackgroundSection ? 'center' : 'flex-start'}
          height="100%"
          padding={withBackgroundSection ? '120px 0 100px' : '0 0 175px'}
        >
          <TextWrapper item theme={appTheme} width={textWidth}>
            <Typography variant={withBackgroundSection ? 'h3' : 'h2'}>
              {heading}
            </Typography>
            <Typography variant="body1" component="div">
              {message}
            </Typography>
            {helpText && <Typography variant="body2">{helpText}</Typography>}
          </TextWrapper>
          {buttons && <Grid item>{buttons}</Grid>}
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default DesktopView;
