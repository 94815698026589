import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { List } from '../styled/List';
import { ListItem } from '../styled/ListItem';

const UserRights = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5" color="text" fontWeight="700">
        UPRAWNIENIA UŻYTKOWNIKÓW W ZWIĄZKU Z PRZETWARZANIEM DANYCH OSOBOWYCH W
        SERWISACH
      </Typography>
      <Typography variant="body1" color="text" sx={{ marginBottom: '10px' }}>
        Użytkownikom przysługuje prawo do:
      </Typography>{' '}
      <List>
        <ListItem dense>
          Żądania informacji o adresie i pełnej nazwie Administratora, źródle
          danych oraz celu, zakresie i sposobie przetwarzania danych osobowych;{' '}
        </ListItem>
        <ListItem dense>
          Dostępu do danych osobowych, sprostowania i uzupełnienia, usunięcia
          lub ograniczenia przetwarzania;
        </ListItem>{' '}
        <ListItem dense>
          Wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (Urząd
          Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa);{' '}
        </ListItem>{' '}
        <ListItem dense>
          Cofnięcia zgody na przetwarzanie danych osobowych w dowolnym momencie
          bez wpływu na zgodność z prawem przetwarzania, którego dokonano na
          podstawie zgody przed jej cofnięciem.
        </ListItem>{' '}
      </List>
      <Typography variant="body1" sx={{ margin: '10px 0' }}>
        Skorzystanie ze wskazanych uprawnień jest możliwe poprzez kontakt z
        Administratorem:
      </Typography>
      <List>
        <ListItem dense>
          Adres e-mail:
          <Link
            sx={{ fontWeight: 700, lineHeight: '125%' }}
            color="primary.light"
            href={`mailto:${t('CONTACT_RODO_EMAIL')}`}
            data-link-no-alert
          >
            {' '}
            {t('CONTACT_RODO_EMAIL')}
          </Link>
        </ListItem>{' '}
        <ListItem dense>
          Adres Administratora: BEST Spółka Akcyjna, ul. Stoczniowa 2, 82-300
          Elbląg;
        </ListItem>{' '}
        <ListItem dense>
          Za pośrednictwem formularza kontaktowego Serwisu w zakładce Kontakt.
        </ListItem>
      </List>
    </>
  );
};

export default UserRights;
