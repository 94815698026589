import styled from 'styled-components/macro';
import { Container } from '@mui/material';

export const DesktopMenuContainer = styled(Container)`
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;
