import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'storage/store';
import { MenuVisibilityContext } from 'components/Menu/utils/menuVisibilityContext';

const useInstallPrompt = () => {
  const { prompt } = useSelector((state: RootState) => state.pwa);
  const { setVisible } = useContext(MenuVisibilityContext);

  const promptToInstall = () => {
    if (prompt) {
      setVisible!(false);
      return prompt.prompt();
    }

    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  return [promptToInstall];
};

export default useInstallPrompt;
