import { ProductParametersKey } from 'storage/agreements/agreements.model';
import { formatCurrency } from './formatCurrency';
import { DebtValueChanged } from '../storage/product/product.model';

interface PlusSettlementPayload {
  date: string;
  installmentsAmount: number;
  valueChanged: DebtValueChanged;
}

const setPayloadForRecalculatePlusSettlement = ({
  date,
  installmentsAmount,
  valueChanged
}: PlusSettlementPayload) => [
  {
    key: ProductParametersKey.INSTALLMENTS_AMOUNT,
    value: formatCurrency(
      'plain',
      installmentsAmount,
      undefined,
      false,
      false,
      false
    ) as string,
    valueChanged: valueChanged === DebtValueChanged.amount
  },
  {
    key: ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
    value: date,
    valueChanged: true
  }
];

export default setPayloadForRecalculatePlusSettlement;
