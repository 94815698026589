import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import { UserStatus, MessageTypes } from 'storage/user/user.model';
import { errorFormEvent } from 'utils/events';
import sendEvent from 'utils/sendEvent';
import { Url } from 'utils/urls';
import { Alert } from 'components/Alert';
import ContactUsModal from 'components/Modal/modals/ContactUs';

interface IErrorMessage {
  messageType: UserStatus;
}

const ErrorMessage = ({ messageType }: IErrorMessage) => {
  const { t } = useTranslation();
  const [isActivatedModalOpen, setIsActivatedModalOpen] = useState(false);
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    if (messageType === MessageTypes.invalidPassword) {
      sendEvent({
        labelText: t('PAGE_LOGIN_ERROR_TITLE'),
        ...errorFormEvent
      });
    }
    if (messageType === MessageTypes.blocked) {
      sendEvent({
        labelText: t('PAGE_LOGIN_ACCOUNT_BLOCKED_TITLE'),
        ...errorFormEvent
      });
    }
    if (messageType === MessageTypes.notActivated) {
      sendEvent({
        labelText: t('PAGE_LOGIN_ACCOUNT_NOT_ACTIVATED_TITLE'),
        ...errorFormEvent
      });
    }
    if (messageType === MessageTypes.sessionExpired) {
      sendEvent({
        labelText: t('PAGE_LOGIN_SESSION_EXPIRED'),
        ...errorFormEvent
      });
    }
  }, [messageType]);

  if (messageType === MessageTypes.blocked) {
    return (
      <div style={{ margin: '10px 0' }}>
        <ContactUsModal
          open={isBlockedModalOpen}
          onClose={() => setIsBlockedModalOpen(false)}
        />
        <Alert
          variant="standard"
          severity="error"
          title={t('PAGE_LOGIN_ACCOUNT_BLOCKED_TITLE')}
        >
          <div>
            <Typography component="span" variant="body2">
              {t('PAGE_LOGIN_ACCOUNT_BLOCKED_MESSAGE_PART_1')}
            </Typography>
            <Link href={Url.unblockAccount} variant="body2">
              {t('PAGE_LOGIN_ACCOUNT_UNLOCK_CLICK_HERE')}
            </Link>
            <Typography component="span" variant="body2">
              {t('PAGE_LOGIN_ACCOUNT_BLOCKED_MESSAGE_PART_2')}
            </Typography>
            <Link
              sx={{ cursor: 'pointer', display: 'contents' }}
              component="button"
              variant="body2"
              type="button"
              onClick={() => {
                setIsBlockedModalOpen(true);
              }}
            >
              {t('PAGE_LOGIN_ACCOUNT_BLOCKED_CONTACT')}
            </Link>
            .
          </div>
        </Alert>
      </div>
    );
  }

  if (messageType === MessageTypes.invalidPassword) {
    return (
      <div style={{ margin: '10px 0' }}>
        <ContactUsModal
          open={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
        />
        <Alert
          variant="standard"
          severity="warning"
          title={t('PAGE_LOGIN_ERROR_TITLE')}
        >
          <div>
            <Typography component="span" variant="body2">
              {t('PAGE_LOGIN_ERROR_MESSAGE')}
            </Typography>{' '}
            <Link href={Url.resetPassword} variant="body2">
              {t('PAGE_LOGIN_RESET_PASSWORD')}
            </Link>{' '}
            <Typography component="span" variant="body2">
              {t('PAGE_LOGIN_OR')}
            </Typography>{' '}
            <Link
              sx={{ cursor: 'pointer', display: 'contents' }}
              component="button"
              variant="body2"
              type="button"
              onClick={() => {
                setIsErrorModalOpen(true);
              }}
            >
              {t('PAGE_LOGIN_ACCOUNT_NOT_ACTIVATED_LINK')}
            </Link>
            <Typography component="span" variant="body2">
              .
            </Typography>
          </div>
        </Alert>
      </div>
    );
  }
  if (messageType === MessageTypes.notActivated) {
    return (
      <div style={{ margin: '10px 0' }}>
        <ContactUsModal
          open={isActivatedModalOpen}
          onClose={() => setIsActivatedModalOpen(false)}
        />
        <Alert
          variant="standard"
          severity="warning"
          title={t('PAGE_LOGIN_ACCOUNT_NOT_ACTIVATED_TITLE')}
        >
          <div>
            <Typography component="span" variant="body2">
              {t('PAGE_LOGIN_ACCOUNT_NOT_ACTIVATED_MESSAGE')}
            </Typography>{' '}
            <Link
              type="button"
              component="button"
              variant="body2"
              sx={{
                cursor: 'pointer',
                display: 'contents'
              }}
              onClick={() => setIsActivatedModalOpen(true)}
            >
              {t('PAGE_LOGIN_ACCOUNT_NOT_ACTIVATED_LINK')}
            </Link>
            .
          </div>
        </Alert>
      </div>
    );
  }
  if (messageType === MessageTypes.sessionExpired) {
    return (
      <Alert
        variant="standard"
        severity="warning"
        title={t('PAGE_LOGIN_SESSION_EXPIRED')}
        sx={{ marginBottom: '10px' }}
      >
        <Typography component="span" variant="body2">
          {t('PAGE_LOGIN_ACCOUNT_SESSION_EXPIRED_MESSAGE')}
        </Typography>
      </Alert>
    );
  }
  return null;
};

export default ErrorMessage;
