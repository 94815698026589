import { Grid, Typography, useMediaQuery } from '@mui/material';
import appTheme from 'App.theme';
import { useImageResized } from 'hooks';
import { Illustration } from 'components/Illustration';
import DesktopView from './DesktopView';
import { IPageTemplate } from './PageTemplate.model';
import { PageWrapper } from './styled/PageWrapper';
import { IllustrationWrapper } from './styled/IllustrationWrapper';
import { TextWrapper } from './styled/TextWrapper';

const PageTemplate = ({
  heading,
  message,
  helpText,
  textWidth,
  image,
  imageWidth,
  imagePositionTop,
  imagePositionRight,
  tabletImageSpaceTop,
  mobileImageMarginBottom,
  buttons,
  imageHeight,
  resizedImageParams,
  withBackgroundSection = true,
  justifyContent = 'flex-start'
}: IPageTemplate) => {
  const { isImageResized } = useImageResized();
  const isDesktopBreakpoint = useMediaQuery(appTheme.breakpoints.up('lg'));
  const isMobileBreakpoint = useMediaQuery(appTheme.breakpoints.down('md'));
  const mobileRowSpacing = 3;
  const tabletRowSpacing = 0;
  const marginWithButton = 5;
  const marginWithoutButton = 0;
  const desktopPaddingTopImage = 0;

  const getImageMarginBottom = () => {
    if (!mobileImageMarginBottom) {
      return buttons ? marginWithButton : marginWithoutButton;
    }
    return mobileImageMarginBottom;
  };

  if (isDesktopBreakpoint) {
    return (
      <DesktopView
        heading={heading}
        message={message}
        helpText={helpText}
        textWidth={textWidth}
        image={image}
        buttons={buttons}
        withBackgroundSection={withBackgroundSection}
        imageWidth={imageWidth}
        imagePositionTop={imagePositionTop}
        imagePositionRight={imagePositionRight}
      />
    );
  }
  return (
    <PageWrapper
      container
      flexWrap="wrap"
      direction="column"
      justifyContent={justifyContent}
      rowSpacing={isMobileBreakpoint ? mobileRowSpacing : tabletRowSpacing}
      theme={appTheme}
    >
      <TextWrapper item theme={appTheme} width={textWidth}>
        {heading && (
          <Typography
            variant={isDesktopBreakpoint ? 'h2' : 'h4'}
            margin="0 0 25px"
          >
            {heading}
          </Typography>
        )}
        <Typography variant="body1" component="div">
          {message}
        </Typography>
        {helpText && <Typography variant="body2">{helpText}</Typography>}
      </TextWrapper>
      <Grid
        item
        marginBottom={getImageMarginBottom()}
        paddingTop={
          tabletImageSpaceTop && !isMobileBreakpoint
            ? tabletImageSpaceTop
            : desktopPaddingTopImage
        }
      >
        <IllustrationWrapper
          theme={appTheme}
          imageWidth={imageWidth}
          imagePositionTop={imagePositionTop}
          imagePositionRight={imagePositionRight}
          withBackgroundSection={false}
          imageHeight={imageHeight}
          resizedImageParams={isImageResized ? resizedImageParams : undefined}
        >
          <Illustration component={image} />
        </IllustrationWrapper>
      </Grid>
      {buttons && (
        <Grid
          item
          sx={{
            textAlign: 'center',
            '&&&': {
              paddingTop: 0
            }
          }}
        >
          {buttons}
        </Grid>
      )}
    </PageWrapper>
  );
};

export default PageTemplate;
