import { useSearchParams } from 'react-router-dom';

interface IPaymentLocation {
  serviceId: string | null;
  orderId: string | null;
  hash: string | null;
}

const usePaymentLocation = (): IPaymentLocation => {
  const [params] = useSearchParams();

  return {
    serviceId: params.get('ServiceID'),
    orderId: params.get('OrderID'),
    hash: params.get('Hash')
  };
};

export default usePaymentLocation;
