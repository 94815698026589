import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import effects from './contact.effects';

const contactModel = createModel<RootModel>()({
  state: {},
  effects
});

export default contactModel;
