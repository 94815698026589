import { useEffect, useState, createContext, ReactElement } from 'react';
import { IBeforeInstallPromptEvent } from 'storage/pwa/pwa.model';

export const PromptContext = createContext<null | IBeforeInstallPromptEvent>(
  null
);
interface PromptProviderProps {
  children: ReactElement;
}

const PromptProvider = ({ children }: PromptProviderProps) => {
  const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const eventReady = (event: IBeforeInstallPromptEvent) => {
      event.preventDefault();
      setPrompt(event);
    };
    window.addEventListener(
      'beforeinstallprompt',
      eventReady as (event: Event) => void
    );
    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        eventReady as (event: Event) => void
      );
    };
  }, []);

  return (
    <PromptContext.Provider value={prompt}>{children}</PromptContext.Provider>
  );
};

export default PromptProvider;
