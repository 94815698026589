import { MenuItem as MenuItemComponent } from '@mui/material';
import styled from 'styled-components/macro';
import theme from 'App.theme';

const MenuItem = styled(MenuItemComponent)`
  && {
    padding: 0;

    & > a {
      padding: 10px 20px;
      width: 100%;
      font-weight: 700;
      color: ${theme.palette.secondary.main};
    }
  }
`;

export default MenuItem;
