import { Box, CircularProgress } from '@mui/material';
import SimpleLoaderWrapper from './styled/SimpleLoaderWrapper';

const SimpleLoader = () => (
  <SimpleLoaderWrapper>
    <Box>
      <CircularProgress
        variant="determinate"
        thickness={4}
        value={100}
        size={58}
      />
      <CircularProgress color="primary" disableShrink thickness={4} size={58} />
    </Box>
  </SimpleLoaderWrapper>
);

export default SimpleLoader;
