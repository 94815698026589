import styled from 'styled-components/macro';
import { Grid } from '@mui/material';

export const TextWrapper = styled(Grid)(
  ({ width, theme }) => `
  && {
    width: ${width ?? '380px'};
  }

  ${[theme.breakpoints.between('md', 'lg')]} {
    && {
      width: ${width ?? '328px'};
      margin: 0 auto 75px;
    }
  }

  ${[theme.breakpoints.down('md')]} {
    && {
      width: ${width ?? '275px'};
      margin: 0 0 34px;
    }
  }
`
);
