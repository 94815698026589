import { OldUrl, Url } from 'utils/urls';
import { IsLoggedIn } from 'utils/user';

const redirectFromOldUrls = () => {
  const { pathname, origin, search } = window.location;
  const validPath = pathname.replace('//', '/');

  switch (validPath) {
    case OldUrl.passwordReset: {
      window.location.pathname = Url.resetPassword;
      break;
    }
    case OldUrl.home: {
      window.location.pathname = IsLoggedIn() ? Url.dashboard : Url.login;
      break;
    }
    case OldUrl.facesAccountUnblock:
    case OldUrl.rsAccountUnblock: {
      const splitParam = search.split('=');
      const [, token] = splitParam;
      window.location.replace(`${origin}${Url.unblockAccount}/${token}`);
      break;
    }
    default:
      break;
  }

  if (
    (validPath.startsWith(OldUrl.rsAccount) &&
      validPath !== OldUrl.rsAccountUnblock) ||
    (validPath.startsWith(OldUrl.facesAccount) &&
      validPath !== OldUrl.facesAccountUnblock)
  ) {
    const splittedPathname = validPath.split('/');
    const [, , , path] = splittedPathname;
    const splittedParam = search.split('=');
    const [, token] = splittedParam;
    window.location.replace(`${origin}/${path}/${token}`);
  } else if (
    validPath.startsWith(OldUrl.faces) &&
    validPath !== OldUrl.passwordReset &&
    validPath !== OldUrl.home &&
    validPath !== OldUrl.facesAccountUnblock
  ) {
    const [, , validPathname] = validPath.split('/');
    window.location.pathname = validPathname;
  }
};

export default redirectFromOldUrls;
