import React from 'react';
import TagManager from 'react-gtm-module';
import { createRoot } from 'react-dom/client';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import initSentry from './utils/initSentry';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// @ts-ignore
import('pdfjs-dist/legacy/build/pdf.worker.entry').then(
  ({ default: PDFJSWorker }) => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;
  }
);

initSentry();
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || ''
};
TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Register the service worker
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
