import styled from 'styled-components/macro';
import { ErrorMessage } from 'formik';
import getColor from 'utils/getColor';

const ErrorMessageStyled = styled(ErrorMessage)<{
  $withoutBottomPosition?: boolean;
}>`
  position: absolute;
  left: 0;
  ${({ $withoutBottomPosition }) =>
    $withoutBottomPosition ? null : 'bottom: -19px'};
  font-size: 13px;
  font-weight: 600;
  color: ${getColor('error')};
  line-height: 125%;
  margin: 3px 0 0 0;
  width: ${({ name }) => (name === 'zipCode' ? 'max-content' : 'auto')};
`;

export default ErrorMessageStyled;
