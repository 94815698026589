import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import reducers from './notifications.reducers';
import { NotificationsState } from './notifications.model';

const model = createModel<RootModel>()({
  state: {} as NotificationsState,
  reducers
});

export default model;
