import { Suspense } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { withProfiler } from '@sentry/react';
import theme from 'App.theme';
import store from 'storage/store';
import {
  FontProvider,
  TranslationProvider,
  LocalizationProvider,
  PromptProvider
} from 'providers';
import { Layout, Loader, TokenRefresh, ErrorBoundary } from 'components';

const App = () => (
  <StoreProvider store={store}>
    <TokenRefresh />
    <FontProvider />
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Loader />
      <TranslationProvider>
        <PromptProvider>
          <LocalizationProvider>
            <HelmetProvider>
              <ErrorBoundary>
                <Suspense fallback="">
                  <Layout />
                </Suspense>
              </ErrorBoundary>
            </HelmetProvider>
          </LocalizationProvider>
        </PromptProvider>
      </TranslationProvider>
    </ThemeProvider>
  </StoreProvider>
);

export default withProfiler(App);
