import { DesktopMenuContainer } from '../styled/DesktopMenuContainer';
import { LogoWrapper } from '../styled/LogoWrapper';
import AppLogo from './AppLogo';

const AppBarWithoutMenu = () => (
  <DesktopMenuContainer maxWidth="xl" sx={{ padding: { lg: '0' } }}>
    <LogoWrapper>
      <AppLogo size={68} />
    </LogoWrapper>
  </DesktopMenuContainer>
);

export default AppBarWithoutMenu;
