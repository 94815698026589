import styled from 'styled-components/macro';
import { Box } from '@mui/material';
import { colors } from 'components/Colors';

const FooterWrapper = styled(Box)(
  ({ theme }) => `
    border-top: 2px solid ${theme.palette.info.light};
    display: flex;
    align-items: center;
    min-height: 74px;
    padding-left: 100px;
    padding-top: 20px;
    ${[theme.breakpoints.down('lg')]} {
      background-color: ${colors.green.lightTeal.value};
      padding-left: 49px;
      padding-top: 0;
    };
    ${[theme.breakpoints.down('md')]} {
      min-height: 100px;
      padding-left: 0px;
      flex-flow: column;
      justify-content: center;
    }
`
);

export default FooterWrapper;
