import { API } from 'utils';
import { TaxOffice, TaxOfficeResponse } from './taxOffice.model';

const endpoint = '/taxOffice';

const effects = () => ({
  getAll(): Promise<TaxOffice[] | undefined> {
    return API.get<void, TaxOfficeResponse>(endpoint)
      .then((response) => response.data)
      .catch(() => undefined);
  }
});

export default effects;
