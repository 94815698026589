import styled from 'styled-components/macro';
import { zIndex } from 'utils/zIndex';
import { IIllustrationProps } from '../PageTemplate.model';

const getDesktopStyle = ({
  withBackgroundSection = true,
  ...args
}: IIllustrationProps) => {
  if (withBackgroundSection) {
    return `
      & > svg {
        position: absolute;
        top: ${args.imagePositionTop ?? '40%'};
        right: ${args.imagePositionRight ?? '-120px'};
        width: ${args.imageWidth ?? '500px'};
        z-index: ${zIndex.image};
      }
      `;
  }
  return `min-width: ${args.imageWidth ?? '500px'};`;
};

const getImageResizedStyle = ({ resizedImageParams }: IIllustrationProps) => {
  if (resizedImageParams) {
    const { height, svgHeight, marginBottom, marginTop } = resizedImageParams;
    return `
    height: ${height || 'auto'} ;
    margin-bottom: ${marginBottom || 'auto'};
    margin-top: ${marginTop || 'auto'};
      & > svg {
      height: ${svgHeight || 'auto'};
     }
  `;
  }
  return null;
};

export const IllustrationWrapper = styled.div<IIllustrationProps>`
  text-align: center;
  ${(props) => [props.theme.breakpoints.up('lg')]} {
    position: relative;
    ${({ ...props }) => getDesktopStyle(props)}
  }
  ${(props) => [props.theme.breakpoints.down('lg')]} {
    & > svg {
      width: ${({ imageWidth }) => imageWidth ?? '500px'};
    }
  }
  ${(props) => [props.theme.breakpoints.down('md')]} {
    position: relative;
    height: ${({ imageHeight }) => imageHeight ?? '300px'};
    & > svg {
      position: absolute;
      right: ${({ imagePositionRight }) => imagePositionRight ?? '-120px'};
      top: ${({ imagePositionTop }) => imagePositionTop ?? '-30px'};
      width: ${({ imageWidth }) => imageWidth ?? '320px'};
    }
    ${({ ...props }) => getImageResizedStyle(props)}
  }
`;
