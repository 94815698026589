import * as Sentry from '@sentry/react';
import { RootDispatch } from 'storage/store';
import { API } from 'utils/Api';
import { Token } from 'utils/Token';
import { StatusCode } from 'utils/consts';
import { events, apiEvents } from 'utils/Api/Api';
import {
  ActivateAccount,
  ActivateAccountResponse,
  PhoneResponse,
  ResetEmailResponse,
  UnblockAccountResponse,
  UserCredentials,
  UserEmail,
  UserLoginPayload,
  MessageTypes,
  ResetPassword,
  ResetPasswordResponse,
  ChangePassword,
  ChangePasswordResponse,
  VerifyClient,
  VerifyClientResponse,
  CreateAccount,
  CreateAccountResponse,
  CreateAccountFromACP,
  CreateAccountFromACPResponse,
  ResendActivateAccount,
  TokenType,
  ValidateToken,
  ValidateTokenResponse,
  TokenStatus,
  UserResponse,
  AddressesData,
  EmailsData,
  PhonesData,
  Address,
  Phone,
  Email,
  UserInfo,
  AddressResponse,
  CreateAddress,
  UpdateAddress,
  CreatePhone,
  UpdatePhone,
  AddEmailAddressPayload,
  EmailResponse,
  EditEmailAddressPayload,
  DeletePhone,
  DeleteAddress,
  TempPasswordResponse,
  UpdateTaxOfficePayload,
  User,
  ValidateTokenData,
  TestingDataResponse,
  UserAcceptanceDataResponse
} from './user.model';

const userEffects = (dispatch: RootDispatch): any => ({
  loginUser(credentials: UserCredentials): void {
    const url = '/auth/login';
    API.post<UserCredentials, UserLoginPayload>(url, credentials)
      .then(({ status, message, data }) => {
        if (status === StatusCode.forbiddenCode) {
          this.changeUserStatus(message);
        }
        if (status === StatusCode.unauthorizedCode) {
          this.changeUserStatus(MessageTypes.invalidPassword);
        }
        if (data?.token) {
          Token.setToken(data.token);
          this.changeUserStatus('ok');
          this.changeToken(data.token);
          this.fetchUserData();
        }
      })
      .catch(() => {
        this.changeUserStatus(null);
        this.changeToken(null);
      });
  },
  fetchUserData(): void {
    const url = '/user';
    API.get<undefined, UserResponse<User>>(url)
      .then((payload) => {
        this.changeUserData(payload);
        const userId = payload.data.id;
        Sentry.setUser({ id: userId!.toString() });
      })
      .catch(() => {
        this.changeUserStatus(null);
        this.changeToken(null);
      });
  },
  logoutUser(): void {
    localStorage.removeItem('token');
    sessionStorage.removeItem('communicationModalAccepted');
    this.changeToken(null);
    this.changeUserStatus(null);
    this.changeUserData({ data: { email: '', firstName: '', lastName: '' } });
    dispatch({ type: 'RESET' });
    events.emit(apiEvents.WSS_CLOSE_CONNECTION);
  },
  async getToken(
    tempPassword: string
  ): Promise<TempPasswordResponse | undefined> {
    const url = `/auth/impersonation/${tempPassword}`;
    return API.get<void, TempPasswordResponse>(url)
      .then((payload) => payload)
      .catch(() => undefined);
  },
  resetPasswordSendEmail(email: string): Promise<number | undefined> {
    const url = '/auth/password/sendResetEmail';
    return API.put<UserEmail, ResetEmailResponse>(url, { email })
      .then((response) => {
        dispatch.enteredEmails.storeResetEmail(email);
        return response.status;
      })
      .catch(() => undefined);
  },
  resetPasswordSetPassword({
    token,
    password,
    pesel
  }: ResetPassword): Promise<ResetPasswordResponse | undefined> {
    const url = '/auth/password/reset';
    return API.put<ResetPassword, ResetPasswordResponse>(url, {
      token,
      password,
      pesel
    })
      .then((response) => response)
      .catch(() => undefined);
  },
  changePassword(
    payload: ChangePassword
  ): Promise<ChangePasswordResponse | undefined> {
    const url = '/auth/password/change';
    return API.put<ChangePassword, ChangePasswordResponse>(url, payload)
      .then((response) => response)
      .catch(() => undefined);
  },
  verifyClient({
    agreementId,
    pesel
  }: VerifyClient): Promise<VerifyClientResponse | undefined> {
    const url = '/auth/client/verify';
    return API.post<VerifyClient, VerifyClientResponse>(url, {
      agreementId,
      pesel
    })
      .then((response) => response)
      .catch(() => undefined);
  },
  validateResetPasswordToken(
    token: string
  ): Promise<ValidateTokenData | undefined> {
    const url = '/actiontoken/validate';
    return API.post<ValidateToken, ValidateTokenResponse>(url, {
      token,
      type: TokenType.RESET_PASSWORD
    })
      .then((response) => response.data)
      .catch(() => undefined);
  },
  validateRegisterAccountToken(
    token: string
  ): Promise<TokenStatus | undefined> {
    const url = '/actiontoken/validate';
    return API.post<ValidateToken, ValidateTokenResponse>(url, {
      token,
      type: TokenType.REGISTER_ACCOUNT
    })
      .then((response) => response.data.status)
      .catch(() => undefined);
  },
  unblockAccountSendEmail(email: string): Promise<number> {
    const unblockAccountUrl = '/auth/account/sendUnblockEmail';
    return (async () => {
      const response = await API.put<UserEmail, UnblockAccountResponse>(
        unblockAccountUrl,
        {
          email
        }
      );
      dispatch.enteredEmails.storeUnblockEmail(email);
      return response.status;
    })();
  },
  validateUnblockAccountToken(
    token: string
  ): Promise<ValidateTokenData | undefined> {
    const url = '/actiontoken/validate';
    return API.post<ValidateToken, ValidateTokenResponse>(url, {
      token,
      type: TokenType.UNBLOCK_ACCOUNT
    })
      .then((response) => response.data)
      .catch(() => undefined);
  },

  unblockAccountSetPassword({
    token,
    password,
    pesel
  }: ResetPassword): Promise<ResetPasswordResponse | undefined> {
    const url = '/auth/account/unblock';
    return API.put<ResetPassword, ResetPasswordResponse>(url, {
      token,
      password,
      pesel
    })
      .then((response) => response)
      .catch(() => undefined);
  },
  resetUserStatus(): void {
    this.changeUserStatus('ok');
  },
  createAccount({
    email,
    password,
    agreementId,
    pesel
  }: CreateAccount): Promise<CreateAccountResponse | undefined> {
    const url = '/auth/account/create';
    return API.post<CreateAccount, CreateAccountResponse>(url, {
      email,
      password,
      agreementId,
      pesel
    })
      .then((response) => {
        dispatch.enteredEmails.storeRegisterEmail(email);
        return response;
      })
      .catch(() => undefined);
  },
  createAccountFromACP({
    email,
    password
  }: CreateAccountFromACP): Promise<CreateAccountResponse | undefined> {
    const url = '/acp/accounts';
    return API.post<CreateAccountFromACP, CreateAccountFromACPResponse>(url, {
      email,
      password
    })
      .then((response) => {
        dispatch.enteredEmails.storeRegisterEmail(email);
        return response;
      })
      .catch(() => undefined);
  },
  activateAccount(token: string): Promise<number | undefined> {
    const url = '/auth/account/activate';
    return API.put<ActivateAccount, ActivateAccountResponse>(url, {
      token
    })
      .then((response) => response.status)
      .catch(() => undefined);
  },
  resendActivateAccount(activationToken: string): Promise<number | undefined> {
    const url = '/auth/account/activate/resend';
    return API.put<ResendActivateAccount, ActivateAccountResponse>(url, {
      activationToken
    })
      .then((response) => response.status)
      .catch(() => undefined);
  },
  getAddresses(): Promise<Address[] | undefined> {
    const url = '/user/addresses';

    return API.get<void, UserResponse<AddressesData>>(url)
      .then((response) => response.data.addresses)
      .catch(() => undefined);
  },
  deleteAddress({
    clientDataId,
    addressId
  }: DeleteAddress): Promise<AddressResponse | undefined> {
    const url = `/user/${clientDataId}/address/${addressId}`;

    return API.delete<DeleteAddress, AddressResponse>(url)
      .then((response) => response)
      .catch(() => undefined);
  },
  getPhones(): Promise<Phone[] | undefined> {
    const url = '/user/phones';

    return API.get<void, UserResponse<PhonesData>>(url)
      .then((response) => response.data.phones)
      .catch(() => undefined);
  },
  createPhone(payload: CreatePhone): Promise<PhoneResponse | undefined> {
    const url = '/user/phone';

    return API.post<CreatePhone, PhoneResponse>(url, payload)
      .then((response) => response)
      .catch(() => undefined);
  },
  updatePhone(payload: UpdatePhone): Promise<PhoneResponse | undefined> {
    const url = '/user/phone';

    return API.put<UpdatePhone, PhoneResponse>(url, payload)
      .then((response) => response)
      .catch(() => undefined);
  },
  deletePhone(payload: DeletePhone): Promise<PhoneResponse | undefined> {
    const url = '/user/phone';

    return API.delete<DeletePhone, PhoneResponse>(url, payload)
      .then((response) => response)
      .catch(() => undefined);
  },
  getEmails(): Promise<Email[] | undefined> {
    const url = '/user/emails';

    return API.get<void, UserResponse<EmailsData>>(url)
      .then((response) => response.data.emails)
      .catch(() => undefined);
  },
  getInfo(): Promise<UserInfo | undefined> {
    const url = '/user/info';

    return API.get<void, UserResponse<UserInfo>>(url)
      .then((response) => response.data)
      .catch(() => undefined);
  },
  createAddress(address: CreateAddress): Promise<AddressResponse | undefined> {
    const url = '/user/address';

    return API.post<CreateAddress, AddressResponse>(url, address)
      .then((response) => response)
      .catch(() => undefined);
  },
  updateAddress(address: UpdateAddress): Promise<AddressResponse | undefined> {
    const url = '/user/address';

    return API.put<UpdateAddress, AddressResponse>(url, address)
      .then((response) => response)
      .catch(() => undefined);
  },
  createEmail(
    payload: AddEmailAddressPayload
  ): Promise<EmailResponse | undefined> {
    const url = '/user/email';

    return API.post<AddEmailAddressPayload, EmailResponse>(url, payload)
      .then((response) => response)
      .catch((error) => error);
  },
  editEmail(
    payload: EditEmailAddressPayload
  ): Promise<EmailResponse | undefined> {
    const url = '/user/email';

    return API.put<EditEmailAddressPayload, EmailResponse>(url, payload)
      .then((response) => response)
      .catch((error) => error);
  },
  deleteEmail(emailAddressId: number) {
    const url = `/user/email/${emailAddressId}`;

    return API.delete<null, EmailResponse>(url)
      .then((response) => response)
      .catch((error) => error);
  },
  updateTaxOffice({ agreementId, taxOfficeId }: UpdateTaxOfficePayload) {
    const url = `/user/${agreementId}/taxOffice/${taxOfficeId}`;

    return API.put<any, any>(url)
      .then((response) => response)
      .catch((error) => error);
  },
  async getClientTestGroup() {
    const endpoint = '/user/testingData';
    return API.get<void, TestingDataResponse>(endpoint)
      .then((response) => {
        dispatch.user.storeTestGroup(response.data.testGroup);
        return response.data;
      })
      .catch((error) => error);
  },
  async updateAcceptanceDate() {
    const endpoint = '/user/contacts/acceptance-date';
    return API.patch<void, UserAcceptanceDataResponse>(endpoint)
      .then((response) => response)
      .catch((error) => error);
  }
});

export default userEffects;
