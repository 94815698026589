import { API } from 'utils';
import {
  ApplicationVersion,
  ApplicationResponse,
  SigmaAvailability
} from './application.model';
import { RootDispatch } from '../store';

const endpoint = '/version';

const effects = (dispatch: RootDispatch) => ({
  async getApplicationVersion(): Promise<ApplicationVersion | undefined> {
    return API.get<undefined, ApplicationResponse<ApplicationVersion>>(endpoint)
      .then((response) => {
        dispatch.application.storeAppVersion(
          response.data.bffVersion,
          response.data.adapterVersion
        );
        return response.data;
      })
      .catch((error) => error);
  },
  async getSigmaAvailability(): Promise<SigmaAvailability | undefined> {
    const url = '/sigma/availability';
    return API.get<undefined, ApplicationResponse<SigmaAvailability>>(
      url,
      false,
      true
    )
      .then((response) => {
        dispatch.application.storeSigmaAvailability(
          response.data.sigmaAvailable
        );
        return response.data;
      })
      .catch((error) => {
        dispatch.application.storeSigmaAvailability(false);
        return error;
      });
  }
});

export default effects;
