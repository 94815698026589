import { getIconComponent } from './utils/getIconComponent';
import { IIcon } from './Icon.model';

const Icon = ({ icon, ...otherProps }: IIcon) => {
  if (typeof icon !== 'string') return icon;
  const Component = getIconComponent(icon);
  return <Component {...otherProps} />;
};

export default Icon;
