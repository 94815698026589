import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import effects from './taxOffice.effects';

const taxOfficeModel = createModel<RootModel>()({
  state: {},
  effects
});

export default taxOfficeModel;
