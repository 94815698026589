import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'storage/store';
import { TranslationProviderProps } from './TranslationProvider.model';

const { REACT_APP_TRANSLATIONS_URL } = process.env;

interface ITranslationMessage {
  messageKey: string;
  content: string;
}

interface ITranslations {
  data: { systemMessages: ITranslationMessage[] };
}

export const TRANSLATION_NAMESPACES = ['translation'];

const getFixedTranslateContent = (content: string) =>
  content
    .replace(/e-mail/g, 'e\u2060-\u2060mail')
    .replace(/__NBSP__/g, '\u00a0');

const parseBackendData = (data: string) => {
  let parsedData: ITranslations;

  try {
    parsedData = JSON.parse(data);
  } catch {
    return {};
  }

  return parsedData.data.systemMessages.reduce(
    (prev, current) => ({
      ...prev,
      [current.messageKey]: getFixedTranslateContent(current.content)
    }),
    {}
  );
};

i18next
  .use(Backend)
  .use(intervalPlural)
  .init({
    initImmediate: false,
    lng: 'pl',
    fallbackLng: 'pl',
    keySeparator: '.',
    nsSeparator: ':',
    interpolation: {
      escapeValue: false
    },
    returnEmptyString: false,
    react: {
      useSuspense: true
    },
    ns: TRANSLATION_NAMESPACES,
    backend: {
      backends: [
        HttpApi,
        resourcesToBackend((language, namespace, callback) => {
          import(`../../i18n/${language}/${namespace}.json`)
            .then(({ default: resources }) => {
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, null);
            });
        })
      ],
      backendOptions: [
        {
          loadPath: REACT_APP_TRANSLATIONS_URL,
          parse: parseBackendData
        }
      ]
    }
  });

export const i18n = i18next;

const TranslationProvider = (props: TranslationProviderProps) => {
  const language = useSelector((state: RootState) => state.user.language);

  i18n.changeLanguage(language);

  return <I18nextProvider i18n={i18n} {...props} />;
};

export default TranslationProvider;
