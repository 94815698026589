import { ApplicationState } from './application.model';

const reducers = {
  storeAppVersion: (
    state: ApplicationState,
    bffVersion: string,
    adapterVersion: string
  ) => ({
    ...state,
    bffVersion,
    adapterVersion
  }),
  storeSigmaAvailability: (
    state: ApplicationState,
    sigmaAvailable: boolean
  ) => ({
    ...state,
    sigmaAvailable
  })
};

export default reducers;
