import theme from 'App.theme';
import { colors } from 'components/Colors';

export type Color =
  | 'primary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | undefined;

const getColor = (color: Color, hasEmptyValue: boolean = false): string =>
  color && !hasEmptyValue
    ? theme.palette[color].main
    : colors.common.grey.value;

export default getColor;
