import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';

export interface IHowInstallPWAModal {
  open: boolean;
  onClose: () => void;
}

const HowInstallPWA = ({ open, onClose }: IHowInstallPWAModal) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose}>
      <Grid container flexWrap="nowrap" alignItems="center" maxWidth="254px">
        <Grid item xs={12}>
          <DialogTitle sx={{ padding: '0 20px 16px 0' }}>
            {t('MODAL_HOW_INSTALL_PWA_TITLE')}
          </DialogTitle>
          <DialogContent sx={{ paddingLeft: 0 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 400, marginBottom: '40px' }}
            >
              <Trans i18nKey="MODAL_HOW_INSTALL_PWA_DESCRIPTION" />
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 400,
                marginBottom: '10px',
                display: 'inline-flex'
              }}
            >
              <Trans i18nKey="MODAL_HOW_INSTALL_PWA_MESSAGE_1" />
              <Icon
                icon="safari"
                color="primary"
                sx={{
                  width: '50px',
                  height: '50px'
                }}
              />
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: 400, marginBottom: '15px' }}
            >
              <Trans i18nKey="MODAL_HOW_INSTALL_PWA_MESSAGE_2" />
            </Typography>
          </DialogContent>
          <DialogActions sx={{ paddingLeft: 0 }}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              size="large"
              label={t('MODAL_UNDERSTAND_TEXT')}
              onClick={onClose}
              sx={{ marginTop: '10px' }}
            />
          </DialogActions>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default HowInstallPWA;
