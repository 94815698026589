import { SvgIcon } from '@mui/material';

const PreviewOff = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_3729)">
      <path d="M3.65148 15.3702L5.15878 13.8735C4.25646 13.1231 3.42825 12.2878 2.68554 11.3791C5.08447 8.46001 7.74879 6.72973 10.5086 6.26268C9.38273 6.56333 8.38708 7.22627 7.67535 8.14903C6.96362 9.07178 6.57537 10.2031 6.57054 11.3684C6.57322 11.7002 6.60878 12.0308 6.67669 12.3555L9.00133 10.031C9.31767 9.35306 9.86256 8.80822 10.5405 8.49188L12.8651 6.16718L14.6802 4.35209C13.8066 4.12481 12.908 4.00706 12.0053 4.00172C7.83371 4.00172 3.85316 6.32645 0.498896 10.7316L0 11.3791L0.498896 12.0266C1.42867 13.2494 2.48543 14.3702 3.65148 15.3702Z" />
      <path d="M18.7455 6.16718L23.416 1.50728L21.9087 6.10352e-05L0.679199 21.2296L2.18649 22.7368L7.23913 17.6842C8.73272 18.3774 10.3585 18.7395 12.0052 18.7457C16.1662 18.7457 20.1467 16.4211 23.5009 12.0159L23.9998 11.3684L23.5009 10.721C22.1817 8.9477 20.5743 7.40837 18.7455 6.16718ZM15.0622 11.3684C15.0622 12.213 14.7267 13.023 14.1295 13.6202C13.5323 14.2174 12.7223 14.5529 11.8778 14.5529C11.4463 14.5532 11.0195 14.4628 10.6252 14.2875L14.8075 10.1158C14.9715 10.5058 15.058 10.9241 15.0622 11.3472V11.3684ZM9.06487 15.8478C9.53075 16.1454 10.0389 16.3709 10.5722 16.5166C9.99323 16.4119 9.42483 16.2557 8.8738 16.0496L9.06487 15.8478ZM12.8437 16.5378C14.0599 16.3102 15.1584 15.6647 15.9492 14.713C16.74 13.7614 17.1734 12.5633 17.1746 11.3259C17.1741 10.3283 16.8866 9.35183 16.3466 8.513L17.1958 7.6639C18.761 8.64963 20.1496 9.89108 21.3037 11.3366C18.7243 14.5316 15.8265 16.2937 12.8543 16.5803L12.8437 16.5378Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_3729">
        <rect width="24" height="22.7262" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default PreviewOff;
