import { SvgIcon } from '@mui/material';

const Popup = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4142)">
      <path d="M10.8 4.8V2.4H0V24H21.6V13.2H19.2V21.6H2.4V4.8H10.8Z" />
      <path d="M23.9999 8.316V0H15.5999V2.4H19.9079L11.1479 11.148L12.8519 12.852L21.5999 4.092V8.316H23.9999Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4142">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Popup;
