import { Typography } from '@mui/material';
import { List } from '../styled/List';
import { ListItem } from '../styled/ListItem';
import { linkToBestPage } from '../utils/urls';

const PrivacyPolicyMainText = () => (
  <>
    <Typography variant="body1">
      <strong> BEST Spółka Akcyjna </strong> z siedzibą w Gdyni przy ulicy
      Łużyckiej 8A, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru
      Sądowego, prowadzonego przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VIII
      Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS:
      0000017158, posiadająca numer NIP: 5850011412 oraz REGON: 19040034400000,
      z kapitałem zakładowym w wysokości 22.652.014,00 zł, w całości wpłaconym{' '}
      <strong>(Administrator)</strong>:
    </Typography>
    <List>
      <ListItem dense>
        Obsługuje serwis internetowy działający pod adresem: {linkToBestPage}{' '}
        <strong>(Serwis)</strong>;
      </ListItem>
      <ListItem dense>
        Przywiązuje szczególną wagę do ochrony prywatności Użytkowników
        pozyskanych za pośrednictwem Serwisu oraz zapewnienia bezpieczeństwa ich
        danych osobowych na najwyższym poziomie z poszanowaniem prawa do dostępu
        do danych osobowych, sprostowania i uzupełnienia, usunięcia lub
        ograniczenia przetwarzania;
      </ListItem>
      <ListItem dense>
        Zapewnia, aby Użytkownicy Serwisu mieli pełną swobodę decydowania o tym,
        jakie informacje ich dotyczące będą zbierane oraz wykorzystywane przez
        Administratora w sposób automatyczny podczas korzystania przez
        Użytkowników z Serwisu. Nasz Serwis nie zbiera w sposób automatyczny
        żadnych danych o Użytkownikach, z wyjątkiem informacji zbieranych za
        pomocą technologii HTTP Cookie <strong>(Ciasteczka, Cookies)</strong>.
      </ListItem>
    </List>
  </>
);

export default PrivacyPolicyMainText;
