import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { RootDispatch } from 'storage/store';
import { Url } from 'utils/urls';
import { IsLoggedIn } from 'utils/user';
import { MenuVisibilityContext } from '../utils/menuVisibilityContext';

interface IAppBarButtons {
  forDesktop?: boolean;
}

const AppBarButtons = ({ forDesktop }: IAppBarButtons) => {
  const { t } = useTranslation();
  const { setVisible } = useContext(MenuVisibilityContext);
  const navigate = useNavigate();
  const { user } = useDispatch<RootDispatch>();

  useEffect(() => {
    if (!IsLoggedIn()) {
      user.clearUserData();
    }
  }, [IsLoggedIn()]);

  return IsLoggedIn() ? (
    <Button
      variant="outlined"
      size={forDesktop ? 'medium' : 'large'}
      fullWidth={!forDesktop}
      data-link
      onClick={(event) => {
        event.preventDefault();
        setVisible!(false);
        user.logoutUser();
        navigate(Url.login);
      }}
    >
      {t('COMMON_LOGOUT')}
    </Button>
  ) : (
    <>
      <Button
        variant="contained"
        size={forDesktop ? 'medium' : 'large'}
        fullWidth={!forDesktop}
        disableElevation
        sx={{
          marginBottom: forDesktop ? '0' : '15px',
          marginRight: forDesktop ? '15px' : '0'
        }}
        onClick={() => {
          setVisible!(false);
          navigate(Url.login);
        }}
      >
        {t('COMMON_LOG_IN')}
      </Button>
      <Button
        variant="outlined"
        size={forDesktop ? 'medium' : 'large'}
        fullWidth={!forDesktop}
        disableElevation
        onClick={() => {
          setVisible!(false);
          navigate(Url.register);
        }}
      >
        {t('COMMON_CREATE_ACCOUNT')}
      </Button>
    </>
  );
};

export default AppBarButtons;
