import { SimulationParameter } from 'storage/product/product.model';
import { Constant } from '../consts';
import { CurrencyType } from '../formatCurrency/formatCurrency.model';
import { formatCurrency } from '../formatCurrency';

const useAmountAndSumSettingsHandler = (
  installmentsCount: SimulationParameter,
  minInstallmentsText: string,
  maxInstallmentsText: string,
  installmentsAmount: SimulationParameter,
  currencyType: CurrencyType
) => {
  const amountSettings = {
    min: {
      value: +installmentsCount.minValue!,
      label: `${installmentsCount.minValue}
          ${minInstallmentsText}`
    },
    max: {
      value: +installmentsCount.maxValue!,
      label: `${installmentsCount.maxValue}
          ${maxInstallmentsText}`
    },
    step: Constant.one
  };

  const sumSettings = {
    min: {
      value: +installmentsAmount.minValue!,
      label: formatCurrency(
        'plain',
        +installmentsAmount.minValue!,
        currencyType,
        true,
        true,
        true,
        true
      ) as string
    },
    max: {
      value: +installmentsAmount.maxValue!,
      label: formatCurrency(
        'plain',
        +installmentsAmount.maxValue!,
        currencyType,
        true,
        true,
        true,
        true
      ) as string
    },
    step: Constant.ten
  };

  return { amountSettings, sumSettings };
};

export default useAmountAndSumSettingsHandler;
