import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import reducers from './enteredEmails.reducers';
import { EnteredEmailsState } from './enteredEmails.model';

const enteredEmailsModel = createModel<RootModel>()({
  state: {
    resetEmail: '',
    unblockEmail: '',
    registerEmail: ''
  } as EnteredEmailsState,
  reducers
});

export default enteredEmailsModel;
