import { API } from 'utils';
import {
  PaymentArgs,
  PaymentPayload,
  PaymentResponse,
  PaymentStatusPayload,
  PaymentStatusResponse
} from './payment.model';

const endpoint = '/payment';

const effects = () => ({
  registerPayment({ agreementId, ...payload }: PaymentArgs) {
    return API.post<PaymentPayload, PaymentResponse>(
      `${endpoint}/${agreementId}/register`,
      payload
    )
      .then((response) => response)
      .catch(() => undefined);
  },
  getPaymentStatus({ merchantId, posId, hash }: PaymentStatusPayload) {
    return API.get<void, PaymentStatusResponse>(
      `${endpoint}/${merchantId}/${posId}?hash=${hash}`,
      true
    )
      .then((response) => response)
      .catch(() => undefined);
  }
});

export default effects;
