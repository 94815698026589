import { SvgIcon } from '@mui/material';

const Chat = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4138)">
      <path d="M3.04004 -0.0199966H21.04C21.8357 -0.0199966 22.5988 0.296056 23.1614 0.858665C23.724 1.42127 24.04 2.18435 24.04 2.98V15.98C24.04 16.7757 23.724 17.5387 23.1614 18.1013C22.5988 18.6639 21.8357 18.98 21.04 18.98H20.04V24.21L14.16 18.98H3.04004C2.24439 18.98 1.48131 18.6639 0.918701 18.1013C0.356092 17.5387 0.0400391 16.7757 0.0400391 15.98V2.98C0.0400391 2.18435 0.356092 1.42127 0.918701 0.858665C1.48131 0.296056 2.24439 -0.0199966 3.04004 -0.0199966ZM2.04004 15.98C2.04004 16.2452 2.14535 16.4996 2.33289 16.6871C2.52042 16.8746 2.77482 16.98 3.04004 16.98H14.92L18.04 19.75V16.98H21.04C21.3053 16.98 21.5597 16.8746 21.7472 16.6871C21.9347 16.4996 22.04 16.2452 22.04 15.98V2.98C22.04 2.71479 21.9347 2.46045 21.7472 2.27291C21.5597 2.08537 21.3053 1.98 21.04 1.98H3.04004C2.77482 1.98 2.52042 2.08537 2.33289 2.27291C2.14535 2.46045 2.04004 2.71479 2.04004 2.98V15.98Z" />
      <path d="M17.04 10C16.4878 10 16.04 9.55228 16.04 9C16.04 8.44772 16.4878 8 17.04 8C17.5923 8 18.04 8.44772 18.04 9C18.04 9.55228 17.5923 10 17.04 10Z" />
      <path d="M12.04 10C11.4878 10 11.04 9.55228 11.04 9C11.04 8.44772 11.4878 8 12.04 8C12.5923 8 13.04 8.44772 13.04 9C13.04 9.55228 12.5923 10 12.04 10Z" />
      <path d="M7.04004 10C6.48776 10 6.04004 9.55228 6.04004 9C6.04004 8.44772 6.48776 8 7.04004 8C7.59232 8 8.04004 8.44772 8.04004 9C8.04004 9.55228 7.59232 10 7.04004 10Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4138">
        <rect
          width="24"
          height="24.23"
          fill="white"
          transform="matrix(-1 0 0 1 24 0)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Chat;
