import styled from 'styled-components/macro';
import { Grid } from '@mui/material';
import theme from 'App.theme';

const ImageGrid = styled(Grid)`
  background: linear-gradient(323.61deg,
  rgba(222, 227, 223, 0.4) 4.23%,
  rgba(189, 198, 190, 0) 77.14%);
  ${theme.breakpoints.down('lg')}: {
    display: none
  };);
  position: relative;
  overflow: hidden;
`;

export default ImageGrid;
