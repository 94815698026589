import {
  Constant,
  NUMBER_OF_DECIMAL_PLACES,
  ONE_HUNDRED_PERCENT
} from 'utils/consts';

const formatValue = (value: string) => {
  if (/[a-zA-Z]/.test(value)) {
    throw new Error(
      `Wrong value format, value cannot contain letters, value: ${value}`
    );
  }
  let result = value;

  result = result.replace(/,/g, '.').replace(/ /g, '');

  result = (parseFloat(result) * ONE_HUNDRED_PERCENT).toFixed();
  result = (parseFloat(result) / Constant.oneHundred).toFixed(
    NUMBER_OF_DECIMAL_PLACES
  );

  return result;
};

export default formatValue;
