import styled from 'styled-components/macro';
import { Box } from '@mui/material';

const ContactWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-flow: wrap;
  align-items: center;
  ${[theme.breakpoints.down('md')]} {
    flex-flow: column;
    align-items: flex-start;
  };
  & a {
    margin-right: 12px;
    margin-bottom: 8px;
  }
`
);

export default ContactWrapper;
