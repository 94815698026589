import { SvgIcon } from '@mui/material';

const User = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.488 17.216C19.2025 15.8232 17.6426 14.7117 15.9064 13.9515C14.1702 13.1912 12.2953 12.7988 10.4 12.7988C8.50466 12.7988 6.62984 13.1912 4.89365 13.9515C3.15745 14.7117 1.59748 15.8232 0.312 17.216C0.113533 17.4355 0.00251383 17.7201 0 18.016V22.816C0.00420676 23.1315 0.13249 23.4326 0.357079 23.6542C0.581668 23.8758 0.884493 24 1.2 24H19.6C19.9183 24 20.2235 23.8736 20.4485 23.6485C20.6736 23.4235 20.8 23.1183 20.8 22.8V18C20.7936 17.7097 20.6829 17.4313 20.488 17.216Z" />
    <path d="M10.4 11.2C13.4928 11.2 16 8.6928 16 5.6C16 2.50721 13.4928 0 10.4 0C7.30721 0 4.8 2.50721 4.8 5.6C4.8 8.6928 7.30721 11.2 10.4 11.2Z" />
  </SvgIcon>
);

export default User;
