import { ProductParametersKey } from 'storage/agreements/agreements.model';
import {
  DynamicRemissionValueChanged,
  PrerequisitePaymentValueChanged,
  InstallmentsPaymentValueChanged
} from 'storage/product/product.model';
import { formatCurrency } from './formatCurrency';

const setPayloadForRecalculateDynamicRemission = (
  prerequisitePaymentDate: string,
  prerequisitePaymentAmount: number,
  valueChanged:
    | InstallmentsPaymentValueChanged
    | PrerequisitePaymentValueChanged,
  firstInstallmentDate?: string,
  installmentsCount?: number,
  installmentsAmount?: number
) => {
  if (firstInstallmentDate && installmentsCount && installmentsAmount) {
    return [
      {
        key: ProductParametersKey.PREREQUISITE_PAYMENT_DATE,
        value: prerequisitePaymentDate,
        valueChanged:
          valueChanged === DynamicRemissionValueChanged.prerequisiteDate
      },
      {
        key: ProductParametersKey.PREREQUISITE_PAYMENT_AMOUNT,
        value: formatCurrency(
          'plain',
          prerequisitePaymentAmount as number,
          undefined,
          false,
          false,
          false
        ) as string,
        valueChanged:
          valueChanged ===
          DynamicRemissionValueChanged.prerequisitePaymentAmount
      },
      {
        key: ProductParametersKey.INSTALLMENTS_COUNT,
        value: installmentsCount.toString(),
        valueChanged:
          valueChanged === DynamicRemissionValueChanged.installmentsCount
      },
      {
        key: ProductParametersKey.INSTALLMENTS_AMOUNT,
        value: formatCurrency(
          'plain',
          installmentsAmount,
          undefined,
          false,
          false,
          false
        ) as string,
        valueChanged:
          valueChanged === DynamicRemissionValueChanged.installmentsAmount
      },
      {
        key: ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
        value: firstInstallmentDate,
        valueChanged: false
      }
    ];
  }

  return [
    {
      key: ProductParametersKey.PREREQUISITE_PAYMENT_DATE,
      value: prerequisitePaymentDate,
      valueChanged:
        valueChanged === DynamicRemissionValueChanged.prerequisiteDate
    },
    {
      key: ProductParametersKey.PREREQUISITE_PAYMENT_AMOUNT,
      value: formatCurrency(
        'plain',
        prerequisitePaymentAmount as number,
        undefined,
        false,
        false,
        false
      ) as string,
      valueChanged:
        valueChanged === DynamicRemissionValueChanged.prerequisitePaymentAmount
    }
  ];
};

export default setPayloadForRecalculateDynamicRemission;
