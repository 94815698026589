import { Statement, StatementState, Consent } from './statement.model';

const reducers = {
  storeAllStatements: (state: StatementState, statements: Statement[]) => ({
    ...state,
    statements
  }),
  storeAllConsents: (state: StatementState, consents: Consent[]) => ({
    ...state,
    consents
  })
};

export default reducers;
