import { Typography, Container } from '@mui/material';
import PrivacyPolicyMainText from './PrivacyPolicyMainText';
import DataProcessing from './DataProcessing';
import PurposesOfDataProcessing from './PurposesOfDataProcessing';
import TransferOfPersonalData from './TransferOfPersonalData';
import PeriodOfDataProcessing from './PeriodOfDataProcessing';
import SecurityAndProcedures from './SecurityAndProcedures';
import UserRights from './UserRights';
import Cookies from './Cookies';
import InformationAboutSocialPlugins from './InformationAboutSocialPlugins';
import OtherTechnology from './OtherTechnology';
import ChangeOfThePrivacyPolicy from './ChangeOfThePrivacyPolicy';
import ContactDetails from './ContactDetails';

const PrivacyPolicyContent = () => (
  <Container disableGutters>
    <Typography variant="h4" fontWeight="700">
      Polityka Prywatności i Cookies
    </Typography>
    <PrivacyPolicyMainText />
    <DataProcessing />
    <PurposesOfDataProcessing />
    <TransferOfPersonalData />
    <PeriodOfDataProcessing />
    <UserRights />
    <SecurityAndProcedures />
    <Cookies />
    <InformationAboutSocialPlugins />
    <OtherTechnology />
    <ChangeOfThePrivacyPolicy />
    <ContactDetails />
  </Container>
);

export default PrivacyPolicyContent;
