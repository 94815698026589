import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EventsType, useIdleTimer } from 'react-idle-timer';
import { RootState } from 'storage/store';
import { Token } from 'utils/Token';
import { IsLoggedIn } from 'utils/user';
import { TimeInMs } from 'utils/consts';
import { events, apiEvents } from 'utils/Api/Api';

const TokenRefresh = memo(() => {
  const worker = new Worker('/workers/tokenWorker.js');
  const user = useSelector((state: RootState) => state.user);
  const onIdle = () => {
    if (IsLoggedIn()) {
      events.emit(apiEvents.SESSION_EXPIRED);
    }
  };

  const eventsIdle: EventsType[] = [
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'visibilitychange'
  ];

  useIdleTimer({
    onIdle,
    timeout: TimeInMs.fortyFiveMinutes,
    events: eventsIdle
  });

  useEffect(() => {
    if (IsLoggedIn() && Token.isTokenNotExpired()) {
      worker.postMessage({
        command: 'token-refresh-start',
        ttl: Token.getTimeToRefresh()
      });
    }
  }, [user.token]);

  useEffect(() => {
    worker.onmessage = ({ data }: MessageEvent) => {
      if (data.command === 'token-refresh-done' && IsLoggedIn()) {
        refreshToken();
      }
    };
  }, [worker]);

  const refreshToken = async () => {
    await Token.refreshToken();

    worker.postMessage({
      command: 'token-refresh-start',
      ttl: Token.getTimeToRefresh()
    });
  };

  return null;
});

export default TokenRefresh;
