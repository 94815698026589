import styled from 'styled-components/macro';
import { ListItem as MuiListItem } from '@mui/material';

export interface IListItem {
  $listType?: string;
}

export const ListItem = styled(MuiListItem)<IListItem>`
  line-height: 140%;
  list-style-type: ${({ $listType }) =>
    $listType ? `${$listType}` : 'decimal'};
  font-size: 16px;
  && {
    padding-left: 0;
    display: list-item;
  }
`;
