const AnnexBox = () => (
  <svg
    width="171"
    height="150"
    viewBox="0 0 171 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6960_8259)">
      <path
        d="M156.726 115.464C170.485 103.009 175.374 85.987 167.645 77.4449C159.916 68.9027 142.495 72.0749 128.736 84.53C114.976 96.9852 110.088 114.007 117.817 122.549C125.546 131.091 142.966 127.919 156.726 115.464Z"
        fill="#F2F2F2"
      />
      <path
        d="M116.692 149.559C114.837 145.725 114.18 141.405 114.503 137.176C114.853 132.973 116.093 128.893 118.139 125.206C120.126 121.647 122.647 118.414 125.613 115.619C128.729 112.633 132.243 110.099 135.94 107.883C139.845 105.544 143.947 103.556 148.097 101.696C148.614 101.464 149.132 101.234 149.651 101.006C149.991 100.856 149.697 100.357 149.359 100.506C145.127 102.369 140.932 104.333 136.92 106.637C133.087 108.839 129.42 111.357 126.143 114.333C123.056 117.093 120.396 120.297 118.251 123.84C116.055 127.485 114.631 131.543 114.068 135.761C113.526 140.01 113.945 144.428 115.555 148.414C115.751 148.9 115.964 149.379 116.192 149.851C116.354 150.187 116.854 149.893 116.692 149.559Z"
        fill="#E6E6E6"
      />
      <path
        d="M132.673 109.362C132.481 106.203 132.719 103.032 133.379 99.9364C134.038 96.8392 135.107 93.8437 136.557 91.0293C137.374 89.4493 138.31 87.9339 139.357 86.4962C139.576 86.1944 139.074 85.905 138.856 86.2038C136.975 88.7953 135.453 91.6291 134.33 94.6282C133.204 97.6277 132.482 100.763 132.183 103.953C132.013 105.751 131.984 107.56 132.094 109.362C132.116 109.733 132.696 109.735 132.673 109.362Z"
        fill="#E6E6E6"
      />
      <path
        d="M70.6848 104.624C88.5943 88.4128 94.9572 66.2574 84.8968 55.1389C74.8364 44.0205 52.1624 48.1493 34.2529 64.361C16.3435 80.5727 9.98058 102.728 20.041 113.847C30.1014 124.965 52.7754 120.836 70.6848 104.624Z"
        fill="#F2F2F2"
      />
      <path
        d="M18.5769 149.004C16.1617 144.014 15.3064 138.392 15.7269 132.886C16.1447 127.416 17.8337 122.11 20.4604 117.306C23.0042 112.654 26.3663 108.49 30.1881 104.828C34.2442 100.942 38.818 97.6428 43.6303 94.7594C48.712 91.7146 54.0516 89.1271 59.4534 86.7062C60.1266 86.4045 60.8008 86.1051 61.4761 85.8081C61.9192 85.6129 61.5359 84.9628 61.0955 85.1568C55.588 87.5823 50.127 90.1383 44.9047 93.1374C39.9157 96.0027 35.1426 99.2804 30.8783 103.154C26.9003 106.767 23.355 110.9 20.6061 115.528C17.7477 120.273 15.8942 125.555 15.1604 131.046C14.4562 136.575 15.0007 142.326 17.0966 147.514C17.3522 148.147 17.6286 148.771 17.9257 149.385C18.1371 149.822 18.7876 149.44 18.5769 149.004Z"
        fill="#E6E6E6"
      />
      <path
        d="M39.3772 96.6836C39.1279 92.5713 39.4373 88.4442 40.2969 84.415C41.1539 80.3836 42.5449 76.4847 44.4331 72.8214C45.4961 70.7649 46.7142 68.7925 48.0769 66.9212C48.363 66.5283 47.709 66.1516 47.4258 66.5405C44.9773 69.9137 42.9955 73.6021 41.534 77.5058C40.0691 81.4099 39.1292 85.4913 38.739 89.643C38.5187 91.9834 38.48 94.3373 38.6231 96.6836C38.6525 97.1659 39.4068 97.1692 39.3772 96.6836Z"
        fill="#E6E6E6"
      />
      <path
        d="M105.618 42.7513C101.097 42.7513 96.593 39.6431 92.9353 33.9991C89.7191 29.0364 87.7211 22.8678 87.7211 17.9008C87.7211 15.55 88.184 13.2223 89.0834 11.0505C89.9829 8.87864 91.3011 6.90527 92.9631 5.24302C94.625 3.58078 96.5979 2.26222 98.7693 1.36262C100.941 0.463018 103.268 0 105.618 0C107.969 0 110.296 0.463018 112.467 1.36262C114.639 2.26222 116.612 3.58078 118.274 5.24302C119.935 6.90527 121.254 8.87864 122.153 11.0505C123.053 13.2223 123.515 15.55 123.515 17.9008C123.515 22.8678 121.518 29.0364 118.301 33.9991C114.644 39.6431 110.139 42.7513 105.618 42.7513ZM105.618 0.421195C100.985 0.426426 96.5429 2.2697 93.2666 5.54663C89.9903 8.82356 88.1474 13.2665 88.1422 17.9008C88.1422 27.5478 95.9715 42.3301 105.618 42.3301C115.265 42.3301 123.094 27.5478 123.094 17.9008C123.089 13.2665 121.246 8.82356 117.97 5.54663C114.694 2.2697 110.252 0.426427 105.618 0.421195Z"
        fill="#3F3D56"
      />
      <path
        d="M107.306 46.542H103.931C103.773 46.542 103.618 46.5066 103.476 46.4384C103.333 46.3702 103.208 46.2709 103.11 46.1479C103.011 46.025 102.941 45.8814 102.906 45.7278C102.87 45.5742 102.869 45.4146 102.903 45.2607L103.554 42.3301H107.682L108.333 45.2607C108.367 45.4146 108.367 45.5742 108.331 45.7278C108.295 45.8814 108.225 46.025 108.127 46.1479C108.028 46.2709 107.903 46.3702 107.761 46.4384C107.619 46.5066 107.463 46.542 107.306 46.542ZM103.892 42.7513L103.314 45.352C103.294 45.4443 103.294 45.5401 103.316 45.6323C103.337 45.7244 103.379 45.8106 103.438 45.8844C103.497 45.9582 103.572 46.0177 103.658 46.0587C103.743 46.0996 103.836 46.1208 103.931 46.1208H107.306C107.4 46.1208 107.494 46.0996 107.579 46.0587C107.664 46.0177 107.739 45.9582 107.798 45.8844C107.857 45.8106 107.899 45.7244 107.921 45.6323C107.942 45.5401 107.943 45.4443 107.922 45.352L107.344 42.7513H103.892Z"
        fill="#3F3D56"
      />
      <path
        d="M97.2887 5.23519C94.5102 6.9227 92.5122 9.64133 91.731 12.7973C91.515 13.6751 91.4002 14.5747 91.3889 15.4786C91.3918 15.5605 91.4263 15.6381 91.4852 15.695C91.5441 15.7519 91.6229 15.7837 91.7048 15.7837C91.7867 15.7837 91.8654 15.7519 91.9243 15.695C91.9833 15.6381 92.0178 15.5605 92.0206 15.4786C92.0848 12.4077 93.3575 9.48601 95.5624 7.34796C96.1808 6.74779 96.8672 6.22182 97.6075 5.78072C97.6768 5.73697 97.7264 5.66799 97.7459 5.58835C97.7653 5.50871 97.7531 5.42464 97.7117 5.35387C97.6703 5.28309 97.6031 5.23116 97.5242 5.20902C97.4453 5.18687 97.3609 5.19626 97.2887 5.23519Z"
        fill="#3F3D56"
      />
      <path
        d="M102.693 43.1487C106.509 42.9533 110.851 42.8492 113.825 45.6644C115.065 46.8386 115.848 48.4122 115.801 50.142C115.749 52.0244 114.792 53.7878 113.606 55.202C111.053 58.2463 107.309 60.1497 103.718 61.6839C99.9013 63.3145 95.9254 64.5878 92.2585 66.5555C89.0389 68.2831 86.0379 70.615 84.0929 73.7565C83.1361 75.2792 82.5014 76.9819 82.2282 78.7595C81.9774 80.5414 82.0676 82.3547 82.4942 84.1028C83.3507 87.6859 85.4595 90.8526 88.2694 93.2056C91.1131 95.5868 94.5579 97.1725 98.1269 98.1199C102.364 99.2446 106.778 99.4991 111.144 99.5246C115.652 99.5508 120.164 99.3302 124.67 99.583C125.766 99.6444 126.86 99.7352 127.95 99.8616C128.353 99.9083 128.35 99.2761 127.95 99.2298C123.449 98.7081 118.913 98.8276 114.392 98.8788C109.959 98.9289 105.489 98.9135 101.112 98.1307C97.3377 97.4558 93.6371 96.191 90.4361 94.0458C87.3699 91.9909 84.871 89.1095 83.6065 85.6132C82.3713 82.198 82.3842 78.4183 84.0382 75.1443C85.6943 71.8661 88.583 69.3863 91.7221 67.5766C95.3446 65.4882 99.3334 64.1813 103.182 62.5889C106.817 61.0849 110.534 59.2914 113.326 56.448C114.62 55.1313 115.727 53.5287 116.201 51.7256C116.433 50.8822 116.488 50.0003 116.365 49.1344C116.242 48.2686 115.942 47.4373 115.485 46.6921C114.569 45.2906 113.263 44.1889 111.727 43.5237C109.873 42.6884 107.817 42.4476 105.802 42.4273C104.765 42.4168 103.728 42.4639 102.693 42.5169C102.289 42.5376 102.286 43.1695 102.693 43.1487Z"
        fill="#3F3D56"
      />
      <path
        d="M83.2685 145.679L80.7367 146.183L77.5877 136.656L81.3242 135.912L83.2685 145.679Z"
        fill="#FFB8B8"
      />
      <path
        d="M84.4028 148.006L76.2397 149.632L76.2192 149.528C76.1361 149.111 76.136 148.681 76.2189 148.264C76.3018 147.847 76.466 147.45 76.7023 147.096C76.9386 146.742 77.2423 146.438 77.5959 146.202C77.9496 145.965 78.3464 145.801 78.7637 145.718L78.7639 145.718L83.7496 144.725L84.4028 148.006Z"
        fill="#2F2E41"
      />
      <path
        d="M107.453 147.129L104.872 147.129L101.96 137.17L105.769 137.17L107.453 147.129Z"
        fill="#FFB8B8"
      />
      <path
        d="M108.112 149.631L99.7884 149.631V149.525C99.7884 149.1 99.8722 148.679 100.035 148.285C100.198 147.892 100.436 147.535 100.737 147.234C101.038 146.933 101.395 146.695 101.788 146.532C102.181 146.369 102.603 146.285 103.028 146.285L108.112 146.285L108.112 149.631Z"
        fill="#2F2E41"
      />
      <path
        d="M101.291 118.685C101.498 118.427 101.644 118.126 101.722 117.804C101.799 117.483 101.805 117.148 101.739 116.824C101.673 116.5 101.537 116.194 101.34 115.928C101.143 115.663 100.891 115.443 100.6 115.286L95.4428 95.3359L90.9573 97.3555L97.4369 116.331C97.2047 116.837 97.1662 117.41 97.3287 117.943C97.4912 118.476 97.8434 118.93 98.3185 119.22C98.7936 119.511 99.3587 119.616 99.9066 119.518C100.455 119.419 100.947 119.123 101.291 118.685Z"
        fill="#FFB8B8"
      />
      <path
        d="M96.6493 98.6782L91.6641 99.8558C91.5223 99.8893 91.3749 99.8918 91.232 99.8632C91.089 99.8346 90.954 99.7755 90.836 99.69C90.7179 99.6045 90.6197 99.4945 90.548 99.3676C90.4763 99.2407 90.4328 99.0998 90.4204 98.9546L89.9877 93.8651C89.8205 93.1383 89.9482 92.3748 90.3429 91.742C90.7376 91.1093 91.3671 90.6589 92.0933 90.4896C92.8195 90.3203 93.5832 90.4459 94.2169 90.8389C94.8506 91.2319 95.3027 91.8603 95.474 92.5861L97.3584 97.3157C97.4124 97.4512 97.4365 97.5966 97.4291 97.7422C97.4217 97.8878 97.3831 98.0301 97.3158 98.1594C97.2485 98.2887 97.1541 98.402 97.0391 98.4915C96.9241 98.5811 96.7911 98.6447 96.6493 98.6782Z"
        fill="#006D48"
      />
      <path
        d="M79.6428 143.873C79.4253 143.874 79.2143 143.799 79.0457 143.661C78.8771 143.523 78.7612 143.332 78.7177 143.119L76.206 131.06C76.0498 130.301 76.0453 129.518 76.1927 128.757C76.3401 127.995 76.6365 127.271 77.0649 126.625L84.9778 114.753V105.856L94.1638 103.785L94.2035 103.817C98.9687 107.709 98.6945 112.507 98.6652 112.878L107.693 142.199C107.737 142.341 107.747 142.491 107.722 142.637C107.697 142.784 107.638 142.922 107.55 143.041C107.462 143.161 107.347 143.258 107.215 143.324C107.082 143.391 106.936 143.426 106.788 143.426H104.077C103.887 143.427 103.702 143.37 103.545 143.264C103.388 143.158 103.267 143.006 103.197 142.83L93.4671 119.586L82.0158 129.012C81.924 129.088 81.8519 129.184 81.8055 129.294C81.7592 129.403 81.74 129.522 81.7496 129.641L82.8054 142.602C82.8267 142.848 82.7507 143.093 82.5937 143.283C82.4367 143.474 82.2112 143.595 81.9657 143.621L79.7499 143.867C79.7143 143.871 79.6786 143.873 79.6428 143.873Z"
        fill="#2F2E41"
      />
      <path
        d="M91.3856 87.177C94.2417 87.177 96.5571 84.8612 96.5571 82.0045C96.5571 79.1478 94.2417 76.832 91.3856 76.832C88.5295 76.832 86.2142 79.1478 86.2142 82.0045C86.2142 84.8612 88.5295 87.177 91.3856 87.177Z"
        fill="#FFB8B8"
      />
      <path
        d="M85.4671 106.842C85.2787 106.843 85.0945 106.787 84.9379 106.682C84.7813 106.577 84.6593 106.428 84.5873 106.254C82.855 102 82.8837 96.627 83.6434 93.4349C83.9671 92.0834 84.7547 90.8883 85.8691 90.0581C86.9834 89.2279 88.3537 88.8152 89.7411 88.8919C90.5667 88.9359 91.3741 89.1518 92.1116 89.5257C92.849 89.8997 93.5004 90.4234 94.0239 91.0635C94.5475 91.7036 94.9317 92.4459 95.152 93.243C95.3724 94.04 95.424 94.8743 95.3036 95.6925C94.8986 98.4375 94.7342 102.304 94.6823 103.838C94.6745 104.043 94.6007 104.24 94.4719 104.399C94.3431 104.559 94.1661 104.672 93.9675 104.723L85.6982 106.814C85.6227 106.833 85.545 106.842 85.4671 106.842Z"
        fill="#006D48"
      />
      <path
        d="M85.5621 75.9639C85.4544 76.0083 85.3434 76.0441 85.23 76.071L81.5883 85.8595L83.2018 87.8174L80.5659 90.6392L77.3727 87.4507C77.1147 87.1932 76.9473 86.8588 76.8955 86.498C76.8436 86.1371 76.9102 85.7691 77.0853 85.4493L82.7904 75.0269C82.5668 74.6377 82.4669 74.1896 82.5041 73.7422C82.5414 73.2948 82.7139 72.8694 82.9987 72.5225C83.2836 72.1755 83.6673 71.9236 84.0988 71.8001C84.5303 71.6766 84.9892 71.6875 85.4144 71.8312C85.8396 71.975 86.211 72.2448 86.4791 72.6048C86.7473 72.9648 86.8995 73.3979 86.9155 73.8465C86.9316 74.2951 86.8107 74.738 86.5689 75.1163C86.3272 75.4945 85.976 75.7901 85.5621 75.9639Z"
        fill="#FFB8B8"
      />
      <path
        d="M79.6344 90.3584L82.5391 86.1384C82.6217 86.0183 82.7293 85.9175 82.8544 85.8427C82.9795 85.768 83.1193 85.7211 83.2642 85.7052C83.4091 85.6894 83.5557 85.7049 83.694 85.7509C83.8323 85.7968 83.9591 85.872 84.0658 85.9714L87.8023 89.4531C88.4143 89.8794 88.8322 90.5311 88.9645 91.2653C89.0967 91.9994 88.9325 92.756 88.5078 93.3691C88.0831 93.9823 87.4326 94.402 86.6989 94.5361C85.9653 94.6703 85.2084 94.5079 84.5943 94.0847L80.0233 91.8444C79.8924 91.7802 79.7769 91.6886 79.6847 91.5758C79.5924 91.4629 79.5255 91.3315 79.4886 91.1905C79.4517 91.0494 79.4457 90.9021 79.4708 90.7585C79.496 90.615 79.5518 90.4785 79.6344 90.3584Z"
        fill="#006D48"
      />
      <path
        d="M91.0879 87.9344C90.7152 87.5211 90.9331 86.8111 91.2778 86.3701C91.6225 85.9291 92.0882 85.5544 92.2242 84.9951C92.4557 84.0426 91.592 83.174 90.7303 82.8662C89.8687 82.5584 88.9033 82.5346 88.1446 81.9948C86.9944 81.1766 86.7324 79.312 87.3931 78.0025C88.0539 76.693 89.4353 75.9421 90.8063 75.8299C92.1773 75.7176 93.5397 76.1635 94.7946 76.7736C96.0082 77.3637 97.1788 78.1325 98.0317 79.2406C98.8845 80.3486 99.3863 81.8377 99.1399 83.2568C98.8389 84.9901 97.4811 86.3281 95.9725 86.9812C94.464 87.6344 92.8059 87.7205 91.1835 87.7986L91.0879 87.9344Z"
        fill="#2F2E41"
      />
      <path
        d="M96.8452 78.0581C98.4311 76.4875 100.553 75.5769 102.784 75.5098C105.015 75.4428 107.188 76.2242 108.865 77.6967C111.664 80.1537 112.731 84.2001 115.669 86.489C118.314 88.5497 122.286 88.7032 125.082 86.8528C123.805 88.3446 122.506 89.8563 120.872 90.9441C119.237 92.032 117.195 92.6603 115.287 92.1936C113.049 91.6459 111.42 89.7392 110.155 87.8123C108.889 85.8853 107.774 83.7744 105.94 82.3786C104.55 81.3273 102.851 80.766 101.108 80.7823C100.041 80.7955 98.952 81.0246 97.9209 80.7495C96.8898 80.4743 95.9426 79.4225 96.2861 78.412L96.8452 78.0581Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <clipPath id="clip0_6960_8259">
        <rect width="171.311" height="150" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AnnexBox;
