import { SvgIcon } from '@mui/material';

const Cursor = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4169)">
      <path d="M4.46016 19.6671C4.53809 20.2047 4.7994 20.6989 5.19986 21.066C5.60031 21.4331 6.11531 21.6505 6.65768 21.6815C7.07147 21.6536 7.47285 21.5285 7.82922 21.3164C8.18559 21.1042 8.48691 20.8111 8.7087 20.4606L10.7475 17.7137L16.0216 22.9756L22.8949 16.0778L17.6209 10.8159L20.4532 8.74057C20.8757 8.51084 21.2144 8.15288 21.4204 7.7183C21.6265 7.28373 21.6892 6.79493 21.5996 6.32242C21.5099 5.84991 21.2726 5.4181 20.9218 5.08913C20.571 4.76017 20.1248 4.55105 19.6475 4.49198L3.3614 0.121404C2.86209 -0.0527806 2.32168 -0.0713837 1.8116 0.0681261C1.30152 0.207636 0.845781 0.498634 0.504625 0.902688C0.256379 1.25245 0.0938801 1.65571 0.0302567 2.07987C-0.0333668 2.50402 0.00366577 2.93718 0.138368 3.34438L4.46016 19.6671ZM2.67773 2.47763L18.915 6.83605L13.9217 10.4986L19.4765 16.0412L16.0216 19.4962L10.4789 13.9535L6.81639 18.9102L2.45798 2.67293C2.44382 2.58396 2.44382 2.49335 2.45798 2.40438C2.53271 2.4241 2.60611 2.44857 2.67773 2.47763Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4169">
        <rect width="23.9745" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Cursor;
