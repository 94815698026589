import { SvgIcon } from '@mui/material';

const Unlock = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.54545 1.44087C7.50988 1.44087 8.4348 1.824 9.11675 2.50595C9.79871 3.1879 10.1818 4.11281 10.1818 5.07724H11.6364C11.6364 3.72704 11.1 2.43214 10.1453 1.47741C9.19054 0.522675 7.89565 -0.0136719 6.54545 -0.0136719C5.19526 -0.0136719 3.90037 0.522675 2.94563 1.47741C1.9909 2.43214 1.45455 3.72704 1.45455 5.07724V6.53178H0V15.9863H13.0909V6.53178H2.90909V5.07724C2.90909 4.11281 3.2922 3.1879 3.97415 2.50595C4.65611 1.824 5.58103 1.44087 6.54545 1.44087ZM11.6364 14.5318H1.45455V7.98633H11.6364V14.5318Z" />
    <path d="M7.2729 10.1816H5.81836V12.3635H7.2729V10.1816Z" />
  </SvgIcon>
);

export default Unlock;
