import { SvgIcon } from '@mui/material';

const Bell = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.3998 15.984C6.82415 15.984 7.23109 15.8154 7.53115 15.5154C7.83121 15.2153 7.9998 14.8084 7.9998 14.384H4.7998C4.7998 14.8084 4.9684 15.2153 5.26846 15.5154C5.56852 15.8154 5.97546 15.984 6.3998 15.984Z" />
    <path d="M7.20022 1.66387V0.783887C7.20022 0.571714 7.11597 0.368242 6.96594 0.218213C6.81592 0.0681838 6.61239 -0.0161133 6.40022 -0.0161133C6.18805 -0.0161133 5.98453 0.0681838 5.8345 0.218213C5.68447 0.368242 5.60022 0.571714 5.60022 0.783887V1.65591C4.26718 1.84832 3.04812 2.51467 2.16653 3.53291C1.28494 4.55115 0.799875 5.85305 0.800221 7.1999V10.8719L0.616237 11.0479C0.361205 11.2374 0.172713 11.5028 0.0778579 11.8061C-0.0169975 12.1093 -0.0133701 12.4348 0.0882095 12.7359C0.224988 13.0124 0.445622 13.2386 0.718581 13.3822C0.99154 13.5259 1.3029 13.5797 1.60823 13.5359H11.2082C11.5147 13.5802 11.8274 13.5267 12.1017 13.3831C12.376 13.2395 12.5981 13.013 12.7363 12.7359C12.8385 12.4361 12.8424 12.1115 12.7474 11.8094C12.6524 11.5072 12.4636 11.2433 12.2082 11.0559L12.0082 10.8639V7.18389C12.0025 5.83997 11.5137 4.54295 10.631 3.52954C9.74829 2.51614 8.53064 1.85392 7.20022 1.66387ZM1.9762 11.9839L2.40022 11.5679V7.18389C2.40022 6.12302 2.82166 5.10561 3.5718 4.35547C4.32195 3.60532 5.33936 3.18389 6.40022 3.18389C7.46109 3.18389 8.47849 3.60532 9.22864 4.35547C9.97878 5.10561 10.4002 6.12302 10.4002 7.18389V11.5199L10.8403 11.9439L1.9762 11.9839Z" />
  </SvgIcon>
);

export default Bell;
