import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ContactDetails = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" fontWeight="700" sx={{ margin: '20px 0' }}>
        Dane kontaktowe do Inspektora Ochrony Danych Osobowych.
      </Typography>
      <Typography variant="body1">
        Adres e-mail:{' '}
        <Link
          sx={{ fontWeight: 700, lineHeight: '125%' }}
          color="primary.light"
          href={`mailto:${t('CONTACT_RODO_EMAIL')}`}
          data-link-no-alert
        >
          {t('CONTACT_RODO_EMAIL')}
        </Link>
      </Typography>
      <Typography variant="body1">
        Adres do kontaktu pisemnego: BEST Spółka Akcyjna, ul. Stoczniowa 2,
        82-300 Elbląg.
      </Typography>
    </>
  );
};

export default ContactDetails;
