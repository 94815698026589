import TagManager from 'react-gtm-module';
import { IsLoggedIn } from 'utils/user';

interface ITrackVirtualPageView {
  step: number | null;
  pathname: string;
  userId: number | null;
}

export const trackVirtualPageView = ({
  pathname,
  step,
  userId
}: ITrackVirtualPageView) => {
  const eventName = 'virtualPageView';
  const currentStep = `/?step=${step}`;
  const path = step !== null ? `${pathname}${currentStep}` : `${pathname}`;
  const isLoggedIn = IsLoggedIn() ? 'logged' : 'not logged';
  const userIdString = userId ? userId.toString() : '';
  const virtualPageView = {
    dataLayer: {
      event: eventName,
      vpv: path,
      user_status: isLoggedIn,
      user_id: userIdString
    }
  };
  TagManager.dataLayer(virtualPageView);
};
