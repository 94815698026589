import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Url } from 'utils/urls';

interface IErrorBoundary {
  children: JSX.Element;
}

class ErrorBoundary extends Component<IErrorBoundary> {
  state = {
    hasError: false
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: false });
  }

  render() {
    return this.state.hasError ? (
      <Navigate to={Url.error} />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
