import React from 'react';
import { SvgIcon } from '@mui/material';

interface ILogo {
  size?: number;
}

const Logo = ({ size, ...props }: ILogo) => {
  const defaultSize = 30;
  return (
    <SvgIcon
      {...props}
      sx={{ fontSize: size ?? defaultSize, verticalAlign: 'middle' }}
      viewBox="0 0 155 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="logo svg"
    >
      <g clipPath="url(#clip0_3152_15822)">
        <path
          d="M0.373598 1.46405C7.70902 1.70309 14.9399 1.48272 22.0363 2.30067C29.6219 3.17465 33.1365 7.1561 33.6669 15.4141C34.1375 22.8279 33.7976 30.309 33.5175 37.749C33.3643 41.8574 31.4446 45.2487 27.7134 47.3366C27.7694 47.5121 27.7731 47.6765 27.8404 47.7101C32.7369 50.1266 34.4624 54.4703 34.5633 59.5013C34.7351 67.3895 34.7264 75.2801 34.5371 83.1733C34.4156 85.5264 33.9109 87.8438 33.0431 90.0344C31.3475 94.6284 27.4407 96.7984 22.9401 97.1308C15.4702 97.7022 7.96674 97.6686 0.33252 97.8965L0.373598 1.46405ZM12.8072 53.6897V85.9708C13.7111 85.9708 14.4543 86.0044 15.1976 85.9708C19.1977 85.7504 21.1175 84.5515 21.2706 80.8726C21.5806 73.4513 21.547 65.9963 21.2407 58.5713C21.1025 55.1314 19.306 53.9399 15.8064 53.7159C14.8316 53.6337 13.8493 53.6897 12.8072 53.6897ZM12.7325 13.7034V41.5773C17.0688 42.5671 20.2024 40.9087 20.4489 36.7779C20.8 30.6576 20.8 24.5223 20.4489 18.402C20.2584 14.8463 18.6636 13.8454 14.9959 13.7072C14.3049 13.681 13.6289 13.7034 12.7325 13.7034Z"
          fill="#006D48"
        />
        <path
          d="M95.4275 79.9576C95.3939 76.3048 95.4275 72.6483 95.4275 68.7266C91.3714 68.7266 87.5692 68.7229 83.7633 68.7453C83.4085 68.749 83.0537 68.8162 82.7325 68.8499C82.1199 71.976 82.5009 85.0483 83.2778 88.4097C84.7755 94.9085 87.8904 97.9189 94.3668 99.0394C95.78 99.2445 97.2086 99.3244 98.6359 99.2784C106.924 99.2224 112.115 95.2148 114.438 87.2519C115.891 82.2695 115.424 77.1638 115.667 72.0881C116.007 64.9095 113.893 58.5377 110.098 52.5618C106.707 47.2134 103.685 41.6334 100.115 36.4044C95.7898 30.0849 95.6703 23.1753 96.3799 16.0304C96.46 15.3537 96.6077 14.6868 96.8207 14.0396C97.2577 12.6428 98.3259 12.1722 99.6891 12.2095C101.052 12.2469 101.777 12.751 102.15 14.1928C102.353 14.973 102.317 15.786 102.363 16.5906V29.3865H104.074C107.364 29.3305 110.659 29.3641 113.949 29.3865H114.633V12.0564C114.151 7.80228 112.253 4.08975 108.145 1.97204C98.6022 -2.97675 87.4236 1.80023 84.4356 12.0564C83.1134 16.6093 83.472 21.2742 83.2778 25.9205C83.0163 32.1093 84.7344 37.6519 87.9689 42.8584C91.6665 48.8156 95.1586 54.9073 98.8936 60.8384C103.585 68.2672 103.204 76.3048 102.005 84.4507C101.695 86.5385 100.511 87.1884 98.6209 87.1174C96.731 87.0465 96.055 85.7803 95.801 84.2154C95.5741 82.807 95.4493 81.384 95.4275 79.9576Z"
          fill="#006D48"
        />
        <path
          d="M58.5039 85.2538V53.7234H71.4268V52.9764C71.5164 49.2191 71.4903 45.4505 71.4903 41.3981H58.5898V14.2264H72.2821V8.97885V1.81897H45.708V97.538H73.0478C73.0478 93.7172 73.0216 90.0569 73.0478 86.4004V85.2538H64.1063H58.5039Z"
          fill="#006D48"
        />
        <path
          d="M132.206 97.5902H145.054L145.069 14.1218H154.974V1.79651H122.2V14.1554H132.206V97.5902Z"
          fill="#006D48"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155 115.783C155 113.308 152.993 111.301 150.518 111.301H4.48193C2.00663 111.301 0 113.308 0 115.783V126.801V137.819C0 140.295 2.00662 142.301 4.48193 142.301H150.518C152.993 142.301 155 140.295 155 137.819V126.801V115.783Z"
        fill="#FE7F2D"
      />
      <path
        d="M24.6186 118.529C21.057 118.529 19.312 122.33 19.312 126.728C19.312 131.15 20.7462 135.023 24.4035 135.023C27.4631 135.023 29.6145 132.56 29.6145 126.489C29.6145 122.808 28.5149 118.529 24.6186 118.529ZM24.4991 121.254C25.9094 121.254 26.3397 123.812 26.3397 126.632C26.3397 129.907 25.8377 132.298 24.523 132.298C23.2561 132.298 22.5629 130.433 22.5629 126.776C22.5629 123.31 23.2561 121.254 24.4991 121.254Z"
        fill="white"
      />
      <path
        d="M45.8318 134.831V131.198C45.8318 127.971 45.7122 125.485 45.6405 123.86H45.6883C46.0469 125.318 46.7879 127.349 47.3138 128.712L49.7759 134.831H52.6443V118.72H49.8954V122.282C49.8954 125.246 50.1105 128.043 50.2061 129.74H50.1583C49.7042 128.353 48.9153 126.274 48.4373 125.031L45.9513 118.72H43.0828V134.831H45.8318Z"
        fill="white"
      />
      <path
        d="M66.8819 134.831H74.1486V132.178H70.0133V118.72H66.8819V134.831Z"
        fill="white"
      />
      <path d="M87.4362 118.72V134.831H90.5676V118.72H87.4362Z" fill="white" />
      <path
        d="M107.588 134.831V131.198C107.588 127.971 107.469 125.485 107.397 123.86H107.445C107.803 125.318 108.544 127.349 109.07 128.712L111.532 134.831H114.401V118.72H111.652V122.282C111.652 125.246 111.867 128.043 111.962 129.74H111.915C111.46 128.353 110.672 126.274 110.194 125.031L107.708 118.72H104.839V134.831H107.588Z"
        fill="white"
      />
      <path
        d="M135.451 125.15H131.77V121.445H135.642V118.72H128.638V134.831H135.929V132.106H131.77V127.756H135.451V125.15Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_3152_15822">
          <rect
            width="154.627"
            height="99.3494"
            fill="white"
            transform="translate(0.373535)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Logo;
