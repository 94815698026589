import styled from 'styled-components/macro';
import { Box } from '@mui/material';
import { colors } from 'components/Colors';
import { zIndex } from 'utils/zIndex';

export const IconBox = styled(Box)`
  position: absolute;
  border: 1px solid ${colors.common.grey.value};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.common.white.value};
  top: -25px;
  z-index: ${zIndex.icon};
`;
