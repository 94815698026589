import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Grid, Typography, Link, useMediaQuery } from '@mui/material';
import { RootDispatch, RootState } from 'storage/store';
import { MessageTypes, UserCredentials } from 'storage/user/user.model';
import ValidationSchema from 'validations/LoginForm';
import appTheme from 'App.theme';
import { Constant } from 'utils/consts';
import { Url } from 'utils/urls';
import { IsLoggedIn } from 'utils/user';
import ImageGrid from 'pages/account/templates/RequestPage/styled/ImageGrid';
import { Button, QuestionAndAnswer } from 'components';
import Illustration from 'components/Illustration/Illustration';
import { TextField as TextFieldComponent } from 'components/TextField';
import { SimplePasswordField } from 'components/SimplePasswordField';
import { MenuVisibilityContext } from 'components/Menu/utils/menuVisibilityContext';
import FormWrapper from './styled/FormWrapper';
import IllustrationWrapper from './styled/IllustartionWrapper';
import ErrorMessage from './ErrorMessage';

const Login = () => {
  const desktopBreakpoint = useMediaQuery(appTheme.breakpoints.up('lg'));
  const { t } = useTranslation();
  const schema = ValidationSchema(t);
  const dispatch = useDispatch<RootDispatch>();
  const navigate = useNavigate();
  const loginUser = (
    value: UserCredentials,
    actions: {
      resetForm: (p: { values: { password: string; login: string } }) => void;
    }
  ) => {
    actions.resetForm({ values: { ...value, password: '' } });
    if (
      value.password.length < Constant.oneThousandTwentyFour &&
      value.login.length < Constant.seventy
    ) {
      dispatch.user.loginUser(value);
    } else {
      dispatch.user.changeUserStatus(MessageTypes.invalidPassword);
    }
  };
  const user = useSelector((state: RootState) => state.user);
  const { setVisible } = useContext(MenuVisibilityContext);

  useEffect(
    () =>
      function cleanup() {
        dispatch.user.resetUserStatus();
      },
    []
  );

  useEffect(() => {
    if (IsLoggedIn()) {
      navigate(Url.dashboard);
    }
    return window.scrollTo({ top: 0 });
  }, [user]);

  useEffect(() => {
    setVisible!(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('ROUTING_LOGIN_TITLE')}</title>
        <meta name="description" content={t('ROUTING_LOGIN_DESCRIPTION')} />
      </Helmet>
      <Grid container>
        <ImageGrid item lg={5} xl={5}>
          <IllustrationWrapper disableGutters>
            <Illustration component="login" />
          </IllustrationWrapper>
        </ImageGrid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={7}
          xl={7}
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '23px',
            paddingBottom: '100px',
            [appTheme.breakpoints.up('lg')]: {
              paddingTop: '100px'
            },
            [appTheme.breakpoints.down('lg')]: {
              marginTop: '86px',
              paddingLeft: '9px'
            },
            [appTheme.breakpoints.down('md')]: {
              alignItems: 'flex-start',
              marginTop: '86px',
              paddingLeft: '0'
            }
          }}
        >
          <FormWrapper disableGutters>
            <Formik
              onSubmit={loginUser}
              validationSchema={schema}
              initialValues={{ login: '', password: '' }}
              validateOnChange={false}
            >
              <Form noValidate>
                <Typography variant={desktopBreakpoint ? 'h3' : 'h4'}>
                  {t('PAGE_LOGIN_TITLE')}
                </Typography>
                <ErrorMessage messageType={user?.userStatus} />
                <Grid container>
                  <Grid item container>
                    <Field
                      type="email"
                      name="login"
                      labelText={t('FORM_FIELD_EMAIL')}
                      label={t('FORM_FIELD_EMAIL')}
                      component={TextFieldComponent}
                      showIcon
                      fullWidth
                      inputProps={{
                        inputMode: 'email'
                      }}
                    />
                  </Grid>
                  <Grid item container>
                    <Field
                      type="password"
                      name="password"
                      label={t('FORM_FIELD_PASSWORD')}
                      labelText={t('FORM_FIELD_PASSWORD')}
                      component={SimplePasswordField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item container>
                    {user?.userStatus === MessageTypes.blocked ? (
                      <Link href={Url.unblockAccount} variant="body1">
                        {t('PAGE_LOGIN_ACCOUNT_UNLOCK')}
                      </Link>
                    ) : (
                      <Link href={Url.resetPassword} variant="body1">
                        {t('PAGE_LOGIN_FORGOTTEN_PASSWORD')}
                      </Link>
                    )}
                  </Grid>
                  <Grid item container style={{ margin: '30px 0 25px' }}>
                    <Button
                      size="large"
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      {t('BUTTON_LOGIN')}
                    </Button>
                  </Grid>
                  <Grid item container style={{ margin: '10px 0 20px 0' }}>
                    <QuestionAndAnswer
                      question={t('PAGE_LOGIN_CREATE_ACCOUNT_MESSAGE')}
                      answer={{
                        href: Url.register,
                        text: t('PAGE_LOGIN_CREATE_ACCOUNT_LINK')
                      }}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </FormWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
