import { usePromiseTracker } from 'react-promise-tracker';
import { CircularProgress, Box } from '@mui/material';
import styled from 'styled-components/macro';
import theme from 'App.theme';

const LoaderWrapper = styled.div`
  position: fixed;
  z-index: 1302;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.info.main}90;
  backdrop-filter: blur(3px);
  .MuiBox-root {
    position: relative;
    & > :first-child {
      color: ${theme.palette.common.white}90;
    }
    & > :last-child {
      position: absolute;
      left: 0;
    }
  }
`;

const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress ? (
    <LoaderWrapper>
      <Box>
        <CircularProgress
          variant="determinate"
          thickness={4}
          value={100}
          size={58}
        />
        <CircularProgress
          color="primary"
          disableShrink
          thickness={4}
          size={58}
        />
      </Box>
    </LoaderWrapper>
  ) : null;
};

export default Loader;
