import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import { AlertsState } from './alerts.model';
import reducers from './alerts.reducers';

const initialState: AlertsState = {
  success: [],
  warning: [],
  error: []
};

const alertsModel = createModel<RootModel>()({
  state: initialState,
  reducers
});

export default alertsModel;
