import { CurrencyType } from 'utils/formatCurrency/formatCurrency.model';
import { IPaymentsOption } from 'pages/Dashboard/pages/Payment/Payment.model';

export type ProductType =
  | 'CUD'
  | 'DUD'
  | 'HARMONOGRAM'
  | 'POROZUMIENIE'
  | 'SPLATA_CALOSCI'
  | 'SPLATA_CALOSCI_0_ODS'
  | 'STARA_UGODA'
  | 'UGODA_0'
  | 'UGODA_NA_PLUS'
  | 'ZIZ';

export enum ProductTypeEnum {
  CUD = 'CUD',
  DUD = 'DUD',
  HARMONOGRAM = 'HARMONOGRAM',
  POROZUMIENIE = 'POROZUMIENIE',
  SPLATA_CALOSCI = 'SPLATA_CALOSCI',
  SPLATA_CALOSCI_0_ODS = 'SPLATA_CALOSCI_0_ODS',
  STARA_UGODA = 'STARA_UGODA',
  UGODA_0 = 'UGODA_0',
  UGODA_NA_PLUS = 'UGODA_NA_PLUS',
  ZIZ = 'ZIZ'
}

export enum ProductName {
  CUD = 'PRODUCT_NAME_PARTIAL_REMISSION_SETTLEMENT',
  DUD = 'PRODUCT_NAME_DYNAMIC_SETTLEMENT',
  HARMONOGRAM = 'PRODUCT_NAME_SCHEDULE_SETTLEMENT',
  POROZUMIENIE = 'PRODUCT_NAME_AGREEMENT_SETTLEMENT',
  SPLATA_CALOSCI = 'PRODUCT_NAME_TOTAL_REPAYMENT_SETTLEMENT',
  SPLATA_CALOSCI_0_ODS = 'PRODUCT_NAME_TOTAL_REPAYMENT_SETTLEMENT',
  STARA_UGODA = 'PRODUCT_NAME_ZERO_SETTLEMENT',
  UGODA_0 = 'PRODUCT_NAME_ZERO_SETTLEMENT',
  UGODA_0_WPLATA_WAR = 'PRODUCT_NAME_ZERO_SETTLEMENT_WITH_PREREQUISITE',
  UGODA_NA_PLUS = 'PRODUCT_NAME_PLUS_SETTLEMENT',
  ZIZ = 'PRODUCT_NAME_PAY_AND_FORGET_SETTLEMENT'
}

export interface IHistoryElement {
  date: string;
  payer: 'BAILIFF' | 'CLIENT';
  amount: number;
  status: 'POSTED' | 'WAITING';
  currency: CurrencyType;
}

export interface IHistoryData extends IHistoryElement {
  uniqueId: number;
}
export interface IHistory {
  historyData: IHistoryData[];
  paymentOptions?: IPaymentsOption[];
  totalDebtAmountInSystemCurrency?: number;
  windowHeight?: number;
}

export type PlanStatusType = 'UNPAID' | 'PAID' | 'CURRENT' | 'EXPIRED';

export interface IPlanData extends IPlanElement {
  id: number;
  status: PlanStatusType;
}

export interface IPlanElement {
  date: string;
  status: PlanStatusType;
  amount: number;
  currency: CurrencyType;
  onClick?: (status: PlanStatusType, installmentAmount: number) => void;
}

export type TotalDebtAmountOnAllAgreementsByCurrency =
  | {
      [key in CurrencyType]: number;
    }
  | {};

export interface AgreementWaitingForContract {
  agreementId: number;
  documentReady: boolean;
}

export interface AgreementsState {
  agreementsWaitingForContract: AgreementWaitingForContract[] | [];
  totalDebtAmountOnAllAgreementsByCurrency: TotalDebtAmountOnAllAgreementsByCurrency;
  agreements: Agreement[] | null;
  agreementsWithProductAvailability: AgreementSwitcher[];
  [key: number]: Agreement;
}

export interface AgreementSwitcher {
  agreementId: number;
  productAvailable: boolean;
}

export interface AgreementsSwitcherProps {
  agreementsArr: AgreementSwitcher[];
}

export type AdditionalCostTypes =
  | 'BAILIFF_ENFORCEMENT_COST'
  | 'COURT_PROCESS_COST'
  | 'NONE';

export enum AdditionalCostsType {
  BAILIFF_ENFORCEMENT_COST = 'BAILIFF_ENFORCEMENT_COST',
  COURT_PROCESS_COST = 'COURT_PROCESS_COST',
  NONE = 'NONE'
}

export enum AdditionalCostModalText {
  BAILIFF_ENFORCEMENT_COST = 'MODAL_ADDITIONAL_COSTS_ENFORCEMENT_TEXT',
  COURT_PROCESS_COST = 'MODAL_ADDITIONAL_COSTS_COURT_TEXT'
}

export type PhaseStatusTypes =
  | 'BAILIFF_APPLICATION'
  | 'BAILIFF_EXECUTION'
  | 'COURT'
  | 'SETTLEMENT'
  | 'DEFAULT';

export enum CaseStep {
  BAILIFF_APPLICATION = 'PAGE_CASE_DETAILS_BAILIFF_APPLICATION',
  BAILIFF_EXECUTION = 'PAGE_CASE_DETAILS_BAILIFF_EXECUTION',
  COURT = 'PAGE_CASE_DETAILS_COURT',
  SETTLEMENT = 'PAGE_CASE_DETAILS_SETTLEMENT'
}

export interface Agreement {
  details: {
    accountNumber: string;
    agreementId: number;
    agreementPhase: string;
    bigHistory: BIGHistory;
    capitalByCurrency:
      | {
          [key in CurrencyType]: number;
        }
      | {};
    costsByCurrency:
      | {
          [key in CurrencyType]: number;
        }
      | {};
    creditType: string;
    debtorRoles: [string];
    interestByCurrency:
      | {
          [key in CurrencyType]: number;
        }
      | {};
    originalCreditor: string;
    originalDebtAmount: number;
    totalAmountPaidCurrentProduct: number;
    payments: IHistoryData[];
  };
  generalData: {
    agreementId: number;
    creditorId: number;
    currentCreditorName: string;
    currency: CurrencyType;
    hasIncreasingInterests: boolean;
    phaseSegmentation: PhaseSegmentation;
    totalDebtAmountWithoutRemissionByCurrency: {
      [key in CurrencyType]: number;
    };
    lastSettledPayment: {
      amount: number;
      currency: CurrencyType;
      date: string;
    };
    offerAvailable: boolean;
    originalCreditorName: string;
    overduePaymentsAmount: number;
    productData: ProductData;
    totalDebtAmountByCurrency: {
      [key in CurrencyType]: number;
    };
    totalDebtAmountInSystemCurrency: number;
    waitingPaymentsAmountByCurrency: {
      [key in CurrencyType]: number;
    };
  };
}

export interface PhaseSegmentation {
  phaseStatus: PhaseStatusTypes;
  additionalCost: AdditionalCostTypes;
}

export interface ProductData {
  annexAvailable: boolean;
  debtAmountOnSimulationDate: number;
  documentBarcode: string;
  offerId: number;
  overdue: boolean;
  paymentHint: number;
  installments: IPlanData[];
  productParameters: [
    {
      key: ProductParametersKey;
      value: string;
    }
  ];
  productStatus: ProductStatus;
  simulationDate: string;
  simulationId: number;
  simulationStatus: SimulationStatus;
  SimulationStatusTextDescription: SimulationStatusTextDescription;
  type: ProductType;
  totalAmountPaidCurrentProduct: number;
  prerequisitePaymentLeft: number;
}

export interface Agreements {
  totalDebtAmountOnAllAgreementsByCurrency: TotalDebtAmountOnAllAgreementsByCurrency;
  agreements: Agreement[];
}

export interface AgreementsResponse {
  data: Agreements;
  success: boolean;
}

export interface AgreementsWithProductAvailability {
  agreementId: number;
  productAvailable: boolean;
}

export interface AgreementsWithProductAvailabilityResponse {
  data: AgreementsWithProductAvailability[];
  success: boolean;
}

export interface IBankAccount {
  accountNumber: string;
  accountCurrency: CurrencyType;
  swiftCode: string;
}
export interface PaymentDetails {
  bankAccounts: IBankAccount[];
  currentCreditorName: string | null;
}
export interface PaymentDetailsResponse {
  data: PaymentDetails;
  success: boolean;
  status: number;
}

export interface AgreementResponse {
  data: Agreement;
  success: boolean;
}

export interface BIGHistory {
  status: BIGHistoryStatus;
  entries: BIGHistoryEntry[];
}
export interface BIGHistoryEntry {
  action: BIGHistoryActionType;
  date: string;
  route: BIGHistoryRouteType;
}

type BIGHistoryActionType = 'ADDED' | 'REMOVED' | 'SUSPENDED' | 'UNSUSPENDED';
export type BIGHistoryRouteType = 'INFOMONITOR' | 'KRD';
type BIGHistoryStatus = BIGHistoryActionType | 'NULL';

export enum ProductParametersKey {
  REPAID_INSTALLMENT_COUNT = 'REPAID_INSTALLMENT_COUNT',
  INSTALLMENTS_COUNT = 'INSTALLMENTS_COUNT',
  INSTALLMENTS_AMOUNT = 'INSTALLMENTS_AMOUNT',
  NEXT_INSTALLMENT_AMOUNT = 'NEXT_INSTALLMENT_AMOUNT',
  NEXT_INSTALLMENT_PAYMENT = 'NEXT_INSTALLMENT_PAYMENT',
  FIRST_INSTALLMENT_PAYMENT = 'FIRST_INSTALLMENT_PAYMENT',
  LAST_INSTALLMENT_AMOUNT = 'LAST_INSTALLMENT_AMOUNT',
  LAST_INSTALLMENT_PAYMENT = 'LAST_INSTALLMENT_PAYMENT',
  REMISSION_PERCENT = 'REMISSION_PERCENT',
  RESIGNATION_PERCENT = 'RESIGNATION_PERCENT',
  REMISSION_AMOUNT = 'REMISSION_AMOUNT',
  RESIGNATION_AMOUNT = 'RESIGNATION_AMOUNT',
  INSTALLMENT_REPAYMENT_AMOUNT = 'INSTALLMENT_REPAYMENT_AMOUNT',
  REMISSION_TOTAL_REPAYMENT_AMOUNT = 'REMISSION_TOTAL_REPAYMENT_AMOUNT',
  FROZEN_INTERESTS_AMOUNT = 'FROZEN_INTERESTS_AMOUNT',
  PAYOFF_DAY = 'PAYOFF_DAY',
  PAYOFF_AMOUNT = 'PAYOFF_AMOUNT',
  PREREQUISITE_PAYMENT_AMOUNT = 'PREREQUISITE_PAYMENT_AMOUNT',
  PREREQUISITE_PAYMENT_DATE = 'PREREQUISITE_PAYMENT_DATE',
  PREREQUISITE_PAYMENT_DEBT_AMOUNT = 'PREREQUISITE_PAYMENT_DEBT_AMOUNT'
}

export enum SimulationStatusText {
  CONFIRMED = 'CASE_STATUS_CONFIRMED',
  PREREQUISITES_SATISFIED = 'CASE_STATUS_PREREQUISITES_SATISFIED',
  WAITING_FOR_LAUNCH = 'CASE_STATUS_WAITING_FOR_LAUNCH',
  WAITING_FOR_SEND_DOCUMENT = 'CASE_STATUS_WAITING_FOR_SEND_DOCUMENT',
  WAITING_TO_SATISFY_PREREQUISITES = 'CASE_STATUS_WAITING_TO_SATISFY_PREREQUISITES'
}

export enum ProductStatusText {
  ACTIVE = 'CASE_STATUS_ACTIVE_NOT_OVERDUE',
  ACTIVE_OVERDUE = 'CASE_STATUS_ACTIVE_OVERDUE',
  ANNEXED = 'CASE_STATUS_ANNEXED_NOT_OVERDUE',
  ANNEXED_OVERDUE = 'CASE_STATUS_ANNEXED_OVERDUE',
  FULFILLED = 'CASE_STATUS_FULFILLED'
}

export enum SimulationStatusDescription {
  CONFIRMED = 'CASE_STATUS_CONFIRMED_TEXT',
  PREREQUISITES_SATISFIED = 'CASE_STATUS_PREREQUISITES_SATISFIED_TEXT',
  WAITING_FOR_LAUNCH = 'CASE_STATUS_WAITING_FOR_LAUNCH_TEXT',
  WAITING_FOR_SEND_DOCUMENT = 'CASE_STATUS_WAITING_FOR_SEND_DOCUMENT_TEXT',
  WAITING_TO_SATISFY_PREREQUISITES = 'CASE_STATUS_WAITING_TO_SATISFY_PREREQUISITES_TEXT'
}

export enum ProductStatusDescription {
  ACTIVE = 'CASE_STATUS_ACTIVE_NOT_OVERDUE_TEXT',
  ACTIVE_OVERDUE = 'CASE_STATUS_ACTIVE_OVERDUE_TEXT',
  ANNEXED = 'CASE_STATUS_ANNEXED_NOT_OVERDUE_TEXT',
  ANNEXED_OVERDUE = 'CASE_STATUS_ANNEXED_OVERDUE_TEXT',
  FULFILLED = 'CASE_STATUS_FULFILLED_TEXT'
}

export type SimulationStatus =
  | 'CONFIRMED'
  | 'WAITING_TO_SATISFY_PREREQUISITES'
  | 'WAITING_FOR_SEND_DOCUMENT'
  | 'PREREQUISITES_SATISFIED'
  | 'WAITING_FOR_LAUNCH';

export type SimulationStatusTextDescription =
  | 'CONFIRMED_TEXT'
  | 'WAITING_TO_SATISFY_PREREQUISITES_TEXT'
  | 'WAITING_FOR_SEND_DOCUMENT_TEXT'
  | 'PREREQUISITES_SATISFIED_TEXT'
  | 'WAITING_FOR_LAUNCH_TEXT';

export enum SimulationStatusEnum {
  FINAL = 'FINAL',
  CONFIRMED = 'CONFIRMED',
  WAITING_TO_SATISFY_PREREQUISITES = 'WAITING_TO_SATISFY_PREREQUISITES',
  WAITING_FOR_SEND_DOCUMENT = 'WAITING_FOR_SEND_DOCUMENT',
  PREREQUISITES_SATISFIED = 'PREREQUISITES_SATISFIED',
  WAITING_FOR_LAUNCH = 'WAITING_FOR_LAUNCH'
}

export type ProductStatus =
  | 'ACTIVE'
  | 'ACTIVE_OVERDUE'
  | 'ANNEXED'
  | 'ANNEXED_OVERDUE'
  | 'FULFILLED';
