import styled from 'styled-components/macro';
import { Container, ContainerProps } from '@mui/material';

export const ButtonContainer = styled(Container)<ContainerProps>(
  ({ theme }) => ({
    margin: '0 auto',
    padding: '0 42px',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  })
);
