import { MouseEvent, useRef, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Icon } from 'components/Icon';
import { TextFieldProps } from 'components/TextField/TextField.model';
import { ZERO_TIMEOUT } from 'utils/consts';
import TextFieldStyled from './styled/TextFieldStyled';

interface IIconComponent {
  passwordVisible: boolean;
  color: string;
}

const IconComponent = ({ passwordVisible, color }: IIconComponent) => (
  <Icon color={color} icon={passwordVisible ? 'previewOff' : 'preview'} />
);

const SimplePasswordField = (props: TextFieldProps) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();
  const {
    field: { name },
    form
  } = props;
  const color = form.touched[name]
    ? form.errors[name]
      ? 'error'
      : 'success'
    : 'secondary';
  const updateSelectionStart = (position: number | null | undefined) => {
    if (
      position === undefined ||
      !inputRef.current ||
      position === inputRef.current.selectionStart
    ) {
      return;
    }

    inputRef.current.selectionStart = position;
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);

    const oldSelectionEnd = inputRef.current && inputRef.current.selectionEnd;
    setTimeout(() => updateSelectionStart(oldSelectionEnd), ZERO_TIMEOUT);
  };

  const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  const fieldName = props.field.name;

  return (
    <TextFieldStyled
      {...props}
      $textSecure={props.type === 'text' && !passwordVisible}
      inputRef={inputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
              onMouseDown={(event: MouseEvent<HTMLButtonElement>) =>
                event.preventDefault()
              }
              edge="end"
              sx={{
                display:
                  isFirefox && fieldName === 'pesel' ? 'none' : 'inherit',
                marginRight: '-15px'
              }}
            >
              <IconComponent color={color} passwordVisible={passwordVisible} />
            </IconButton>
          </InputAdornment>
        ),
        autoComplete: 'off',
        inputProps: { ...props.inputProps }
      }}
      type={
        props.type === 'text' ? 'text' : passwordVisible ? 'text' : 'password'
      }
    />
  );
};

export default SimplePasswordField;
