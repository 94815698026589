import { ReactNode, useEffect, useRef, useState } from 'react';
import { Container, Slide, SxProps } from '@mui/material';
import theme from 'App.theme';
import { ONE_HUNDRED_PERCENT } from 'utils/consts';
import { BottomButtonBar } from './BottomButtonBar';
import { Wrapper } from './styled/Wrapper';
import { ContentContainer } from './styled/ContentContainer';

interface IElementScrollableWithButton {
  children: ReactNode;
  scrollAmount: number;
  buttonBarHeight: number;
  additionalHeightToCut?: number;
  description: string;
  contentContainerSx?: SxProps;
  isStaticPosition?: boolean;
}

const DEFAULT_BOTTOM_BUTTON_BAR_HEIGHT = 100;
const INITIAL_HEIGHT = 0;
const INITIAL_WIDTH = 320;

const ScrollableContent = ({
  children,
  scrollAmount,
  buttonBarHeight = DEFAULT_BOTTOM_BUTTON_BAR_HEIGHT,
  additionalHeightToCut,
  description,
  contentContainerSx,
  isStaticPosition
}: IElementScrollableWithButton) => {
  const scrollableElement = useRef<HTMLDivElement>(null);
  const [isButtonBarVisible, setIsButtonBarVisible] = useState(false);
  const [elScrollHeight, setElScrollHeight] = useState<number>(INITIAL_HEIGHT);
  const [elClientHeight, setElClientHeight] = useState<number>(INITIAL_HEIGHT);
  const [elScrollTop, setElScrollTop] = useState<number>(INITIAL_HEIGHT);
  const [windowHeight, setWindowHeight] = useState<number>(INITIAL_HEIGHT);
  const [elementWidth, setElementWidth] = useState<number>(INITIAL_WIDTH);
  const setElementPosition = () => {
    setWindowHeight(window.innerHeight);
    if (scrollableElement.current) {
      const { clientHeight, scrollHeight, scrollTop, clientWidth } =
        scrollableElement.current;
      setElClientHeight(clientHeight);
      setElScrollHeight(scrollHeight);
      setElScrollTop(scrollTop);
      setElementWidth(clientWidth);
    }
  };

  const toggleButtonVisibility = () => {
    const visible = !(
      elScrollHeight === elClientHeight ||
      Math.ceil(elScrollTop) + elClientHeight + buttonBarHeight >=
        elScrollHeight ||
      elScrollHeight - elClientHeight <= buttonBarHeight
    );
    setIsButtonBarVisible(visible);
  };

  const handleScroll = () => {
    setElementPosition();
  };

  const handleScrollButton = () => {
    if (scrollableElement.current) {
      const scrollToPosition =
        elScrollTop +
        (scrollAmount / ONE_HUNDRED_PERCENT) *
          (elClientHeight - buttonBarHeight);
      scrollableElement.current.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleResize = () => {
    setElementPosition();
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    setElementPosition();
    // if you add empty dependency array here you will not be able to click the button
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    window.requestAnimationFrame(toggleButtonVisibility);
  }, [elScrollHeight, elScrollTop, elClientHeight]);

  useEffect(() => {
    if (scrollableElement.current) {
      scrollableElement.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollableElement.current) {
        scrollableElement.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollableElement]);

  return (
    <Wrapper
      $additionalHeightToCut={additionalHeightToCut}
      $windowHeight={windowHeight}
      disableGutters
    >
      <ContentContainer
        ref={scrollableElement}
        theme={theme}
        disableGutters
        sx={contentContainerSx}
      >
        {children}
      </ContentContainer>
      {isButtonBarVisible && (
        <Slide in={isButtonBarVisible} direction="up">
          <Container
            sx={{
              background: '#ffffff',
              position: isStaticPosition ? 'static' : 'absolute',
              bottom: '-4px',
              minHeight: `${buttonBarHeight}px`,
              width: '100%',
              left: 0,
              right: 0
            }}
            disableGutters
          >
            <BottomButtonBar
              handleScroll={handleScrollButton}
              buttonDescription={description}
              width={elementWidth}
            />
          </Container>
        </Slide>
      )}
    </Wrapper>
  );
};

export default ScrollableContent;
