import { AvailableDocuments, DocumentsState } from './documents.model';

const reducers = {
  storeAvailableDocuments: (
    state: DocumentsState,
    availableDocuments: AvailableDocuments[]
  ) => ({
    ...state,
    availableDocuments
  })
};

export default reducers;
