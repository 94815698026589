import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const UserInfo = styled(Typography)`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 8px;
    font-size: 13px;
  }
`;
