import { SvgIcon } from '@mui/material';

const Info = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0C9.62662 0 7.30651 0.703792 5.33312 2.02237C3.35973 3.34095 1.8217 5.21509 0.913445 7.40781C0.00519377 9.60052 -0.232439 12.0133 0.230584 14.3411C0.693607 16.6689 1.83647 18.8071 3.5147 20.4853C5.19293 22.1635 7.33116 23.3064 9.65893 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9777 18.6668C23.2962 16.6934 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51471C18.2348 1.26427 15.1826 0 12 0ZM12 22C10.0222 22 8.08876 21.4135 6.44427 20.3147C4.79978 19.2159 3.5181 17.6541 2.76122 15.8268C2.00435 13.9996 1.80628 11.9889 2.19213 10.0491C2.57798 8.10929 3.53042 6.32745 4.92895 4.92892C6.32747 3.5304 8.10926 2.57799 10.0491 2.19214C11.9889 1.80629 13.9996 2.00432 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.44431C21.4135 8.0888 22 10.0222 22 12C22 14.6522 20.9464 17.1957 19.071 19.0711C17.1957 20.9464 14.6522 22 12 22Z" />
    <path d="M14 12V10H8V12H9V19H15V17H14V12ZM11 17V12H12V17H11Z" />
    <path d="M11.5 9C11.9945 9 12.4778 8.85338 12.8889 8.57868C13.3 8.30397 13.6205 7.91351 13.8097 7.4567C13.9989 6.99988 14.0484 6.49722 13.952 6.01227C13.8555 5.52732 13.6174 5.08187 13.2678 4.73224C12.9181 4.38261 12.4727 4.1445 11.9877 4.04804C11.5028 3.95157 11.0001 4.00109 10.5433 4.19031C10.0865 4.37953 9.69603 4.69996 9.42133 5.11109C9.14662 5.52221 9 6.00555 9 6.5C9 7.16304 9.2634 7.79892 9.73224 8.26776C10.2011 8.7366 10.837 9 11.5 9ZM11.5 6C11.5989 6 11.6955 6.02932 11.7778 6.08426C11.86 6.1392 11.9241 6.21729 11.9619 6.30866C11.9998 6.40002 12.0097 6.50054 11.9904 6.59754C11.9711 6.69453 11.9235 6.78362 11.8536 6.85355C11.7837 6.92347 11.6945 6.9711 11.5975 6.99039C11.5005 7.00968 11.4 6.99979 11.3087 6.96195C11.2173 6.9241 11.1392 6.86 11.0843 6.77777C11.0293 6.69555 11 6.59889 11 6.5C11 6.36739 11.0527 6.24022 11.1464 6.14645C11.2402 6.05269 11.3674 6 11.5 6Z" />
  </SvgIcon>
);

export default Info;
