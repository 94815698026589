import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import { ProductState } from './product.model';
import reducers from './product.reducers';
import effects from './product.effects';

const initialState: ProductState = {
  userOffers: [],
  pitRequired: false,
  initialAnnexSimulation: null,
  partialSimulation: null,
  dynamicSimulation: null,
  zeroSimulation: null,
  zeroWithPrerequisiteSimulation: null,
  totalSimulation: null,
  plusSimulation: null
};

const model = createModel<RootModel>()({
  state: initialState,
  reducers,
  effects
});

export default model;
