import { SvgIcon } from '@mui/material';

const Mobile = ({ ...props }) => (
  <SvgIcon
    {...props}
    width="43"
    height="55"
    viewBox="0 0 43 55"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4087 12.9101H22.1902V6.92497C22.1902 6.47007 22.1006 6.01962 21.9265 5.59934C21.7524 5.17907 21.4973 4.79719 21.1756 4.47553C20.8539 4.15386 20.4721 3.8987 20.0518 3.72462C19.6315 3.55054 19.1811 3.46094 18.7261 3.46094H6.04583C5.59093 3.46094 5.14048 3.55054 4.7202 3.72462C4.29992 3.8987 3.91805 4.15386 3.59638 4.47553C3.27472 4.79719 3.01956 5.17907 2.84547 5.59934C2.67139 6.01962 2.58179 6.47007 2.58179 6.92497V39.7599C2.58179 40.2148 2.67139 40.6653 2.84547 41.0855C3.01956 41.5058 3.27472 41.8877 3.59638 42.2094C3.91805 42.531 4.29992 42.7862 4.7202 42.9603C5.14048 43.1343 5.59093 43.2239 6.04583 43.2239H18.7261C19.1811 43.2239 19.6315 43.1343 20.0518 42.9603C20.4721 42.7862 20.8539 42.531 21.1756 42.2094C21.4973 41.8877 21.7524 41.5058 21.9265 41.0855C22.1006 40.6653 22.1902 40.2148 22.1902 39.7599V17.1704H22.4087V12.9101Z"
      fill="#3F3D56"
    />
    <path
      d="M21.453 6.9503V39.7367C21.4524 40.1201 21.3669 40.4987 21.2027 40.8452C21.0384 41.1917 20.7995 41.4976 20.503 41.7408C20.4375 41.7945 20.3691 41.8448 20.2982 41.8913C19.8741 42.174 19.3756 42.3245 18.8659 42.3237H6.12462C5.91808 42.3241 5.71225 42.2994 5.51164 42.2503C4.94859 42.113 4.44796 41.7906 4.09001 41.3348C3.73206 40.879 3.53753 40.3162 3.5376 39.7367V6.9503C3.53766 6.2642 3.81024 5.60622 4.29539 5.12107C4.78053 4.63592 5.43852 4.36334 6.12462 4.36328H7.67067C7.59454 4.54988 7.56549 4.75233 7.58605 4.9528C7.60662 5.15328 7.67618 5.34561 7.7886 5.51287C7.90102 5.68012 8.05285 5.81716 8.23072 5.9119C8.40859 6.00664 8.60702 6.05618 8.80855 6.05615H16.0726C16.2741 6.05593 16.4724 6.00625 16.6502 5.91146C16.8279 5.81666 16.9797 5.67967 17.0921 5.51251C17.2046 5.34535 17.2742 5.15313 17.295 4.95275C17.3158 4.75237 17.287 4.54995 17.2112 4.36328H18.8659C19.552 4.36334 20.21 4.63592 20.6952 5.12107C21.1803 5.60622 21.4529 6.2642 21.453 6.9503Z"
      fill="white"
    />
    <path
      d="M5.68562 16.7969C6.35179 16.8186 7.00847 16.7986 7.65294 16.8729C8.34184 16.9523 8.66102 17.3139 8.70918 18.0641C8.75192 18.7376 8.72106 19.4172 8.69562 20.093C8.68171 20.4662 8.50736 20.7743 8.16851 20.9639C8.1736 20.9799 8.17394 20.9948 8.18004 20.9979C8.62473 21.2174 8.78143 21.612 8.79059 22.069C8.80619 22.7856 8.8054 23.5024 8.78822 24.2194C8.77718 24.4331 8.73135 24.6436 8.65254 24.8426C8.49855 25.2599 8.14375 25.4571 7.73502 25.4873C7.05664 25.5392 6.3752 25.5361 5.68188 25.5568L5.68562 16.7969ZM6.81479 21.5411V24.4735C6.89688 24.4735 6.96437 24.4765 7.03187 24.4735C7.39515 24.4535 7.5695 24.3446 7.5834 24.0104C7.61156 23.3362 7.6085 22.659 7.58069 21.9845C7.56814 21.672 7.40499 21.5638 7.08716 21.5434C6.99863 21.536 6.90943 21.5411 6.81479 21.5411ZM6.80801 17.9087V20.4408C7.20181 20.5307 7.48639 20.38 7.50878 20.0048C7.54066 19.4488 7.54066 18.8915 7.50878 18.3355C7.49148 18.0125 7.34665 17.9216 7.01356 17.909C6.95081 17.9067 6.88941 17.9087 6.80801 17.9087Z"
      fill="#006D48"
    />
    <path
      d="M14.297 23.9268C14.2939 23.595 14.297 23.2628 14.297 22.9066C13.9286 22.9066 13.5833 22.9062 13.2377 22.9083C13.2054 22.9086 13.1732 22.9147 13.144 22.9178C13.0884 23.2018 13.123 24.3892 13.1936 24.6946C13.3296 25.285 13.6125 25.5584 14.2006 25.6602C14.329 25.6788 14.4587 25.6861 14.5883 25.6819C15.341 25.6768 15.8125 25.3128 16.0235 24.5894C16.1554 24.1368 16.113 23.673 16.135 23.2119C16.1659 22.5598 15.9739 21.981 15.6293 21.4382C15.3213 20.9523 15.0469 20.4454 14.7226 19.9704C14.3299 19.3964 14.319 18.7687 14.3835 18.1196C14.3907 18.0582 14.4041 17.9976 14.4235 17.9388C14.4632 17.8119 14.5602 17.7692 14.684 17.7725C14.8078 17.7759 14.8736 17.8217 14.9075 17.9527C14.9259 18.0236 14.9226 18.0974 14.9268 18.1705V19.3329H15.0822C15.381 19.3278 15.6802 19.3309 15.979 19.3329H16.0411V17.7586C15.9973 17.3722 15.825 17.0349 15.4519 16.8426C14.5853 16.393 13.5701 16.827 13.2987 17.7586C13.1786 18.1722 13.2112 18.596 13.1936 19.0181C13.1698 19.5802 13.3258 20.0837 13.6196 20.5567C13.9554 21.0979 14.2725 21.6512 14.6117 22.19C15.0378 22.8648 15.0032 23.595 14.8943 24.335C14.8661 24.5246 14.7586 24.5837 14.587 24.5772C14.4153 24.5708 14.3539 24.4557 14.3309 24.3136C14.3103 24.1856 14.2989 24.0564 14.297 23.9268Z"
      fill="#006D48"
    />
    <path
      d="M10.9436 24.4074V21.5431H12.1172V21.4753C12.1253 21.134 12.1229 20.7916 12.1229 20.4235H10.9514V17.9552H12.1949V17.4785V16.8281H9.78149V25.5233H12.2644C12.2644 25.1762 12.262 24.8437 12.2644 24.5115V24.4074H11.4524H10.9436Z"
      fill="#006D48"
    />
    <path
      d="M17.6372 25.5261H18.804L18.8054 17.9439H19.7049V16.8242H16.7285V17.9469H17.6372V25.5261Z"
      fill="#006D48"
    />
    <path
      d="M5.63062 27.9023C5.63062 27.3501 6.07833 26.9023 6.63062 26.9023H18.7071C19.2594 26.9023 19.7071 27.3501 19.7071 27.9023V28.5916C19.7071 29.1438 19.2594 29.5916 18.7071 29.5916H6.63062C6.07833 29.5916 5.63062 29.1438 5.63062 28.5916V27.9023Z"
      fill="#FE7F2D"
    />
  </SvgIcon>
);

export default Mobile;
