import { useEffect, useState } from 'react';
import { useWindowSize } from '../hooks';

const useImageResized = () => {
  const { windowSize } = useWindowSize();
  const [isImageResized, setImageResized] = useState(false);
  const maxWindowWidth = 420;
  const maxWindowHeight = 740;

  useEffect(() => {
    if (
      windowSize.width <= maxWindowWidth &&
      windowSize.height <= maxWindowHeight
    ) {
      setImageResized(true);
    } else {
      setImageResized(false);
    }
  }, [windowSize]);

  return { isImageResized };
};

export default useImageResized;
