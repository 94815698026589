import { SvgIcon } from '@mui/material';

const Home = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.45571 13.4507L2.77826 12.1179V21.9496H21.2319V12.1179L22.5545 13.4507L24.0102 11.9949L12.0051 0L6.87907 5.12601V3.49595H4.82866V7.17642L0 11.9949L1.45571 13.4507ZM19.1815 10.0675V19.8992H4.82866V10.0675L12.0051 2.89108L19.1815 10.0675Z" />
  </SvgIcon>
);

export default Home;
