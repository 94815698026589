import { AppSubRoute } from 'App.routing';
import lazyWithRetry from 'utils/lazyWithRetry';

const Main = lazyWithRetry(() => import('./pages/Main'));
const Case = lazyWithRetry(() => import('./pages/Case'));
const PaymentDone = lazyWithRetry(() => import('./pages/PaymentDone'));
const Payment = lazyWithRetry(() => import('./pages/Payment'));
const UserProfile = lazyWithRetry(() => import('./pages/UserProfile'));
const Contact = lazyWithRetry(() => import('./pages/Contact'));

const routes: AppSubRoute[] = [
  {
    key: 'dashboard-main',
    path: '/',
    element: <Main />
  },
  {
    key: 'dashboard-contact',
    path: '/contact',
    element: <Contact />
  },
  {
    key: 'route-case',
    path: '/case/payment/done/*',
    element: <PaymentDone />
  },
  {
    key: 'route-case',
    path: '/case/:id/payment',
    element: <Payment />
  },
  {
    key: 'route-user-profile',
    path: '/user-profile/*',
    element: <UserProfile />
  },
  {
    key: 'route-case',
    path: '/case/*',
    element: <Case />
  },
  {
    key: 'not-found-route',
    path: '*',
    element: null
  }
];

export default routes;
