import { SvgIcon } from '@mui/material';

const Percent = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_3717)">
      <path d="M22.4325 0.322643L0.326172 22.429L1.88956 23.9923L23.9959 1.88603L22.4325 0.322643Z" />
      <path d="M5.52741 0C4.43419 0 3.36555 0.324176 2.45657 0.931535C1.54759 1.53889 0.839122 2.40215 0.420765 3.41215C0.00240901 4.42216 -0.107071 5.53355 0.106205 6.60576C0.319481 7.67798 0.845933 8.66284 1.61895 9.43586C2.39198 10.2089 3.37684 10.7353 4.44905 10.9486C5.52126 11.1619 6.63263 11.0524 7.64263 10.6341C8.65263 10.2157 9.51592 9.50726 10.1233 8.59828C10.7306 7.6893 11.0548 6.62062 11.0548 5.52741C11.0548 4.06145 10.4725 2.65554 9.43586 1.61895C8.39927 0.582363 6.99337 0 5.52741 0ZM5.52741 8.84385C4.87148 8.84385 4.2303 8.64935 3.68492 8.28494C3.13953 7.92052 2.7144 7.40255 2.46338 6.79654C2.21237 6.19054 2.14669 5.52373 2.27466 4.88041C2.40263 4.23708 2.7185 3.64615 3.18231 3.18234C3.64612 2.71853 4.23708 2.40266 4.88041 2.27469C5.52374 2.14673 6.19058 2.2124 6.79658 2.46341C7.40258 2.71443 7.92049 3.1395 8.28491 3.68488C8.64932 4.23027 8.84385 4.87148 8.84385 5.52741C8.84385 6.40698 8.49446 7.25052 7.87251 7.87247C7.25055 8.49443 6.40698 8.84385 5.52741 8.84385Z" />
      <path d="M12.1602 18.7932C12.1602 19.8864 12.4843 20.9551 13.0917 21.8641C13.6991 22.773 14.5623 23.4815 15.5723 23.8998C16.5823 24.3182 17.6937 24.4277 18.7659 24.2144C19.8381 24.0011 20.823 23.4747 21.596 22.7016C22.369 21.9286 22.8955 20.9438 23.1088 19.8715C23.322 18.7993 23.2126 17.6879 22.7942 16.6779C22.3759 15.6679 21.6674 14.8047 20.7584 14.1973C19.8494 13.59 18.7808 13.2658 17.6876 13.2658C16.2216 13.2658 14.8157 13.8481 13.7791 14.8847C12.7425 15.9213 12.1602 17.3272 12.1602 18.7932ZM21.004 18.7932C21.004 19.4491 20.8095 20.0903 20.4451 20.6357C20.0806 21.1811 19.5627 21.6062 18.9567 21.8572C18.3507 22.1082 17.6839 22.1739 17.0406 22.0459C16.3972 21.9179 15.8063 21.6021 15.3425 21.1383C14.8787 20.6744 14.5628 20.0835 14.4348 19.4402C14.3068 18.7969 14.3725 18.13 14.6235 17.524C14.8746 16.918 15.2997 16.4001 15.8451 16.0357C16.3905 15.6712 17.0316 15.4767 17.6876 15.4767C18.5671 15.4767 19.4107 15.8262 20.0327 16.4481C20.6546 17.0701 21.004 17.9136 21.004 18.7932Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_3717">
        <rect width="24" height="24.3206" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Percent;
