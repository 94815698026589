import styled from 'styled-components/macro';
import { colors } from 'components/Colors';
import theme from 'App.theme';

interface IBar {
  $width: number;
}

export const Bar = styled.div<IBar>`
  height: 1px;
  background-color: ${colors.common.grey.value};
  width: 100%;
  box-shadow: 0 0 25px 25px ${theme.palette.common.white};
  ${[theme.breakpoints.down('lg')]} {
    width: calc(${(props) => props.$width}px - 80px);
  }
  ${[theme.breakpoints.down('md')]} {
    width: calc(${(props) => props.$width}px - 10px);
  }
`;
