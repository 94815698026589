import { useMediaQuery } from '@mui/material';
import theme from 'App.theme';
import { useLocation } from 'react-router-dom';
import { IsLoggedIn } from 'utils/user';
import { AppBarWithoutMenu, AppBar } from './components';
import { getHiddenPaths } from './utils';
import { DesktopMenuAnchorProvider } from './utils/desktopMenuAnchorContext';

const Menu = () => {
  const desktopBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
  const location = useLocation();
  const hiddenPaths = getHiddenPaths().filter((path) =>
    IsLoggedIn() ? path !== '/' : path
  );
  const hideMenu =
    desktopBreakpoint &&
    hiddenPaths.includes(`/${location.pathname.split('/')[1]}`);

  return hideMenu ? (
    <AppBarWithoutMenu />
  ) : (
    <DesktopMenuAnchorProvider>
      <AppBar />
    </DesktopMenuAnchorProvider>
  );
};

export default Menu;
