import { SvgIcon } from '@mui/material';

const Valid = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.63487 14.8435L2.53949 10.7303L0 13.2697L6.63487 19.8867L24 2.53949L21.4605 0L6.63487 14.8435Z" />
  </SvgIcon>
);

export default Valid;
