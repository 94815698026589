import { Typography } from '@mui/material';

const DataProcessing = () => (
  <>
    <Typography variant="h5">ZASADY PRZETWARZANIA DANYCH OSOBOWYCH</Typography>

    <Typography variant="h5" fontWeight="700" sx={{ marginTop: '0' }}>
      PODSTAWA PRAWNA
    </Typography>
    <Typography variant="body1">
      Dane osobowe Użytkowników zawarte w Serwisie przetwarzane są przez
      Administratora na podstawie art. 6 ust. 1 lit. a), b) i f) rozporządzenia
      Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
      sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
      osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia
      dyrektywy 95/46/WE <strong>(RODO)</strong>, ustawy o ochronie danych
      osobowych, ustawy o świadczeniu usług drogą elektroniczną oraz ustawy
      Prawo telekomunikacyjne.
    </Typography>
  </>
);

export default DataProcessing;
