import React from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { Constant } from './consts';
import json from '../../package.json';

const initSentry = () => {
  const productionTraceSampler = 0.7;
  return process.env.REACT_APP_SENTRY_KEY
    ? Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_KEY,
        release: `BEST Online - ${json.version}`,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        maxBreadcrumbs: 50,
        tracesSampler: () => {
          if (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'prod') {
            return productionTraceSampler;
          }
          return Constant.one;
        },
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            )
          })
        ]
      })
    : null;
};

export default initSentry;
