import { SvgIcon } from '@mui/material';

const Plus = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 6H11V11H6V13H11V18H13V13H18V11H13V6Z" />
    <path d="M12 -0.0195312C9.62663 -0.0195312 7.30656 0.684231 5.33317 2.00281C3.35978 3.32138 1.8217 5.19553 0.913451 7.38824C0.00519943 9.58096 -0.232441 11.9938 0.230582 14.3215C0.693605 16.6493 1.83649 18.7875 3.51472 20.4658C5.19295 22.144 7.33115 23.2869 9.65892 23.7499C11.9867 24.2129 14.3995 23.9753 16.5922 23.067C18.7849 22.1588 20.6591 20.6207 21.9776 18.6473C23.2962 16.6739 24 14.3538 24 11.9805C24 8.79787 22.7357 5.74562 20.4853 3.49518C18.2349 1.24474 15.1826 -0.0195312 12 -0.0195312ZM12 21.9805C10.0222 21.9805 8.0888 21.394 6.4443 20.2952C4.79981 19.1964 3.51809 17.6346 2.76121 15.8073C2.00434 13.9801 1.8063 11.9694 2.19215 10.0295C2.578 8.08973 3.53041 6.30795 4.92894 4.90942C6.32746 3.5109 8.10929 2.55846 10.0491 2.17261C11.9889 1.78676 13.9996 1.98482 15.8268 2.7417C17.6541 3.49858 19.2159 4.78025 20.3147 6.42474C21.4135 8.06923 22 10.0027 22 11.9805C22 14.6326 20.9464 17.1761 19.0711 19.0515C17.1957 20.9269 14.6522 21.9805 12 21.9805Z" />
  </SvgIcon>
);

export default Plus;
