import { NotificationsState, Notification } from './notifications.model';

const reducers = {
  storeNotification: (
    state: NotificationsState,
    notification: Notification,
    agreementId: number
  ) => ({
    ...state,
    [agreementId]: notification
  }),
  removeNotification: (state: NotificationsState, agreementId: number) => {
    const newState = state;
    delete newState[agreementId];
    return { ...newState };
  }
};
export default reducers;
