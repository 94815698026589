import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import reducers from './processStep.reducers';
import { ProcessStepState } from './processStep.model';

const processStepModel = createModel<RootModel>()({
  state: { step: null } as ProcessStepState,
  reducers
});

export default processStepModel;
