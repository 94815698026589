import { EnteredEmailsState } from './enteredEmails.model';

const reducers = {
  storeResetEmail: (state: EnteredEmailsState, resetEmail: string) => ({
    ...state,
    resetEmail
  }),
  storeUnblockEmail: (state: EnteredEmailsState, unblockEmail: string) => ({
    ...state,
    unblockEmail
  }),
  storeRegisterEmail: (state: EnteredEmailsState, registerEmail: string) => ({
    ...state,
    registerEmail
  })
};

export default reducers;
