import theme from 'App.theme';
import styled from 'styled-components/macro';
import getColor, { Color } from 'utils/getColor';

interface ITextFieldWrapper {
  $color: Color;
  $hasEmptyValue: boolean;
  $noPaddingBottom?: boolean;
  $hidden?: boolean;
  $withoutBottomPosition?: boolean;
}

const TextFieldWrapper = styled.div<ITextFieldWrapper>`
  position: relative;
  width: 100%;

  ${({ $hidden }) => $hidden && 'display: none;'}

  && {
    ${({ $noPaddingBottom }) =>
      !$noPaddingBottom && 'padding-bottom: calc(16px * 1.25)'};
  }
  .MuiOutlinedInput-notchedOutline,
  .Mui-focused > fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ $color, $hasEmptyValue }) =>
      getColor($color, $hasEmptyValue)};
  }
  .MuiOutlinedInput-notchedOutline > legend > span {
    display: none;
  }
  .MuiButtonBase-root > .MuiSvgIcon-root {
    color: ${({ $color }) => getColor($color)};
  }
  .MuiInputAdornment-positionEnd {
    color: ${({ $color }) => getColor($color)};
    position: absolute;
    left: 100%;
    transform: translateX(-200%);
  }
  input.MuiOutlinedInput-input {
    padding-right: 50px;
  }
  .MuiOutlinedInput-root {
    padding: 0;
  }
  .MuiFormHelperText-root,
  .MuiFormHelperText-root.Mui-error {
    color: ${theme.palette.text.secondary};
    letter-spacing: inherit;
  }
  textarea.MuiOutlinedInput-input {
    padding: 13px 16px;
  }
`;

export default TextFieldWrapper;
