import { Typography } from '@mui/material';
import { List } from '../styled/List';
import { ListItem } from '../styled/ListItem';

const PurposesOfDataProcessing = () => (
  <>
    <Typography variant="h5" color="text" fontWeight="700">
      CELE PRZETWARZANIA DANYCH OSOBOWYCH
    </Typography>
    <Typography variant="body1">
      Administrator przetwarza dane osobowe Użytkowników odwiedzających Serwis w
      celu:
    </Typography>
    <List>
      <ListItem dense>
        Zoptymalizowania świadczenia usług elektronicznych za pośrednictwem
        Serwisu;
      </ListItem>
      <ListItem dense>
        Dostosowania zawartości wyświetlanych treści do indywidualnych
        preferencji Użytkownika oraz optymalizacji korzystania przez niego z
        Serwisu;
      </ListItem>
      <ListItem dense>Badania ruchu w ramach Serwisu;</ListItem>
      <ListItem dense>
        Badania satysfakcji Użytkownika i jakości świadczonych usług;
      </ListItem>
      <ListItem dense>
        Raportowania oraz wsparcia emisji reklam i informacji;
      </ListItem>
      <ListItem dense>
        Tworzenia statystyk dotyczących korzystania przez Użytkowników z
        Serwisu;
      </ListItem>
      <ListItem dense>
        Wykorzystania funkcji serwisów społecznościowych do promocji Serwisu;
      </ListItem>
      <ListItem dense>
        Wysyłki newslettera - na podstawie zgody Użytkownika (w tym w celach
        handlowych i marketingowych);
      </ListItem>
      <ListItem dense>W celu wykonania umowy.</ListItem>
    </List>
    <Typography variant="h5" color="text" fontWeight="700">
      ZAKRES PRZETWARZANYCH DANYCH OSOBOWYCH
    </Typography>
    <Typography variant="body1">
      W ramach Serwisu przetwarzamy dane Użytkowników: imię; nazwisko; dane
      kontaktowe (w tym adres poczty elektronicznej); nr PESEL; dane dotyczące
      zadłużenia (w tym dotyczące spłat zadłużenia); historia wpisów w biurach
      informacji gospodarczych, informacja o rozpoczęciu, zakończeniu a także w
      zakresie każdorazowego korzystania przez Użytkownika z Serwisu; oznaczenia
      identyfikujące zakończenie sieci teleinformatycznej lub systemu
      teleinformatycznego, z którego korzysta Użytkownik.
    </Typography>
  </>
);

export default PurposesOfDataProcessing;
