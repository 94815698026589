import { Typography, Link } from '@mui/material';
import { linkRodoBest } from '../utils/urls';

const SecurityAndProcedures = () => (
  <>
    <Typography variant="h5" color="text" fontWeight="700">
      ZABEZPIECZENIA I PROCEDURY
    </Typography>
    <Typography variant="body1">
      Aby wypełnić powyższe cele, Serwis Administratora został stworzony za
      pomocą najnowszych technologii oraz najbardziej zaawanasowanych systemów
      informatycznych i zabezpieczeń technicznych urządzeń, służących do
      przetwarzania danych osobowych oraz przesyłu informacji. Wszelkie
      działania na danych osobowych podejmowane są przez Administratora w
      oparciu o restrykcyjne procedury wewnętrzne.{' '}
    </Typography>
    <Typography variant="body1" sx={{ marginTop: '10px' }}>
      Więcej informacji na temat zasad przetwarzania danych w celu zarządzania
      wierzytelnościami znajduje się na:{' '}
      <Link
        sx={{ fontWeight: 700, lineHeight: '125%' }}
        color="primary.light"
        href={linkRodoBest}
        target="_blank"
        rel="nofollow"
        data-link-no-alert
      >
        {linkRodoBest}
      </Link>
      .
    </Typography>
  </>
);

export default SecurityAndProcedures;
