import { SvgIcon } from '@mui/material';

const Settings = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4132)">
      <path d="M12 7.99999C11.2089 7.99999 10.4355 8.2346 9.77769 8.67413C9.11989 9.11365 8.60717 9.73836 8.30442 10.4693C8.00167 11.2002 7.92254 12.0044 8.07688 12.7804C8.23122 13.5563 8.6122 14.269 9.17161 14.8284C9.73102 15.3878 10.4437 15.7688 11.2196 15.9231C11.9955 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5785 9.92171 14.8283 9.17156C14.0782 8.42142 13.0608 7.99999 12 7.99999ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0008 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96124 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.5599 13.6629 10.8888C13.8827 11.2177 14 11.6044 14 12C14 12.5304 13.7892 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z" />
      <path d="M21.0699 7.82L22.45 5.51L18.52 1.58001L16.21 2.96999C15.9633 2.84999 15.7034 2.74001 15.4301 2.64001L14.78 0.0299911H9.21997L8.56995 2.64001C8.30328 2.74001 8.04337 2.84999 7.79004 2.96999L5.47998 1.58001L1.55005 5.52001L2.93005 7.82C2.82005 8.08 2.70999 8.33 2.60999 8.6L0 9.24999V14.82L2.60999 15.48C2.70999 15.74 2.81005 16 2.93005 16.26L1.55005 18.57L5.47998 22.5L7.79004 21.11C8.04307 21.2329 8.3035 21.3398 8.56995 21.43L9.21997 24.05H14.78L15.4301 21.44C15.6967 21.34 15.9566 21.23 16.21 21.11L18.52 22.5L22.45 18.57L21.0699 16.26C21.1899 16 21.3 15.74 21.39 15.48L24 14.82V9.21999L21.39 8.57C21.3 8.31 21.1899 8.05 21.0699 7.82ZM22 13.25L19.8199 13.8L19.66 14.34C19.5088 14.8532 19.3044 15.3493 19.05 15.82L18.78 16.32L19.9399 18.24L18.21 19.97L16.29 18.81L15.79 19.08C15.3193 19.3343 14.8233 19.5388 14.3101 19.69L13.77 19.86L13.22 22.04H10.78L10.24 19.85L9.68994 19.69C9.18056 19.5369 8.68807 19.3326 8.21997 19.08L7.71997 18.81L5.79004 19.97L4.06995 18.24L5.21997 16.32L4.94995 15.82C4.6998 15.3473 4.49555 14.8517 4.33997 14.34L4.18005 13.8L2 13.25V10.82L4.18005 10.28L4.33997 9.74001C4.49375 9.22768 4.69807 8.7319 4.94995 8.26L5.21997 7.76L4.06995 5.83999L5.79004 4.11001L7.71997 5.26L8.21997 4.99999C8.68724 4.74571 9.17992 4.54126 9.68994 4.39001L10.24 4.21999L10.78 2.04H13.22L13.77 4.21999L14.3101 4.39001C14.8242 4.5387 15.3205 4.74324 15.79 4.99999L16.29 5.26L18.21 4.11001L19.9399 5.83001L18.78 7.76L19.05 8.26C19.3019 8.7319 19.5063 9.22768 19.66 9.74001L19.8199 10.28L22 10.83V13.25Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4132">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Settings;
