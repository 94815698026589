import styled from 'styled-components/macro';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
  display: flex;
  flex-flow: wrap column;
  padding: 0;
  height: 100%;
  && {
    padding: 0;
  }
  &&:hover {
    background-color: transparent;
  }
  .MuiTouchRipple-root {
    display: none;
  }
`;
