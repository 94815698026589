import { SvgIcon } from '@mui/material';

const Search = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.4235 5.71174C11.4235 4.58206 11.0885 3.47776 10.4608 2.53847C9.83323 1.59918 8.94119 0.867098 7.89751 0.434791C6.85383 0.00248287 5.70538 -0.110639 4.59742 0.109749C3.48945 0.330138 2.47171 0.874124 1.67291 1.67292C0.874114 2.47173 0.330149 3.48946 0.10976 4.59743C-0.110628 5.7054 0.00245057 6.85384 0.434758 7.89752C0.867066 8.9412 1.59915 9.83326 2.53844 10.4609C3.47773 11.0885 4.58205 11.4235 5.71173 11.4235C6.9802 11.425 8.21238 11.0001 9.21018 10.2169L9.70282 10.7095L8.98885 11.4235L13.5654 16L16 13.5654L11.4235 8.98884L10.7095 9.70281L10.2169 9.21017C11.0001 8.21237 11.425 6.98021 11.4235 5.71174ZM13.9866 13.5654L13.5654 13.9866L11.0022 11.4235L11.4235 11.0022L13.9866 13.5654ZM5.71173 9.99554C4.86447 9.99554 4.03623 9.74431 3.33176 9.2736C2.6273 8.80289 2.07824 8.13385 1.75401 7.35108C1.42978 6.56832 1.34495 5.70699 1.51024 4.87602C1.67553 4.04504 2.08354 3.28173 2.68264 2.68263C3.28174 2.08353 4.04503 1.67554 4.876 1.51025C5.70698 1.34496 6.56829 1.42979 7.35105 1.75402C8.13381 2.07825 8.80288 2.62731 9.27359 3.33177C9.7443 4.03624 9.99553 4.86448 9.99553 5.71174C9.99553 6.84787 9.54418 7.93748 8.74081 8.74084C7.93744 9.54421 6.84786 9.99554 5.71173 9.99554Z" />
  </SvgIcon>
);

export default Search;
