const SettlementGreen = ({ ...props }) => (
  <svg
    width="194"
    height="194"
    {...props}
    viewBox="0 0 194 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5133_27443)">
      <path
        d="M153.571 148.27C207.14 148.27 250.565 104.845 250.565 51.2763C250.565 -2.29203 207.14 -45.7178 153.571 -45.7178C100.003 -45.7178 56.5771 -2.29203 56.5771 51.2763C56.5771 104.845 100.003 148.27 153.571 148.27Z"
        fill="#F0F7F6"
      />
      <path
        d="M145.333 86.3657H144.814C143.28 86.29 141.781 85.8844 140.418 85.1765C139.055 84.4687 137.861 83.4752 136.917 82.2638L122.042 63.122C120.311 60.8895 119.535 58.062 119.884 55.2586C120.233 52.4551 121.678 49.9042 123.904 48.1644L124.446 47.7401C126.679 46.0072 129.508 45.2312 132.313 45.5826C135.117 45.934 137.668 47.384 139.404 49.6142C140.164 50.5996 141.112 51.4248 142.192 52.0423C143.273 52.6597 144.465 53.0571 145.7 53.2116C146.935 53.3661 148.188 53.2746 149.387 52.9424C150.586 52.6102 151.708 52.0439 152.688 51.2762C153.066 50.9823 153.421 50.6592 153.748 50.3096L183.958 18.4144C184.918 17.3934 186.07 16.5726 187.348 15.9994C188.627 15.4261 190.006 15.1118 191.406 15.0746C192.807 15.0373 194.201 15.2778 195.508 15.7823C196.815 16.2867 198.009 17.0451 199.022 18.0136V18.0136L199.505 18.4851C200.527 19.4438 201.349 20.5953 201.923 21.8733C202.497 23.1513 202.813 24.5304 202.851 25.9309C202.889 27.3315 202.65 28.7258 202.146 30.0333C201.643 31.3408 200.885 32.5356 199.918 33.5487L153.029 83.0535C152.039 84.0993 150.845 84.9323 149.522 85.5017C148.199 86.0712 146.773 86.3651 145.333 86.3657V86.3657Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5133_27443">
        <rect width="194" height="194" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SettlementGreen;
