import { CircularProgress, Box, SxProps } from '@mui/material';

import { Variant, Color } from 'components/Button/Button.model';
import { InlineLoaderWrapper } from './styled/InlineLoader';

export interface IInlineLoader {
  variant: Variant;
  color: Color;
  size?: number;
  thickness?: number;
  sx?: SxProps;
}

const InlineLoader = ({ size, thickness, sx, ...props }: IInlineLoader) => (
  <InlineLoaderWrapper sx={sx} {...props}>
    <Box>
      <CircularProgress
        variant="determinate"
        thickness={thickness}
        value={100}
        size={size}
      />
      <CircularProgress
        color="info"
        disableShrink
        thickness={thickness}
        size={size}
      />
    </Box>
  </InlineLoaderWrapper>
);

export default InlineLoader;
