import { useEffect, KeyboardEvent } from 'react';
import {
  TextField as TextFieldComponent,
  InputLabel,
  InputAdornment,
  FormHelperText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Color } from 'utils/getColor';
import { errorInputEvent } from 'utils/events';
import sendEvent from 'utils/sendEvent';
import { Icon } from 'components/Icon';
import { AdditionalInputProps, TextFieldProps } from './TextField.model';
import TextFieldWrapper from './styled/TextFieldWrapper';
import ErrorMessage from './styled/ErrorMessage';
import WarningMessage from './styled/WarningMessage';

const TextField = ({
  field,
  form,
  showIcon,
  helperText,
  hideBottomPadding,
  innerRef,
  hidden,
  withoutBottomPosition,
  addEvent,
  label,
  labelText,
  onFieldFocusLost,
  warningMessageData,
  ...otherProps
}: TextFieldProps) => {
  const { t } = useTranslation();
  const { name } = field;
  const currentError = form.errors[name];
  const touched = form.touched[name];

  const onFocus = () => {
    form.setFieldError(name, undefined);
    form.setFieldTouched(name, false, false);
    if (addEvent) {
      addEvent();
    }
  };

  const onBlur = async () => {
    const isPasswordField =
      name === 'password' || name === 'oldPassword' || name === 'newPassword';
    const isNumberInput =
      otherProps.type === 'number' && inputMode === 'numeric';
    if (!isPasswordField && !isNumberInput) {
      await form.setFieldValue(name, field.value.trim(), false);
    }
    if (onFieldFocusLost) onFieldFocusLost(field.value);
    form.setFieldTouched(name, true, true);
  };

  useEffect(() => {
    if (currentError && touched && labelText) {
      sendEvent({
        labelText: `${labelText}: ${currentError}`,
        ...errorInputEvent
      });
    }
  }, [currentError, touched]);

  const getColor = () => (currentError ? 'error' : 'success');

  const color: Color = touched ? getColor() : undefined;

  const inputProps =
    showIcon && touched && (currentError || color === 'success')
      ? {
          endAdornment: (
            <InputAdornment position="end">
              {currentError ? (
                <Icon icon="error" color="error" />
              ) : (
                <Icon
                  icon="valid"
                  color={otherProps.disabled ? 'disabled' : color}
                />
              )}
            </InputAdornment>
          )
        }
      : undefined;

  const additionalInputProps: AdditionalInputProps = {};
  const inputMode = otherProps.InputProps?.inputProps?.inputMode;
  if (otherProps.type === 'number' && inputMode === 'numeric') {
    const notAllowedKeys = ['e', 'E', '+', '-', '.', ','];

    const correctKeyRegex = /^[0-9]$/;
    const maxValueParam: string | undefined =
      otherProps.InputProps?.inputProps?.max;

    additionalInputProps.onKeyDown = (
      event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (notAllowedKeys.includes(event.key)) {
        event.preventDefault();
        return;
      }

      if (maxValueParam) {
        if (
          field.value.toString().length >= maxValueParam &&
          correctKeyRegex.test(event.key)
        ) {
          event.preventDefault();
        }
      }
    };
  }

  return (
    <TextFieldWrapper
      $color={color}
      $hasEmptyValue={!field.value || field.value === ''}
      $noPaddingBottom={hideBottomPadding}
      $hidden={hidden}
    >
      <InputLabel>{label}</InputLabel>
      <div style={{ position: 'relative' }}>
        <TextFieldComponent
          {...otherProps}
          {...field}
          inputRef={innerRef}
          error={touched && Boolean(currentError)}
          onFocus={onFocus}
          onBlur={onBlur}
          label=""
          InputProps={
            (otherProps.InputProps && {
              ...otherProps.InputProps,
              inputProps: {
                ...otherProps.InputProps.inputProps,
                ...additionalInputProps
              }
            }) ||
            inputProps
          }
          color={color}
        />
        <ErrorMessage
          $withoutBottomPosition={withoutBottomPosition}
          name={field.name}
          component="p"
        />
        {warningMessageData && warningMessageData.isVisible && (
          <WarningMessage
            color="warning.main"
            sx={{ fontSize: '13px', fontWeight: '600', lineHeight: '125%' }}
          >
            {t('MODAL_DOMAIN_CONFIRM_DESCRIPTION_3', {
              domain: warningMessageData.domain
            })}
          </WarningMessage>
        )}
      </div>
      <FormHelperText sx={{ marginTop: '20px' }}>{helperText}</FormHelperText>
    </TextFieldWrapper>
  );
};

export default TextField;
