import styled from 'styled-components/macro';

import theme from 'App.theme';
import { colors } from 'components/Colors';

import { IInlineLoader } from '../InlineLoader';

const firstCirclePalette = {
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
  success: theme.palette.success.main,
  warning: theme.palette.warning.main,
  error: theme.palette.error.main,
  info: theme.palette.info.main,
  inherit: theme.palette.common.white
};

const secondCirclePalette = {
  primary: theme.palette.info.main,
  secondary: colors.common.grey.value,
  success: theme.palette.info.light,
  warning: colors.orange.grey.value,
  error: colors.red.grey.value,
  info: theme.palette.info.light,
  inherit: theme.palette.info.main
};

export const InlineLoaderWrapper = styled.div<IInlineLoader>`
  .MuiBox-root {
    position: relative;
    & > :first-child {
      color: ${({ variant, color }) =>
        variant === 'contained'
          ? theme.palette.common.white
          : firstCirclePalette[color]};
    }
    & > :last-child {
      position: absolute;
      left: 0;
      color: ${({ color }) => secondCirclePalette[color]};
    }
  }
`;
