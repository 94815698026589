import ModalsState from './modals.model';

const modalsReducers = {
  changeRegulationsVisibility: (state: ModalsState) => ({
    ...state,
    isRegulationOpen: !state.isRegulationOpen
  }),
  changePrivacyPolicyVisibility: (state: ModalsState) => ({
    ...state,
    isPrivacyPolicyOpen: !state.isPrivacyPolicyOpen
  })
};

export default modalsReducers;
