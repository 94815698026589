import { ProductParametersKey } from 'storage/agreements/agreements.model';
import { Debt, SimulationParameter } from 'storage/product/product.model';

const getDebtData = (data: SimulationParameter[]): Debt | null => {
  const amount = data.find(
    (item) => item.key === ProductParametersKey.INSTALLMENTS_COUNT
  )?.value;
  const sum = data.find(
    (item) => item.key === ProductParametersKey.INSTALLMENTS_AMOUNT
  )?.value;
  const lastInstallmentAmount = data.find(
    (item) => item.key === ProductParametersKey.LAST_INSTALLMENT_AMOUNT
  )?.value;
  const lastInstallmentPayment = data.find(
    (item) => item.key === ProductParametersKey.LAST_INSTALLMENT_PAYMENT
  )?.value;

  if (
    amount !== undefined &&
    sum !== undefined &&
    lastInstallmentAmount !== undefined &&
    lastInstallmentPayment !== undefined
  ) {
    return {
      sum: +sum,
      amount: +amount,
      lastInstallment: +lastInstallmentAmount,
      endOfDebtDate: lastInstallmentPayment
    };
  }

  return null;
};

export default getDebtData;
