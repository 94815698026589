import { IIllustration } from '../Illustration.model';

const ExpiredPassword = ({ width, height }: IIllustration) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 246 207"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1202_6332)">
      <path
        d="M75.4978 168.023C86.9528 168.023 96.2389 158.737 96.2389 147.282C96.2389 135.827 86.9528 126.541 75.4978 126.541C64.0428 126.541 54.7567 135.827 54.7567 147.282C54.7567 158.737 64.0428 168.023 75.4978 168.023Z"
        fill="#E73F0D"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8432 123.854C57.9962 123.301 53.0183 119.35 51.1615 113.78L25.482 36.86C22.0054 26.5092 27.5758 15.3287 37.9267 11.8916C48.238 8.45448 59.4185 14.025 62.8556 24.3363C63.0926 25.0869 63.2901 25.8375 63.4482 26.6277L79.804 106.076C81.4633 114.096 76.2879 121.958 68.268 123.617C66.8062 123.894 65.305 123.973 63.8432 123.854Z"
        fill="#E73F0D"
        stroke="#1D1D1B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M216.656 105.444H216.617L188.369 117.494L149.731 134.008C149.415 134.126 149.099 134.126 148.783 134.008L108.921 117.454L79.9621 105.484H79.9226H79.8831C78.9349 105.484 78.1448 106.274 78.1448 107.222V182.009C78.1448 182.957 78.9349 183.747 79.8831 183.747H216.656C217.604 183.747 218.394 182.957 218.394 182.009V107.182C218.394 106.234 217.604 105.444 216.656 105.444Z"
        fill="white"
      />
      <path
        d="M216.775 105.958C216.735 105.958 216.656 105.958 216.617 105.918L148.625 58.5098C148.191 58.2333 147.637 58.2333 147.203 58.5098L79.646 105.879C79.5275 105.958 79.3695 105.918 79.2904 105.8C79.2114 105.681 79.2509 105.523 79.3694 105.444L146.926 58.0752C147.519 57.6802 148.309 57.6802 148.902 58.0752L216.933 105.444C217.051 105.523 217.091 105.681 217.012 105.8C216.933 105.918 216.854 105.958 216.775 105.958Z"
        fill="#3F3D56"
      />
      <path
        d="M83.5177 107.617L147.954 59.8531L212.942 111.015L151.312 147.558L117.849 139.934L83.5177 107.617Z"
        fill="#E6E6E6"
      />
      <path
        d="M120.654 170.117H88.8116C87.982 170.117 87.3104 169.445 87.3104 168.616C87.3104 167.786 87.982 167.114 88.8116 167.114H120.654C121.484 167.114 122.156 167.786 122.156 168.616C122.156 169.445 121.484 170.117 120.654 170.117Z"
        fill="#58A765"
      />
      <path
        d="M100.229 163.717H88.8116C87.982 163.717 87.3104 163.045 87.3104 162.216C87.3104 161.386 87.982 160.714 88.8116 160.714H100.229C101.059 160.714 101.73 161.386 101.73 162.216C101.73 163.085 101.059 163.717 100.229 163.717Z"
        fill="#58A765"
      />
      <path
        d="M148.862 134.245C148.625 134.245 148.388 134.205 148.151 134.087L108.407 117.573V65.9766C108.407 65.0284 109.197 64.2383 110.145 64.2383H186.394C187.342 64.2383 188.132 65.0284 188.132 65.9766V117.652L188.053 117.691L149.613 134.126C149.376 134.205 149.139 134.245 148.862 134.245Z"
        fill="white"
      />
      <path
        d="M148.862 134.363C148.586 134.363 148.349 134.324 148.112 134.205L108.289 117.691V65.9766C108.289 64.9495 109.118 64.1198 110.145 64.1198H186.394C187.421 64.1198 188.251 64.9495 188.251 65.9766V117.731L149.652 134.205C149.415 134.324 149.139 134.363 148.862 134.363ZM108.802 117.375L148.349 133.771C148.704 133.929 149.139 133.929 149.494 133.771L187.777 117.415V65.9766C187.777 65.226 187.184 64.6334 186.433 64.6334H110.185C109.434 64.6334 108.842 65.226 108.842 65.9766L108.802 117.375Z"
        fill="#3F3D56"
      />
      <path
        d="M216.301 105.444H216.261L188.014 117.494L149.376 134.008C149.06 134.126 148.744 134.126 148.428 134.008L108.565 117.454L79.6065 105.484H79.567H79.5275C78.5793 105.484 77.7892 106.274 77.7892 107.222V182.009C77.7892 182.957 78.5793 183.747 79.5275 183.747H216.301C217.249 183.747 218.039 182.957 218.039 182.009V107.182C218.039 106.234 217.249 105.444 216.301 105.444ZM217.525 181.969C217.525 182.641 216.972 183.194 216.301 183.194H79.5275C78.8559 183.194 78.3028 182.641 78.3028 181.969V107.182C78.3028 106.511 78.8164 105.997 79.488 105.958L108.565 118.007L148.23 134.482C148.665 134.64 149.139 134.64 149.573 134.482L188.014 118.047L216.34 105.958C217.012 105.997 217.525 106.511 217.525 107.182V181.969Z"
        fill="#3F3D56"
      />
      <path
        d="M142.699 83.5572H115.637C114.531 83.5572 113.661 82.6881 113.661 81.5819C113.661 80.5152 114.531 79.6065 115.637 79.6065H142.699C143.805 79.6065 144.674 80.4757 144.674 81.5819C144.635 82.6881 143.766 83.5572 142.699 83.5572Z"
        fill="#58A765"
      />
      <path
        d="M128.674 75.1817H115.637C114.531 75.1817 113.661 74.3126 113.661 73.2064C113.661 72.1002 114.531 71.231 115.637 71.231H128.674C129.78 71.231 130.649 72.1002 130.649 73.2064C130.649 74.3126 129.741 75.1817 128.674 75.1817Z"
        fill="#58A765"
      />
      <path
        d="M168.497 103.232H127.173C126.067 103.232 125.198 102.363 125.198 101.256C125.198 100.19 126.067 99.281 127.173 99.281H168.497C169.603 99.281 170.473 100.15 170.473 101.256C170.473 102.363 169.603 103.232 168.497 103.232Z"
        fill="#CCCCCC"
      />
      <path
        d="M168.497 112.318H127.173C126.067 112.318 125.198 111.449 125.198 110.343C125.198 109.237 126.067 108.368 127.173 108.368H168.497C169.603 108.368 170.473 109.237 170.473 110.343C170.473 111.449 169.603 112.318 168.497 112.318Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1202_6332">
        <rect
          width="245.186"
          height="206.564"
          fill="white"
          transform="translate(0.814209 0.436462)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ExpiredPassword;
