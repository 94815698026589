import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState
} from 'react';

interface IDesktopMenuAnchorContext {
  anchorElNav: HTMLElement | null;
  setAnchorEl: null | Dispatch<SetStateAction<HTMLElement | null>>;
}

export const DesktopMenuAnchorContext =
  createContext<IDesktopMenuAnchorContext>({
    anchorElNav: null,
    setAnchorEl: null
  });

export const DesktopMenuAnchorProvider: React.FC<React.ReactNode> = ({
  children
}) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const val = React.useMemo(
    () => ({ anchorElNav, setAnchorEl: setAnchorElNav }),
    [anchorElNav]
  );
  return (
    <DesktopMenuAnchorContext.Provider value={val}>
      {children}
    </DesktopMenuAnchorContext.Provider>
  );
};
