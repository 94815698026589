import { TFunction } from 'react-i18next';
import { ProductParametersKey } from 'storage/agreements/agreements.model';
import {
  DynamicSimulationData,
  PartialSimulationData,
  PlusSimulationData,
  ProductType,
  SimulationParameter,
  TotalSimulationData,
  ZeroSimulationData,
  ZeroWithPrerequisiteSimulationData
} from 'storage/product/product.model';
import findProductParameter from '../findProductParameter';
import { CurrencyType } from '../formatCurrency/formatCurrency.model';
import getDifferenceInDays from '../getDifferenceInDays';
import getWordVariation from '../getWordVariation';
import useAmountAndSumSettingsHandler from './useAmountAndSumSettingsHandler';
import { parseDate } from './parseDate';
import useSimulationDataHandler from './useSimulationDataHandler';

const getPlusSimulationData = (
  response: SimulationParameter[],
  t?: TFunction<'translation'>,
  pitRequired?: boolean
) => {
  const searchedParameters = [
    ProductParametersKey.INSTALLMENTS_AMOUNT,
    ProductParametersKey.INSTALLMENTS_COUNT,
    ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
    ProductParametersKey.LAST_INSTALLMENT_AMOUNT,
    ProductParametersKey.LAST_INSTALLMENT_PAYMENT,
    ProductParametersKey.REMISSION_TOTAL_REPAYMENT_AMOUNT,
    ProductParametersKey.REMISSION_AMOUNT
  ];
  const foundParameters = searchedParameters.map((parameter) =>
    findProductParameter(response, parameter)
  );
  const [
    installmentsAmount,
    installmentsCount,
    firstInstallmentPaymentDate,
    lastInstallmentAmount,
    lastInstallmentPaymentDate,
    remissionTotalRepaymentAmount,
    remissionAmount
  ] = foundParameters;

  if (
    t &&
    firstInstallmentPaymentDate &&
    firstInstallmentPaymentDate.maxValue &&
    firstInstallmentPaymentDate.minValue &&
    installmentsAmount &&
    installmentsAmount.minValue &&
    installmentsAmount.maxValue &&
    lastInstallmentAmount &&
    lastInstallmentPaymentDate &&
    installmentsCount &&
    remissionAmount &&
    remissionTotalRepaymentAmount
  ) {
    const firstInstallmentPaymentAverage = parseDate(
      firstInstallmentPaymentDate.value
    );
    const firstInstallmentPaymentMax = parseDate(
      firstInstallmentPaymentDate.maxValue
    );
    const firstInstallmentPaymentMin = parseDate(
      firstInstallmentPaymentDate.minValue
    );
    const lastInstallmentPayment = parseDate(lastInstallmentPaymentDate.value);
    const result = {
      firstInstallmentPaymentMin,
      firstInstallmentPaymentMax,
      firstInstallmentPaymentAverage,
      lastInstallmentPayment,
      lastInstallment: +lastInstallmentAmount.value,
      installmentsCount: +installmentsCount.value,
      installmentsAmountMin: +installmentsAmount.minValue,
      installmentsAmountMax: +installmentsAmount.maxValue,
      installmentsAmountAverage: +installmentsAmount.value,
      remissionAmount: +remissionAmount.value,
      remissionTotalRepayment: +remissionTotalRepaymentAmount.value
    };

    return { ...result, ...(pitRequired && { pitRequired }) };
  }
  return null;
};

const getPartialSimulationData = (
  response: SimulationParameter[],
  pitRequired: boolean
): PartialSimulationData | null => {
  const searchedParameters = [
    ProductParametersKey.PAYOFF_AMOUNT,
    ProductParametersKey.REMISSION_AMOUNT,
    ProductParametersKey.PAYOFF_DAY
  ];
  const foundParameters = searchedParameters.map((parameter) =>
    findProductParameter(response, parameter)
  );
  const [payoffAmount, remissionAmount, payoffDay] = foundParameters;

  if (
    payoffAmount &&
    remissionAmount &&
    payoffDay &&
    payoffDay.minValue &&
    payoffDay.maxValue
  ) {
    const minDate = parseDate(payoffDay.minValue);
    const maxDate = parseDate(payoffDay.maxValue);
    const maxDaysAmount = getDifferenceInDays(payoffDay.maxValue);

    return {
      debt: +payoffAmount.value + +remissionAmount.value,
      remission: +remissionAmount.value,
      pitRequired,
      minDate,
      maxDate,
      maxDaysAmount,
      payoffAmount: +payoffAmount.value
    };
  }
  return null;
};

const getZeroSimulationData = (
  response: SimulationParameter[],
  t?: TFunction<'translation'>,
  currencyType?: CurrencyType
): ZeroSimulationData | null => {
  const searchedParameters = [
    ProductParametersKey.INSTALLMENTS_COUNT,
    ProductParametersKey.INSTALLMENTS_AMOUNT,
    ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
    ProductParametersKey.LAST_INSTALLMENT_AMOUNT,
    ProductParametersKey.LAST_INSTALLMENT_PAYMENT
  ];
  const foundParameters = searchedParameters.map((parameter) =>
    findProductParameter(response, parameter)
  );
  const [
    installmentsCount,
    installmentsAmount,
    firstInstallmentPayment,
    lastInstallmentAmount,
    lastInstallmentPayment
  ] = foundParameters;

  if (
    t &&
    currencyType &&
    installmentsCount &&
    installmentsCount.minValue &&
    installmentsCount.maxValue &&
    installmentsAmount &&
    installmentsAmount.minValue &&
    installmentsAmount.maxValue &&
    firstInstallmentPayment &&
    firstInstallmentPayment.minValue &&
    firstInstallmentPayment.maxValue &&
    lastInstallmentAmount &&
    lastInstallmentPayment
  ) {
    const { minValue, maxValue, value } = firstInstallmentPayment;
    const date = parseDate(value);
    const minDate = parseDate(minValue);
    const maxDate = parseDate(maxValue);
    const minText = getWordVariation(+installmentsCount.minValue, t);
    const maxText = getWordVariation(+installmentsCount.maxValue, t);
    const { amountSettings, sumSettings } = useAmountAndSumSettingsHandler(
      installmentsCount,
      minText,
      maxText,
      installmentsAmount,
      currencyType!
    );

    return {
      debt: {
        amount: +installmentsCount.value,
        sum: +installmentsAmount.value,
        lastInstallment: +lastInstallmentAmount.value,
        endOfDebtDate: lastInstallmentPayment.value
      },
      date,
      minDate,
      maxDate,
      sumSettings,
      amountSettings
    };
  }
  return null;
};

const getTotalSimulationData = (response: SimulationParameter[]) => {
  const searchedParameters = [ProductParametersKey.PAYOFF_DAY];
  const foundParameters = searchedParameters.map((parameter) =>
    findProductParameter(response, parameter)
  );
  const [payoffDay] = foundParameters;

  if (payoffDay && payoffDay.minValue && payoffDay.maxValue) {
    const minDate = parseDate(payoffDay.minValue);
    const maxDate = parseDate(payoffDay.maxValue);
    const maxDaysAmount = getDifferenceInDays(payoffDay.maxValue);

    return {
      minDate,
      maxDate,
      maxDaysAmount
    };
  }
  return null;
};

const getDynamicSimulationData = (
  response: SimulationParameter[],
  t?: TFunction<'translation'>,
  currencyType?: CurrencyType,
  pitRequired?: boolean
) => {
  const searchedParameters = [
    ProductParametersKey.PREREQUISITE_PAYMENT_AMOUNT,
    ProductParametersKey.PREREQUISITE_PAYMENT_DATE,
    ProductParametersKey.REMISSION_AMOUNT,
    ProductParametersKey.REMISSION_TOTAL_REPAYMENT_AMOUNT,
    ProductParametersKey.INSTALLMENT_REPAYMENT_AMOUNT,
    ProductParametersKey.PREREQUISITE_PAYMENT_DEBT_AMOUNT,
    ProductParametersKey.INSTALLMENTS_COUNT,
    ProductParametersKey.INSTALLMENTS_AMOUNT,
    ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
    ProductParametersKey.LAST_INSTALLMENT_PAYMENT,
    ProductParametersKey.LAST_INSTALLMENT_AMOUNT
  ];
  const foundParameters = searchedParameters.map((parameter) =>
    findProductParameter(response, parameter)
  );
  const [
    prerequisitePaymentAmount,
    prerequisitePaymentDate,
    remissionAmount,
    remissionTotalRepaymentAmount,
    installmentRepaymentAmount,
    prerequisitePaymentDebtAmount,
    installmentsCount,
    installmentsAmount,
    firstInstallmentPayment,
    lastInstallmentPaymentDate,
    lastInstallmentAmount
  ] = foundParameters;

  if (
    t &&
    prerequisitePaymentAmount &&
    prerequisitePaymentAmount.minValue &&
    prerequisitePaymentAmount.maxValue &&
    prerequisitePaymentDate &&
    prerequisitePaymentDate.minValue &&
    prerequisitePaymentDate.maxValue &&
    remissionAmount &&
    installmentRepaymentAmount &&
    remissionTotalRepaymentAmount &&
    prerequisitePaymentDebtAmount &&
    installmentsCount &&
    installmentsCount.minValue &&
    installmentsCount.maxValue &&
    installmentsAmount &&
    installmentsAmount.minValue &&
    installmentsAmount.maxValue &&
    firstInstallmentPayment &&
    firstInstallmentPayment.minValue &&
    firstInstallmentPayment.maxValue &&
    lastInstallmentPaymentDate &&
    lastInstallmentAmount
  ) {
    const {
      minDate,
      maxDate,
      averageDate,
      firstInstallmentPaymentMin,
      firstInstallmentPaymentMax,
      firstInstallmentPaymentAverage,
      lastInstallmentPayment,
      sumSettings,
      amountSettings
    } = useSimulationDataHandler(
      prerequisitePaymentDate,
      lastInstallmentPaymentDate,
      firstInstallmentPayment,
      installmentsCount,
      installmentsAmount,
      currencyType!,
      t
    );
    const result = {
      sumSettings,
      amountSettings,
      minDate,
      maxDate,
      averageDate,
      firstInstallmentPaymentMin,
      firstInstallmentPaymentMax,
      firstInstallmentPaymentAverage,
      lastInstallmentPayment,
      lastInstallment: +lastInstallmentAmount.value,
      remissionTotalRepayment: +remissionTotalRepaymentAmount.value,
      remissionAmount: +remissionAmount.value,
      remissionInstalmentRepaymentAmount: +installmentRepaymentAmount.value,
      prerequisitePaymentDebtAmount: +prerequisitePaymentDebtAmount.value,
      minPrerequisitePaymentAmount: +prerequisitePaymentAmount.minValue,
      maxPrerequisitePaymentAmount: +prerequisitePaymentAmount.maxValue,
      averagePrerequisitePaymentAmount: +prerequisitePaymentAmount.value,
      installmentsCountMin: +installmentsCount.minValue,
      installmentsCountMax: +installmentsCount.maxValue,
      installmentsCountAverage: +installmentsCount.value,
      installmentsAmountMin: +installmentsAmount.minValue,
      installmentsAmountMax: +installmentsAmount.maxValue,
      installmentsAmountAverage: +installmentsAmount.value
    };

    return { ...result, ...(pitRequired && { pitRequired }) };
  }

  return null;
};

const getZeroWithPrerequisiteSimulationData = (
  response: SimulationParameter[],
  t?: TFunction<'translation'>,
  currencyType?: CurrencyType
) => {
  const searchedParameters = [
    ProductParametersKey.PREREQUISITE_PAYMENT_AMOUNT,
    ProductParametersKey.PREREQUISITE_PAYMENT_DATE,
    ProductParametersKey.INSTALLMENT_REPAYMENT_AMOUNT,
    ProductParametersKey.PREREQUISITE_PAYMENT_DEBT_AMOUNT,
    ProductParametersKey.INSTALLMENTS_COUNT,
    ProductParametersKey.INSTALLMENTS_AMOUNT,
    ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
    ProductParametersKey.LAST_INSTALLMENT_PAYMENT,
    ProductParametersKey.LAST_INSTALLMENT_AMOUNT
  ];
  const foundParameters = searchedParameters.map((parameter) =>
    findProductParameter(response, parameter)
  );
  const [
    prerequisitePaymentAmount,
    prerequisitePaymentDate,
    installmentRepaymentAmount,
    prerequisitePaymentDebtAmount,
    installmentsCount,
    installmentsAmount,
    firstInstallmentPayment,
    lastInstallmentPaymentDate,
    lastInstallmentAmount
  ] = foundParameters;

  if (
    t &&
    prerequisitePaymentAmount &&
    prerequisitePaymentAmount.minValue &&
    prerequisitePaymentAmount.maxValue &&
    prerequisitePaymentDate &&
    prerequisitePaymentDate.minValue &&
    prerequisitePaymentDate.maxValue &&
    installmentRepaymentAmount &&
    prerequisitePaymentDebtAmount &&
    installmentsCount &&
    installmentsCount.minValue &&
    installmentsCount.maxValue &&
    installmentsAmount &&
    installmentsAmount.minValue &&
    installmentsAmount.maxValue &&
    firstInstallmentPayment &&
    firstInstallmentPayment.minValue &&
    firstInstallmentPayment.maxValue &&
    lastInstallmentPaymentDate &&
    lastInstallmentAmount
  ) {
    const {
      minDate,
      maxDate,
      averageDate,
      firstInstallmentPaymentMin,
      firstInstallmentPaymentMax,
      firstInstallmentPaymentAverage,
      lastInstallmentPayment,
      sumSettings,
      amountSettings
    } = useSimulationDataHandler(
      prerequisitePaymentDate,
      lastInstallmentPaymentDate,
      firstInstallmentPayment,
      installmentsCount,
      installmentsAmount,
      currencyType!,
      t
    );

    return {
      sumSettings,
      amountSettings,
      minDate,
      maxDate,
      averageDate,
      firstInstallmentPaymentMin,
      firstInstallmentPaymentMax,
      firstInstallmentPaymentAverage,
      lastInstallmentPayment,
      lastInstallment: +lastInstallmentAmount.value,
      remissionInstalmentRepaymentAmount: +installmentRepaymentAmount.value,
      prerequisitePaymentDebtAmount: +prerequisitePaymentDebtAmount.value,
      minPrerequisitePaymentAmount: +prerequisitePaymentAmount.minValue,
      maxPrerequisitePaymentAmount: +prerequisitePaymentAmount.maxValue,
      averagePrerequisitePaymentAmount: +prerequisitePaymentAmount.value,
      installmentsCountMin: +installmentsCount.minValue,
      installmentsCountMax: +installmentsCount.maxValue,
      installmentsCountAverage: +installmentsCount.value,
      installmentsAmountMin: +installmentsAmount.minValue,
      installmentsAmountMax: +installmentsAmount.maxValue,
      installmentsAmountAverage: +installmentsAmount.value
    };
  }
  return null;
};

const getSimulationData = (
  response: SimulationParameter[],
  type: ProductType,
  t?: TFunction<'translation'>,
  currencyType?: CurrencyType,
  pitRequired?: boolean
):
  | PartialSimulationData
  | ZeroSimulationData
  | TotalSimulationData
  | DynamicSimulationData
  | ZeroWithPrerequisiteSimulationData
  | PlusSimulationData
  | null => {
  switch (type) {
    case ProductType.DUD: {
      return getDynamicSimulationData(response, t, currencyType, pitRequired);
    }
    case ProductType.CUD: {
      return getPartialSimulationData(response, pitRequired!);
    }
    case ProductType.SETTLEMENT_ZERO: {
      return getZeroSimulationData(response, t, currencyType);
    }
    case ProductType.SETTLEMENT_ZERO_WITH_PREREQUISITE: {
      return getZeroWithPrerequisiteSimulationData(response, t, currencyType);
    }
    case ProductType.TOTAL_REPAYMENT: {
      return getTotalSimulationData(response);
    }
    case ProductType.PLUS_SETTLEMENT: {
      return getPlusSimulationData(response, t, pitRequired);
    }
    default: {
      return null;
    }
  }
};

export default getSimulationData;
