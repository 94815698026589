import { SvgIcon } from '@mui/material';

const Download = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4123)">
      <path d="M10.7998 -3.29016e-06L10.7998 14.4L7.0918 14.4L11.9518 19.2L16.7998 14.4L13.1998 14.4L13.1998 -3.49998e-06L10.7998 -3.29016e-06Z" />
      <path d="M0 24L24 24L24 21.6L-2.09815e-07 21.6L0 24Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4123">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24 24) rotate(180)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Download;
