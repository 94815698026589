import { SvgIcon } from '@mui/material';

const Calendar = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24 2.18182H19.6364V0H17.4545V6.54545H19.6364V4.36364H21.8182V21.8182H2.18182V4.36364H3.27273V2.18182H0V24H24V2.18182Z" />
    <path d="M6.54559 0H4.36377V6.54545H6.54559V4.36364H16.3638V2.18182H6.54559V0Z" />
    <path d="M10.9089 8.72727H8.72705V10.9091H10.9089V8.72727Z" />
    <path d="M15.2726 8.72727H13.0908V10.9091H15.2726V8.72727Z" />
    <path d="M19.6364 8.72727H17.4546V10.9091H19.6364V8.72727Z" />
    <path d="M6.54559 13.0909H4.36377V15.2727H6.54559V13.0909Z" />
    <path d="M10.9089 13.0909H8.72705V15.2727H10.9089V13.0909Z" />
    <path d="M15.2726 13.0909H13.0908V15.2727H15.2726V13.0909Z" />
    <path d="M19.6364 13.0909H17.4546V15.2727H19.6364V13.0909Z" />
    <path d="M6.54559 17.4545H4.36377V19.6364H6.54559V17.4545Z" />
    <path d="M10.9089 17.4545H8.72705V19.6364H10.9089V17.4545Z" />
    <path d="M15.2726 17.4545H13.0908V19.6364H15.2726V17.4545Z" />
    <path d="M19.6364 17.4545H17.4546V19.6364H19.6364V17.4545Z" />
  </SvgIcon>
);

export default Calendar;
