import { TFunction } from 'react-i18next';
import { object, string } from 'yup';
import { correctEmailDomain } from 'utils/regexes';

const validationSchema = (t: TFunction<'translation'>) =>
  object({
    login: string()
      .required(t('ERRORS_COMMON_REQUIRED'))
      .email(t('ERRORS_COMMON_INVALID_EMAIL'))
      .matches(correctEmailDomain, t('ERRORS_COMMON_INVALID_EMAIL')),
    password: string().required(t('ERRORS_COMMON_REQUIRED'))
  });

export default validationSchema;
