export interface ColorGroups {
  [name: string]: ColorGroup;
}

export interface ColorGroup {
  [name: string]: Color;
}

export interface Color {
  name: string;
  value: string;
}

export const colors: ColorGroups = {
  beige: {
    primary: {
      name: 'Beige',
      value: '#D9AF7C'
    },
    alternative: {
      name: 'Beige Alternative',
      value: '#DFD2C2'
    }
  },
  olive: {
    primary: {
      name: 'Olive',
      value: '#96B75F'
    },
    alternative: {
      name: 'Olive Alternative',
      value: '#B7C3A0'
    }
  },
  yellow: {
    primary: {
      name: 'Yellow',
      value: '#CDC656'
    },
    alternative: {
      name: 'Yellow Alternative',
      value: '#DBD35C'
    }
  },
  green: {
    primary: {
      name: 'Primary Green',
      value: '#006D48'
    },
    alternative: {
      name: 'Green Alternative',
      value: '#58A765'
    },
    lightTeal: {
      name: 'Light Teal',
      value: '#F0F6F6'
    },
    midTeal: {
      name: 'Mid Teal',
      value: '#D4E0DF'
    },
    teal: {
      name: 'Teal',
      value: '#97B6B6'
    }
  },
  orange: {
    primary: {
      name: 'Orange',
      value: '#FBB13C'
    },
    alternative: {
      name: 'Orange Alternative',
      value: '#FFC871'
    },
    grey: {
      name: 'Orange Grey',
      value: '#EEE5D6'
    },
    light: {
      name: 'Orange Light',
      value: '#FFF6E5'
    }
  },
  red: {
    primary: {
      name: 'Red',
      value: '#E73F0D'
    },
    alternative: {
      name: 'Red Alternative',
      value: '#FF5D2D'
    },
    grey: {
      name: 'Red Grey',
      value: '#E9CDC5'
    },
    light: {
      name: 'Red Light',
      value: '#FEF1F1'
    }
  },
  blue: {
    primary: {
      name: 'Blue',
      value: '#13B5F5'
    },
    alternative: {
      name: 'Blue Alternative',
      value: '#5ECBF7'
    }
  },
  cobalt: {
    primary: {
      name: 'Cobalt',
      value: '#004EA3'
    },
    alternative: {
      name: 'Cobalt Alternative',
      value: '#3672B3'
    }
  },
  turquoise: {
    primary: {
      name: 'Turquoise',
      value: '#00849D'
    },
    alternative: {
      name: 'Turquoise Alternative',
      value: '#39A7BD'
    }
  },
  common: {
    text: {
      name: 'Text',
      value: '#262626'
    },
    white: {
      name: 'White',
      value: '#FFFFFF'
    },
    lightGrey: {
      name: 'Light Grey',
      value: '#F2F2F2'
    },
    grey: {
      name: 'Grey',
      value: '#BFBFBF'
    },
    darkGrey: {
      name: 'Dark Grey',
      value: '#808080'
    }
  }
};
