import { SvgIcon } from '@mui/material';

const Cancel = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4099)">
      <path d="M15.5398 7.05L11.9998 10.59L8.4598 7.05L7.0498 8.46L10.5898 12L7.0498 15.54L8.4598 16.95L11.9998 13.41L15.5398 16.95L16.9498 15.54L13.4098 12L16.9498 8.46L15.5398 7.05Z" />
      <path d="M12 -0.0199966C9.62663 -0.0199966 7.30655 0.683765 5.33316 2.00234C3.35977 3.32092 1.82171 5.19507 0.913457 7.38778C0.00520509 9.58049 -0.232443 11.9933 0.23058 14.3211C0.693603 16.6488 1.8365 18.7871 3.51473 20.4653C5.19296 22.1435 7.33115 23.2864 9.65893 23.7494C11.9867 24.2124 14.3995 23.9748 16.5922 23.0666C18.7849 22.1583 20.6591 20.6203 21.9776 18.6469C23.2962 16.6735 24 14.3534 24 11.98C24 8.79741 22.7357 5.74515 20.4853 3.49471C18.2348 1.24428 15.1826 -0.0199966 12 -0.0199966ZM12 21.98C10.0222 21.98 8.08879 21.3935 6.44429 20.2947C4.7998 19.1959 3.51808 17.6341 2.7612 15.8068C2.00433 13.9796 1.80631 11.9689 2.19216 10.0291C2.57801 8.08927 3.5304 6.30748 4.92893 4.90896C6.32745 3.51043 8.1093 2.55799 10.0491 2.17214C11.9889 1.78629 13.9996 1.98436 15.8268 2.74123C17.6541 3.49811 19.2159 4.77979 20.3147 6.42428C21.4135 8.06877 22 10.0022 22 11.98C22 14.6322 20.9464 17.1757 19.0711 19.051C17.1957 20.9264 14.6522 21.98 12 21.98Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4099">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Cancel;
