import getAppRoutes from 'App.routing';
import { Index, INDEX_DOUBLE_STEP, INDEX_SINGLE_STEP } from 'utils/consts';

const getHiddenPaths = () =>
  getAppRoutes(() => '')
    .filter((route) => route.desktopHideMenuBar)
    .map(({ path }) => {
      if (path.charAt(path.length - INDEX_SINGLE_STEP) === '*') {
        return path.slice(Index.zero, path.length - INDEX_DOUBLE_STEP);
      }
      return path;
    });

export default getHiddenPaths;
