import { IIllustration } from '../Illustration.model';

const QuestionMark = ({ width, height }: IIllustration) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_405_1007)">
      <path
        d="M6.76561 48.2195L4.39258 41.0768C4.39258 41.0768 7.00506 43.6798 7.00506 45.8164L6.53693 40.8726C6.53693 40.8726 7.87662 42.6749 7.74286 45.4112C7.60911 48.1475 6.76561 48.2195 6.76561 48.2195Z"
        fill="#E6E6E6"
      />
      <path
        d="M43.841 51.5147C43.841 56.2134 34.4923 54.7679 23.0435 54.7776C11.5948 54.7873 2.38312 56.2489 2.37988 51.5534C2.37665 46.8578 11.7274 45.8282 23.173 45.8132C34.6185 45.7981 43.8367 46.817 43.841 51.5147Z"
        fill="#E6E6E6"
      />
      <g clipPath="url(#clip1_405_1007)">
        <path
          d="M19.1411 35.2273L18.2631 35.5678C17.4752 35.8726 16.5988 35.8528 15.8259 35.5126C15.053 35.1724 14.4469 34.5396 14.1405 33.7531L13.8546 33.0159C13.142 31.1785 12.8995 29.5236 13.1207 28.0692C13.4126 26.3641 13.8762 24.6927 14.5045 23.0807C14.945 22.0739 15.294 21.0295 15.5471 19.9603C15.7103 19.143 15.63 18.2959 15.3162 17.524C14.6037 15.6867 13.0647 15.2637 10.6077 16.2166C9.25048 16.7619 7.97439 17.4906 6.81508 18.3823C6.04629 18.964 5.07798 19.2169 4.12305 19.0853C3.16812 18.9538 2.30475 18.4486 1.72279 17.6808C1.52267 17.4144 1.35953 17.1221 1.23781 16.812L0.65353 15.3054C0.401354 14.6642 0.340862 13.9635 0.479393 13.2884C0.617924 12.6132 0.949524 11.9927 1.43395 11.502C3.36169 9.62149 5.66248 8.16577 8.18769 7.22891C10.8171 6.20919 13.3647 5.72847 15.7547 5.8029C18.1448 5.87734 20.2481 6.48044 22.0003 7.58958C23.7427 8.68879 25.0774 10.3274 25.8009 12.2558C26.6025 14.3227 26.7341 16.3325 26.1985 18.2444C25.6628 20.1562 24.7171 22.3539 23.3665 24.8302C22.6507 26.12 22.0141 27.4521 21.4603 28.8192C21.1053 29.7101 20.9859 30.6772 21.1136 31.6274C21.2629 32.3634 21.1468 33.1286 20.7857 33.7874C20.4247 34.4463 19.8421 34.9564 19.1411 35.2273Z"
          fill="#58A765"
        />
        <path
          d="M24.0669 49.3734L23.9497 49.4188C22.5162 49.8708 20.9646 49.7607 19.6099 49.1108C18.2553 48.4609 17.1992 47.3199 16.6562 45.9196C16.1131 44.5194 16.1238 42.9647 16.686 41.5714C17.2483 40.1781 18.3199 39.0506 19.6834 38.4179L19.8006 38.3725C20.5345 38.0319 21.3305 37.8454 22.1393 37.8244C22.948 37.8034 23.7524 37.9484 24.5027 38.2505C25.253 38.5526 25.9333 39.0053 26.5014 39.5807C27.0696 40.1561 27.5135 40.8418 27.8058 41.5956C28.0982 42.3494 28.2327 43.1552 28.201 43.9632C28.1694 44.7712 27.9722 45.5641 27.6218 46.2931C27.2713 47.0221 26.7751 47.6715 26.1636 48.2014C25.5522 48.7312 24.8386 49.1301 24.0669 49.3734Z"
          fill="#58A765"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_405_1007">
        <rect width="43.8406" height="55" fill="white" />
      </clipPath>
      <clipPath id="clip1_405_1007">
        <rect
          width="24.5536"
          height="45.034"
          fill="white"
          transform="translate(-1.08398 10.8252) rotate(-21.1971)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default QuestionMark;
