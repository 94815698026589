import {
  AccountActivationImage,
  CreateAccountImage,
  ErrorImage,
  ExpiredPasswordImage,
  LoginImage,
  NewPasswordSuccessImage,
  NotFoundPageImage,
  OnboardingImage,
  ResetPasswordImage,
  UsedPasswordImage,
  WhyIsItWorth1Image,
  WhyIsItWorth2Image,
  ExclamationMark,
  Balloon,
  Faq,
  LanternGreenFemale,
  LanternGreenMale,
  LanternGreyFemale,
  LanternRedFemale,
  LanternGreyMale,
  LanternRedMale,
  LanternYellowFemale,
  LanternYellowMale,
  OfferSuccess,
  SettlementGreen,
  SettlementRed,
  SettlementYellow,
  SettlementRejected,
  FileTimeout,
  QuestionMark,
  InformationMark,
  CaseDetailsPageAnnexBox,
  CaseDetailsPageOfferAvailable,
  CaseDetailsPageNoOffer,
  SettledCaseSummary,
  PaymentSuccess,
  PaymentError,
  PaymentPending,
  Parachute
} from '../index';
import { ImageType } from '../Illustration.model';

export const getIllustrationComponent = (name: ImageType) => {
  const images = {
    lanternGreenFemale: LanternGreenFemale,
    lanternGreenMale: LanternGreenMale,
    lanternGreyFemale: LanternGreyFemale,
    lanternRedFemale: LanternRedFemale,
    lanternGreyMale: LanternGreyMale,
    lanternRedMale: LanternRedMale,
    lanternYellowFemale: LanternYellowFemale,
    lanternYellowMale: LanternYellowMale,
    createAccount: CreateAccountImage,
    accountActivation: AccountActivationImage,
    error: ErrorImage,
    login: LoginImage,
    expiredPassword: ExpiredPasswordImage,
    newPasswordSuccess: NewPasswordSuccessImage,
    resetPassword: ResetPasswordImage,
    usedPassword: UsedPasswordImage,
    notFoundPage: NotFoundPageImage,
    onboarding: OnboardingImage,
    whyIsItWorth1: WhyIsItWorth1Image,
    whyIsItWorth2: WhyIsItWorth2Image,
    exclamationMark: ExclamationMark,
    questionMark: QuestionMark,
    informationMark: InformationMark,
    faq: Faq,
    balloon: Balloon,
    settledCaseSummary: SettledCaseSummary,
    settlementGreen: SettlementGreen,
    settlementRed: SettlementRed,
    settlementYellow: SettlementYellow,
    settlementRejected: SettlementRejected,
    fileTimeout: FileTimeout,
    offerSuccess: OfferSuccess,
    caseDetailsPageAnnexBox: CaseDetailsPageAnnexBox,
    caseDetailsPageNoOffer: CaseDetailsPageNoOffer,
    caseDetailsPageOfferAvailable: CaseDetailsPageOfferAvailable,
    paymentSuccess: PaymentSuccess,
    paymentError: PaymentError,
    paymentPending: PaymentPending,
    parachute: Parachute
  };

  return images[name];
};
