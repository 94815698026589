import formatValue from './utils/formatValue';
import { CurrencyType, VariantType } from './formatCurrency.model';
import formatWithCurrency from './utils/formatWithCurrency';
import addSpaces from './utils/addSpaces';
import splitValue from './utils/splitValue';

const formatCurrency = (
  variant: VariantType,
  value: string | number,
  currencyType: CurrencyType = 'PLN',
  withCurrency: boolean = true,
  withSpaceBeforeCurrency: boolean = true,
  withSpaces: boolean = true,
  withComma: boolean = false
) => {
  if (value === '') {
    return '';
  }
  let formattedValue = formatValue(value.toString());
  if (withSpaces) {
    formattedValue = addSpaces(formattedValue);
  }
  formattedValue = formatWithCurrency(
    formattedValue,
    withCurrency,
    withSpaceBeforeCurrency,
    currencyType
  );
  const { integer, afterDecimal } = splitValue(formattedValue);

  switch (variant) {
    case 'plain':
      return withComma ? formattedValue.replace('.', ',') : formattedValue;
    case 'component':
      return { integer, afterDecimal };
    default:
      return null;
  }
};
export default formatCurrency;
