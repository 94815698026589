import { Typography } from '@mui/material';

const ChangeOfThePrivacyPolicy = () => (
  <>
    <Typography variant="h5" fontWeight="700">
      ZMIANA POLITYKI PRYWATNOŚCI I COOKIES
    </Typography>
    <Typography variant="body1">
      Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce w
      dowolnym czasie. Zmiany mogą okazać się konieczne w szczególności w
      przypadku zmiany prawa bądź wykorzystywanych w Serwisie rozwiązań
      informatycznych i technologicznych.
    </Typography>
    <Typography variant="body1" sx={{ marginTop: '10px' }}>
      Informacje o treści zmian Polityki są niezwłocznie publikowane na stronie
      internetowej Serwisu i wchodzą w życie z dniem ich opublikowania na
      stronie internetowej Serwisu.
    </Typography>
  </>
);

export default ChangeOfThePrivacyPolicy;
