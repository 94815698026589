export const LanternGreyMale = ({ ...props }) => (
  <svg
    {...props}
    width="163"
    height="139"
    viewBox="0 0 163 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5160_25204)">
      <path
        d="M13.3352 130.192C17.9771 139.041 24.8759 139.379 31.3745 135.984C37.8732 132.589 41.5329 126.748 36.8935 117.899C32.2542 109.05 14.5688 103.938 14.5688 103.938C14.5688 103.938 11.7478 112.33 11.5605 120.422L19.8339 125.497L11.6272 123.297C11.7965 125.83 12.3094 128.222 13.3352 130.192Z"
        fill="#F2F2F2"
      />
      <path
        d="M18.7798 119.276C18.7182 119.127 18.6567 118.974 18.5977 118.82C18.3994 118.326 18.2045 117.822 18.013 117.311C17.2436 115.264 16.5896 113.284 16.0383 111.491C15.9895 111.335 15.9434 111.176 15.8972 111.025C14.8714 107.656 14.2661 105.018 14.0507 104.043C13.9917 103.788 13.9635 103.639 13.9609 103.632L14.1994 103.58L14.4354 103.532C14.4354 103.532 14.4354 103.585 14.4636 103.66C14.5995 104.286 15.233 107.101 16.364 110.848C16.4101 111.005 16.4563 111.161 16.505 111.317C17.1846 113.527 18.0438 116.039 19.0516 118.6C19.1106 118.751 19.1722 118.905 19.2337 119.053C20.0159 120.99 20.893 122.944 21.8701 124.819C21.9419 124.965 22.0214 125.108 22.0984 125.249C24.4398 129.66 27.3301 133.566 30.7744 135.6L30.6538 135.807L30.5256 136.02L30.3871 135.938C27.2455 134.029 24.3142 130.519 21.6291 125.464L21.4008 125.027C20.4981 123.287 19.6244 121.37 18.7798 119.276Z"
        fill="white"
      />
      <path
        d="M115.697 111.03L115.228 110.854C112.972 109.957 111.007 108.461 109.544 106.528L109.088 106.73C110.317 108.393 111.909 109.754 113.745 110.71C114.181 110.939 114.629 111.143 115.086 111.322C115.24 111.383 115.397 111.442 115.553 111.496C116.84 111.952 118.189 112.211 119.554 112.263C119.523 112.097 119.492 111.933 119.464 111.772C118.179 111.71 116.909 111.46 115.697 111.03Z"
        fill="white"
      />
      <path
        d="M11.1953 119.887C11.1953 120.053 11.1953 120.217 11.1953 120.381C13.7813 120.747 16.4181 120.363 18.7916 119.276C18.9455 119.204 19.0968 119.13 19.2481 119.053L19.4328 118.961C21.3569 117.957 23.0135 116.51 24.265 114.739C25.5164 112.969 26.3269 110.927 26.629 108.781C26.4777 108.697 26.3238 108.615 26.1699 108.526C25.903 110.629 25.1302 112.636 23.9173 114.376C22.7044 116.117 21.0875 117.539 19.2045 118.521C19.1609 118.546 19.1122 118.57 19.066 118.593C18.9173 118.669 18.7634 118.743 18.6095 118.813C16.296 119.884 13.7189 120.258 11.1953 119.887Z"
        fill="white"
      />
      <path
        d="M11.5039 125.188C11.5347 125.374 11.5655 125.561 11.6039 125.743C14.8609 126.922 18.4454 126.823 21.6315 125.464C21.7879 125.395 21.9443 125.326 22.1008 125.249C22.2572 125.172 22.429 125.093 22.5906 125.006C25.1417 123.672 27.2061 121.569 28.4903 118.997C29.7744 116.425 30.2129 113.515 29.7433 110.68C29.5586 110.554 29.3714 110.424 29.1816 110.309C29.7467 113.104 29.3877 116.007 28.1587 118.582C26.9296 121.157 24.8967 123.264 22.3649 124.589C22.2008 124.673 22.0367 124.753 21.8725 124.832C21.7084 124.911 21.5597 124.975 21.4032 125.039C18.2486 126.372 14.6972 126.426 11.5039 125.188Z"
        fill="white"
      />
      <path
        d="M117.777 130.959C113.138 139.808 106.236 140.146 99.7376 136.751C93.2389 133.356 89.5793 127.516 94.2212 118.667C98.8631 109.818 116.533 104.706 116.533 104.706C116.533 104.706 119.354 113.097 119.544 121.189L111.278 126.265L119.485 124.065C119.321 126.597 118.81 128.989 117.777 130.959Z"
        fill="#F2F2F2"
      />
      <path
        d="M112.329 120.043C112.39 119.895 112.449 119.741 112.508 119.588C112.705 119.093 112.9 118.59 113.093 118.078C113.862 116.032 114.519 114.052 115.068 112.258C115.116 112.102 115.162 111.944 115.211 111.793C116.237 108.423 116.84 105.786 117.058 104.811C117.117 104.555 117.145 104.407 117.147 104.399L116.909 104.348L116.67 104.299C116.663 104.342 116.654 104.385 116.642 104.427C116.509 105.054 115.873 107.868 114.742 111.616C114.698 111.772 114.65 111.928 114.601 112.084C113.921 114.295 113.062 116.807 112.057 119.368L111.872 119.821C111.103 121.757 110.213 123.712 109.238 125.587C109.164 125.733 109.087 125.876 109.01 126.017C106.666 130.427 103.776 134.334 100.334 136.368L100.455 136.575L100.583 136.787L100.719 136.705C103.863 134.797 106.794 131.287 109.479 126.232L109.708 125.794C110.612 124.054 111.486 122.137 112.329 120.043Z"
        fill="white"
      />
      <path
        d="M115.214 111.798C115.058 111.741 114.902 111.685 114.743 111.621C114.315 111.451 113.896 111.258 113.488 111.045C111.75 110.142 110.237 108.861 109.062 107.295L108.605 107.497C110.134 109.558 112.211 111.149 114.601 112.089C114.758 112.151 114.914 112.209 115.071 112.263C116.358 112.72 117.707 112.978 119.072 113.031C119.041 112.864 119.01 112.701 118.982 112.539C117.696 112.478 116.427 112.228 115.214 111.798Z"
        fill="white"
      />
      <path
        d="M112.512 119.588C112.358 119.519 112.206 119.444 112.058 119.368L111.917 119.296C110.034 118.313 108.417 116.891 107.205 115.151C105.992 113.411 105.219 111.403 104.951 109.301L104.492 109.557C104.797 111.7 105.608 113.741 106.86 115.51C108.112 117.279 109.768 118.725 111.691 119.728C111.753 119.759 111.812 119.792 111.873 119.82C112.027 119.897 112.178 119.971 112.332 120.043C114.705 121.13 117.341 121.514 119.926 121.148V120.654C117.403 121.028 114.826 120.657 112.512 119.588Z"
        fill="white"
      />
      <path
        d="M109.707 125.794C109.548 125.73 109.392 125.658 109.238 125.587C109.084 125.515 108.907 125.428 108.745 125.344C106.217 124.02 104.186 121.915 102.957 119.344C101.727 116.773 101.366 113.874 101.926 111.081C101.739 111.204 101.549 111.337 101.364 111.452C100.895 114.287 101.334 117.198 102.619 119.77C103.904 122.342 105.968 124.445 108.52 125.779C108.681 125.866 108.845 125.948 109.009 126.022C109.174 126.096 109.322 126.168 109.479 126.237C112.665 127.595 116.249 127.695 119.506 126.516C119.545 126.334 119.576 126.147 119.606 125.96C116.411 127.193 112.859 127.133 109.707 125.794Z"
        fill="white"
      />
      <path
        d="M79.9591 134.367C77.1945 139.635 73.0911 139.837 69.2186 137.813C65.3461 135.789 63.1713 132.315 65.9334 127.048C68.6954 121.78 79.223 118.736 79.223 118.736C79.223 118.736 80.9105 123.732 81.0183 128.549L76.0917 131.571L80.9823 130.261C80.8772 131.768 80.5746 133.193 79.9591 134.367Z"
        fill="#F2F2F2"
      />
      <path
        d="M76.714 127.866C76.7525 127.779 76.7884 127.687 76.8243 127.595C76.9423 127.304 77.0577 127.002 77.1705 126.697C77.6296 125.482 78.0194 124.3 78.3477 123.233L78.4323 122.954C79.035 120.949 79.4017 119.373 79.5299 118.8C79.5658 118.644 79.5838 118.559 79.5838 118.544L79.4427 118.513L79.3017 118.485C79.3017 118.485 79.3017 118.516 79.2863 118.562C79.2042 118.933 78.8298 120.608 78.1553 122.839L78.0707 123.118C77.6655 124.436 77.1603 125.932 76.555 127.455C76.5191 127.544 76.4832 127.636 76.4447 127.726C75.9805 128.877 75.4574 130.041 74.8778 131.156C74.8342 131.243 74.788 131.33 74.7419 131.412C73.3467 134.055 71.6233 136.375 69.5742 137.585L69.646 137.708L69.7204 137.836L69.8025 137.785C71.6746 136.652 73.4185 134.561 75.0188 131.55L75.1548 131.295C75.6933 130.256 76.2131 129.113 76.714 127.866Z"
        fill="white"
      />
      <path
        d="M78.4321 122.954C78.3398 122.921 78.2449 122.888 78.1526 122.849C77.8978 122.75 77.6487 122.636 77.4063 122.509C76.3707 121.97 75.4697 121.206 74.7699 120.273L74.498 120.393C75.2282 121.385 76.1766 122.196 77.2704 122.765C77.5296 122.9 77.7958 123.021 78.068 123.128C78.1603 123.167 78.2526 123.2 78.3475 123.233C79.1133 123.503 79.9158 123.655 80.7274 123.683L80.6736 123.392C79.9089 123.357 79.1536 123.209 78.4321 122.954Z"
        fill="white"
      />
      <path
        d="M76.8247 127.595C76.7324 127.554 76.6426 127.511 76.5529 127.465L76.4682 127.424C75.3465 126.839 74.3834 125.992 73.6613 124.955C72.9391 123.919 72.4793 122.723 72.3213 121.47L72.0469 121.621C72.2277 122.898 72.7108 124.113 73.456 125.167C74.2012 126.22 75.1872 127.082 76.3323 127.679L76.44 127.736L76.7119 127.866C78.1248 128.516 79.6958 128.744 81.2358 128.524C81.2358 128.426 81.2358 128.329 81.2358 128.232C79.7346 128.453 78.2013 128.232 76.8247 127.595Z"
        fill="white"
      />
      <path
        d="M75.1554 131.292C75.0605 131.253 74.9682 131.21 74.8759 131.166L74.581 131.023C73.0785 130.235 71.8718 128.983 71.141 127.455C70.4103 125.926 70.1946 124.203 70.5264 122.542L70.1904 122.762C69.9118 124.45 70.1735 126.182 70.9382 127.713C71.7029 129.244 72.9318 130.495 74.4502 131.289C74.5476 131.34 74.6425 131.389 74.7425 131.433C74.8426 131.476 74.9272 131.522 75.0221 131.56C76.9177 132.371 79.0518 132.431 80.9899 131.727C81.0129 131.619 81.0309 131.509 81.0514 131.397C79.1473 132.129 77.0321 132.091 75.1554 131.292Z"
        fill="white"
      />
      <path
        d="M4.48797 92.5439H2.94922V93.8231H4.48797V92.5439Z"
        fill="#F0F0F0"
      />
      <path
        d="M150.028 93.8231H147.005V92.5439H150.028V93.8231ZM143.963 93.8231H140.94V92.5439H143.971L143.963 93.8231ZM137.901 93.8231H134.874V92.5439H137.908L137.901 93.8231ZM131.835 93.8231H128.809V92.5439H131.843L131.835 93.8231ZM125.77 93.8231H122.746V92.5439H125.778L125.77 93.8231ZM119.705 93.8231H116.689V92.5439H119.72L119.705 93.8231ZM113.642 93.8231H110.616V92.5439H113.65L113.642 93.8231ZM107.577 93.8231H104.553V92.5439H107.585L107.577 93.8231ZM101.512 93.8231H98.4803V92.5439H101.512V93.8231ZM95.4489 93.8231H92.4227V92.5439H95.4566L95.4489 93.8231ZM89.3837 93.8231H86.3601V92.5439H89.3914L89.3837 93.8231ZM83.3185 93.8231H80.2948V92.5439H83.3261L83.3185 93.8231ZM77.2558 93.8231H74.2296V92.5439H77.2635L77.2558 93.8231ZM71.1905 93.8231H68.1643V92.5439H71.1982L71.1905 93.8231ZM65.1253 93.8231H62.1016V92.5439H65.1407L65.1253 93.8231ZM59.06 93.8231H56.0364V92.5439H59.0677L59.06 93.8231ZM52.9973 93.8231H49.9711V92.5439H53.005L52.9973 93.8231ZM46.9321 93.8231H43.9084V92.5439H46.9321V93.8231ZM40.8669 93.8231H37.8432V92.5439H40.8745L40.8669 93.8231ZM34.8042 93.8231H31.778V92.5439H34.8119L34.8042 93.8231ZM28.7389 93.8231H25.7153V92.5439H28.7466L28.7389 93.8231ZM22.6737 93.8231H19.65V92.5439H22.6814L22.6737 93.8231ZM16.6084 93.8231H13.5925V92.5439H16.6238L16.6084 93.8231ZM10.5457 93.8231H7.51953V92.5439H10.5534L10.5457 93.8231Z"
        fill="#F0F0F0"
      />
      <path
        d="M4.48797 60.0535H2.94922V61.3326H4.48797V60.0535Z"
        fill="#F0F0F0"
      />
      <path
        d="M150.028 61.3326H147.005V60.0535H150.028V61.3326ZM143.963 61.3326H140.94V60.0535H143.971L143.963 61.3326ZM137.901 61.3326H134.874V60.0535H137.908L137.901 61.3326ZM131.835 61.3326H128.809V60.0535H131.843L131.835 61.3326ZM125.77 61.3326H122.746V60.0535H125.778L125.77 61.3326ZM119.705 61.3326H116.689V60.0535H119.72L119.705 61.3326ZM113.642 61.3326H110.616V60.0535H113.65L113.642 61.3326ZM107.577 61.3326H104.553V60.0535H107.585L107.577 61.3326ZM101.512 61.3326H98.4803V60.0535H101.512V61.3326ZM95.4489 61.3326H92.4227V60.0535H95.4566L95.4489 61.3326ZM89.3837 61.3326H86.3601V60.0535H89.3914L89.3837 61.3326ZM83.3185 61.3326H80.2948V60.0535H83.3261L83.3185 61.3326ZM77.2558 61.3326H74.2296V60.0535H77.2635L77.2558 61.3326ZM71.1905 61.3326H68.1643V60.0535H71.1982L71.1905 61.3326ZM65.1253 61.3326H62.1016V60.0535H65.1407L65.1253 61.3326ZM59.06 61.3326H56.0364V60.0535H59.0677L59.06 61.3326ZM52.9973 61.3326H49.9711V60.0535H53.005L52.9973 61.3326ZM46.9321 61.3326H43.9084V60.0535H46.9321V61.3326ZM40.8669 61.3326H37.8432V60.0535H40.8745L40.8669 61.3326ZM34.8042 61.3326H31.778V60.0535H34.8119L34.8042 61.3326ZM28.7389 61.3326H25.7153V60.0535H28.7466L28.7389 61.3326ZM22.6737 61.3326H19.65V60.0535H22.6814L22.6737 61.3326ZM16.6084 61.3326H13.5925V60.0535H16.6238L16.6084 61.3326ZM10.5457 61.3326H7.51953V60.0535H10.5534L10.5457 61.3326Z"
        fill="#F0F0F0"
      />
      <path
        d="M4.48797 27.563H2.94922V28.8421H4.48797V27.563Z"
        fill="#F0F0F0"
      />
      <path
        d="M150.028 28.8421H147.005V27.563H150.028V28.8421ZM143.963 28.8421H140.94V27.563H143.971L143.963 28.8421ZM137.901 28.8421H134.874V27.563H137.908L137.901 28.8421ZM131.835 28.8421H128.809V27.563H131.843L131.835 28.8421ZM125.77 28.8421H122.746V27.563H125.778L125.77 28.8421ZM119.705 28.8421H116.689V27.563H119.72L119.705 28.8421ZM113.642 28.8421H110.616V27.563H113.65L113.642 28.8421ZM107.577 28.8421H104.553V27.563H107.585L107.577 28.8421ZM101.512 28.8421H98.4803V27.563H101.512V28.8421ZM95.4489 28.8421H92.4227V27.563H95.4566L95.4489 28.8421ZM89.3837 28.8421H86.3601V27.563H89.3914L89.3837 28.8421ZM83.3185 28.8421H80.2948V27.563H83.3261L83.3185 28.8421ZM77.2558 28.8421H74.2296V27.563H77.2635L77.2558 28.8421ZM71.1905 28.8421H68.1643V27.563H71.1982L71.1905 28.8421ZM65.1253 28.8421H62.1016V27.563H65.1407L65.1253 28.8421ZM59.06 28.8421H56.0364V27.563H59.0677L59.06 28.8421ZM52.9973 28.8421H49.9711V27.563H53.005L52.9973 28.8421ZM46.9321 28.8421H43.9084V27.563H46.9321V28.8421ZM40.8669 28.8421H37.8432V27.563H40.8745L40.8669 28.8421ZM34.8042 28.8421H31.778V27.563H34.8119L34.8042 28.8421ZM28.7389 28.8421H25.7153V27.563H28.7466L28.7389 28.8421ZM22.6737 28.8421H19.65V27.563H22.6814L22.6737 28.8421ZM16.6084 28.8421H13.5925V27.563H16.6238L16.6084 28.8421ZM10.5457 28.8421H7.51953V27.563H10.5534L10.5457 28.8421Z"
        fill="#F0F0F0"
      />
      <path
        d="M101.332 92.9275H88.7656V138.977H101.332V92.9275Z"
        fill="#3F3D56"
      />
      <path
        d="M99.0235 92.9275H86.457V138.977H99.0235V92.9275Z"
        fill="#D4E0DF"
      />
      <path
        d="M71.5821 60.4373H59.0156V138.977H71.5821V60.4373Z"
        fill="#3F3D56"
      />
      <path
        d="M69.2755 60.4373H56.709V138.977H69.2755V60.4373Z"
        fill="#D4E0DF"
      />
      <path
        d="M41.8341 27.9468H29.2676V138.977H41.8341V27.9468Z"
        fill="#3F3D56"
      />
      <path
        d="M39.5255 27.9468H26.959V138.977H39.5255V27.9468Z"
        fill="#D4E0DF"
      />
      <path
        d="M3.0775 31.2727C4.77716 31.2727 6.15501 29.8983 6.15501 28.2028C6.15501 26.5073 4.77716 25.1328 3.0775 25.1328C1.37785 25.1328 0 26.5073 0 28.2028C0 29.8983 1.37785 31.2727 3.0775 31.2727Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.0775 63.763C4.77716 63.763 6.15501 62.3885 6.15501 60.693C6.15501 58.9975 4.77716 57.623 3.0775 57.623C1.37785 57.623 0 58.9975 0 60.693C0 62.3885 1.37785 63.763 3.0775 63.763Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.0775 96.2534C4.77716 96.2534 6.15501 94.879 6.15501 93.1835C6.15501 91.488 4.77716 90.1135 3.0775 90.1135C1.37785 90.1135 0 91.488 0 93.1835C0 94.879 1.37785 96.2534 3.0775 96.2534Z"
        fill="#F0F0F0"
      />
      <path
        d="M99.022 57.1199C97.9058 57.1199 97.001 55.914 97.001 54.4264C97.001 52.9388 97.9058 51.7329 99.022 51.7329C100.138 51.7329 101.043 52.9388 101.043 54.4264C101.043 55.914 100.138 57.1199 99.022 57.1199Z"
        fill="#E6E6E6"
      />
      <path
        d="M123.116 79.1854C123.394 78.8744 123.6 78.5066 123.72 78.108C123.839 77.7093 123.869 77.2894 123.808 76.8779C123.746 76.4664 123.595 76.0733 123.364 75.7263C123.133 75.3793 122.829 75.0867 122.472 74.8693L126.195 57.2493L121.261 59.135L118.404 75.9683C118.076 76.5883 117.988 77.3063 118.158 77.9862C118.327 78.666 118.741 79.2606 119.322 79.6571C119.903 80.0536 120.61 80.2246 121.309 80.1377C122.008 80.0507 122.651 79.7119 123.116 79.1854Z"
        fill="#FFB8B8"
      />
      <path
        d="M124.757 134.914L120.695 134.914L118.763 119.342L124.758 119.342L124.757 134.914Z"
        fill="#FFB6B6"
      />
      <path
        d="M125.795 133.596L124.652 133.596L120.188 131.792L117.796 133.595H117.795C116.443 133.595 115.147 134.129 114.191 135.079C113.235 136.03 112.698 137.318 112.698 138.662V138.827L125.795 138.827L125.795 133.596Z"
        fill="#2F2E41"
      />
      <path
        d="M161.459 129.665L157.824 131.466L149.105 118.386L154.471 115.729L161.459 129.665Z"
        fill="#FFB6B6"
      />
      <path
        d="M161.795 128.026L160.771 128.533L155.967 128.897L154.635 131.572L154.635 131.573C153.425 132.172 152.504 133.225 152.075 134.499C151.646 135.773 151.744 137.165 152.347 138.367L152.421 138.515L164.143 132.708L161.795 128.026Z"
        fill="#2F2E41"
      />
      <path
        d="M122.958 64.1936L148.785 64.8632L148.336 77.3628C148.336 77.3628 148.785 80.7108 147.887 81.6037C146.988 82.4965 146.539 82.4965 146.988 84.0589C147.438 85.6214 148.599 100.55 148.599 100.55C148.599 100.55 159.199 119.178 158.749 119.624C158.3 120.071 157.851 119.624 158.3 120.74C158.749 121.856 159.199 121.41 158.749 121.856C158.352 122.283 157.977 122.73 157.627 123.196H150.306C150.306 123.196 149.897 120.964 149.897 120.74C149.897 120.517 149.448 119.178 149.448 118.955C149.448 118.732 136.658 105.263 136.658 105.263L133.963 82.9429L127.45 104.817C127.45 104.817 127.45 124.013 127.001 124.459C126.552 124.906 126.552 124.682 126.776 125.575C127.001 126.468 127.899 126.245 127.225 126.914C126.552 127.584 126.327 126.468 126.552 127.584L126.776 128.7L118.148 128.796C118.148 128.796 117.25 126.914 117.699 126.245C118.148 125.575 118.121 125.758 117.573 124.662C117.025 123.566 116.801 123.343 117.025 123.12C117.25 122.897 117.25 121.71 117.25 121.71C117.25 121.71 120.488 77.8092 120.488 77.1395C120.505 76.795 120.441 76.4512 120.302 76.1354V75.2265L121.162 72.0058L122.958 64.1936Z"
        fill="#2F2E41"
      />
      <path
        d="M136.33 20.3577L123.394 46.3599L125.925 77.9169L142.456 76.4157L145.42 24.1551L136.33 20.3577Z"
        fill="#2F2E41"
      />
      <path
        d="M147.648 87.974H147.625C143.737 87.3344 139.418 87.3344 134.418 87.974C134.293 87.976 134.17 87.9535 134.054 87.9077C133.939 87.8619 133.834 87.7938 133.745 87.7072C133.656 87.6206 133.585 87.5173 133.536 87.4033C133.488 87.2893 133.462 87.1667 133.461 87.0428V82.5171C133.461 82.2681 133.56 82.0293 133.737 81.8532C133.913 81.6772 134.153 81.5782 134.402 81.5782C138.805 81.0052 143.264 81.0052 147.666 81.5782C147.913 81.5816 148.149 81.6821 148.323 81.8579C148.496 82.0337 148.593 82.2706 148.592 82.5171V87.0326C148.591 87.282 148.492 87.5211 148.315 87.6975C148.138 87.8739 147.898 87.9733 147.648 87.974Z"
        fill="white"
      />
      <path
        d="M147.648 87.974H147.625C143.737 87.3344 139.418 87.3344 134.418 87.974C134.293 87.976 134.17 87.9535 134.054 87.9077C133.939 87.8619 133.834 87.7938 133.745 87.7072C133.656 87.6206 133.585 87.5173 133.536 87.4033C133.488 87.2893 133.462 87.1667 133.461 87.0428V82.5171C133.461 82.2681 133.56 82.0293 133.737 81.8532C133.913 81.6772 134.153 81.5782 134.402 81.5782C138.805 81.0052 143.264 81.0052 147.666 81.5782C147.913 81.5816 148.149 81.6821 148.323 81.8579C148.496 82.0337 148.593 82.2706 148.592 82.5171V87.0326C148.591 87.282 148.492 87.5211 148.315 87.6975C148.138 87.8739 147.898 87.9733 147.648 87.974ZM140.855 81.4043C138.702 81.4007 136.551 81.5435 134.418 81.8315C134.327 81.8301 134.236 81.8467 134.152 81.8802C134.067 81.9136 133.99 81.9634 133.925 82.0266C133.86 82.0898 133.807 82.1652 133.771 82.2484C133.735 82.3317 133.716 82.4213 133.715 82.512V87.0326C133.715 87.214 133.787 87.388 133.915 87.5165C134.044 87.645 134.218 87.7175 134.4 87.7182C139.403 87.0735 143.745 87.0735 147.659 87.7182C147.839 87.7148 148.01 87.6411 148.136 87.5129C148.262 87.3847 148.333 87.2122 148.333 87.0326V82.5171C148.333 82.336 148.261 82.1622 148.132 82.0341C148.004 81.906 147.83 81.8341 147.648 81.8341C145.395 81.5515 143.126 81.408 140.855 81.4043Z"
        fill="#D4E0DF"
      />
      <path
        d="M141.026 86.3497C142.017 86.3497 142.821 85.5479 142.821 84.5589C142.821 83.5698 142.017 82.7681 141.026 82.7681C140.034 82.7681 139.23 83.5698 139.23 84.5589C139.23 85.5479 140.034 86.3497 141.026 86.3497Z"
        fill="#D4E0DF"
      />
      <path
        d="M136.408 83.6251H134.869C134.767 83.6251 134.669 83.5847 134.597 83.5127C134.525 83.4407 134.484 83.3431 134.484 83.2413V82.7297C134.484 82.6279 134.525 82.5303 134.597 82.4583C134.669 82.3864 134.767 82.3459 134.869 82.3459H136.408C136.51 82.3459 136.608 82.3864 136.68 82.4583C136.752 82.5303 136.793 82.6279 136.793 82.7297V83.2413C136.793 83.3431 136.752 83.4407 136.68 83.5127C136.608 83.5847 136.51 83.6251 136.408 83.6251Z"
        fill="#D4E0DF"
      />
      <path
        d="M147.179 87.2066H145.641C145.539 87.2066 145.441 87.1662 145.369 87.0942C145.296 87.0223 145.256 86.9247 145.256 86.8229V86.3112C145.256 86.2095 145.296 86.1118 145.369 86.0399C145.441 85.9679 145.539 85.9275 145.641 85.9275H147.179C147.281 85.9275 147.379 85.9679 147.451 86.0399C147.523 86.1118 147.564 86.2095 147.564 86.3112V86.8229C147.564 86.9247 147.523 87.0223 147.451 87.0942C147.379 87.1662 147.281 87.2066 147.179 87.2066Z"
        fill="#D4E0DF"
      />
      <path
        d="M145.459 86.1066H145.441C142.107 84.0062 138.127 82.3305 133.269 80.99C133.038 80.8943 132.854 80.711 132.758 80.4804C132.662 80.2498 132.661 79.9905 132.756 79.7594L134.508 75.5741C134.556 75.4605 134.626 75.3575 134.714 75.271C134.802 75.1845 134.907 75.1161 135.021 75.0698C135.136 75.0234 135.258 75.0001 135.382 75.001C135.506 75.0019 135.628 75.0271 135.742 75.0752C140.021 76.2547 144.129 77.9841 147.962 80.2199C148.188 80.3194 148.366 80.5037 148.457 80.733C148.549 80.9623 148.546 81.2182 148.449 81.4453L146.687 85.6051C146.59 85.8332 146.406 86.0138 146.176 86.1077C145.946 86.2015 145.689 86.2011 145.459 86.1066Z"
        fill="white"
      />
      <path
        d="M145.459 86.1066H145.441C142.107 84.0062 138.127 82.3305 133.269 80.99C133.038 80.8943 132.854 80.711 132.758 80.4804C132.662 80.2498 132.661 79.9905 132.756 79.7594L134.508 75.5741C134.556 75.4605 134.626 75.3575 134.714 75.271C134.802 75.1845 134.907 75.1161 135.021 75.0698C135.136 75.0234 135.258 75.0001 135.382 75.001C135.506 75.0019 135.628 75.0271 135.742 75.0752C140.021 76.2547 144.129 77.9841 147.962 80.2199C148.188 80.3194 148.366 80.5037 148.457 80.733C148.549 80.9623 148.546 81.2182 148.449 81.4453L146.687 85.6051C146.59 85.8332 146.406 86.0138 146.176 86.1077C145.946 86.2015 145.689 86.2011 145.459 86.1066ZM141.763 77.4084C139.781 76.5702 137.743 75.8676 135.665 75.3054C135.496 75.2374 135.306 75.2385 135.138 75.3083C134.97 75.3782 134.835 75.5113 134.765 75.6789L133.005 79.8387C132.934 80.006 132.933 80.1945 133.001 80.3628C133.069 80.5312 133.202 80.6656 133.369 80.7367C138.242 82.0849 142.23 83.7683 145.582 85.8789C145.749 85.9468 145.936 85.9462 146.103 85.8772C146.269 85.8082 146.402 85.6764 146.472 85.5105L148.234 81.3507C148.304 81.183 148.305 80.9945 148.236 80.8263C148.167 80.6581 148.035 80.5238 147.867 80.4527C145.902 79.3196 143.868 78.308 141.779 77.4237L141.763 77.4084Z"
        fill="#D4E0DF"
      />
      <path
        d="M140.686 82.1822C141.677 82.1822 142.481 81.3804 142.481 80.3914C142.481 79.4024 141.677 78.6006 140.686 78.6006C139.694 78.6006 138.891 79.4024 138.891 80.3914C138.891 81.3804 139.694 82.1822 140.686 82.1822Z"
        fill="#D4E0DF"
      />
      <path
        d="M136.798 77.7386L135.382 77.1425C135.335 77.1227 135.292 77.0937 135.256 77.0571C135.22 77.0205 135.191 76.9772 135.172 76.9296C135.153 76.8819 135.144 76.831 135.145 76.7797C135.145 76.7284 135.157 76.6778 135.177 76.6308L135.375 76.1601C135.395 76.1128 135.424 76.0699 135.46 76.0339C135.497 75.9979 135.54 75.9695 135.588 75.9505C135.636 75.9314 135.687 75.9221 135.738 75.9229C135.79 75.9238 135.84 75.9348 135.888 75.9554L137.306 76.5515C137.353 76.5713 137.396 76.6003 137.432 76.6369C137.468 76.6734 137.497 76.7168 137.516 76.7644C137.535 76.8121 137.544 76.863 137.544 76.9143C137.543 76.9656 137.532 77.0162 137.511 77.0632L137.311 77.5339C137.291 77.5812 137.262 77.6241 137.225 77.6601C137.189 77.6961 137.145 77.7245 137.097 77.7435C137.05 77.7626 136.999 77.7719 136.947 77.7711C136.896 77.7702 136.845 77.7592 136.798 77.7386Z"
        fill="#D4E0DF"
      />
      <path
        d="M145.326 85.2165L143.911 84.6332C143.863 84.6137 143.82 84.5848 143.783 84.5483C143.747 84.5118 143.719 84.4684 143.699 84.4207C143.68 84.373 143.671 84.3219 143.672 84.2705C143.673 84.2191 143.684 84.1685 143.705 84.1215L143.903 83.6508C143.923 83.6035 143.952 83.5606 143.988 83.5246C144.025 83.4886 144.069 83.4603 144.116 83.4412C144.164 83.4222 144.215 83.4128 144.267 83.4136C144.318 83.4145 144.369 83.4255 144.416 83.4462L145.834 84.0422C145.881 84.062 145.924 84.091 145.961 84.1276C145.997 84.1642 146.025 84.2075 146.044 84.2551C146.063 84.3028 146.073 84.3537 146.072 84.405C146.071 84.4563 146.06 84.5069 146.039 84.5539L145.839 85.0246C145.818 85.071 145.789 85.1129 145.751 85.1477C145.714 85.1826 145.671 85.2097 145.623 85.2275C145.575 85.2454 145.524 85.2536 145.473 85.2517C145.423 85.2498 145.372 85.2378 145.326 85.2165Z"
        fill="#D4E0DF"
      />
      <path
        d="M118.167 74.433L125.726 73.7462L125.738 73.6509L130.811 31.9054L129.675 28.7772C129.404 28.0345 128.943 27.3743 128.339 26.8607C127.735 26.3471 127.007 25.9976 126.227 25.8461V25.846C125.632 25.7322 125.021 25.7364 124.428 25.8584C123.835 25.9805 123.273 26.2179 122.773 26.557C122.272 26.8961 121.845 27.3302 121.514 27.8344C121.184 28.3385 120.957 28.9026 120.846 29.4943L119.489 36.7821L117.563 61.4173L118.167 74.433Z"
        fill="#2F2E41"
      />
      <path
        d="M137.848 24.2138L136.323 20.5886L131.605 20.671L126.685 27.588L121.521 49.1725C121.521 49.1725 119.666 64.9399 121.521 65.7723C123.375 66.6046 129.662 65.5767 129.662 65.5767L137.848 24.2138Z"
        fill="#97B6B6"
      />
      <path
        d="M131.604 20.6709C131.604 20.6709 121.44 26.5104 121.042 29.5586C120.644 32.6068 119.313 69.9151 119.313 69.9151L122.908 67.6068L123.89 54.0092L128.277 26.1696L131.604 20.6709Z"
        fill="#2F2E41"
      />
      <path
        d="M134.005 18.9724C130.179 18.9724 127.077 15.89 127.077 12.0876C127.077 8.28529 130.179 5.20288 134.005 5.20288C137.83 5.20288 140.932 8.28529 140.932 12.0876C140.932 15.89 137.83 18.9724 134.005 18.9724Z"
        fill="#FFB8B8"
      />
      <path
        d="M141.862 7.56402C141.084 6.36801 139.624 5.57091 138.209 5.7949C138.078 4.89737 137.701 4.05295 137.119 3.35359C136.538 2.65423 135.775 2.12673 134.913 1.82854C134.052 1.53035 133.124 1.47288 132.231 1.66241C131.339 1.85193 130.516 2.28118 129.852 2.90341C129.634 2.75061 129.379 2.65643 129.113 2.63C128.848 2.60357 128.58 2.64578 128.335 2.75254C127.848 2.97264 127.424 3.31064 127.103 3.73562C126.291 4.742 125.769 5.94838 125.592 7.22634C125.415 8.50429 125.59 9.806 126.099 10.9929C125.926 10.368 126.556 9.78021 127.192 9.64C127.829 9.49978 128.497 9.63243 129.142 9.54112C129.969 9.42412 130.713 8.94708 131.542 8.83972C132.238 8.7495 132.939 8.92696 133.618 9.10485C134.297 9.28272 134.995 9.46412 135.693 9.38182C136.39 9.29951 137.289 11.9265 137.277 13.7768C137.274 14.1274 137.347 14.5776 137.692 14.652C138.116 14.7437 138.363 14.1612 138.767 14.0025C138.953 13.9495 139.153 13.9703 139.324 14.0608C139.495 14.1512 139.624 14.3041 139.684 14.4872C139.735 14.6734 139.74 14.869 139.7 15.0577C139.659 15.2464 139.574 15.4227 139.451 15.5721C139.203 15.8682 138.908 16.1224 138.578 16.325L138.744 16.4626C139.021 16.8261 139.595 16.8419 139.994 16.617C140.376 16.3601 140.685 16.0104 140.892 15.6012C141.63 14.3807 142.278 13.0803 142.538 11.68C142.799 10.2797 142.64 8.76002 141.862 7.56402Z"
        fill="#2F2E41"
      />
      <path
        d="M149.95 55.9121C149.693 55.6391 149.338 55.4784 148.962 55.4652C148.587 55.4521 148.221 55.5876 147.946 55.8421L141.261 62.0351C140.986 62.29 140.824 62.6431 140.81 63.0166C140.797 63.3902 140.934 63.7537 141.19 64.0271C141.447 64.3006 141.802 64.4616 142.178 64.4747C142.554 64.4878 142.919 64.352 143.194 64.0972L149.88 57.9041C150.154 57.6491 150.316 57.2961 150.329 56.9226C150.342 56.5492 150.206 56.1858 149.95 55.9121Z"
        fill="#E6E6E6"
      />
      <path
        d="M144.858 80.4381C145.136 80.1271 145.342 79.7593 145.462 79.3607C145.581 78.962 145.611 78.5421 145.55 78.1306C145.489 77.7191 145.337 77.326 145.106 76.979C144.876 76.632 144.571 76.3394 144.214 76.122L147.938 58.502L143.003 60.3877L140.146 77.221C139.818 77.841 139.731 78.559 139.9 79.2389C140.069 79.9187 140.483 80.5133 141.064 80.9098C141.645 81.3063 142.352 81.4773 143.051 81.3904C143.751 81.3034 144.394 80.9646 144.858 80.4381Z"
        fill="#FFB8B8"
      />
      <path
        d="M147.592 73.2881L149.601 60.4271L150.355 35.7269L149.795 28.3237C149.749 27.7237 149.585 27.1385 149.311 26.602C149.037 26.0655 148.658 25.5881 148.198 25.1975C147.737 24.8069 147.204 24.5107 146.628 24.3259C146.051 24.1412 145.444 24.0716 144.841 24.1212C144.049 24.1883 143.287 24.458 142.631 24.9039C141.974 25.3499 141.445 25.9571 141.095 26.6664L139.627 29.6551L140.151 71.7966L147.592 73.2881Z"
        fill="#2F2E41"
      />
      <path
        d="M138.863 15.7712C138.833 15.8298 138.762 15.8534 138.703 15.8238C138.644 15.7942 138.62 15.7227 138.65 15.6641L138.865 15.2395C138.895 15.1809 138.967 15.1573 139.026 15.1869C139.085 15.2164 139.108 15.2879 139.079 15.3466L138.863 15.7712Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <clipPath id="clip0_5160_25204">
        <rect width="163" height="139" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LanternGreyMale;
