const SettledCaseSummary = ({ ...props }) => (
  <svg
    {...props}
    width="264"
    height="211"
    viewBox="0 0 264 211"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.29356 63.9956C3.89011 63.9972 2.54461 64.5548 1.55222 65.5461C0.559836 66.5375 0.00160714 67.8815 0 69.2834V99.0012C0.00159787 100.403 0.559814 101.747 1.5522 102.739C2.54458 103.73 3.89009 104.288 5.29356 104.289H95.333C96.7364 104.288 98.0819 103.73 99.0743 102.739C100.067 101.747 100.625 100.403 100.627 99.0012V69.2834C100.625 67.8815 100.067 66.5375 99.0743 65.5461C98.0819 64.5548 96.7364 63.9972 95.333 63.9956H5.29356Z"
      fill="#F0F7F6"
    />
    <path
      d="M127.375 98.8631H23.7256C23.1164 98.8624 22.5323 98.6203 22.1015 98.19C21.6708 97.7597 21.4284 97.1762 21.4277 96.5677V69.287C21.4284 68.6785 21.6708 68.095 22.1016 67.6647C22.5323 67.2344 23.1164 66.9924 23.7256 66.9917H127.375C127.985 66.9924 128.569 67.2344 128.999 67.6647C129.43 68.095 129.673 68.6785 129.673 69.287V96.5677C129.673 97.1762 129.43 97.7597 128.999 98.19C128.569 98.6203 127.985 98.8624 127.375 98.8631Z"
      fill="white"
    />
    <path
      d="M127.375 99.2965H23.7257C23.0015 99.2957 22.3072 99.008 21.7951 98.4964C21.283 97.9849 20.995 97.2913 20.9941 96.5679V69.2872C20.995 68.5638 21.283 67.8702 21.7951 67.3587C22.3072 66.8472 23.0015 66.5594 23.7257 66.5586H127.375C128.1 66.5594 128.794 66.8472 129.306 67.3587C129.818 67.8702 130.106 68.5638 130.107 69.2872V96.5679C130.106 97.2913 129.818 97.9849 129.306 98.4964C128.794 99.008 128.1 99.2957 127.375 99.2965ZM23.7257 67.4251C23.2315 67.4257 22.7577 67.6221 22.4083 67.9712C22.0588 68.3203 21.8622 68.7936 21.8616 69.2872V96.5679C21.8622 97.0616 22.0588 97.5349 22.4083 97.884C22.7577 98.2331 23.2315 98.4294 23.7257 98.43H127.375C127.87 98.4294 128.344 98.2331 128.693 97.884C129.042 97.5349 129.239 97.0616 129.24 96.5679V69.2872C129.239 68.7936 129.042 68.3203 128.693 67.9712C128.344 67.6221 127.87 67.4257 127.375 67.4251L23.7257 67.4251Z"
      fill="#CCCCCC"
    />
    <path
      d="M32.5394 79.9227C34.5988 79.9227 36.2682 78.2551 36.2682 76.1979C36.2682 74.1408 34.5988 72.4731 32.5394 72.4731C30.48 72.4731 28.8105 74.1408 28.8105 76.1979C28.8105 78.2551 30.48 79.9227 32.5394 79.9227Z"
      fill="#97B6B6"
    />
    <path
      d="M121.18 85.584H29.9209C29.6264 85.584 29.344 85.4672 29.1358 85.2592C28.9275 85.0512 28.8105 84.769 28.8105 84.4749C28.8105 84.1807 28.9275 83.8986 29.1358 83.6906C29.344 83.4826 29.6264 83.3657 29.9209 83.3657H121.18C121.474 83.3657 121.757 83.4826 121.965 83.6906C122.173 83.8986 122.29 84.1807 122.29 84.4749C122.29 84.769 122.173 85.0512 121.965 85.2592C121.757 85.4672 121.474 85.584 121.18 85.584Z"
      fill="#97B6B6"
    />
    <path
      d="M112.112 89.967H29.9212C29.7752 89.9673 29.6306 89.9388 29.4956 89.8832C29.3606 89.8276 29.238 89.7459 29.1346 89.6429C29.0313 89.5398 28.9493 89.4175 28.8933 89.2827C28.8374 89.148 28.8086 89.0036 28.8086 88.8578C28.8086 88.7119 28.8374 88.5675 28.8933 88.4328C28.9493 88.2981 29.0313 88.1757 29.1346 88.0726C29.238 87.9696 29.3606 87.888 29.4956 87.8323C29.6306 87.7767 29.7752 87.7482 29.9212 87.7485H112.112C112.407 87.7485 112.689 87.8654 112.897 88.0734C113.106 88.2814 113.223 88.5636 113.223 88.8578C113.223 89.1519 113.106 89.4341 112.897 89.6421C112.689 89.8501 112.407 89.967 112.112 89.967Z"
      fill="#97B6B6"
    />
    <path
      d="M241.753 162.053L158.423 100.481C157.933 100.119 157.608 99.577 157.518 98.9751C157.428 98.3732 157.58 97.7602 157.942 97.2705L174.183 75.338C174.546 74.8491 175.088 74.524 175.69 74.434C176.293 74.344 176.907 74.4963 177.397 74.8577L260.727 136.43C261.216 136.792 261.542 137.334 261.632 137.936C261.722 138.537 261.57 139.15 261.208 139.64L244.967 161.573C244.604 162.061 244.062 162.387 243.459 162.477C242.857 162.567 242.243 162.414 241.753 162.053Z"
      fill="white"
    />
    <path
      d="M241.495 162.401L158.165 100.829C157.583 100.398 157.196 99.7546 157.089 99.0392C156.982 98.3237 157.163 97.595 157.593 97.0129L173.834 75.0803C174.265 74.4992 174.91 74.1127 175.626 74.0057C176.342 73.8986 177.072 74.0797 177.655 74.5093L260.985 136.081C261.567 136.512 261.953 137.156 262.061 137.871C262.168 138.587 261.987 139.316 261.556 139.898L245.316 161.83C244.884 162.411 244.24 162.798 243.523 162.905C242.807 163.012 242.078 162.831 241.495 162.401ZM177.139 75.2059C176.741 74.9128 176.243 74.7892 175.754 74.8623C175.266 74.9354 174.826 75.1991 174.532 75.5956L158.291 97.5282C157.997 97.9254 157.873 98.4227 157.947 98.911C158.02 99.3992 158.284 99.8386 158.681 100.133L242.011 161.705C242.409 161.998 242.906 162.121 243.395 162.048C243.884 161.975 244.324 161.712 244.618 161.315L260.859 139.382C261.153 138.985 261.276 138.488 261.203 138C261.13 137.511 260.866 137.072 260.469 136.778L177.139 75.2059Z"
      fill="#CCCCCC"
    />
    <path
      d="M179.002 91.2191C181.061 91.2191 182.731 89.5515 182.731 87.4943C182.731 85.4372 181.061 83.7695 179.002 83.7695C176.942 83.7695 175.273 85.4372 175.273 87.4943C175.273 89.5515 176.942 91.2191 179.002 91.2191Z"
      fill="#97B6B6"
    />
    <path
      d="M244.677 147.697L171.309 93.4853C171.072 93.3103 170.915 93.0486 170.871 92.7577C170.828 92.4668 170.901 92.1704 171.077 91.9339C171.252 91.6974 171.514 91.5401 171.805 91.4966C172.096 91.4531 172.393 91.5269 172.63 91.7018L245.998 145.913C246.115 146 246.214 146.109 246.289 146.233C246.364 146.358 246.414 146.497 246.436 146.641C246.457 146.785 246.45 146.932 246.415 147.073C246.38 147.214 246.317 147.347 246.23 147.464C246.144 147.582 246.035 147.68 245.91 147.756C245.785 147.831 245.646 147.88 245.502 147.902C245.358 147.923 245.211 147.916 245.069 147.881C244.928 147.846 244.794 147.783 244.677 147.697Z"
      fill="#97B6B6"
    />
    <path
      d="M234.778 145.834L168.7 97.0088C168.582 96.9222 168.483 96.8133 168.408 96.6885C168.333 96.5636 168.283 96.4252 168.262 96.2812C168.218 95.9902 168.292 95.6939 168.467 95.4574C168.642 95.2209 168.904 95.0636 169.196 95.02C169.487 94.9765 169.784 95.0503 170.02 95.2253L236.099 144.05C236.335 144.225 236.493 144.487 236.536 144.778C236.58 145.069 236.506 145.365 236.331 145.601C236.156 145.838 235.894 145.995 235.602 146.039C235.311 146.082 235.015 146.009 234.778 145.834L234.778 145.834Z"
      fill="#97B6B6"
    />
    <path
      d="M202.841 97.2312C202.383 96.8638 202.01 96.4012 201.748 95.8756C201.487 95.35 201.342 94.7739 201.325 94.1871C201.308 93.6003 201.419 93.0169 201.65 92.4771C201.881 91.9373 202.227 91.4541 202.663 91.0609L196.094 71.9543L200.938 68.8398L208.574 91.59C209.182 92.373 209.482 93.3515 209.416 94.3401C209.35 95.3288 208.923 96.2589 208.216 96.9543C207.509 97.6496 206.572 98.0619 205.581 98.1129C204.591 98.164 203.616 97.8502 202.841 97.2312Z"
      fill="#FFB6B6"
    />
    <path
      d="M197.235 44.5134C198.395 49.4161 200.127 53.6987 199.892 55.7554C199.66 57.8065 202.169 59.7773 201.372 61.3986C200.576 63.0239 203.026 63.1978 202.364 65.8136C201.699 68.4349 201.267 68.9955 201.828 69.4269C202.393 69.8567 205.38 79.8616 205.38 79.8616L203.628 80.5029L198.517 82.3655L186.506 62.6317L186.542 38.4096L189.525 38.314C190.241 38.5822 191.034 38.9039 191.825 39.2916C194.24 40.4565 196.684 42.1784 197.235 44.5134Z"
      fill="#E6E6E6"
    />
    <path
      d="M153.007 198.208L146.761 198.207L143.789 174.142L153.007 174.143L153.007 198.208Z"
      fill="#FFB6B6"
    />
    <path
      d="M134.154 207.413C134.158 208.112 134.436 208.782 134.931 209.276C135.425 209.771 136.095 210.051 136.794 210.056H148.552L148.916 209.301L150.58 205.873L151.224 209.301L151.366 210.056H155.801L155.738 209.296L154.555 195.13L153.008 195.035L146.324 194.64L144.682 194.54V198.769C143.436 200.093 133.658 205.098 134.154 207.413Z"
      fill="#2F2E41"
    />
    <path
      d="M226.181 191.416L220.748 194.495L206.275 175.029L214.293 170.485L226.181 191.416Z"
      fill="#FFB6B6"
    />
    <path
      d="M214.332 208.716C214.677 209.325 215.25 209.772 215.925 209.959C216.601 210.145 217.322 210.057 217.932 209.713L218.55 209.365H218.555L228.159 203.917L227.853 199.281L230.609 202.53L232.641 201.375L234.463 200.341L232.853 197.989L226.005 187.974L224.611 188.659L218.598 191.607L217.13 192.33L219.215 196.006C218.788 197.778 212.758 206.944 214.332 208.716Z"
      fill="#2F2E41"
    />
    <path
      d="M184.003 80.0765C192.373 85.6167 200.658 138.845 198.484 137.976L218.211 174.701L190.132 187.274L171.669 123.237L168.592 146.803L172.695 184.201L154.545 188.585L141.991 187.689C145.253 143.112 153.398 96.0964 160.147 77.9102L184.003 80.0765Z"
      fill="#2F2E41"
    />
    <path
      d="M190.358 84.0293L190.449 84.3716C181.6 89.2892 174.925 92.5343 165.253 89.5578L158.825 83.7781L155.828 72.2316L156.014 43.1161C156.021 41.998 156.259 40.8932 156.711 39.8704C157.163 38.8476 157.821 37.9286 158.644 37.1705C159.467 36.4124 160.437 35.8313 161.494 35.4633C162.551 35.0953 163.673 34.9483 164.789 35.0314L171.147 30.3262L180.199 30.6381L186.254 37.3191L189.872 38.6102L192.682 39.611C193.251 40.1223 193.871 40.7115 194.465 41.3614L190.019 67.7906L188.128 75.6933L190.358 84.0293Z"
      fill="#E6E6E6"
    />
    <path
      d="M181.261 27.8425C186.882 24.7549 188.933 17.7004 185.842 12.0858C182.751 6.47125 175.689 4.42278 170.068 7.51041C164.447 10.5981 162.397 17.6526 165.488 23.2671C168.579 28.8817 175.641 30.9301 181.261 27.8425Z"
      fill="#FFB6B6"
    />
    <path
      d="M190.936 13.6344C190.901 13.6475 190.871 13.6605 190.836 13.6736C190.77 13.7029 190.702 13.7276 190.633 13.7478C190.19 13.9103 189.728 14.0162 189.258 14.0632C189.058 14.0848 188.857 14.0955 188.656 14.095C188.397 14.0959 188.139 14.0792 187.884 14.045C187.599 14.0101 187.317 13.9529 187.042 13.8738C186.911 13.8388 186.782 13.7972 186.655 13.7492C185.793 13.4432 185.014 12.9417 184.378 12.284C183.743 11.6262 183.269 10.8303 182.994 9.95868V9.95435C182.964 9.86913 182.939 9.78242 182.919 9.69459C182.79 9.20693 182.725 8.70469 182.725 8.20031V8.19164C182.721 6.96169 183.105 5.76184 183.822 4.76174C184.254 4.14974 184.753 4.04745 184.473 3.34764C183.58 1.11529 187.235 -0.709558 189.393 0.272017C189.471 0.264599 189.549 0.261504 189.627 0.262748C191.08 0.259079 191.619 2.4024 192.485 3.60901L192.46 3.61718C192.241 3.69389 192.051 3.83716 191.917 4.02696C191.783 4.21676 191.712 4.4436 191.714 4.67575C191.715 4.9079 191.789 5.13375 191.926 5.32173C192.062 5.50972 192.254 5.65043 192.474 5.72419C193.051 5.91188 193.634 6.07768 194.222 6.22158C196.164 8.51217 193.938 15.2134 190.936 13.6344Z"
      fill="#2F2E41"
    />
    <path
      d="M189.867 19.7766C189.227 22.2174 188.045 24.5991 186.084 26.1854C184.119 27.7718 174.877 31.5299 174.096 28.3127C174.618 30.5601 174.304 31.4187 173.464 32.2224C173.321 32.0278 173.168 31.8419 173.012 31.6603C172.435 30.9964 171.743 30.4419 170.969 30.0234C170.949 31.3808 171.047 32.7374 171.261 34.078C170.915 34.3968 170.586 34.7331 170.275 35.0857C168.78 30.6355 165.504 25.3053 167.876 21.2532C168.817 19.6451 168.033 19.4248 167.199 19.2402C165.743 18.918 164.94 12.9506 164.594 11.5C164.164 9.6814 163.994 7.58915 165.123 6.09586C166.251 4.60258 169.105 4.53903 169.682 6.31828L170.883 4.68618C172.436 1.27245 176.558 1.95525 180.285 2.39642C184.012 2.83328 187.302 5.45056 188.981 8.80414C190.655 12.1577 190.816 16.1477 189.867 19.7766Z"
      fill="#2F2E41"
    />
    <path
      d="M183.992 4.18285C183.913 6.24138 184.441 8.27774 185.511 10.0388C186.581 11.7999 188.146 13.208 190.01 14.0881C190.767 14.4308 191.427 13.3093 190.664 12.9642C188.998 12.1965 187.598 10.9516 186.642 9.38746C185.685 7.82336 185.216 6.01067 185.293 4.17956C185.336 3.3436 184.035 3.34942 183.992 4.18284L183.992 4.18285Z"
      fill="#97B6B6"
    />
    <path
      d="M130.234 85.0961C130.8 84.9391 131.325 84.6611 131.774 84.2814C132.222 83.9016 132.582 83.4292 132.829 82.8967C133.077 82.3642 133.205 81.7844 133.206 81.1973C133.207 80.6103 133.08 80.0301 132.835 79.4967L146.419 64.5304L143.199 59.7593L127.195 77.6529C126.327 78.1326 125.665 78.9137 125.335 79.8481C125.005 80.7826 125.03 81.8054 125.405 82.7229C125.779 83.6404 126.478 84.3887 127.368 84.8261C128.258 85.2635 129.278 85.3596 130.234 85.0961H130.234Z"
      fill="#FFB6B6"
    />
    <path
      d="M168.848 36.5421C168.848 36.5421 159.213 34.8138 156.211 38.8652C153.208 42.9166 149.925 46.1664 149.328 48.1465C148.73 50.1267 145.648 50.9451 145.739 52.7513C145.83 54.5575 143.508 53.7507 143.085 56.4185C142.661 59.0863 142.836 59.774 142.147 59.948C141.459 60.122 134.765 68.1358 134.765 68.1358L140.081 73.1423L158.911 59.7443L168.848 36.5421Z"
      fill="#E6E6E6"
    />
    <path
      d="M234.73 209.96L234.672 209.651C233.729 204.459 233.93 199.124 235.26 194.017C236.589 188.91 239.016 184.153 242.372 180.078C244.273 177.807 246.452 175.784 248.859 174.057L249.088 173.892L249.188 174.156C250.385 177.308 251.896 180.466 252.585 181.863L253.049 171.431L253.315 171.29C254.529 170.64 255.911 170.368 257.282 170.51C258.653 170.651 259.95 171.199 261.006 172.083C262.099 172.96 262.903 174.144 263.316 175.482C263.729 176.82 263.732 178.251 263.324 179.59C262.963 180.816 262.622 182.087 262.292 183.317C261.158 187.542 259.987 191.911 257.716 195.59C255.969 198.358 253.626 200.701 250.857 202.449C248.088 204.196 244.963 205.304 241.711 205.691L234.73 209.96Z"
      fill="#F2F2F2"
    />
  </svg>
);

export default SettledCaseSummary;
