import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import reducers from './statement.reducers';
import effects from './statement.effects';
import { StatementState } from './statement.model';

const model = createModel<RootModel>()({
  state: {
    statements: [],
    consents: []
  } as StatementState,
  reducers,
  effects
});

export default model;
