import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps
} from '@mui/material';
import theme from 'App.theme';
import { RootState } from 'storage/store';
import { blockBodyPosition, resetBodyPosition } from 'utils/bodyPosition';
import { Icon } from 'components/Icon';
import { EMPTY_ARRAY_LENGTH } from 'utils/consts';
import { ModalContainer } from './styled/ModalContainer';
import { ModalContentWrapper } from './styled/ModalContentWrapper';

export interface IModal extends DialogProps {
  heading?: JSX.Element | string;
  content?: JSX.Element | string;
  actions?: JSX.Element;
  withoutCloseIcon?: boolean;
}

export interface IModalContent {
  fullScreen?: boolean;
}

const Modal = ({
  heading,
  content,
  actions,
  withoutCloseIcon = false,
  children,
  ...props
}: IModal) => {
  const { statements } = useSelector((state: RootState) => state.statements);

  const handleClose = (
    event: Object,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason !== 'backdropClick') {
      props.onClose?.(event, reason);
    }
  };

  useEffect(() => {
    const cookies = localStorage.getItem('cookiesStatus') === 'true' ?? false;
    const isNotAcceptedStatement =
      statements.filter((statement) => !statement.accepted).length >
      EMPTY_ARRAY_LENGTH;

    if (props.open || !cookies || isNotAcceptedStatement) {
      blockBodyPosition();
      return;
    }
    if (document.body.style.position === 'fixed' && !props.open && cookies) {
      resetBodyPosition();
    }
  }, [props.open]);

  return (
    <ModalContainer
      {...props}
      disableEscapeKeyDown
      disableScrollLock
      onClose={(event, reason) => handleClose(event, reason)}
    >
      <DialogTitle>
        {heading}
        {!withoutCloseIcon ? (
          <Icon
            icon="cancel"
            color="primary"
            fontSize="medium"
            onClick={(event: Object) => handleClose(event, 'escapeKeyDown')}
            sx={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.light
              }
            }}
          />
        ) : null}
      </DialogTitle>
      <ModalContentWrapper fullScreen={props.fullScreen}>
        <DialogContent>{children || content}</DialogContent>
      </ModalContentWrapper>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </ModalContainer>
  );
};

export default Modal;
