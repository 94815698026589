import { CurrencySign, CurrencyType } from '../formatCurrency.model';

const formatWithCurrency = (
  value: string,
  withCurrency: boolean,
  withSpaceBeforeCurrency: boolean,
  currencyType: CurrencyType
) => {
  const currencySign =
    currencyType in CurrencySign ? CurrencySign[currencyType] : currencyType;

  if (withCurrency && withSpaceBeforeCurrency) {
    return value.concat(' ', currencySign);
  }
  if (withCurrency && !withSpaceBeforeCurrency) {
    return value.concat(currencySign);
  }
  return value;
};

export default formatWithCurrency;
