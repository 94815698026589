import styled from 'styled-components/macro';
import { Container, ContainerProps } from '@mui/material';

interface IStyledContainer extends ContainerProps {
  $additionalHeightToCut?: number;
  $windowHeight: number;
}

export const Wrapper = styled(Container)<IStyledContainer>(
  ({ $additionalHeightToCut, $windowHeight }) => ({
    position: 'relative',
    height: '100%',
    width: '100%',
    maxHeight: `calc(${$windowHeight}px - ${$additionalHeightToCut}px)`,
    overflow: 'hidden'
  })
);
