import { SvgIcon } from '@mui/material';

const ExclamationMark = ({ ...props }) => (
  <SvgIcon viewBox="0 0 31 119" fill="none" {...props}>
    <path
      d="M10.1503 117.656C17.5562 120.452 25.826 116.714 28.6215 109.308C31.417 101.902 27.6796 93.6326 20.2737 90.8371C12.8679 88.0416 4.59806 91.779 1.80256 99.1848C-0.992943 106.591 2.74448 114.861 10.1503 117.656Z"
      fill="white"
    />
    <path
      d="M13.0562 79.498C11.1974 78.7966 9.5818 77.5714 8.40497 75.9708C7.22814 74.3702 6.54053 72.4627 6.4254 70.4794L3.2322 14.5385C3.12635 12.685 3.40071 10.8295 4.0384 9.08598C4.67609 7.34246 5.66364 5.74778 6.94034 4.39999C8.21704 3.05219 9.75591 1.97976 11.4624 1.24861C13.1688 0.517466 15.0067 0.143059 16.8632 0.148408C18.7197 0.153757 20.5555 0.538749 22.2577 1.27972C23.9599 2.02068 25.4925 3.10197 26.7614 4.4571C28.0304 5.81223 29.0087 7.41256 29.6363 9.15973C30.264 10.9069 30.5276 12.764 30.4111 14.6168L26.9026 70.5477C26.8023 72.1479 26.3283 73.7023 25.5186 75.0861C24.7089 76.47 23.586 77.6448 22.2402 78.5162C20.8943 79.3877 19.3629 79.9315 17.7689 80.104C16.1749 80.2765 14.5626 80.073 13.0615 79.5097L13.0562 79.498Z"
      fill="white"
    />
  </SvgIcon>
);

export default ExclamationMark;
