import { useLocation } from 'react-router-dom';
import { Constant } from 'utils/consts';

const LastUrl = () => {
  const { pathname } = useLocation();
  const pattern = /^\/dashboard\/case\/([0-9]+)\/payment$/;
  const patternDone = /^\/dashboard\/payment\/done$/;
  const patternNotFoundAndError = /\bnot-found\b|\berror\b/;
  if (
    pathname &&
    !pattern.test(pathname) &&
    !patternDone.test(pathname) &&
    !patternNotFoundAndError.test(pathname)
  ) {
    localStorage.setItem('urlBeforePayment', pathname);
  }

  const offerPattern =
    /^\/(\bnot-found\b|\berror\b)|(dashboard\/case\/([0-9]+)\/offer\/?.*)$/;

  if (window.history.length <= Constant.two) {
    localStorage.removeItem('urlBeforeOffers');
  } else if (!offerPattern.test(pathname)) {
    localStorage.setItem('urlBeforeOffers', pathname);
  }

  return null;
};

export default LastUrl;
