import { Route, Routes as ReactRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { RouteGuard } from 'components';
import { IRoutes } from './Routes.model';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(ReactRoutes);

const Routes = ({ routes }: IRoutes) => (
  <SentryRoutes>
    {routes.map((route) => (
      <Route element={<RouteGuard {...route} />} key={route.key}>
        <Route
          path={route.path}
          element={
            <Sentry.ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag('path', route.path);
              }}
            >
              {route.element}
            </Sentry.ErrorBoundary>
          }
        />
      </Route>
    ))}
  </SentryRoutes>
);

export default Routes;
