import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import setDefaultPosition from 'utils/setDefaultPosition';

const ScrollToTop = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  useEffect(() => {
    setDefaultPosition();
    window.requestAnimationFrame(() => {
      window.scrollTo({ top: 0 });
    });
  }, [location]);

  return children;
};

export default ScrollToTop;
