import { UserStatus, User, UserPayload, TestGroupTypes } from './user.model';

const userReducers = {
  changeUserStatus: (state: User, status: UserStatus) => ({
    ...state,
    userStatus: status
  }),
  changeUserData: (state: User, userData: UserPayload) => ({
    ...state,
    ...userData.data
  }),
  changeToken: (state: User, token: string) => ({
    ...state,
    token
  }),
  clearUserData: (state: User) => ({
    ...state,
    email: '',
    firstName: '',
    lastName: ''
  }),
  storeTestGroup: (state: User, testGroup: TestGroupTypes) => ({
    ...state,
    testGroup
  }),
  setImpersonation: (state: User, impersonation: boolean) => ({
    ...state,
    impersonation
  })
};

export default userReducers;
