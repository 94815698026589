import { SvgIcon } from '@mui/material';

const Location = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4152)">
      <path d="M8.91779 0C6.55264 0 4.28436 0.939543 2.61195 2.61195C0.939543 4.28436 0 6.55264 0 8.91779C0 13.5104 7.24569 22.0604 8.07059 23.0191L8.90663 24L9.75383 23.0191C10.5787 22.0604 17.8244 13.5104 17.8244 8.91779C17.8244 6.55457 16.8864 4.28799 15.2164 2.6159C13.5464 0.943809 11.281 0.00295402 8.91779 0ZM8.91779 20.5555C6.48769 17.5569 2.22945 11.738 2.22945 8.91779C2.22945 7.14393 2.9341 5.44275 4.18841 4.18845C5.44272 2.93414 7.14393 2.22945 8.91779 2.22945C10.6916 2.22945 12.3929 2.93414 13.6472 4.18845C14.9015 5.44275 15.6061 7.14393 15.6061 8.91779C15.6061 11.738 11.3367 17.5569 8.91779 20.5555Z" />
      <path d="M8.91787 4.45889C8.03598 4.45889 7.17389 4.72042 6.44063 5.21037C5.70737 5.70031 5.13586 6.39665 4.79838 7.21141C4.4609 8.02617 4.37262 8.9227 4.54467 9.78764C4.71672 10.6526 5.14137 11.4471 5.76495 12.0707C6.38854 12.6943 7.18304 13.119 8.04798 13.291C8.91292 13.4631 9.80946 13.3747 10.6242 13.0372C11.439 12.6998 12.1353 12.1283 12.6253 11.395C13.1152 10.6618 13.3768 9.79967 13.3768 8.91779C13.3768 7.73521 12.907 6.60107 12.0708 5.76487C11.2346 4.92866 10.1004 4.45889 8.91787 4.45889ZM8.91787 11.1472C8.47693 11.1472 8.04588 11.0165 7.67925 10.7715C7.31262 10.5266 7.02688 10.1784 6.85814 9.77098C6.6894 9.3636 6.64523 8.91529 6.73125 8.48282C6.81728 8.05035 7.02962 7.65316 7.34141 7.34136C7.65321 7.02957 8.05047 6.81723 8.48294 6.7312C8.91541 6.64518 9.36368 6.68928 9.77106 6.85803C10.1784 7.02677 10.5266 7.31254 10.7716 7.67917C11.0166 8.0458 11.1473 8.47684 11.1473 8.91779C11.1473 9.50907 10.9124 10.0761 10.4943 10.4942C10.0762 10.9123 9.50916 11.1472 8.91787 11.1472Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4152">
        <rect width="17.8244" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Location;
