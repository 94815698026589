const OfferAvailable = ({ ...props }) => (
  <svg
    {...props}
    width="264"
    height="163"
    viewBox="0 0 264 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1995_3720)">
      <path
        d="M12.8168 170.551C6.43648 162.075 2.74878 152.597 2.62794 141.791C2.42238 141.208 2.4565 140.568 2.7228 140.011C2.98911 139.454 3.46585 139.025 4.04845 138.818L25.7959 131.133C26.379 130.927 27.0198 130.961 27.5777 131.227C28.1357 131.493 28.5652 131.97 28.7721 132.551L38.9609 161.312C39.1665 161.895 39.1324 162.535 38.8661 163.092C38.5998 163.649 38.123 164.078 37.5404 164.285L15.793 171.97C15.2099 172.175 14.5691 172.141 14.0111 171.875C13.4532 171.609 13.0237 171.133 12.8168 170.551Z"
        fill="#E6E6E6"
      />
      <path
        d="M11.4077 161.274C7.57028 157.398 5.92767 150.487 4.85731 142.784C4.66479 142.238 4.69673 141.639 4.94614 141.117C5.19554 140.595 5.64203 140.193 6.18766 140L24.8778 133.395C25.4239 133.202 26.024 133.234 26.5466 133.483C27.0692 133.733 27.4714 134.178 27.6652 134.723L36.7319 160.317C36.9244 160.862 36.8925 161.461 36.643 161.983C36.3936 162.505 35.9471 162.907 35.4015 163.1L23.8233 167.192C21.3909 168.049 18.7177 167.907 16.3902 166.797C14.0626 165.687 12.2707 163.701 11.4077 161.274V161.274Z"
        fill="white"
      />
      <path
        d="M27.9152 148.511L13.356 153.702C13.1244 153.785 12.8693 153.772 12.647 153.667C12.4247 153.561 12.2534 153.372 12.1707 153.141C12.088 152.909 12.1007 152.655 12.2061 152.433C12.3114 152.211 12.5007 152.04 12.7324 151.957L27.2916 146.766C27.5232 146.684 27.778 146.697 28.0001 146.802C28.2222 146.908 28.3934 147.097 28.476 147.328C28.5587 147.559 28.546 147.814 28.4409 148.036C28.3357 148.257 28.1466 148.429 27.9152 148.511V148.511Z"
        fill="#97B6B6"
      />
      <path
        d="M14.849 147.775L11.646 148.917C11.5312 148.958 11.4095 148.976 11.2878 148.97C11.166 148.964 11.0467 148.934 10.9365 148.882C10.8264 148.83 10.7275 148.757 10.6457 148.667C10.5639 148.576 10.5007 148.471 10.4598 148.356C10.4188 148.242 10.4008 148.12 10.4069 147.998C10.413 147.877 10.4431 147.758 10.4953 147.648C10.5476 147.538 10.621 147.439 10.7115 147.357C10.8019 147.276 10.9075 147.213 11.0224 147.172L14.2254 146.03C14.457 145.948 14.7118 145.961 14.9339 146.066C15.156 146.171 15.3272 146.36 15.4098 146.592C15.4925 146.823 15.4798 147.077 15.3747 147.299C15.2695 147.521 15.0804 147.692 14.849 147.775V147.775Z"
        fill="#97B6B6"
      />
      <path
        d="M30.6565 156.182L16.0973 161.373C15.8657 161.455 15.6109 161.442 15.3888 161.337C15.1667 161.232 14.9955 161.043 14.9129 160.811C14.8302 160.58 14.8429 160.326 14.948 160.104C15.0532 159.882 15.2423 159.711 15.4737 159.628L30.0329 154.437C30.2645 154.355 30.5193 154.368 30.7414 154.473C30.9635 154.578 31.1347 154.768 31.2173 154.999C31.3 155.23 31.2873 155.485 31.1821 155.706C31.077 155.928 30.8879 156.1 30.6565 156.182V156.182Z"
        fill="#97B6B6"
      />
      <path
        d="M29.2857 152.347L14.7266 157.538C14.495 157.62 14.2399 157.607 14.0176 157.502C13.7953 157.397 13.624 157.208 13.5413 156.976C13.4586 156.745 13.4713 156.49 13.5767 156.268C13.682 156.046 13.8713 155.875 14.103 155.793L28.6621 150.602C28.7769 150.561 28.8986 150.543 29.0203 150.549C29.1421 150.555 29.2615 150.585 29.3716 150.637C29.4818 150.689 29.5806 150.762 29.6624 150.852C29.7442 150.943 29.8074 151.048 29.8484 151.163C29.8894 151.277 29.9073 151.399 29.9012 151.521C29.8951 151.642 29.8651 151.761 29.8128 151.871C29.7606 151.981 29.6871 152.08 29.5967 152.162C29.5062 152.243 29.4006 152.306 29.2857 152.347V152.347Z"
        fill="#97B6B6"
      />
      <path
        d="M3.86434 144.873C5.99855 144.873 7.72867 143.145 7.72867 141.014C7.72867 138.882 5.99855 137.154 3.86434 137.154C1.73012 137.154 0 138.882 0 141.014C0 143.145 1.73012 144.873 3.86434 144.873Z"
        fill="#97B6B6"
      />
    </g>
    <g clipPath="url(#clip1_1995_3720)">
      <path
        d="M243.754 51.3875C240.534 56.9473 246.597 66.3152 240.487 68.2605L240.99 67.756C244.679 63.2085 236.969 53.5835 240.658 49.036C241.231 48.3689 241.723 47.636 242.123 46.8525"
        fill="#2F2E41"
      />
      <path
        d="M273.909 132.954C274.014 132.398 273.997 131.825 273.858 131.276C273.718 130.727 273.461 130.216 273.103 129.777C272.745 129.339 272.296 128.985 271.787 128.739C271.278 128.494 270.721 128.363 270.156 128.357L262.197 105.179L257.792 110.808L266.206 132.464C266.259 133.416 266.657 134.316 267.327 134.993C267.997 135.67 268.891 136.078 269.84 136.138C270.79 136.199 271.728 135.908 272.478 135.321C273.228 134.734 273.737 133.892 273.909 132.954L273.909 132.954Z"
        fill="#FFB8B8"
      />
      <path
        d="M238.923 76.9952C238.923 76.9952 238.676 68.6054 246.161 73.4116C253.646 78.2178 269.943 123.24 269.943 123.24L261.833 127.638L243.422 86.4376L238.923 76.9952Z"
        fill="#97B6B6"
      />
      <path
        d="M212.67 122.795C212.67 122.795 204.415 145.346 204.281 147.869C204.148 150.393 201.793 182.306 199.819 184.16C197.844 186.014 191.807 214.762 196.505 217.01C201.202 219.259 216.022 215.534 216.022 215.534L221.177 181.576L222.928 155.561C222.928 155.561 228.26 216.823 237.93 216.643C247.601 216.463 259.385 212.772 259.385 212.772L245.849 175.671C245.849 175.671 246.041 131.748 242.903 122.889C239.766 114.03 212.67 122.795 212.67 122.795Z"
        fill="#2F2E41"
      />
      <path
        d="M237.167 64.6931L226.111 65.7082L224.33 71.1885C224.33 71.1885 215.077 76.2818 217.126 78.5957C219.175 80.9096 216.668 96.5032 216.668 96.5032L217.234 109.161C217.234 109.161 216.626 117.93 214.806 119.951C212.986 121.971 208.223 126.926 211.588 124.556C223.386 116.244 244.008 123.257 244.008 123.257L241.062 105.171C241.062 105.171 247.753 75.56 245.113 73.0592C242.472 70.5584 239.98 70.2423 239.98 70.2423L237.167 64.6931Z"
        fill="#97B6B6"
      />
      <path
        d="M179.072 122.063C179.627 121.959 180.153 121.735 180.613 121.405C181.072 121.076 181.454 120.649 181.731 120.156C182.009 119.662 182.175 119.114 182.218 118.549C182.261 117.985 182.18 117.417 181.98 116.887L200.628 101.03L193.795 98.9624L176.727 114.693C175.861 115.088 175.17 115.787 174.784 116.659C174.398 117.53 174.344 118.513 174.633 119.422C174.922 120.33 175.534 121.101 176.352 121.588C177.17 122.074 178.138 122.243 179.072 122.063V122.063Z"
        fill="#FFB8B8"
      />
      <path
        d="M226.43 80.5838C226.43 80.5838 230.104 73.0413 221.308 74.3297C212.512 75.6181 179.157 109.912 179.157 109.912L184.739 117.269L218.45 87.33L226.43 80.5838Z"
        fill="#97B6B6"
      />
      <path
        d="M212.106 67.7895L177.232 112.571C176.629 113.349 176.358 114.334 176.478 115.311C176.598 116.289 177.1 117.178 177.874 117.785C185.972 137.495 210.201 152.111 238.702 165.377C239.478 165.982 240.461 166.255 241.436 166.134C242.411 166.014 243.299 165.51 243.904 164.734L278.778 119.952C279.382 119.175 279.654 118.19 279.533 117.212C279.413 116.235 278.911 115.345 278.137 114.738C254.219 101.201 233.661 85.5171 217.308 67.1469C216.533 66.5422 215.55 66.2703 214.575 66.3907C213.6 66.5112 212.712 67.0142 212.106 67.7895V67.7895Z"
        fill="#E6E6E6"
      />
      <path
        d="M276.331 118.934L265.715 132.565C259.231 140.841 249.743 146.209 239.322 147.497C228.902 148.784 218.397 145.885 210.102 139.434L180.354 116.16C179.913 115.815 179.627 115.309 179.558 114.753C179.489 114.196 179.643 113.636 179.986 113.193L213.684 69.9203C214.029 69.4792 214.535 69.1933 215.09 69.1253C215.645 69.0573 216.204 69.2127 216.645 69.5574C231.693 89.0347 251.837 104.192 275.966 115.969C276.406 116.314 276.692 116.82 276.76 117.376C276.828 117.932 276.674 118.492 276.331 118.934Z"
        fill="white"
      />
      <path
        d="M211.411 92.2364L204.497 101.114C204.314 101.349 204.045 101.501 203.749 101.538C203.454 101.574 203.156 101.491 202.921 101.308C202.686 101.124 202.534 100.854 202.497 100.558C202.461 100.262 202.543 99.9633 202.726 99.7279L209.639 90.8505C209.73 90.7338 209.843 90.6361 209.971 90.5631C210.1 90.4901 210.241 90.4432 210.388 90.425C210.534 90.4069 210.683 90.4178 210.825 90.4573C210.967 90.4967 211.1 90.5639 211.217 90.6549C211.333 90.746 211.431 90.8591 211.503 90.9879C211.576 91.1167 211.623 91.2585 211.641 91.4054C211.659 91.5523 211.648 91.7013 211.608 91.8439C211.569 91.9864 211.501 92.1198 211.411 92.2364H211.411Z"
        fill="#CCCCCC"
      />
      <path
        d="M224.378 85.6519L209.196 105.147C209.105 105.264 208.992 105.361 208.864 105.434C208.735 105.507 208.594 105.554 208.447 105.572C208.301 105.59 208.152 105.579 208.01 105.54C207.868 105.5 207.735 105.433 207.619 105.342C207.502 105.251 207.405 105.138 207.332 105.009C207.26 104.881 207.213 104.739 207.195 104.592C207.177 104.445 207.188 104.296 207.227 104.154C207.267 104.011 207.334 103.878 207.424 103.761L222.606 84.266C222.697 84.1492 222.81 84.0514 222.938 83.9783C223.067 83.9051 223.208 83.8581 223.355 83.8399C223.501 83.8216 223.65 83.8326 223.792 83.872C223.935 83.9114 224.068 83.9786 224.184 84.0697C224.301 84.1608 224.398 84.274 224.471 84.4029C224.544 84.5317 224.59 84.6737 224.608 84.8207C224.626 84.9676 224.615 85.1167 224.576 85.2593C224.536 85.4019 224.469 85.5353 224.378 85.6519H224.378Z"
        fill="#CCCCCC"
      />
      <path
        d="M198.484 118.169C202.571 118.169 205.884 114.848 205.884 110.751C205.884 106.655 202.571 103.334 198.484 103.334C194.397 103.334 191.084 106.655 191.084 110.751C191.084 114.848 194.397 118.169 198.484 118.169Z"
        fill="#97B6B6"
      />
      <path
        d="M225.184 101.06L206.537 125.004C206.343 125.202 206.082 125.322 205.805 125.341C205.528 125.359 205.254 125.274 205.035 125.103C204.816 124.932 204.667 124.686 204.618 124.412C204.568 124.138 204.621 123.855 204.766 123.618L223.412 99.6738C223.607 99.4751 223.868 99.3552 224.145 99.3369C224.422 99.3186 224.696 99.4032 224.915 99.5744C225.134 99.7457 225.282 99.9918 225.332 100.266C225.381 100.54 225.329 100.822 225.184 101.06Z"
        fill="#CCCCCC"
      />
      <path
        d="M237.28 95.5926L211.254 129.013C211.059 129.212 210.799 129.331 210.522 129.35C210.245 129.368 209.97 129.283 209.751 129.112C209.532 128.941 209.384 128.695 209.335 128.421C209.285 128.147 209.338 127.864 209.483 127.627L235.509 94.2067C235.703 94.0081 235.964 93.8882 236.241 93.8699C236.518 93.8516 236.792 93.9361 237.011 94.1074C237.23 94.2787 237.379 94.5247 237.428 94.7987C237.478 95.0726 237.425 95.3552 237.28 95.5926V95.5926Z"
        fill="#CCCCCC"
      />
      <path
        d="M237.19 110.452L218.544 134.397C218.349 134.595 218.088 134.715 217.811 134.733C217.534 134.752 217.26 134.667 217.041 134.496C216.822 134.325 216.674 134.079 216.624 133.805C216.575 133.531 216.627 133.248 216.772 133.011L235.419 109.067C235.613 108.868 235.874 108.748 236.151 108.73C236.428 108.711 236.702 108.796 236.921 108.967C237.14 109.139 237.289 109.385 237.338 109.659C237.388 109.932 237.335 110.215 237.19 110.452Z"
        fill="#CCCCCC"
      />
      <path
        d="M261.862 113.405L243.215 137.349C243.021 137.548 242.76 137.668 242.483 137.686C242.206 137.704 241.932 137.62 241.713 137.449C241.494 137.277 241.346 137.031 241.296 136.757C241.246 136.483 241.299 136.201 241.444 135.963L260.091 112.019C260.285 111.821 260.546 111.701 260.823 111.682C261.1 111.664 261.374 111.749 261.593 111.92C261.812 112.091 261.961 112.337 262.01 112.611C262.06 112.885 262.007 113.168 261.862 113.405Z"
        fill="#97B6B6"
      />
      <path
        d="M249.286 104.985L223.26 138.406C223.065 138.604 222.805 138.724 222.527 138.743C222.25 138.761 221.976 138.676 221.757 138.505C221.538 138.334 221.39 138.088 221.34 137.814C221.291 137.54 221.344 137.257 221.489 137.02L247.514 103.6C247.709 103.401 247.97 103.281 248.247 103.263C248.524 103.244 248.798 103.329 249.017 103.5C249.236 103.672 249.384 103.918 249.434 104.191C249.483 104.465 249.431 104.748 249.286 104.985V104.985Z"
        fill="#CCCCCC"
      />
      <path
        d="M218.631 50.5456C218.63 52.3474 219.071 54.1219 219.914 55.7132C220.758 57.3045 221.978 58.6639 223.467 59.6723C224.957 60.6806 226.671 61.307 228.459 61.4964C230.246 61.6858 232.053 61.4324 233.72 60.7586C235.388 60.0847 236.864 59.0109 238.021 57.6315C239.177 56.252 239.978 54.6091 240.353 52.847C240.729 51.0848 240.666 49.2573 240.173 47.5248C239.679 45.7923 238.768 44.2079 237.52 42.9106L237.468 42.8578C237.38 42.7653 237.292 42.6729 237.2 42.5848C237.199 42.5848 237.198 42.5847 237.198 42.5844C237.197 42.5842 237.197 42.5839 237.196 42.5835C237.196 42.5831 237.196 42.5826 237.196 42.5821C237.195 42.5815 237.195 42.5809 237.195 42.5804C237.081 42.4747 236.967 42.369 236.848 42.2677C235.261 40.8732 233.307 39.9667 231.219 39.6567C229.132 39.3467 226.999 39.6463 225.077 40.5197C223.155 41.3931 221.525 42.8033 220.381 44.5814C219.238 46.3595 218.631 48.4302 218.631 50.5456V50.5456Z"
        fill="#2F2E41"
      />
      <path
        d="M232.319 62.3217C237.405 62.3217 241.528 58.1891 241.528 53.0913C241.528 47.9935 237.405 43.8608 232.319 43.8608C227.233 43.8608 223.11 47.9935 223.11 53.0913C223.11 58.1891 227.233 62.3217 232.319 62.3217Z"
        fill="#FFB8B8"
      />
      <path
        d="M222.502 55.5515C221.601 51.4856 222.902 46.9304 225.739 44.216C227.166 42.8777 228.94 41.9701 230.859 41.5974C232.777 41.2247 234.761 41.4021 236.583 42.1091C236.815 42.2029 237.046 42.3054 237.274 42.4125L237.379 42.4624V42.4779C237.4 42.4921 237.42 42.5071 237.441 42.5222C237.484 42.5625 237.536 42.5915 237.593 42.6064C238.393 42.5854 239.188 42.734 239.926 43.0425C240.664 43.351 241.329 43.8124 241.877 44.3964C243.304 45.9487 243.977 48.4573 243.77 51.46L243.764 51.5539L243.684 51.6038C242.604 52.2902 241.482 52.9099 240.326 53.4594C236.284 55.3648 232.593 55.7178 229.046 54.5374L228.981 54.5157L227.492 52.0147C227.186 53.7397 227.349 59.4613 225.596 59.7713L222.54 55.724L222.502 55.5515Z"
        fill="#2F2E41"
      />
      <path
        d="M203.116 63.5887C206.25 61.9674 202.22 56.0832 203.029 52.6429C203.839 49.2025 209.671 48.096 210.043 44.5808C210.415 41.0657 211.289 37.4402 213.603 34.7728C215.081 33.0684 217.26 31.8101 219.504 32.0236C220.263 32.0959 220.997 32.3312 221.658 32.7137C222.318 33.0961 222.888 33.6167 223.329 34.2399C223.77 34.8631 224.073 35.5744 224.215 36.3252C224.357 37.076 224.337 37.8487 224.154 38.5908C224.793 37.6836 226.446 37.8802 226.855 38.9121C227.219 39.8294 226.415 40.9405 225.469 41.0217C224.232 42.5535 222.815 44.1791 220.876 44.4881C220.567 44.5592 220.246 44.559 219.938 44.4876C219.629 44.4161 219.34 44.2753 219.094 44.0757C218.638 43.6367 218.602 42.759 219.143 42.4309C216.707 43.4009 215.278 46.0413 214.917 48.6438C214.555 51.2464 215.056 53.8797 215.385 56.4865C215.715 59.0933 215.857 61.8355 214.837 64.2563C213.632 67.1164 210.858 69.1663 207.831 69.8237C204.804 70.4811 201.58 69.8386 198.84 68.391C196.1 66.9434 193.815 64.7334 191.948 62.2558C195.163 64.5444 199.612 65.4017 203.116 63.5887Z"
        fill="#2F2E41"
      />
      <path
        d="M227.92 52.4949C224.7 58.0547 230.763 67.4226 224.653 69.3679L225.156 68.8634C228.845 64.3159 221.135 54.6909 224.824 50.1434C225.397 49.4764 225.889 48.7434 226.289 47.96"
        fill="#2F2E41"
      />
      <path
        d="M222.932 55.4676C222.932 55.4676 225.66 45.2613 237.354 42.9056C237.367 42.9012 237.411 42.8836 237.468 42.8572C237.503 42.8352 237.547 42.8131 237.591 42.7911C237.459 42.7162 237.332 42.6502 237.2 42.5841C237.199 42.5841 237.199 42.584 237.198 42.5838C237.198 42.5836 237.197 42.5833 237.197 42.5829C237.196 42.5824 237.196 42.582 237.196 42.5814C237.196 42.5809 237.195 42.5803 237.195 42.5797C237.081 42.4741 236.967 42.3684 236.848 42.2671L236.515 42.2803C236.515 42.2803 224.781 40.4179 222.932 55.4676Z"
        fill="#FD6584"
      />
      <path
        d="M153.277 39.5513C159.657 31.0752 163.345 21.5968 163.466 10.7906C163.671 10.2082 163.637 9.56821 163.371 9.01095C163.105 8.45369 162.628 8.02468 162.045 7.81805L140.298 0.132658C139.715 -0.0726484 139.074 -0.0385765 138.516 0.227405C137.958 0.493386 137.529 0.969554 137.322 1.55144L127.133 30.3122C126.927 30.8946 126.961 31.5346 127.228 32.0918C127.494 32.6491 127.971 33.0781 128.553 33.2847L150.301 40.9701C150.884 41.1754 151.525 41.1413 152.083 40.8754C152.641 40.6094 153.07 40.1332 153.277 39.5513Z"
        fill="#E6E6E6"
      />
      <path
        d="M154.686 30.2736C158.523 26.3984 160.166 19.4869 161.236 11.7836C161.429 11.2382 161.397 10.6388 161.148 10.1169C160.898 9.59499 160.452 9.19322 159.906 8.99972L141.216 2.39477C140.67 2.20247 140.07 2.23437 139.547 2.48345C139.025 2.73254 138.622 3.17848 138.429 3.72344L129.362 29.3166C129.169 29.862 129.201 30.4614 129.451 30.9833C129.7 31.5052 130.147 31.907 130.692 32.1005L142.27 36.1921C144.703 37.0486 147.376 36.9065 149.704 35.797C152.031 34.6874 153.823 32.701 154.686 30.2736V30.2736Z"
        fill="white"
      />
      <path
        d="M138.179 17.5114L152.738 22.7019C152.969 22.7845 153.224 22.7718 153.447 22.6666C153.669 22.5614 153.84 22.3723 153.923 22.1409C154.006 21.9095 153.993 21.6547 153.888 21.4327C153.782 21.2107 153.593 21.0396 153.361 20.957L138.802 15.7665C138.571 15.6842 138.316 15.6971 138.094 15.8023C137.872 15.9076 137.7 16.0966 137.618 16.3278C137.535 16.5591 137.548 16.8136 137.653 17.0356C137.758 17.2575 137.947 17.4287 138.179 17.5114V17.5114Z"
        fill="#97B6B6"
      />
      <path
        d="M151.245 16.7751L154.448 17.917C154.563 17.958 154.684 17.9761 154.806 17.9701C154.928 17.9642 155.047 17.9343 155.157 17.8822C155.267 17.8301 155.366 17.7569 155.448 17.6666C155.53 17.5764 155.593 17.4709 155.634 17.3563C155.675 17.2416 155.693 17.12 155.687 16.9985C155.681 16.8769 155.651 16.7577 155.598 16.6477C155.546 16.5377 155.473 16.4391 155.382 16.3575C155.292 16.2759 155.186 16.2129 155.071 16.1721L151.868 15.0302C151.637 14.9479 151.382 14.9607 151.16 15.066C150.938 15.1713 150.767 15.3603 150.684 15.5915C150.601 15.8228 150.614 16.0773 150.719 16.2992C150.824 16.5212 151.013 16.6923 151.245 16.7751V16.7751Z"
        fill="#97B6B6"
      />
      <path
        d="M135.437 25.1823L149.996 30.3728C150.228 30.4551 150.483 30.4422 150.705 30.337C150.927 30.2317 151.098 30.0427 151.181 29.8115C151.264 29.5802 151.251 29.3257 151.146 29.1037C151.041 28.8818 150.851 28.7106 150.62 28.6279L136.061 23.4374C135.829 23.3551 135.574 23.368 135.352 23.4732C135.13 23.5785 134.959 23.7675 134.876 23.9987C134.794 24.23 134.806 24.4845 134.912 24.7065C135.017 24.9284 135.206 25.0996 135.437 25.1823V25.1823Z"
        fill="#97B6B6"
      />
      <path
        d="M136.808 21.347L151.367 26.5375C151.599 26.6201 151.854 26.6074 152.076 26.5022C152.298 26.397 152.47 26.2079 152.552 25.9765C152.635 25.7451 152.622 25.4903 152.517 25.2683C152.412 25.0463 152.222 24.8752 151.991 24.7926L137.432 19.6021C137.317 19.561 137.195 19.543 137.073 19.549C136.952 19.5549 136.832 19.5848 136.722 19.6369C136.612 19.689 136.513 19.7622 136.431 19.8525C136.35 19.9427 136.286 20.0482 136.245 20.1628C136.204 20.2775 136.186 20.3991 136.193 20.5206C136.199 20.6422 136.229 20.7614 136.281 20.8714C136.333 20.9814 136.407 21.08 136.497 21.1616C136.588 21.2432 136.693 21.3062 136.808 21.347V21.347Z"
        fill="#97B6B6"
      />
      <path
        d="M162.229 13.8735C160.095 13.8735 158.365 12.1455 158.365 10.0139C158.365 7.88229 160.095 6.1543 162.229 6.1543C164.364 6.1543 166.094 7.88229 166.094 10.0139C166.094 12.1455 164.364 13.8735 162.229 13.8735Z"
        fill="#97B6B6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1995_3720">
        <rect
          width="39.0938"
          height="41.1028"
          fill="white"
          transform="translate(0 121)"
        />
      </clipPath>
      <clipPath id="clip1_1995_3720">
        <rect width="213" height="162" fill="white" transform="translate(51)" />
      </clipPath>
    </defs>
  </svg>
);
export default OfferAvailable;
