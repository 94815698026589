import styled from 'styled-components/macro';
import { colors } from 'components/Colors';

const SimpleLoaderWrapper = styled.div`
  .MuiBox-root {
    position: relative;
    & > :first-child {
      color: ${colors.green.lightTeal.value};
    }
    & > :last-child {
      position: absolute;
      left: 0;
    }
  }
`;

export default SimpleLoaderWrapper;
