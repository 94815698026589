import { Link, Typography } from '@mui/material';
import { ElementType, MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import { colors } from 'components/Colors';

interface LinkNavigation {
  href?: string;
  navigate?: () => void;
  to?: any;
  component?: any;
  handleEvent?: () => void;
}

export interface Answer extends LinkNavigation {
  text: string;
}

interface LinkProps extends LinkNavigation {
  onClick?: MouseEventHandler;
  component?: ElementType;
}

export interface IQuestionAndAnswer {
  question: string;
  answer: Answer;
}

const Wrapper = styled.div`
  margin-left: 50px;
  position: relative;
  &:before {
    content: '';
    width: 32px;
    height: 1px;
    background-color: ${colors.common.grey.value};
    position: absolute;
    left: -50px;
    top: 11px;
  }
  & > a {
    line-height: 26px;
  }
`;

const LinkElement = ({
  href,
  navigate,
  text,
  component,
  to,
  handleEvent
}: Answer) => {
  const props: LinkProps = {};

  if (href) {
    props.href = href;
  } else if (component && to) {
    props.component = component;
    props.to = to;
  } else if (navigate) {
    props.component = 'button';
    props.onClick = navigate;
  }

  if (handleEvent) {
    props.onClick = handleEvent;
  }

  return (
    <Link variant="body1" {...props}>
      {text}
    </Link>
  );
};

const QuestionAndAnswer = ({ question, answer }: IQuestionAndAnswer) => (
  <Wrapper>
    <Typography variant="body1">{question}</Typography>
    <LinkElement {...answer} />
  </Wrapper>
);

export default QuestionAndAnswer;
