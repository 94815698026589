import { createGlobalStyle } from 'styled-components/macro';
import mulishFont from './fonts/Mulish.ttf';

const FontProvider = createGlobalStyle`
  @font-face {
    font-family: 'Mulish';
    font-weight: 400 900;
    src: url(${mulishFont}) format('truetype');
  }
`;

export default FontProvider;
