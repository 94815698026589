import { SvgIcon } from '@mui/material';

const Star = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.05806 15.4424L8.00366 11.8427L12.9566 15.4424L11.0544 9.65069L16 6.05104H9.89117L8.00366 0.222656L6.11613 6.05104H0L4.94557 9.65069L3.05806 15.4424ZM4.52126 7.48795H7.19158L7.5208 6.47623L8.01828 4.93667L8.51576 6.47623L8.84499 7.48795H11.4934L10.1911 8.43372L9.32785 9.05686L9.65705 10.0759L10.1546 11.6081L8.85231 10.6551L7.99634 10.0319L7.13306 10.6551L5.83082 11.6081L6.3283 10.0759L6.65753 9.05686L5.80155 8.43372L4.52126 7.48795Z" />
  </SvgIcon>
);

export default Star;
