import { IIllustration } from '../Illustration.model';

const ExclamationMark = ({ width, height }: IIllustration) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1436_6609)">
      <path
        d="M6.76561 48.2194L4.39258 41.0766C4.39258 41.0766 7.00506 43.6797 7.00506 45.8163L6.53693 40.8724C6.53693 40.8724 7.87662 42.6748 7.74286 45.4111C7.60911 48.1474 6.76561 48.2194 6.76561 48.2194Z"
        fill="#E6E6E6"
      />
      <path
        d="M43.841 51.5145C43.841 56.2133 34.4923 54.7678 23.0435 54.7775C11.5948 54.7871 2.38312 56.2488 2.37988 51.5532C2.37665 46.8577 11.7274 45.8281 23.173 45.813C34.6185 45.798 43.8367 46.8168 43.841 51.5145Z"
        fill="#E6E6E6"
      />
      <path
        d="M20.3261 50.1325C24.0786 50.1325 27.1206 47.1016 27.1206 43.3627C27.1206 39.6238 24.0786 36.5929 20.3261 36.5929C16.5737 36.5929 13.5317 39.6238 13.5317 43.3627C13.5317 47.1016 16.5737 50.1325 20.3261 50.1325Z"
        fill="#E73F0D"
      />
      <path
        d="M15.7881 36.112C14.8465 36.1122 13.9249 35.8404 13.1351 35.3296C12.3452 34.8188 11.7209 34.0908 11.3376 33.2338L0.556515 9.04239C0.212092 8.2704 0.0236955 7.43836 0.00209495 6.5938C-0.0195056 5.74924 0.126112 4.90872 0.430625 4.12025C0.735137 3.33178 1.19258 2.61082 1.77682 1.99855C2.36106 1.38629 3.06064 0.894711 3.83561 0.551912C4.61038 0.20859 5.44547 0.0207651 6.29313 -0.000828925C7.14079 -0.0224229 7.98441 0.122638 8.77578 0.426063C9.56715 0.729487 10.2908 1.18533 10.9052 1.76753C11.5197 2.34973 12.013 3.04688 12.3569 3.81913C12.4616 4.05351 12.552 4.29398 12.6277 4.53921L20.4349 29.8398C20.6226 30.4479 20.6883 31.087 20.6281 31.7204C20.5679 32.3538 20.3831 32.9693 20.0842 33.5315C19.7854 34.0938 19.3782 34.5919 18.8862 34.9974C18.3941 35.4029 17.8266 35.7079 17.2162 35.8949C16.7551 36.037 16.2751 36.1094 15.7924 36.1098L15.7881 36.112Z"
        fill="#E73F0D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1436_6609">
        <rect width="43.8406" height="55" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ExclamationMark;
