import { InlineLoader } from 'components/Loader';
import { IButton } from './Button.model';

import { ButtonContainer } from './styled/ButtonContainer';

const Button = ({
  onClick,
  variant = 'contained',
  color = 'primary',
  label,
  ...props
}: IButton) => {
  const inlineLoaderSmallSize = 20;
  const inlineLoaderBigSize = 24;
  const inlineLoaderSize =
    props.size === 'small' ? inlineLoaderSmallSize : inlineLoaderBigSize;
  return (
    <ButtonContainer
      loadingIndicator={
        <InlineLoader
          size={inlineLoaderSize}
          thickness={3}
          variant={variant}
          color={color}
        />
      }
      loadingPosition="center"
      variant={variant}
      color={color}
      onClick={onClick}
      {...props}
    >
      {label || props.children}
    </ButtonContainer>
  );
};

export default Button;
