import styled from 'styled-components/macro';
import { Dialog } from '@mui/material';
import theme from 'App.theme';

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0;
    padding: 19px 29px;
    word-break: break-word;
    box-shadow: 3px 6px 40px rgba(0, 0, 0, 0.1);
  }
  .MuiBackdrop-root {
    background-color: rgba(151, 182, 182, 0.6);
  }
  .MuiDialogTitle-root {
    ${[theme.breakpoints.down('md')]} {
      padding-right: 10px;
    }
  }
`;
