import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import { AgreementsState } from './agreements.model';
import reducers from './agreements.reducers';
import effects from './agreements.effects';

const model = createModel<RootModel>()({
  state: {
    agreementsWaitingForContract: [],
    totalDebtAmountOnAllAgreementsByCurrency: {},
    agreements: null,
    agreementsWithProductAvailability: []
  } as AgreementsState,
  reducers,
  effects
});

export default model;
