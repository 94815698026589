import mitt from 'mitt';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-unresolved
import { Client } from '@stomp/stompjs';
import { trackPromise } from 'react-promise-tracker';
import { EMPTY_ARRAY_LENGTH, Index, StatusCode, TimeInMs } from 'utils/consts';
import {
  CreateAccountMessage,
  VerifyClientMessage,
  ChangePasswordMessage,
  ResetPasswordMessage,
  GetDocumentMessage
} from 'storage/user/user.model';
import { RequestMethod, SendRequestParams } from './Api.model';
import { getBrokerUrl } from './getBrokerUrl';
import { getUserId } from './getUserId';
import { Token } from '../Token';
import { AgreementWaitingForContract } from '../../storage/agreements/agreements.model';

const { REACT_APP_API_URL } = process.env;

const messages = [
  ...Object.values(CreateAccountMessage),
  ...Object.values(VerifyClientMessage),
  ...Object.values(ChangePasswordMessage),
  ...Object.values(ResetPasswordMessage),
  ...Object.values(GetDocumentMessage)
];

export enum ErrorsTag {
  Modal = 'cos-poszlo-nie-tak',
  NotFound = 'nie-znaleziono-strony',
  BadRequest = 'strona-bledu',
  Unauthorized = 'brak-autoryzacji',
  Forbidden = 'blokada-konta'
}

type MessagesType = CreateAccountMessage &
  VerifyClientMessage &
  ChangePasswordMessage &
  ResetPasswordMessage &
  GetDocumentMessage;

type ResponseData<D> = {
  [key: string]: D | boolean | undefined;
  success?: boolean;
};

export const events = mitt();
export const apiEvents = {
  WSS_CLOSE_CONNECTION: 'WSS_CLOSE_CONNECTION',
  WSS_DOCUMENT_GENERATION: 'WSS_DOCUMENT_GENERATION',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  GENERAL_WARN: 'GENERAL_WARN',
  GENERAL_ERROR: 'GENERAL_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  ERROR: 'ERROR',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  SIGMA_TIMEOUT: 'SIGMA_TIMEOUT'
};

export const appEvents = {
  CLEAR_TIMEOUT: 'CLEAR_TIMEOUT'
};

const getHeaders = (token?: string): Record<string, string> => ({
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
  ...(token && { Authorization: token })
});

const getBody = <T>(payload?: T) => payload && JSON.stringify(payload);

const sendRequest = <T, D>(
  params: SendRequestParams<T>,
  noTrack: boolean,
  timeoutOn?: boolean
): Promise<D> => {
  const token = Token.getBearer();
  const headers = getHeaders(token);
  const body = getBody(params.payload);
  const { method } = params;
  const abortController = new AbortController();
  const timeout = setTimeout(
    () => {
      abortController.abort();
      events.emit(apiEvents.SIGMA_TIMEOUT);
    },
    timeoutOn ? TimeInMs.thirtySeconds : TimeInMs.fiveMinutes
  );
  const request = fetch(`${REACT_APP_API_URL}${params.endpoint}`, {
    headers,
    body,
    method,
    signal: abortController.signal
  })
    .then((response: Response) => {
      const HttpStatusCodePattern = /([4][0-9][0-9])|([5][0-9]{2})/;
      const HttpOkOrCreatedStatusCodePattern = /20[01]/;
      clearInterval(timeout);
      if (response.status === StatusCode.badRequestCode) {
        (response.json() as Promise<ResponseData<D>>).then((data) => {
          getUserId().then((userId) => {
            Sentry.withScope((scope) => {
              scope.setUser({ id: userId!.toString() });
              Sentry.captureException(data?.message, {
                tags: {
                  error_message: ErrorsTag.BadRequest,
                  https_status: response.status
                },
                extra: {
                  responseData: data
                }
              });
            });
          });
          events.emit(apiEvents.ERROR);
        });
      } else if (
        response.status === StatusCode.unauthorizedCode &&
        params.endpoint !== '/auth/login'
      ) {
        events.emit(apiEvents.TOKEN_EXPIRED);
      } else if (
        (response.status === StatusCode.unauthorizedCode ||
          response.status === StatusCode.forbiddenCode) &&
        params.endpoint === '/auth/login'
      ) {
        //  This case is handled in user.effects loginUser
      } else if (response.status === StatusCode.notFoundCode) {
        (response.json() as Promise<ResponseData<D>>).then((data) => {
          getUserId().then((userId) => {
            Sentry.withScope((scope) => {
              scope.setUser({ id: userId!.toString() });
              Sentry.captureException(data?.message, {
                tags: {
                  error_message: ErrorsTag.NotFound,
                  https_status: response.status
                },
                extra: {
                  responseData: data
                }
              });
            });
          });
          events.emit(apiEvents.NOT_FOUND);
        });
      } else if (HttpStatusCodePattern.test(response.status.toString())) {
        (response.json() as Promise<ResponseData<D>>).then((data) => {
          getUserId().then((userId) => {
            Sentry.withScope((scope) => {
              scope.setUser({ id: userId!.toString() });
              Sentry.captureException(data?.message, {
                tags: {
                  error_message: ErrorsTag.Modal,
                  https_status: response.status
                },
                extra: {
                  responseData: data
                }
              });
            });
          });
          events.emit(apiEvents.GENERAL_ERROR);
        });
      }
      const contentType = response.headers.get('content-type');
      if (
        contentType &&
        contentType.indexOf('application/json') !== Index.minusOne
      ) {
        return (response.json() as Promise<ResponseData<D>>).then((data) => {
          if (
            HttpOkOrCreatedStatusCodePattern.test(response.status.toString()) &&
            data?.success === false &&
            !messages.includes(data?.message as unknown as MessagesType)
          ) {
            getUserId().then((userId) => {
              Sentry.withScope((scope) => {
                scope.setUser({ id: userId!.toString() });
                Sentry.captureException(data.message, {
                  tags: {
                    error_message: ErrorsTag.Modal,
                    https_status: response.status,
                    success: data.success
                  },
                  extra: {
                    responseData: data
                  }
                });
              });
            });
            events.emit(apiEvents.GENERAL_ERROR);
          }
          return {
            ...data,
            status: response.status
          };
        });
      }
      return response as any;
    })
    .catch((error) => {
      if (error.message === 'Failed to fetch') {
        getUserId().then((userId) => {
          Sentry.withScope((scope) => {
            scope.setUser({ id: userId!.toString() });
            Sentry.captureException(error, {
              tags: {
                error_message: ErrorsTag.Modal,
                https_status: error.status
              }
            });
          });
        });
        events.emit(apiEvents.GENERAL_ERROR);
      }
      throw error;
    });
  return noTrack ? request : trackPromise(request);
};

export const client = new Client({
  reconnectDelay: 200
});

export const webSocketsConnection = () => {
  client.brokerURL = getBrokerUrl();
  client.forceBinaryWSFrames = true;
  client.beforeConnect = () => {
    client.connectHeaders = {
      Authorization: Token.getBearer(),
      'Content-Type': 'application/json'
    };
  };
  client.onConnect = () => {
    client.subscribe('/listen/event/PRODUCT_DOCUMENT_READY', (response) => {
      const data: AgreementWaitingForContract[] | [] = JSON.parse(
        response.body
      );
      if (!data) {
        client.deactivate();
        events.emit(appEvents.CLEAR_TIMEOUT);
      }

      if (data.length === EMPTY_ARRAY_LENGTH) {
        events.emit(apiEvents.WSS_DOCUMENT_GENERATION, { data });
        client.deactivate();
        events.emit(appEvents.CLEAR_TIMEOUT);
      }

      if (data && data.length > EMPTY_ARRAY_LENGTH) {
        const areAllDocumentsGenerated = data.every(
          (agreement: AgreementWaitingForContract) => agreement.documentReady
        );
        events.emit(apiEvents.WSS_DOCUMENT_GENERATION, { data });

        if (areAllDocumentsGenerated) {
          client.deactivate();
          events.emit(appEvents.CLEAR_TIMEOUT);
        }
      }
    });
  };
  client.onStompError = (err) => {
    console.log('Stomp error:', err);
  };

  client.onWebSocketError = (err) => {
    console.log('Web socket error: ', err);
  };

  client.activate();

  events.on(apiEvents.WSS_CLOSE_CONNECTION, () => client.deactivate());
};

const sendGet = <T, D>(endpoint: string, noTrack = false, timeoutOn = false) =>
  sendRequest<T, D>(
    { endpoint, method: RequestMethod.GET },
    noTrack,
    timeoutOn
  );

const sendPost = <T, D>(endpoint: string, payload?: T, noTrack = false) =>
  sendRequest<T, D>({ endpoint, payload, method: RequestMethod.POST }, noTrack);

const sendPut = <T, D>(endpoint: string, payload?: T, noTrack = false) =>
  sendRequest<T, D>({ endpoint, payload, method: RequestMethod.PUT }, noTrack);

const sendPatch = <T, D>(
  endpoint: string,
  payload?: Partial<T>,
  noTrack = false
) =>
  sendRequest<T, D>(
    { endpoint, payload, method: RequestMethod.PATCH },
    noTrack
  );

const sendDelete = <T, D>(endpoint: string, payload?: T, noTrack = false) =>
  sendRequest<T, D>(
    { endpoint, payload, method: RequestMethod.DELETE },
    noTrack
  );

export default {
  get: sendGet,
  post: sendPost,
  put: sendPut,
  patch: sendPatch,
  delete: sendDelete
};
