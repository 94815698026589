import {
  Slide,
  AppBar as AppBarComponent,
  Toolbar,
  Container,
  Grid,
  useMediaQuery
} from '@mui/material';
import theme from 'App.theme';
import useScroll, { ScrollDirection } from 'hooks/UseScroll';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'storage/store';
import AppLogo from './AppLogo';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import { MenuVisibilityContext } from '../utils/menuVisibilityContext';

const AppBar = () => {
  const { menuVisible } = useContext(MenuVisibilityContext);
  const scroll = useScroll(menuVisible);
  const desktopBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
  const username = useSelector((state: RootState) =>
    state.user.firstName && state.user.lastName
      ? `${state.user.firstName} ${state.user.lastName}`
      : ''
  );

  return (
    <Slide
      in={[ScrollDirection.UP, null].includes(scroll)}
      direction="down"
      appear={false}
    >
      <AppBarComponent
        position="fixed"
        color="inherit"
        elevation={2}
        sx={{
          boxShadow: '1px 2px 4px 1px rgba(0, 0, 0, 0.1)',
          zIndex: 1301
        }}
      >
        <Toolbar sx={{ minHeight: { xs: '64px', lg: '92px' } }} disableGutters>
          <Container
            maxWidth="xl"
            sx={{
              padding: {
                xs: '0 14px 0 23px',
                md: '0 47px'
              }
            }}
          >
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item sx={{ display: 'flex' }}>
                <AppLogo />
              </Grid>
              {desktopBreakpoint ? (
                <DesktopMenu username={username} />
              ) : (
                <MobileMenu username={username} />
              )}
            </Grid>
          </Container>
        </Toolbar>
      </AppBarComponent>
    </Slide>
  );
};

export default AppBar;
