import styled from 'styled-components/macro';
// import { ErrorMessage } from 'formik';
import { Typography } from '@mui/material';
import getColor from 'utils/getColor';

const WarningMessageStyled = styled(Typography)`
  position: absolute;
  left: 0;
  bottom: -19px;
  font-size: 13px;
  font-weight: 600;
  color: ${getColor('warning')};
  line-height: 125%;
  margin: 3px 0 0 0;
  width: auto;
`;

export default WarningMessageStyled;
