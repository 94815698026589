import { ProcessStepState } from './processStep.model';

const reducers = {
  storeStep: (state: ProcessStepState, step: number | null) => ({
    ...state,
    step
  })
};

export default reducers;
