import styled from 'styled-components/macro';
import { IModalContent } from '../Modal';

export const ModalContentWrapper = styled.div<IModalContent>`
  flex: 1;
  & > .MuiDialogContent-root {
    height: 100%;
    padding-bottom: 5px;
    ${({ fullScreen }) =>
      fullScreen &&
      `
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  }
`;
