import { SvgIcon } from '@mui/material';

const Tools = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4103)">
      <path d="M12.6184 2.15594C11.225 0.790591 9.34866 0.0309341 7.39784 0.0423322C6.30145 0.0493507 5.21968 0.294479 4.22743 0.760886L2.87472 1.40552L7.40841 5.93925C7.50827 6.03712 7.58768 6.15386 7.64211 6.28266C7.69653 6.41145 7.72486 6.54971 7.72544 6.68954C7.72147 6.96829 7.60753 7.23426 7.40841 7.42938C7.21041 7.62621 6.94254 7.73666 6.66335 7.73666C6.38416 7.73666 6.11632 7.62621 5.91832 7.42938L1.38462 2.89565L0.739989 4.23781C0.071683 5.62173 -0.149022 7.17942 0.108545 8.69452C0.366112 10.2096 1.08915 11.6069 2.17723 12.6922C2.86961 13.3874 3.69335 13.9379 4.60051 14.3116C5.50767 14.6854 6.48012 14.8748 7.46124 14.8692C7.96885 14.864 8.47483 14.8109 8.97246 14.7106L18.3358 24.074L19.8364 22.5733L11.1389 13.8758C12.2742 13.2211 13.2151 12.2765 13.8655 11.1388L22.563 19.8468L24.0636 18.3461L14.6898 8.98284C14.9481 7.76256 14.8936 6.49672 14.5315 5.30313C14.1693 4.10953 13.5113 3.02698 12.6184 2.15594ZM11.1284 11.1282C10.1375 12.1188 8.79368 12.6752 7.39255 12.6752C5.99142 12.6752 4.64764 12.1188 3.65674 11.1282C3.07789 10.548 2.64192 9.84115 2.38331 9.06347C2.1247 8.28579 2.05052 7.45863 2.16665 6.64735L4.4071 8.88777C5.01188 9.46162 5.8138 9.78151 6.64751 9.78151C7.48123 9.78151 8.28315 9.46162 8.88793 8.88777C9.48165 8.29331 9.81512 7.48751 9.81512 6.64735C9.81512 5.80719 9.48165 5.00126 8.88793 4.40681L6.64751 2.16639C7.74402 2.01183 8.86134 2.20575 9.84173 2.72059C10.8221 3.23543 11.616 4.04526 12.1112 5.0357C12.6064 6.02614 12.778 7.14709 12.6016 8.2403C12.4253 9.3335 11.9099 10.3437 11.1284 11.1282Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4103">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Tools;
