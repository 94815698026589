import styled from 'styled-components/macro';
import { Grid } from '@mui/material';

export const PageWrapper = styled(Grid)(
  ({ theme }) => `
  &.MuiGrid-root {
    &.MuiGrid-container {
      position: relative;
      ${[theme.breakpoints.down('md')]} {
        padding: 105px 20px 100px;
      }
      ${[theme.breakpoints.between('md', 'lg')]} {
        padding: 153px 0 100px 0;
      }
    }
  }
`
);
