import { Alert as MuiAlert } from '@mui/material';
import styled from 'styled-components/macro';

import theme from 'App.theme';
import { IAlert } from '../Alert.model';

export const AlertWrapper = styled(MuiAlert)<IAlert>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  border: ${({ variant, severity }) =>
    variant !== 'filled' && severity
      ? `1px solid ${theme.palette[severity].main}`
      : 'none'};
  word-break: break-word;

  .MuiAlert-icon {
    margin-right: 15px;
    color: ${({ variant, severity }) =>
      variant !== 'filled' && severity
        ? theme.palette[severity].main
        : theme.palette.common.white};
  }
`;
