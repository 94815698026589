import { IBeforeInstallPromptEvent, PWAState } from './pwa.model';

const reducers = {
  setButtonType: (state: PWAState, buttonType: string) => ({
    ...state,
    buttonType
  }),
  setPWAButton: (state: PWAState, showButton: boolean) => ({
    ...state,
    showButton
  }),
  setPrompt: (state: PWAState, prompt: IBeforeInstallPromptEvent | null) => ({
    ...state,
    prompt
  }),
  setIsIphone: (state: PWAState, isIphone: boolean) => ({
    ...state,
    isIphone
  })
};

export default reducers;
