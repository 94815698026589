import {
  Agreement,
  AgreementsState,
  AgreementSwitcher,
  AgreementWaitingForContract,
  TotalDebtAmountOnAllAgreementsByCurrency
} from './agreements.model';

const reducers = {
  storeAllAgreements: (
    state: AgreementsState,
    agreements: Agreement[],
    totalDebtAmountOnAllAgreementsByCurrency: TotalDebtAmountOnAllAgreementsByCurrency
  ) => ({
    ...state,
    totalDebtAmountOnAllAgreementsByCurrency,
    agreements
  }),
  storeAgreementDetails: (
    state: AgreementsState,
    agreement: Agreement,
    agreementId: number
  ) => ({
    ...state,
    [agreementId]: agreement
  }),
  storeAgreementsWaitingForContract: (
    state: AgreementsState,
    data: AgreementWaitingForContract[]
  ) => ({ ...state, agreementsWaitingForContract: data }),
  storeAgreementsWithProductAvailability: (
    state: AgreementsState,
    agreements: AgreementSwitcher[]
  ) => ({ ...state, agreementsWithProductAvailability: agreements })
};

export default reducers;
