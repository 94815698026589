import { SvgIcon } from '@mui/material';

const Phone = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_3707)">
      <path d="M21.26 15H16.74C16.3522 14.9412 15.9566 14.9613 15.5768 15.0593C15.197 15.1573 14.8409 15.331 14.53 15.57C14.3582 15.7514 14.2238 15.9648 14.1346 16.1982C14.0453 16.4316 14.003 16.6803 14.01 16.93C12.2482 16.7089 10.61 15.9079 9.3535 14.6532C8.09702 13.3985 7.29367 11.7615 7.07 10C7.31975 10.007 7.56842 9.96469 7.80179 9.87546C8.03516 9.78623 8.24863 9.65185 8.43 9.48002C8.66904 9.16907 8.84275 8.81301 8.9407 8.43323C9.03866 8.05345 9.05882 7.65777 9 7.26999V2.75C9.05882 2.36223 9.03866 1.96658 8.9407 1.5868C8.84275 1.20702 8.66904 0.850961 8.43 0.540013C8.24063 0.357855 8.01624 0.216059 7.77042 0.123235C7.52461 0.0304099 7.2625 -0.0115181 7 4.66406e-06H3C2.20435 4.66406e-06 1.44129 0.316088 0.878677 0.878697C0.316068 1.44131 0 2.20436 0 3V7C0 11.5087 1.79107 15.8327 4.97919 19.0208C8.1673 22.2089 12.4913 24 17 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7957 24 21V17C24.0128 16.7382 23.9725 16.4765 23.8814 16.2307C23.7903 15.985 23.6504 15.7602 23.47 15.57C22.8372 15.0963 22.043 14.8914 21.26 15ZM22 21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H17C13.0218 22 9.20644 20.4196 6.39339 17.6066C3.58035 14.7936 2 10.9783 2 7V3C2 2.73479 2.10536 2.48045 2.29289 2.29291C2.48043 2.10538 2.73478 2 3 2H7C7.01554 2.24309 7.01554 2.48693 7 2.73002V7.26999C7.01554 7.51308 7.01554 7.75692 7 8H5V9C5 11.6522 6.05357 14.1957 7.92893 16.0711C9.8043 17.9464 12.3478 19 15 19H16V17C16.2431 16.9845 16.4869 16.9845 16.73 17H21.27C21.5131 16.9845 21.7569 16.9845 22 17V21Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_3707">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Phone;
