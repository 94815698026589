import { Typography, AlertTitle } from '@mui/material';
import { Icon } from 'components/Icon/index';
import theme from 'App.theme';
import { IAlertComponent } from './Alert.model';
import { AlertWrapper } from './styled/AlertWrapper';

const Alert = ({
  title,
  children,
  variant,
  severity,
  width,
  hideIcon,
  icon = 'error',
  ...otherProps
}: IAlertComponent) => (
  <AlertWrapper
    {...otherProps}
    severity={severity}
    variant={variant}
    icon={hideIcon ? false : <Icon icon={icon} />}
    width={width}
  >
    <AlertTitle
      sx={{
        color: variant === 'filled' ? theme.palette.common.white : 'inherit'
      }}
    >
      {title}
    </AlertTitle>
    {children && (
      <Typography variant="body2" component="div">
        {children}
      </Typography>
    )}
  </AlertWrapper>
);

export default Alert;
