export interface LiveChatState {
  visibility: LiveChatTypes;
}

export type LiveChatTypes = 'hidden' | 'maximized' | 'minimized' | undefined;

export enum AvailabilityStatus {
  online = 'online'
}

export enum VisibilityStatus {
  maximized = 'maximized',
  hidden = 'hidden'
}
