import { TFunction } from 'react-i18next';
import { SimulationParameter } from 'storage/product/product.model';
import getWordVariation from '../getWordVariation';
import useAmountAndSumSettingsHandler from './useAmountAndSumSettingsHandler';
import { CurrencyType } from '../formatCurrency/formatCurrency.model';
import { parseDate } from './parseDate';

const useSimulationDataHandler = (
  prerequisitePaymentDate: SimulationParameter,
  lastInstallmentPaymentDate: SimulationParameter,
  firstInstallmentPayment: SimulationParameter,
  installmentsCount: SimulationParameter,
  installmentsAmount: SimulationParameter,
  currencyType: CurrencyType,
  t: TFunction<'translation'>
) => {
  const minDate = parseDate(prerequisitePaymentDate.minValue!);
  const maxDate = parseDate(prerequisitePaymentDate.maxValue!);
  const averageDate = parseDate(prerequisitePaymentDate.value);
  const lastInstallmentPayment = parseDate(lastInstallmentPaymentDate.value);
  const firstInstallmentPaymentMin = parseDate(
    firstInstallmentPayment.minValue!
  );
  const firstInstallmentPaymentMax = parseDate(
    firstInstallmentPayment.maxValue!
  );
  const firstInstallmentPaymentAverage = parseDate(
    firstInstallmentPayment.value
  );
  const minText = getWordVariation(+installmentsCount.minValue!, t);
  const maxText = getWordVariation(+installmentsCount.maxValue!, t);
  const { amountSettings, sumSettings } = useAmountAndSumSettingsHandler(
    installmentsCount,
    minText,
    maxText,
    installmentsAmount,
    currencyType!
  );

  return {
    minDate,
    maxDate,
    averageDate,
    lastInstallmentPayment,
    firstInstallmentPaymentMin,
    firstInstallmentPaymentMax,
    firstInstallmentPaymentAverage,
    sumSettings,
    amountSettings
  };
};

export default useSimulationDataHandler;
