import { useDispatch } from 'react-redux';
import { events, apiEvents } from 'utils/Api/Api';
import { RootDispatch } from 'storage/store';
import { AgreementWaitingForContract } from 'storage/agreements/agreements.model';

const WebSocketsEvents = () => {
  const dispatch = useDispatch<RootDispatch>();

  events.on(apiEvents.WSS_DOCUMENT_GENERATION, (agreements) => {
    const { data } = agreements as { data: AgreementWaitingForContract[] | [] };
    dispatch.agreements.storeAgreementsWaitingForContract(
      data as AgreementWaitingForContract[]
    );
  });
};

export default WebSocketsEvents;
