import { API } from 'utils';
import { SendEmailRequest, SendEmailResponse } from './contact.model';

const effects = () => ({
  async sendEmailForm(
    payload: SendEmailRequest
  ): Promise<SendEmailResponse | undefined> {
    const url = '/contact/message';
    return API.put<SendEmailRequest, SendEmailResponse>(url, payload)
      .then((response) => response)
      .catch((error) => error);
  }
});

export default effects;
