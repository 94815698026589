export const linkChrome =
  'https://support.google.com/chrome/answer/95647?hl=pl';
export const linkFirefox =
  'https://support.mozilla.org/pl/kb/ciasteczka?esab=a&s=ciasteczka&r=0&as=s';
export const linkOpera = 'https://help.opera.com/pl/latest/web-preferences/';
export const linkSafari =
  'https://support.apple.com/pl-pl/guide/safari/sfri11471/mac';
export const linkEdge =
  'https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09';
export const linkGoogleSettings = 'https://adssettings.google.pl';
export const linkFb = 'https://www.facebook.com/privacy/explanation';
export const linkRodoBest = 'https://www.best.com.pl/ochrona-danych-osobowych';
export const linkFbPolicyCookies =
  'https://pl-pl.facebook.com/policies/cookies/';
export const linkToBestPage = 'https://online.best.com.pl';
