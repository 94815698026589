import { SvgIcon } from '@mui/material';

const Preview = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_3723)">
      <path d="M12 -0.0213013C7.82608 -0.0213013 3.84383 2.33186 0.479145 6.78262L0 7.42146L0.479145 8.06029C3.84383 12.511 7.82608 14.8642 12 14.8642C16.1739 14.8642 20.1455 12.5111 23.5102 8.07095L24 7.42146L23.5102 6.78262C20.1455 2.35316 16.1633 -0.0213013 12 -0.0213013ZM8.80567 7.43211C8.80567 6.80034 8.99302 6.18271 9.34402 5.6574C9.69501 5.1321 10.1939 4.72275 10.7776 4.48098C11.3613 4.23921 12.0035 4.17589 12.6232 4.29914C13.2428 4.4224 13.812 4.72664 14.2587 5.17337C14.7055 5.6201 15.0097 6.18924 15.1329 6.80887C15.2562 7.42851 15.1929 8.07086 14.9512 8.65455C14.7094 9.23824 14.3 9.73707 13.7747 10.0881C13.2494 10.4391 12.6318 10.6264 12 10.6264C11.1528 10.6264 10.3403 10.2899 9.74127 9.69086C9.14222 9.09181 8.80567 8.2793 8.80567 7.43211ZM2.68323 7.43211C5.48358 3.98225 8.66725 2.10825 11.9148 2.10825C10.6051 2.16879 9.36374 2.71054 8.42867 3.62963C7.4936 4.54872 6.93057 5.78047 6.84747 7.08897C6.76437 8.39748 7.16704 9.69067 7.97833 10.7207C8.78961 11.7507 9.95245 12.4451 11.244 12.6708C8.2307 12.4366 5.29192 10.6264 2.68323 7.43211ZM12.7666 12.692C14.0586 12.4657 15.2216 11.7704 16.0325 10.7394C16.8433 9.70839 17.2451 8.4143 17.1606 7.10535C17.0762 5.7964 16.5115 4.56465 15.5749 3.6464C14.6382 2.72814 13.3955 2.18804 12.0852 2.12956C15.2795 2.12956 18.4738 3.98227 21.3168 7.45343C18.7081 10.6265 15.7799 12.4366 12.7666 12.756V12.692Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_3723">
        <rect width="24" height="14.8855" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Preview;
