import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWidgetState } from '@livechat/widget-react';
import { Grid, Typography, Link } from '@mui/material';
import {
  AvailabilityStatus,
  VisibilityStatus
} from 'storage/liveChat/liveChat.model';
import { RootDispatch } from 'storage/store';
import { IsLoggedIn } from 'utils/user';
import { Icon } from 'components/Icon/';
import { Modal } from 'components/Modal';
import { IModal } from 'components/Modal/Modal';

const ContactUs = (props: IModal) => {
  const { t } = useTranslation();
  const { liveChat } = useDispatch<RootDispatch>();
  const widgetState = useWidgetState();
  const widgetIsAvailable =
    IsLoggedIn() &&
    widgetState &&
    widgetState.availability === AvailabilityStatus.online;

  return (
    <Modal {...props}>
      <Grid
        container
        rowSpacing={3}
        sx={{
          width: '264px'
        }}
      >
        <Grid item xs={12}>
          <Grid container flexWrap="nowrap">
            <Grid
              item
              xs={1}
              sx={{
                paddingTop: '5px',
                marginRight: '13px'
              }}
            >
              <Icon icon="phone" color="success" />
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  marginBottom: 1
                }}
              >
                {t('MODAL_CONTACT_CONTACTUS')}
              </Typography>
              <Link
                href={`tel:${t('COMMON_PHONE_NUMBER').replace(/ /g, '')}`}
                variant="h4"
                sx={{
                  fontWeight: 900
                }}
              >
                {t('COMMON_PHONE_NUMBER')}
              </Link>
              <Typography
                variant="body1"
                sx={{
                  marginTop: 1
                }}
              >
                {t('MODAL_CONTACT_WEEK')}
                <br />
                {t('MODAL_CONTACT_WEEKEND')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {widgetIsAvailable && (
          <Grid item xs={12}>
            <Grid
              container
              flexWrap="nowrap"
              alignItems="center"
              marginBottom="-10px"
              marginTop="-2px"
            >
              <Grid item xs={1} sx={{ marginRight: '13px', marginTop: '3px' }}>
                <Icon icon="chat" color="success" />
              </Grid>
              <Grid item xs={11} sx={{ paddingBottom: '6px' }}>
                <Link
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    lineHeight: '125%',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    liveChat.setVisibility(VisibilityStatus.maximized)
                  }
                >
                  {t('CHAT_CONVERSATION')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container flexWrap="nowrap" alignItems="center">
            <Grid item xs={1} sx={{ marginRight: '13px' }}>
              <Icon icon="mail" color="success" />
            </Grid>
            <Grid item xs={11} sx={{ paddingBottom: '6px' }}>
              <Link
                href={`mailto:${t('CONTACT_EMAIL')}`}
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textTransform: 'lowercase',
                  lineHeight: '125%'
                }}
              >
                {t('CONTACT_EMAIL')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ContactUs;
