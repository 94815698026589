import { useLocation } from 'react-router-dom';
import { Url } from 'utils/urls';
import { useState } from 'react';

interface ICaseLocation {
  caseUrl: string;
  agreementId: number;
}

const useCaseLocation = () => {
  const { pathname } = useLocation();
  const [, , , agreementId] = pathname.split('/');
  const [caseLocation] = useState<ICaseLocation>({
    caseUrl: `${Url.case}/${agreementId}`,
    agreementId: parseInt(agreementId, 10)
  });
  return caseLocation;
};

export default useCaseLocation;
