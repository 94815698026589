import { format } from 'date-fns';
import { TFunction } from 'react-i18next';
import { ProductParametersKey } from 'storage/agreements/agreements.model';
import { API } from 'utils';
import { formatCurrency } from 'utils/formatCurrency';
import { CurrencyType } from 'utils/formatCurrency/formatCurrency.model';
import getSimulationData from 'utils/getSimulationData/getSimulationData';
import getDebtData from 'utils/getDebtData';
import setPayloadForRecalculateDynamicRemission from 'utils/setPayloadForRecalculateDynamicRemission';
import setPayloadForRecalculatePlusSettlement from 'utils/setPayloadForRecalculatePlusSettlement';
import setPayloadForRecalculateZeroWithPrerequisite from 'utils/setPayloadForRecalculateZeroWithPrerequisite';
import { RootDispatch } from '../store';
import {
  ConfirmDynamicRemissionSimulationRequest,
  ConfirmPlusSimulationRequest,
  ConfirmRemissionSimulationRequest,
  ConfirmSimulationRequest,
  ConfirmSimulationResponse,
  ConfirmZeroSimulationRequest,
  ConfirmZeroWithPrerequisiteSimulationRequest,
  Debt,
  DebtValueChanged,
  DynamicRemissionSimulationParameters,
  DynamicSimulationData,
  OfferResponse,
  PartialSimulationData,
  PlusSimulationData,
  PlusSimulationParameters,
  PrerequisitePaymentData,
  DynamicRemissionValueChanged,
  ProductType,
  RecalculateDynamicRemissionParameters,
  RecalculateParameters,
  RecalculateSimulationChangeItem,
  RecalculateSimulationResponse,
  RecalculateZeroWithPrerequisiteParameters,
  RemissionSimulationParameters,
  SimulationResponse,
  SettlementZeroWithPrerequisiteValueChanged,
  TotalSimulationData,
  TotalSimulationParameters,
  ZeroSimulationData,
  ZeroSimulationParameters,
  Offer,
  ZeroWithPrerequisiteSimulationData,
  ZeroWithPrerequisiteSimulationParameters,
  RecalculatePlusSettlementParameters
} from './product.model';

const endpoint = '/product';

const effects = (dispatch: RootDispatch) => ({
  getOffers({
    agreementId,
    isMaximalOffer = false
  }: {
    agreementId: number;
    isMaximalOffer?: boolean;
  }): Promise<Offer[] | undefined> {
    return API.get<void, OfferResponse>(
      `${endpoint}/offers/${agreementId}?maximalOffer=${isMaximalOffer}`
    )
      .then((response) => {
        dispatch.product.storeAllOffers(response.data);
        return response.data;
      })
      .catch((error) => error);
  },
  getSimulation({
    agreementId,
    productType,
    t,
    currencyType
  }: {
    agreementId: number;
    productType: ProductType;
    t?: TFunction<'translation'>;
    currencyType?: CurrencyType;
  }): Promise<
    | SimulationResponse
    | DynamicSimulationData
    | ZeroWithPrerequisiteSimulationData
    | PlusSimulationData
    | undefined
  > {
    return API.get<void, SimulationResponse>(
      `${endpoint}/simulation/${agreementId}?productTypeId=${productType}`
    )
      .then((response) => {
        const data = getSimulationData(
          response.data.simulationParameters,
          productType,
          t,
          currencyType,
          response.data.pitRequired
        );
        if (!data) {
          return response;
        }
        switch (productType) {
          case ProductType.DUD: {
            dispatch.product.storeDynamicSimulation(
              data as DynamicSimulationData
            );
            dispatch.product.storePitRequired(
              response.data.pitRequired as boolean
            );
            return data;
          }
          case ProductType.CUD: {
            dispatch.product.storePartialSimulation(
              data as PartialSimulationData
            );
            return response;
          }
          case ProductType.SETTLEMENT_ZERO: {
            dispatch.product.storeZeroSimulation(data as ZeroSimulationData);
            return response;
          }
          case ProductType.SETTLEMENT_ZERO_WITH_PREREQUISITE: {
            dispatch.product.storeZeroWithPrerequisiteSimulation(
              data as ZeroWithPrerequisiteSimulationData
            );
            return data as ZeroWithPrerequisiteSimulationData;
          }
          case ProductType.TOTAL_REPAYMENT: {
            dispatch.product.storeTotalSimulation(data as TotalSimulationData);
            return response;
          }
          case ProductType.PLUS_SETTLEMENT: {
            dispatch.product.storePlusSimulation(data as PlusSimulationData);
            dispatch.product.storePitRequired(
              response.data.pitRequired as boolean
            );
            return data;
          }
          default: {
            return response;
          }
        }
      })
      .catch((error) => error);
  },
  getAnnexSimulation({
    agreementId,
    productType,
    t,
    currencyType
  }: {
    agreementId: number;
    productType: ProductType;
    t?: TFunction<'translation'>;
    currencyType?: CurrencyType;
  }): Promise<SimulationResponse> {
    return API.get<void, SimulationResponse>(
      `${endpoint}/simulation/annex/${agreementId}?productTypeId=${productType}`
    )
      .then((response) => {
        const data = getSimulationData(
          response.data.simulationParameters,
          productType,
          t,
          currencyType
        );
        if (!data) {
          return response;
        }
        switch (productType) {
          case ProductType.SETTLEMENT_ZERO: {
            dispatch.product.storeZeroSimulation(data as ZeroSimulationData);
            return response;
          }
          default: {
            return response;
          }
        }
      })
      .catch((error) => error);
  },
  recalculateSimulation({
    agreementId,
    productType,
    debt,
    date,
    valueChanged,
    t,
    currencyType
  }: RecalculateParameters): Promise<Debt | undefined> {
    return API.post<
      RecalculateSimulationChangeItem[],
      RecalculateSimulationResponse
    >(
      `${endpoint}/simulation/${agreementId}/recalculate?productTypeId=${productType}`,
      [
        {
          key: ProductParametersKey.INSTALLMENTS_COUNT,
          value: debt!.amount.toString(),
          valueChanged: valueChanged === DebtValueChanged.amount
        },
        {
          key: ProductParametersKey.INSTALLMENTS_AMOUNT,
          value: formatCurrency(
            'plain',
            debt!.sum,
            undefined,
            false,
            false,
            false
          ) as string,
          valueChanged: valueChanged === DebtValueChanged.sum
        },
        {
          key: ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
          value: date,
          valueChanged: valueChanged === DebtValueChanged.date
        }
      ]
    )
      .then((response) => {
        const data = getSimulationData(
          response.data,
          productType,
          t,
          currencyType
        );
        dispatch.product.storeZeroSimulation(data as ZeroSimulationData);
        return getDebtData(response.data);
      })
      .catch((error) => error);
  },

  recalculatePlusSimulation({
    agreementId,
    currencyType,
    date,
    initialCalculate = false,
    installmentsAmount,
    productType,
    t,
    valueChanged
  }: RecalculatePlusSettlementParameters): Promise<PrerequisitePaymentData> {
    return API.post<
      RecalculateSimulationChangeItem[],
      RecalculateSimulationResponse
    >(
      `${endpoint}/simulation/${agreementId}/recalculate?productTypeId=${productType}&initialSecondStepCalculation=${initialCalculate}`,
      setPayloadForRecalculatePlusSettlement({
        date,
        installmentsAmount,
        valueChanged
      })
    )
      .then((response) => {
        const data = getSimulationData(
          response.data,
          productType,
          t,
          currencyType
        );
        dispatch.product.storePlusSimulation(data as PlusSimulationData);
        return data;
      })
      .catch((error) => error);
  },

  recalculateDynamicRemissionSimulation({
    agreementId,
    productType,
    date,
    prerequisitePaymentAmount,
    valueChanged,
    firstInstallmentDate,
    installmentsAmount,
    installmentsCount,
    t,
    currencyType,
    initialCalculate = false
  }: RecalculateDynamicRemissionParameters): Promise<PrerequisitePaymentData> {
    return API.post<
      RecalculateSimulationChangeItem[],
      RecalculateSimulationResponse
    >(
      `${endpoint}/simulation/${agreementId}/recalculate?productTypeId=${productType}&initialSecondStepCalculation=${initialCalculate}`,
      setPayloadForRecalculateDynamicRemission(
        date,
        prerequisitePaymentAmount,
        valueChanged,
        firstInstallmentDate,
        installmentsCount,
        installmentsAmount
      )
    )
      .then((response) => {
        const data = getSimulationData(
          response.data,
          productType,
          t,
          currencyType
        );
        dispatch.product.storeDynamicSimulation(data as DynamicSimulationData);
        return data;
      })
      .catch((error) => error);
  },
  recalculateZeroWithPrerequisiteSimulation({
    agreementId,
    productType,
    date,
    valueChanged,
    firstInstallmentDate,
    installmentsAmount,
    installmentsCount,
    t,
    currencyType,
    initialCalculate = false
  }: RecalculateZeroWithPrerequisiteParameters): Promise<PrerequisitePaymentData> {
    return API.post<
      RecalculateSimulationChangeItem[],
      RecalculateSimulationResponse
    >(
      `${endpoint}/simulation/${agreementId}/recalculate?productTypeId=${productType}&initialSecondStepCalculation=${initialCalculate}`,
      setPayloadForRecalculateZeroWithPrerequisite(
        date,
        valueChanged,
        firstInstallmentDate,
        installmentsCount,
        installmentsAmount
      )
    )
      .then((response) => {
        const data = getSimulationData(
          response.data,
          productType,
          t,
          currencyType
        );
        dispatch.product.storeZeroWithPrerequisiteSimulation(
          data as ZeroWithPrerequisiteSimulationData
        );
        return data;
      })
      .catch((error) => error);
  },
  confirmZeroSimulation({
    debtValueChanged,
    agreementId,
    productType,
    addressId,
    emailAddressId,
    date,
    debt
  }: ZeroSimulationParameters) {
    return API.post<ConfirmZeroSimulationRequest, ConfirmSimulationResponse>(
      `${endpoint}/simulation/${agreementId}/confirm`,
      {
        productTypeId: productType,
        productParameters: [
          {
            key: ProductParametersKey.INSTALLMENTS_COUNT,
            value: debt.amount.toString(),
            valueChanged: debtValueChanged === DebtValueChanged.amount
          },
          {
            key: ProductParametersKey.INSTALLMENTS_AMOUNT,
            value: formatCurrency(
              'plain',
              debt.sum,
              undefined,
              false,
              false,
              false
            ) as string,
            valueChanged: debtValueChanged === DebtValueChanged.sum
          },
          {
            key: ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
            value: format(date, 'dd.MM.yyyy'),
            valueChanged: false
          }
        ],
        documentDeliveryType: 'EMAIL',
        addressId,
        emailAddressId
      }
    );
  },
  confirmTotalSimulation({
    agreementId,
    date,
    productType
  }: TotalSimulationParameters) {
    return API.post<ConfirmSimulationRequest, ConfirmSimulationResponse>(
      `${endpoint}/simulation/${agreementId}/confirm`,
      {
        productTypeId: productType,
        productParameters: [
          {
            key: ProductParametersKey.PAYOFF_DAY,
            value: format(date, 'dd.MM.yyyy'),
            valueChanged: true
          }
        ],
        documentDeliveryType: 'NONE'
      }
    );
  },
  confirmRemissionSimulation({
    agreementId,
    productType,
    date,
    addressId,
    pitAddressId,
    isRemittedDebtRelatedToBusiness
  }: RemissionSimulationParameters) {
    return API.post<
      ConfirmRemissionSimulationRequest,
      ConfirmSimulationResponse
    >(`${endpoint}/simulation/${agreementId}/confirm`, {
      productTypeId: productType,
      productParameters: [
        {
          key: ProductParametersKey.PAYOFF_DAY,
          value: format(date, 'dd.MM.yyyy'),
          valueChanged: true
        }
      ],
      documentDeliveryType: 'PAPER',
      addressId,
      pitAddressId,
      isRemittedDebtRelatedToBusiness
    });
  },

  confirmPlusSimulation({
    agreementId,
    productType,
    date,
    installmentsAmount,
    emailAddressId,
    addressId,
    pitAddressId,
    valueChanged,
    isRemittedDebtRelatedToBusiness
  }: PlusSimulationParameters) {
    return API.post<ConfirmPlusSimulationRequest, ConfirmSimulationResponse>(
      `${endpoint}/simulation/${agreementId}/confirm`,
      {
        productTypeId: productType,
        productParameters: [
          {
            key: ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
            value: format(date, 'dd.MM.yyyy'),
            valueChanged: true
          },
          {
            key: ProductParametersKey.INSTALLMENTS_AMOUNT,
            value: formatCurrency(
              'plain',
              installmentsAmount,
              undefined,
              false,
              false,
              false
            ) as string,
            valueChanged: valueChanged === DebtValueChanged.amount
          }
        ],
        documentDeliveryType: 'EMAIL',
        addressId,
        pitAddressId,
        emailAddressId,
        isRemittedDebtRelatedToBusiness
      }
    );
  },

  confirmDynamicRemissionSimulation({
    agreementId,
    prerequisiteDate,
    productType,
    firstInstallmentDate,
    prerequisitePaymentAmount,
    installments,
    emailAddressId,
    addressId,
    pitAddressId,
    valueChanged,
    isRemittedDebtRelatedToBusiness
  }: DynamicRemissionSimulationParameters) {
    return API.post<
      ConfirmDynamicRemissionSimulationRequest,
      ConfirmSimulationResponse
    >(`${endpoint}/simulation/${agreementId}/confirm`, {
      productTypeId: productType,
      productParameters: [
        {
          key: ProductParametersKey.PREREQUISITE_PAYMENT_DATE,
          value: format(prerequisiteDate, 'dd.MM.yyyy'),
          valueChanged:
            valueChanged === DynamicRemissionValueChanged.prerequisiteDate
        },
        {
          key: ProductParametersKey.PREREQUISITE_PAYMENT_AMOUNT,
          value: formatCurrency(
            'plain',
            prerequisitePaymentAmount,
            undefined,
            false,
            false,
            false
          ) as string,
          valueChanged:
            valueChanged ===
            DynamicRemissionValueChanged.prerequisitePaymentAmount
        },
        {
          key: ProductParametersKey.INSTALLMENTS_COUNT,
          value: installments.amount.toString(),
          valueChanged:
            valueChanged === DynamicRemissionValueChanged.installmentsCount
        },
        {
          key: ProductParametersKey.INSTALLMENTS_AMOUNT,
          value: formatCurrency(
            'plain',
            installments.sum,
            undefined,
            false,
            false,
            false
          ) as string,
          valueChanged:
            valueChanged === DynamicRemissionValueChanged.installmentsAmount
        },
        {
          key: ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
          value: format(firstInstallmentDate, 'dd.MM.yyyy'),
          valueChanged: false
        }
      ],
      documentDeliveryType: 'EMAIL',
      addressId,
      pitAddressId,
      emailAddressId,
      isRemittedDebtRelatedToBusiness
    });
  },
  confirmZeroWithPrerequisiteSimulation({
    agreementId,
    productType,
    prerequisiteDate,
    installments,
    firstInstallmentDate,
    prerequisitePaymentAmount,
    addressId,
    emailAddressId,
    valueChanged
  }: ZeroWithPrerequisiteSimulationParameters) {
    return API.post<
      ConfirmZeroWithPrerequisiteSimulationRequest,
      ConfirmSimulationResponse
    >(`${endpoint}/simulation/${agreementId}/confirm`, {
      productTypeId: productType,
      productParameters: [
        {
          key: ProductParametersKey.PREREQUISITE_PAYMENT_DATE,
          value: format(prerequisiteDate, 'dd.MM.yyyy'),
          valueChanged:
            valueChanged ===
            SettlementZeroWithPrerequisiteValueChanged.prerequisiteDate
        },
        {
          key: ProductParametersKey.PREREQUISITE_PAYMENT_AMOUNT,
          value: formatCurrency(
            'plain',
            prerequisitePaymentAmount,
            undefined,
            false,
            false,
            false
          ) as string,
          valueChanged: false
        },
        {
          key: ProductParametersKey.INSTALLMENTS_COUNT,
          value: installments.amount.toString(),
          valueChanged:
            valueChanged ===
            SettlementZeroWithPrerequisiteValueChanged.installmentsCount
        },
        {
          key: ProductParametersKey.INSTALLMENTS_AMOUNT,
          value: formatCurrency(
            'plain',
            installments.sum,
            undefined,
            false,
            false,
            false
          ) as string,
          valueChanged:
            valueChanged ===
            SettlementZeroWithPrerequisiteValueChanged.installmentsAmount
        },
        {
          key: ProductParametersKey.FIRST_INSTALLMENT_PAYMENT,
          value: format(firstInstallmentDate, 'dd.MM.yyyy'),
          valueChanged: false
        }
      ],
      documentDeliveryType: 'EMAIL',
      addressId,
      emailAddressId
    });
  }
});

export default effects;
