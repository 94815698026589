import { SvgIcon } from '@mui/material';

const Arrow = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_730_4145)">
      <path d="M9.08989 13.4831L4.31461 8.66292H24V6.41573H4.26966L9.08989 1.58427L7.49438 0L0 7.49438L7.49438 15.0674L9.08989 13.4831Z" />
    </g>
    <defs>
      <clipPath id="clip0_730_4145">
        <rect width="24" height="15.0674" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Arrow;
