import styled from 'styled-components/macro';
import { Container, ContainerProps } from '@mui/material';

export const ContentContainer = styled(Container)<ContainerProps>(
  ({ theme }) => ({
    height: '100%',
    overflow: 'auto',
    padding: '0 42px',
    [theme.breakpoints.down('md')]: {
      padding: '0 24px'
    }
  })
);
