const Balloon = ({ ...props }) => (
  <svg
    {...props}
    width="474"
    height="360"
    viewBox="0 0 474 360"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3771_22038)">
      <path
        d="M168.441 83.7667C168.245 83.7665 168.055 83.6918 167.912 83.5578C167.768 83.4238 167.681 83.2404 167.667 83.0444C167.159 76.1448 164.062 69.6914 158.997 64.9755C153.931 60.2596 147.271 57.6292 140.347 57.6106C139.519 57.6106 138.649 57.6527 137.76 57.7357C137.612 57.7496 137.462 57.7205 137.33 57.6519C137.198 57.5832 137.088 57.4779 137.014 57.3485C133.834 51.786 129.085 47.2826 123.359 44.4003C117.634 41.5179 111.186 40.3843 104.821 41.1409C98.4547 41.8975 92.4528 44.5108 87.5638 48.6546C82.6749 52.7985 79.1154 58.2894 77.3296 64.4421C77.2825 64.607 77.1818 64.7515 77.0434 64.8529C76.9051 64.9544 76.7369 65.0069 76.5654 65.0024L76.3587 64.9961C76.2512 64.9925 76.1438 64.9887 76.0355 64.9887C70.7844 65.0052 65.6968 66.8153 61.6168 70.1187C57.5369 73.4222 54.7098 78.0203 53.6047 83.1503C53.568 83.3246 53.4723 83.481 53.3339 83.5932C53.1954 83.7055 53.0225 83.7667 52.8443 83.7667H0.777201C0.571436 83.7662 0.374287 83.6841 0.228982 83.5385C0.0836769 83.3929 0.0020752 83.1957 0.0020752 82.9901C0.0020752 82.7844 0.0836769 82.5872 0.228982 82.4416C0.374287 82.296 0.571436 82.214 0.777201 82.2134H52.2223C53.5174 76.8776 56.5637 72.129 60.875 68.7254C65.1864 65.3218 70.5143 63.4595 76.0087 63.4355C77.9646 57.1128 81.716 51.4939 86.8069 47.2613C91.8978 43.0287 98.1092 40.3646 104.686 39.5926C111.264 38.8207 117.924 39.9742 123.857 42.9129C129.791 45.8516 134.743 50.4491 138.112 56.1468C138.876 56.0873 139.626 56.0573 140.347 56.0573C147.664 56.0771 154.702 58.857 160.055 63.8407C165.408 68.8244 168.681 75.6443 169.217 82.9357C169.224 83.0374 169.211 83.1396 169.179 83.2363C169.147 83.333 169.096 83.4225 169.029 83.4995C168.962 83.5765 168.88 83.6395 168.789 83.6851C168.698 83.7306 168.598 83.7577 168.496 83.7648C168.478 83.7661 168.459 83.7667 168.441 83.7667Z"
        fill="#CBCBCB"
      />
      <path
        d="M159.194 93.7244H31.736C31.5299 93.7244 31.3322 93.6426 31.1865 93.497C31.0407 93.3513 30.9589 93.1538 30.9589 92.9478C30.9589 92.7418 31.0407 92.5443 31.1865 92.3986C31.3322 92.253 31.5299 92.1711 31.736 92.1711H159.194C159.4 92.1711 159.598 92.253 159.744 92.3986C159.889 92.5443 159.971 92.7418 159.971 92.9478C159.971 93.1538 159.889 93.3513 159.744 93.497C159.598 93.6426 159.4 93.7244 159.194 93.7244Z"
        fill="#CBCBCB"
      />
      <path
        d="M62.8233 104.986H20.0782C19.976 104.986 19.8747 104.966 19.7802 104.927C19.6856 104.888 19.5997 104.831 19.5273 104.759C19.4549 104.687 19.3975 104.601 19.3583 104.507C19.3191 104.413 19.299 104.311 19.299 104.209C19.299 104.107 19.3191 104.006 19.3583 103.912C19.3975 103.817 19.4549 103.732 19.5273 103.659C19.5997 103.587 19.6856 103.53 19.7802 103.491C19.8747 103.452 19.976 103.432 20.0782 103.433H62.8233C63.0291 103.433 63.2262 103.515 63.3715 103.661C63.5168 103.806 63.5984 104.004 63.5984 104.209C63.5984 104.415 63.5168 104.612 63.3715 104.758C63.2262 104.903 63.0291 104.985 62.8233 104.986Z"
        fill="#CBCBCB"
      />
      <path
        d="M102.788 256.101C102.788 287.675 69.6627 302.784 69.6627 302.784C69.6627 302.784 36.1977 287.675 35.5182 256.101C35.1188 237.542 50.5771 222.489 69.1531 222.489C73.5701 222.489 77.9439 223.358 82.0246 225.048C86.1054 226.737 89.8133 229.212 92.9366 232.334C96.0599 235.455 98.5374 239.16 100.228 243.238C101.918 247.316 102.788 251.687 102.788 256.101Z"
        fill="#F2F2F2"
      />
      <path
        d="M80.8746 304.651H58.4511L55.7329 293.787H83.5922L80.8746 304.651Z"
        fill="#CBCBCB"
      />
      <path
        d="M80.1099 323.231H59.2155V334.112H80.1099V323.231Z"
        fill="white"
      />
      <path
        d="M80.2797 334.282H59.0455V323.061H80.2797V334.282ZM59.3853 333.942H79.94V323.401H59.3853V333.942Z"
        fill="#CBCBCB"
      />
      <path
        d="M73.9459 305.932H65.3799L64.3969 304.388H74.9289L73.9459 305.932Z"
        fill="#CBCBCB"
      />
      <path
        d="M58.9607 304.651H58.621V321.287H58.9607V304.651Z"
        fill="#CBCBCB"
      />
      <path
        d="M80.7045 304.651H80.3647V321.287H80.7045V304.651Z"
        fill="#CBCBCB"
      />
      <path
        d="M73.0682 293.603L72.8607 293.334C82.6757 285.77 94.3756 272.812 94.3756 254.035C94.3835 247.458 92.448 241.025 88.8119 235.544C85.1759 230.062 80.0011 225.775 73.9366 223.221L74.069 222.908C80.195 225.488 85.4223 229.819 89.0952 235.356C92.7682 240.893 94.7233 247.391 94.7154 254.035C94.7154 272.95 82.9435 285.992 73.0682 293.603Z"
        fill="#CBCBCB"
      />
      <path
        d="M62.1962 293.603L61.9888 293.334C71.8038 285.77 83.5038 272.812 83.5038 254.035C83.5064 247.502 81.5942 241.112 78.0032 235.654C74.4122 230.195 69.2998 225.907 63.2975 223.32L63.4322 223.008C69.4954 225.622 74.6597 229.953 78.2871 235.467C81.9145 240.981 83.8462 247.436 83.8435 254.035C83.8435 272.95 72.0716 285.992 62.1962 293.603Z"
        fill="#CBCBCB"
      />
      <path
        d="M76.1099 293.603C66.2345 285.992 54.4626 272.95 54.4626 254.035C54.46 247.436 56.3916 240.981 60.0191 235.467C63.6466 229.953 68.811 225.622 74.8743 223.008L75.009 223.32C69.0066 225.907 63.8941 230.195 60.3031 235.654C56.712 241.112 54.7997 247.502 54.8024 254.035C54.8024 272.812 66.5023 285.77 76.3173 293.334L76.1099 293.603Z"
        fill="#CBCBCB"
      />
      <path
        d="M65.238 293.603C55.3626 285.993 43.5907 272.95 43.5907 254.035C43.5797 247.365 45.5485 240.842 49.2483 235.291C52.948 229.74 58.2123 225.41 64.3751 222.85L64.506 223.163C58.4052 225.698 53.1937 229.984 49.5312 235.479C45.8686 240.975 43.9195 247.432 43.9305 254.035C43.9305 272.812 55.6304 285.77 65.4454 293.334L65.238 293.603Z"
        fill="#CBCBCB"
      />
      <path
        d="M69.3229 222.489H68.9832V293.787H69.3229V222.489Z"
        fill="#CBCBCB"
      />
      <path
        d="M65.3253 320.764L59.0953 326.99L59.3355 327.23L65.5655 321.004L65.3253 320.764Z"
        fill="#CBCBCB"
      />
      <path
        d="M68.354 320.66L59.093 330.394L59.3392 330.627L68.6001 320.894L68.354 320.66Z"
        fill="#CBCBCB"
      />
      <path
        d="M82.0634 320.778H56.5824V323.833H82.0634V320.778Z"
        fill="white"
      />
      <path
        d="M82.2334 324.003H56.4126V320.608H82.2334V324.003ZM56.7523 323.664H81.8936V320.948H56.7523V323.664Z"
        fill="#CBCBCB"
      />
      <path
        d="M166.895 176.081C166.895 196.922 145.031 206.894 145.031 206.894C145.031 206.894 122.942 196.922 122.494 176.081C122.23 163.831 132.433 153.896 144.694 153.896C150.582 153.896 156.229 156.233 160.393 160.394C164.556 164.554 166.895 170.197 166.895 176.081Z"
        fill="#F2F2F2"
      />
      <path
        d="M152.431 208.126H137.63L135.837 200.955H154.225L152.431 208.126Z"
        fill="#CBCBCB"
      />
      <path d="M151.926 220.39H138.135V227.572H151.926V220.39Z" fill="white" />
      <path
        d="M152.039 227.684H138.023V220.278H152.039V227.684ZM138.247 227.46H151.814V220.502H138.247V227.46Z"
        fill="#CBCBCB"
      />
      <path
        d="M147.858 208.972H142.204L141.555 207.953H148.507L147.858 208.972Z"
        fill="#CBCBCB"
      />
      <path
        d="M137.967 208.126H137.743V219.107H137.967V208.126Z"
        fill="#CBCBCB"
      />
      <path
        d="M152.319 208.126H152.095V219.107H152.319V208.126Z"
        fill="#CBCBCB"
      />
      <path
        d="M147.279 200.834L147.142 200.656C153.62 195.664 161.342 187.111 161.342 174.717C161.348 170.376 160.07 166.131 157.67 162.512C155.27 158.894 151.855 156.065 147.852 154.379L147.939 154.172C151.983 155.875 155.433 158.734 157.857 162.389C160.282 166.044 161.572 170.332 161.567 174.717C161.567 187.202 153.797 195.811 147.279 200.834Z"
        fill="#CBCBCB"
      />
      <path
        d="M140.103 200.834L139.966 200.656C146.444 195.664 154.167 187.111 154.167 174.717C154.168 170.406 152.906 166.188 150.536 162.585C148.166 158.982 144.791 156.152 140.83 154.444L140.918 154.239C144.92 155.964 148.329 158.822 150.723 162.462C153.118 166.101 154.393 170.362 154.391 174.717C154.391 187.202 146.621 195.811 140.103 200.834Z"
        fill="#CBCBCB"
      />
      <path
        d="M149.286 200.834C142.768 195.811 134.998 187.202 134.998 174.717C134.996 170.362 136.271 166.101 138.666 162.462C141.06 158.822 144.469 155.964 148.471 154.239L148.56 154.444C144.598 156.152 141.223 158.982 138.853 162.585C136.483 166.188 135.221 170.406 135.222 174.717C135.222 187.111 142.945 195.664 149.423 200.656L149.286 200.834Z"
        fill="#CBCBCB"
      />
      <path
        d="M142.11 200.834C135.592 195.811 127.822 187.202 127.822 174.717C127.815 170.315 129.114 166.01 131.556 162.346C133.998 158.682 137.473 155.824 141.541 154.134L141.627 154.341C137.6 156.014 134.161 158.843 131.743 162.47C129.326 166.097 128.039 170.359 128.046 174.717C128.046 187.111 135.769 195.664 142.247 200.656L142.11 200.834Z"
        fill="#CBCBCB"
      />
      <path
        d="M144.807 153.896H144.582V200.955H144.807V153.896Z"
        fill="#CBCBCB"
      />
      <path
        d="M142.168 218.761L138.056 222.871L138.214 223.029L142.326 218.92L142.168 218.761Z"
        fill="#CBCBCB"
      />
      <path
        d="M144.167 218.693L138.054 225.118L138.217 225.272L144.329 218.848L144.167 218.693Z"
        fill="#CBCBCB"
      />
      <path
        d="M153.216 218.771H136.397V220.788H153.216V218.771Z"
        fill="white"
      />
      <path
        d="M153.328 220.9H136.285V218.659H153.328V220.9ZM136.509 220.676H153.104V218.883H136.509V220.676Z"
        fill="#CBCBCB"
      />
      <path
        d="M352.761 204.574C352.902 204.574 353.037 204.52 353.14 204.424C353.243 204.328 353.306 204.197 353.316 204.056C353.68 199.111 355.9 194.485 359.531 191.105C363.162 187.724 367.936 185.839 372.898 185.826C373.492 185.826 374.116 185.856 374.753 185.915C374.859 185.925 374.966 185.904 375.061 185.855C375.156 185.806 375.235 185.731 375.288 185.638C377.567 181.651 380.971 178.423 385.075 176.357C389.179 174.291 393.801 173.478 398.363 174.02C402.926 174.563 407.229 176.436 410.733 179.406C414.237 182.376 416.789 186.312 418.069 190.722C418.102 190.841 418.175 190.944 418.274 191.017C418.373 191.09 418.494 191.127 418.616 191.124L418.765 191.12C418.842 191.117 418.919 191.114 418.996 191.114C422.76 191.126 426.407 192.423 429.331 194.791C432.256 197.159 434.282 200.455 435.074 204.132C435.101 204.257 435.169 204.369 435.269 204.45C435.368 204.53 435.492 204.574 435.619 204.574H472.94C473.014 204.574 473.086 204.56 473.154 204.532C473.222 204.504 473.284 204.463 473.336 204.412C473.387 204.36 473.429 204.298 473.457 204.231C473.485 204.163 473.499 204.091 473.499 204.017C473.499 203.944 473.485 203.872 473.457 203.804C473.429 203.736 473.387 203.675 473.336 203.623C473.284 203.571 473.222 203.53 473.154 203.503C473.086 203.475 473.014 203.46 472.94 203.461H436.065C435.137 199.636 432.953 196.232 429.863 193.793C426.773 191.353 422.954 190.018 419.015 190.001C417.613 185.469 414.925 181.441 411.275 178.407C407.626 175.373 403.174 173.464 398.46 172.911C393.745 172.357 388.971 173.184 384.718 175.291C380.465 177.397 376.915 180.692 374.501 184.776C373.953 184.734 373.415 184.712 372.898 184.712C367.654 184.726 362.609 186.719 358.772 190.291C354.935 193.864 352.589 198.752 352.205 203.978C352.199 204.051 352.209 204.125 352.232 204.194C352.255 204.263 352.292 204.327 352.34 204.382C352.388 204.438 352.446 204.483 352.512 204.516C352.577 204.548 352.648 204.568 352.721 204.573C352.735 204.574 352.748 204.574 352.761 204.574Z"
        fill="#CBCBCB"
      />
      <path
        d="M359.389 211.712H450.749C450.897 211.712 451.039 211.653 451.143 211.549C451.248 211.444 451.307 211.303 451.307 211.155C451.307 211.007 451.248 210.866 451.143 210.761C451.039 210.657 450.897 210.598 450.749 210.598H359.389C359.241 210.598 359.1 210.657 358.995 210.761C358.891 210.866 358.832 211.007 358.832 211.155C358.832 211.303 358.891 211.444 358.995 211.549C359.1 211.653 359.241 211.712 359.389 211.712Z"
        fill="#CBCBCB"
      />
      <path
        d="M428.467 219.784H459.106C459.253 219.783 459.394 219.724 459.499 219.62C459.603 219.516 459.661 219.374 459.661 219.227C459.661 219.08 459.603 218.938 459.499 218.834C459.394 218.73 459.253 218.671 459.106 218.67H428.467C428.393 218.67 428.321 218.685 428.253 218.713C428.186 218.741 428.124 218.782 428.073 218.833C428.021 218.885 427.98 218.947 427.952 219.014C427.924 219.082 427.909 219.154 427.909 219.227C427.909 219.3 427.924 219.373 427.952 219.44C427.98 219.508 428.021 219.569 428.073 219.621C428.124 219.672 428.186 219.713 428.253 219.741C428.321 219.769 428.393 219.784 428.467 219.784Z"
        fill="#CBCBCB"
      />
      <path
        d="M286.094 354.106H284.26L283.387 347.039H286.094V354.106Z"
        fill="#9F616A"
      />
      <path
        d="M286.562 355.883H280.649V355.808C280.65 355.198 280.892 354.614 281.324 354.182C281.755 353.751 282.34 353.509 282.95 353.508H286.562V355.883Z"
        fill="#2F2E41"
      />
      <path
        d="M309.741 350.634L308.169 351.578L303.778 345.968L306.098 344.575L309.741 350.634Z"
        fill="#9F616A"
      />
      <path
        d="M305.989 354.959L305.95 354.895C305.637 354.372 305.543 353.746 305.691 353.154C305.839 352.563 306.215 352.054 306.738 351.739L309.833 349.881L311.057 351.916L305.989 354.959Z"
        fill="#2F2E41"
      />
      <path
        d="M286.571 352.648L286.539 352.648C285.647 352.645 284.756 352.594 283.869 352.494C283.532 352.461 283.165 352.425 282.723 352.388L282.708 352.385C282.407 352.296 282.399 349.472 282.526 339.806C282.592 334.856 282.666 329.245 282.578 325.671L282.577 325.636L282.603 325.613C284.198 324.216 286.101 323.215 288.157 322.694C290.213 322.172 292.363 322.144 294.432 322.612L294.464 322.619L294.481 322.647C296.283 325.694 299.004 330.084 301.636 334.329C307.677 344.073 309.404 346.939 309.215 347.155L309.192 347.173C308.78 347.379 308.47 347.537 308.207 347.672C307.164 348.212 306.094 348.694 304.999 349.118L304.951 349.138L304.915 349.099C298.786 342.387 293.685 334.89 290.53 327.959C290.162 330.325 289.714 333.415 289.241 336.68C287.882 346.067 286.963 352.249 286.593 352.625L286.571 352.648Z"
        fill="#2F2E41"
      />
      <path
        d="M288.222 326.393C291.877 326.377 295.508 326.984 298.958 328.189C299.078 322.401 297.137 316.758 293.48 312.268L293.453 312.236L293.467 312.196C294.43 309.416 295.425 306.542 296.314 303.683L296.301 303.635C295.677 301.419 294.338 299.18 292.208 296.79C291.977 296.55 291.664 296.408 291.331 296.391C290.999 296.374 290.673 296.484 290.419 296.699C288.552 298.404 287.099 300.512 286.168 302.861C285.237 305.211 284.854 307.742 285.046 310.262L285.048 310.276L285.043 310.29C283.375 315.937 282.77 318.837 282.236 321.396C281.846 323.263 281.509 324.879 280.831 327.188C283.259 326.66 285.737 326.394 288.222 326.393Z"
        fill="#CBCBCB"
      />
      <path
        opacity="0.1"
        d="M293.603 308.543L288.177 320.348L285.077 317.212L293.603 308.543Z"
        fill="black"
      />
      <path
        d="M291.283 295.64C293.415 295.64 295.144 293.913 295.144 291.783C295.144 289.652 293.415 287.925 291.283 287.925C289.151 287.925 287.423 289.652 287.423 291.783C287.423 293.913 289.151 295.64 291.283 295.64Z"
        fill="#9F616A"
      />
      <path
        d="M286.603 289.604H292.733V286.934C291.387 286.4 290.071 285.945 289.275 286.934C288.566 286.934 287.886 287.215 287.385 287.716C286.884 288.216 286.603 288.896 286.603 289.604Z"
        fill="#2F2E41"
      />
      <path
        d="M293.072 286.462C296.736 286.462 297.762 291.053 297.762 293.642C297.762 295.086 297.109 295.603 296.082 295.778L295.719 293.845L294.869 295.861C294.581 295.862 294.278 295.857 293.964 295.851L293.676 295.258L293.033 295.84C290.461 295.844 288.382 296.219 288.382 293.642C288.382 291.053 289.281 286.462 293.072 286.462Z"
        fill="#2F2E41"
      />
      <path
        d="M403.761 108.238C403.761 209.916 297.089 258.568 297.089 258.568C297.089 258.568 189.323 209.916 187.135 108.238C185.849 48.4736 235.628 0 295.448 0C355.267 0 403.761 48.4598 403.761 108.238Z"
        fill="#D4E0DF"
      />
      <path
        d="M299.377 353.308H297.128L296.059 344.641H299.377V353.308Z"
        fill="#FFB7B7"
      />
      <path
        d="M295.523 352.666H299.859V355.394H292.792C292.792 355.036 292.863 354.681 293 354.35C293.137 354.019 293.339 353.718 293.592 353.465C293.846 353.211 294.147 353.01 294.478 352.873C294.809 352.736 295.164 352.666 295.523 352.666Z"
        fill="#2F2E41"
      />
      <path
        d="M307.814 353.308H305.565L304.495 344.641H307.814L307.814 353.308Z"
        fill="#FFB7B7"
      />
      <path
        d="M303.959 352.666H308.295V355.394H301.229C301.229 354.67 301.516 353.976 302.028 353.465C302.54 352.953 303.235 352.666 303.959 352.666Z"
        fill="#2F2E41"
      />
      <path
        d="M307.539 327.495C307.297 327.34 307.092 327.133 306.938 326.889C306.784 326.646 306.686 326.372 306.65 326.086C306.614 325.8 306.641 325.511 306.729 325.237C306.817 324.963 306.965 324.711 307.161 324.5L305.68 303.505L309.945 303.918L310.062 324.479C310.395 324.831 310.587 325.294 310.601 325.778C310.616 326.262 310.452 326.735 310.141 327.107C309.83 327.479 309.394 327.724 308.914 327.795C308.434 327.867 307.945 327.76 307.539 327.495Z"
        fill="#FFB7B7"
      />
      <path
        d="M299.271 348.941L296.796 348.824C296.585 348.814 296.386 348.723 296.24 348.571C296.093 348.419 296.011 348.217 296.01 348.006L295.837 322.978C295.836 322.859 295.861 322.742 295.91 322.634C295.959 322.525 296.03 322.429 296.12 322.351C296.21 322.273 296.315 322.215 296.429 322.181C296.543 322.148 296.663 322.139 296.781 322.156L306.683 323.593C306.879 323.62 307.058 323.717 307.188 323.866C307.318 324.015 307.39 324.205 307.39 324.403L308.664 347.593C308.664 347.702 308.644 347.81 308.603 347.911C308.561 348.012 308.501 348.103 308.424 348.18C308.347 348.258 308.256 348.319 308.156 348.361C308.055 348.403 307.947 348.424 307.838 348.424H305.17C304.973 348.425 304.782 348.355 304.633 348.227C304.483 348.099 304.384 347.922 304.355 347.728C304.355 347.728 302.339 330.333 302.062 330.343C301.784 330.348 300.13 348.216 300.13 348.216C300.105 348.416 300.008 348.6 299.857 348.734C299.706 348.868 299.512 348.942 299.31 348.943C299.297 348.943 299.284 348.942 299.271 348.941Z"
        fill="#2F2E41"
      />
      <path
        d="M306.646 310.559C306.546 310.488 306.463 310.394 306.404 310.286C306.345 310.179 306.311 310.059 306.305 309.936L305.992 304.277C305.961 303.717 306.139 303.164 306.492 302.727C306.844 302.29 307.346 301.998 307.901 301.909C308.455 301.819 309.024 301.938 309.496 302.243C309.968 302.547 310.31 303.016 310.457 303.558L311.83 308.617C311.887 308.828 311.858 309.053 311.749 309.243C311.64 309.432 311.461 309.571 311.25 309.629L307.345 310.687C307.226 310.719 307.102 310.724 306.981 310.702C306.86 310.68 306.745 310.632 306.646 310.559Z"
        fill="#3F3D56"
      />
      <path
        d="M302.998 297.111C305.486 297.111 307.503 295.095 307.503 292.609C307.503 290.123 305.486 288.108 302.998 288.108C300.51 288.108 298.494 290.123 298.494 292.609C298.494 295.095 300.51 297.111 302.998 297.111Z"
        fill="#FFB7B7"
      />
      <path
        d="M306.186 296.171C305.997 296.374 305.755 296.519 305.487 296.589C305.219 296.659 304.937 296.651 304.673 296.566C304.409 296.481 304.175 296.323 303.999 296.11C303.822 295.897 303.71 295.638 303.675 295.363C303.667 295.268 303.667 295.173 303.677 295.078C303.734 294.536 304.047 294.045 303.972 293.473C303.955 293.331 303.902 293.196 303.818 293.08C303.148 292.184 301.576 293.48 300.944 292.669C300.556 292.172 301.012 291.389 300.715 290.833C300.322 290.099 299.16 290.461 298.431 290.059C297.619 289.612 297.668 288.368 298.202 287.612C298.549 287.165 298.991 286.8 299.496 286.543C300.001 286.286 300.556 286.144 301.122 286.126C302.256 286.1 303.382 286.319 304.422 286.77C305.651 287.169 306.735 287.92 307.54 288.93C307.957 289.592 308.221 290.339 308.313 291.115C308.405 291.892 308.323 292.679 308.074 293.421C307.801 294.33 306.872 295.44 306.186 296.171Z"
        fill="#2F2E41"
      />
      <path
        d="M307.494 300.118C307.416 300.061 307.336 300.006 307.255 299.952C306.822 299.665 306.354 299.435 305.862 299.269V298.087H302.377V299.184C301.222 299.502 300.186 300.153 299.398 301.055C298.61 301.958 298.105 303.071 297.946 304.258L295.573 321.862C295.558 321.972 295.565 322.084 295.594 322.191C295.624 322.298 295.674 322.398 295.744 322.485C295.811 322.572 295.896 322.643 295.992 322.696C296.088 322.749 296.194 322.781 296.304 322.792C297.966 323.007 299.568 323.555 301.013 324.404C302.063 324.985 303.244 325.286 304.444 325.279C305.397 325.275 306.341 325.107 307.236 324.782C307.388 324.726 307.52 324.627 307.615 324.496C307.71 324.365 307.765 324.21 307.772 324.048C307.857 322.154 308.357 312.402 309.812 306.454C310.095 305.287 310.025 304.063 309.613 302.935C309.201 301.807 308.463 300.827 307.494 300.118Z"
        fill="#3F3D56"
      />
      <path
        d="M333.193 264.581H260.985L252.232 229.595H341.946L333.193 264.581Z"
        fill="#3F3D56"
      />
      <path
        d="M330.732 324.414H263.446V359.453H330.732V324.414Z"
        fill="white"
      />
      <path
        d="M331.279 360H262.899V323.867H331.279V360ZM263.993 358.907H330.185V324.96H263.993V358.907Z"
        fill="#3F3D56"
      />
      <path
        d="M310.882 268.707H283.297L280.131 263.736H314.047L310.882 268.707Z"
        fill="#3F3D56"
      />
      <path
        d="M262.626 264.582H261.532V318.154H262.626V264.582Z"
        fill="#3F3D56"
      />
      <path
        d="M332.646 264.582H331.552V318.154H332.646V264.582Z"
        fill="#3F3D56"
      />
      <path
        d="M308.056 229.003L307.388 228.137C338.994 203.78 376.671 162.053 376.671 101.584C376.696 80.4061 370.463 59.6917 358.754 42.0391C347.045 24.3866 330.381 10.5817 310.852 2.35595L311.279 1.34912C331.006 9.65839 347.839 23.6033 359.667 41.435C371.495 59.2666 377.79 80.1912 377.765 101.584C377.765 162.497 339.857 204.496 308.056 229.003Z"
        fill="#3F3D56"
      />
      <path
        d="M273.045 229.003L272.377 228.137C303.984 203.781 341.661 162.053 341.661 101.584C341.669 80.5485 335.511 59.9708 323.947 42.3931C312.383 24.8155 295.92 11.0079 276.591 2.67599L277.025 1.67236C296.55 10.0889 313.18 24.0365 324.862 41.7925C336.543 59.5485 342.763 80.335 342.755 101.584C342.755 162.497 304.846 204.497 273.045 229.003Z"
        fill="#3F3D56"
      />
      <path
        d="M317.85 229.003C286.049 204.496 248.141 162.497 248.141 101.584C248.132 80.3349 254.353 59.5482 266.034 41.7922C277.716 24.0361 294.346 10.0884 313.872 1.67212L314.305 2.67574C294.976 11.0075 278.513 24.8151 266.949 42.3928C255.385 59.9705 249.227 80.5483 249.235 101.584C249.235 162.053 286.912 203.78 318.518 228.137L317.85 229.003Z"
        fill="#3F3D56"
      />
      <path
        d="M282.84 229.003C251.039 204.496 213.131 162.497 213.131 101.584C213.095 80.1067 219.436 59.1012 231.35 41.2255C243.264 23.3498 260.216 9.40741 280.062 1.16235L280.483 2.17131C260.837 10.3335 244.055 24.1358 232.261 41.8319C220.466 59.528 214.19 80.3225 214.225 101.584C214.225 162.053 251.902 203.78 283.508 228.137L282.84 229.003Z"
        fill="#3F3D56"
      />
      <path d="M295.995 0H294.901V229.596H295.995V0Z" fill="#3F3D56" />
      <path
        d="M283.122 316.469L263.06 336.517L263.833 337.29L283.895 317.242L283.122 316.469Z"
        fill="#3F3D56"
      />
      <path
        d="M292.874 316.135L263.052 347.479L263.845 348.233L293.667 316.889L292.874 316.135Z"
        fill="#3F3D56"
      />
      <path
        d="M337.022 316.514H254.967V326.354H337.022V316.514Z"
        fill="white"
      />
      <path
        d="M337.57 326.9H254.42V315.967H337.57V326.9ZM255.514 325.807H336.475V317.061H255.514V325.807Z"
        fill="#3F3D56"
      />
      <path
        d="M281.506 321.09C281.726 321.075 281.939 321.012 282.131 320.906C282.324 320.799 282.49 320.652 282.619 320.474C282.748 320.296 282.837 320.092 282.879 319.876C282.92 319.66 282.914 319.438 282.861 319.225L287.331 316.298L284.782 315.197L280.927 318.146C280.575 318.259 280.276 318.498 280.089 318.817C279.901 319.135 279.836 319.512 279.908 319.875C279.979 320.238 280.181 320.562 280.476 320.786C280.771 321.01 281.137 321.119 281.506 321.09H281.506Z"
        fill="#9F616A"
      />
      <path
        d="M285.808 318.982L285.752 318.942C284.688 318.159 283.507 317.35 282.364 316.567L282.296 316.52L282.35 316.457C284.788 313.606 287.229 310.629 289.59 307.748L289.585 307.738L289.607 307.726L289.617 307.715L289.622 307.719L289.636 307.712L289.572 307.717L288.844 302.014C288.484 301.421 288.3 300.738 288.314 300.044C288.328 299.351 288.539 298.676 288.922 298.097C289.364 297.474 290.02 297.035 290.766 296.864C291.511 296.694 292.293 296.805 292.962 297.176C293.292 297.397 293.568 297.69 293.769 298.032C293.971 298.375 294.093 298.758 294.126 299.154C294.172 299.506 294.14 299.865 294.03 300.203C293.921 300.542 293.737 300.852 293.492 301.11C293.744 303.877 293.855 306.656 293.825 309.435L293.825 309.461L293.809 309.482C291.292 312.677 288.527 315.856 285.853 318.929L285.808 318.982Z"
        fill="#CBCBCB"
      />
      <path
        d="M286.675 301.453C286.697 301.552 286.712 301.653 286.719 301.754L294.597 306.296L296.512 305.194L298.554 307.865L294.457 310.782L285.469 303.697C285.102 303.832 284.703 303.853 284.324 303.756C283.945 303.66 283.605 303.45 283.349 303.155C283.092 302.861 282.931 302.495 282.888 302.107C282.844 301.719 282.92 301.326 283.105 300.982C283.29 300.638 283.575 300.359 283.923 300.18C284.271 300.002 284.665 299.934 285.053 299.986C285.44 300.037 285.803 300.204 286.093 300.467C286.382 300.729 286.585 301.073 286.675 301.453H286.675Z"
        fill="#FFB7B7"
      />
      <path
        d="M294.878 305.909C294.865 305.787 294.88 305.663 294.921 305.548C294.962 305.432 295.028 305.326 295.116 305.24L299.125 301.232C299.523 300.835 300.053 300.599 300.614 300.569C301.175 300.54 301.727 300.719 302.164 301.072C302.601 301.426 302.891 301.928 302.979 302.482C303.067 303.037 302.947 303.604 302.641 304.075L299.785 308.473C299.666 308.656 299.479 308.784 299.265 308.83C299.051 308.876 298.827 308.834 298.644 308.716L295.25 306.515C295.147 306.448 295.06 306.359 294.995 306.254C294.931 306.149 294.891 306.032 294.878 305.909Z"
        fill="#3F3D56"
      />
      <path
        d="M240.323 266.893C240.182 266.893 240.046 266.839 239.943 266.743C239.84 266.647 239.778 266.515 239.768 266.375C239.404 261.429 237.184 256.804 233.553 253.423C229.922 250.043 225.148 248.158 220.186 248.144C219.592 248.144 218.968 248.174 218.331 248.234C218.225 248.244 218.118 248.223 218.023 248.174C217.928 248.125 217.849 248.049 217.796 247.956C215.517 243.969 212.113 240.741 208.009 238.675C203.905 236.609 199.283 235.797 194.72 236.339C190.158 236.881 185.855 238.755 182.351 241.725C178.847 244.695 176.295 248.631 175.015 253.041C174.982 253.159 174.909 253.263 174.81 253.336C174.711 253.408 174.59 253.446 174.468 253.443L174.319 253.438C174.242 253.436 174.165 253.433 174.088 253.433C170.324 253.445 166.677 254.742 163.753 257.11C160.828 259.478 158.802 262.774 158.01 266.451C157.983 266.576 157.915 266.688 157.816 266.768C157.716 266.849 157.592 266.893 157.465 266.893H120.144C120.07 266.893 119.998 266.879 119.93 266.851C119.862 266.823 119.8 266.782 119.749 266.73C119.697 266.679 119.655 266.617 119.627 266.549C119.599 266.482 119.585 266.409 119.585 266.336C119.585 266.263 119.599 266.19 119.627 266.123C119.655 266.055 119.697 265.993 119.749 265.942C119.8 265.89 119.862 265.849 119.93 265.821C119.998 265.793 120.07 265.779 120.144 265.779H157.019C157.947 261.955 160.131 258.551 163.221 256.111C166.311 253.672 170.13 252.337 174.068 252.319C175.471 247.787 178.159 243.76 181.809 240.726C185.458 237.692 189.91 235.783 194.624 235.229C199.339 234.676 204.113 235.503 208.366 237.609C212.619 239.716 216.169 243.011 218.583 247.095C219.131 247.052 219.669 247.031 220.186 247.031C225.43 247.045 230.475 249.038 234.312 252.61C238.149 256.182 240.495 261.071 240.879 266.297C240.884 266.37 240.875 266.443 240.852 266.513C240.829 266.582 240.792 266.646 240.744 266.701C240.696 266.756 240.638 266.802 240.572 266.834C240.507 266.867 240.436 266.886 240.363 266.891C240.349 266.892 240.336 266.893 240.323 266.893Z"
        fill="#CBCBCB"
      />
      <path
        d="M233.695 274.031H142.334C142.261 274.031 142.188 274.017 142.121 273.989C142.053 273.961 141.991 273.92 141.939 273.868C141.887 273.816 141.846 273.755 141.818 273.687C141.79 273.62 141.776 273.547 141.776 273.474C141.776 273.401 141.79 273.328 141.818 273.26C141.846 273.193 141.887 273.131 141.939 273.08C141.991 273.028 142.053 272.987 142.121 272.959C142.188 272.931 142.261 272.917 142.334 272.917H233.695C233.842 272.917 233.984 272.976 234.089 273.08C234.193 273.185 234.252 273.326 234.252 273.474C234.252 273.622 234.193 273.763 234.089 273.868C233.984 273.972 233.842 274.031 233.695 274.031Z"
        fill="#CBCBCB"
      />
      <path
        d="M164.617 282.103H133.978C133.905 282.103 133.832 282.089 133.765 282.061C133.697 282.033 133.635 281.992 133.583 281.94C133.531 281.889 133.49 281.827 133.462 281.759C133.434 281.692 133.419 281.619 133.419 281.546C133.419 281.473 133.434 281.4 133.462 281.333C133.49 281.265 133.531 281.203 133.583 281.152C133.635 281.1 133.697 281.059 133.765 281.031C133.832 281.003 133.905 280.989 133.978 280.989H164.617C164.691 280.989 164.763 281.003 164.831 281.031C164.899 281.059 164.961 281.1 165.012 281.152C165.064 281.203 165.106 281.265 165.134 281.333C165.162 281.4 165.176 281.473 165.176 281.546C165.176 281.619 165.162 281.692 165.134 281.759C165.106 281.827 165.064 281.889 165.012 281.94C164.961 281.992 164.899 282.033 164.831 282.061C164.763 282.089 164.691 282.103 164.617 282.103Z"
        fill="#CBCBCB"
      />
    </g>
    <defs>
      <clipPath id="clip0_3771_22038">
        <rect width="473.498" height="360" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Balloon;
