import { SvgIcon } from '@mui/material';

const ChevronRight = ({ ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.48685 22.8612L14.9292 11.8028L3.48685 0.744464C3.17498 0.444049 2.75654 0.279829 2.32359 0.28793C1.89064 0.29603 1.47864 0.475789 1.17822 0.787661C0.877806 1.09953 0.713586 1.51797 0.721687 1.95092C0.729788 2.38387 0.909547 2.79587 1.22142 3.09629L10.2351 11.8028L1.22142 20.519C0.909547 20.8194 0.729788 21.2314 0.721687 21.6644C0.713586 22.0973 0.877806 22.5158 1.17822 22.8276C1.47864 23.1395 1.89064 23.3193 2.32359 23.3274C2.75654 23.3355 3.17498 23.1712 3.48685 22.8708V22.8612Z" />
  </SvgIcon>
);

export default ChevronRight;
