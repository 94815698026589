export type VariantType = 'plain' | 'component';
export type CurrencyType = 'PLN' | 'EUR' | 'CHF' | 'USD' | 'GPB';

export enum CurrencySign {
  PLN = 'zł',
  EUR = '€',
  CHF = 'fr.',
  USD = '$',
  GPB = '£'
}
