import { TFunction } from 'react-i18next';
import {
  PRODUCT_TYPE_SETTLEMENT_ZERO,
  PRODUCT_TYPE_CUD,
  PRODUCT_TYPE_DUD,
  PRODUCT_TYPE_TOTAL_REPAYMENT,
  PRODUCT_TYPE_SETTLEMENT_ZERO_PREREQUISITE,
  PRODUCT_TYPE_PLUS_SETTLEMENT
} from 'utils/consts';
import { CurrencyType } from 'utils/formatCurrency/formatCurrency.model';
import { ProductParametersKey } from 'storage/agreements/agreements.model';
import { SliderOptions } from 'components/CustomizeDebtSlider/CustomizeDebtSlider.model';
import { ProductOffer } from 'components/ProductBox/ProductBox.model';

export interface Offer extends ProductOffer {
  agreementId: number;
  paperFormRequired: boolean;
  pitRequired: boolean;
}

export interface ProductState {
  userOffers: Offer[];
  pitRequired: boolean;
  initialAnnexSimulation: ZeroSimulationData | null;
  partialSimulation: PartialSimulationData | null;
  zeroSimulation: ZeroSimulationData | null;
  zeroWithPrerequisiteSimulation: ZeroWithPrerequisiteSimulationData | null;
  totalSimulation: TotalSimulationData | null;
  dynamicSimulation: DynamicSimulationData | null;
  plusSimulation: PlusSimulationData | null;
}

export interface OfferResponse {
  data: Offer[];
  status: number;
}

export enum ProductType {
  SETTLEMENT_ZERO = PRODUCT_TYPE_SETTLEMENT_ZERO,
  SETTLEMENT_ZERO_WITH_PREREQUISITE = PRODUCT_TYPE_SETTLEMENT_ZERO_PREREQUISITE,
  CUD = PRODUCT_TYPE_CUD,
  DUD = PRODUCT_TYPE_DUD,
  TOTAL_REPAYMENT = PRODUCT_TYPE_TOTAL_REPAYMENT,
  PLUS_SETTLEMENT = PRODUCT_TYPE_PLUS_SETTLEMENT
}

export interface SimulationParameter {
  key: ProductParametersKey;
  value: string;
  minValue: string | null;
  maxValue: string | null;
}

export interface Simulation {
  productTypeId: ProductType;
  agreementId: number;
  offerId: number;
  type: string;
  paperFormRequired: boolean;
  pitRequired: boolean;
  simulationParameters: SimulationParameter[];
}

export interface PartialSimulationData {
  debt: number;
  remission: number;
  minDate: Date;
  maxDate: Date;
  pitRequired: boolean;
  maxDaysAmount: number;
  payoffAmount: number;
}

export interface DynamicSimulationData {
  sumSettings: SliderOptions;
  amountSettings: SliderOptions;
  minDate: Date;
  maxDate: Date;
  averageDate: Date;
  firstInstallmentPaymentMin: Date;
  firstInstallmentPaymentMax: Date;
  firstInstallmentPaymentAverage: Date;
  lastInstallmentPayment: Date;
  lastInstallment: number;
  remissionTotalRepayment: number;
  remissionAmount: number;
  remissionInstalmentRepaymentAmount: number;
  minPrerequisitePaymentAmount: number;
  maxPrerequisitePaymentAmount: number;
  averagePrerequisitePaymentAmount: number;
  prerequisitePaymentDebtAmount: number;
  installmentsCountMin: number;
  installmentsCountMax: number;
  installmentsCountAverage: number;
  installmentsAmountMin: number;
  installmentsAmountMax: number;
  installmentsAmountAverage: number;
}

export interface DynamicSimulationResponse extends DynamicSimulationData {
  pitRequired: boolean;
}

export interface ZeroWithPrerequisiteSimulationData {
  sumSettings: SliderOptions;
  amountSettings: SliderOptions;
  minDate: Date;
  maxDate: Date;
  averageDate: Date;
  firstInstallmentPaymentMin: Date;
  firstInstallmentPaymentMax: Date;
  firstInstallmentPaymentAverage: Date;
  lastInstallmentPayment: Date;
  lastInstallment: number;
  remissionInstalmentRepaymentAmount: number;
  minPrerequisitePaymentAmount: number;
  maxPrerequisitePaymentAmount: number;
  averagePrerequisitePaymentAmount: number;
  prerequisitePaymentDebtAmount: number;
  installmentsCountMin: number;
  installmentsCountMax: number;
  installmentsCountAverage: number;
  installmentsAmountMin: number;
  installmentsAmountMax: number;
  installmentsAmountAverage: number;
}

interface ZeroSimulationDebt {
  amount: number;
  sum: number;
  lastInstallment: number;
  endOfDebtDate: string;
}

export interface ZeroSimulationData {
  debt: ZeroSimulationDebt;
  date: Date;
  minDate: Date;
  maxDate: Date;
  sumSettings: SliderOptions;
  amountSettings: SliderOptions;
}

export interface PlusSimulationData {
  firstInstallmentPaymentDate?: Date;
  firstInstallmentPaymentMin: Date;
  firstInstallmentPaymentMax: Date;
  installmentsCount: number;
  installmentsAmountAverage: number;
  installmentsAmountMax: number;
  installmentsAmountMin: number;
  lastInstallment: number;
  lastInstallmentPayment: Date;
  remissionAmount: number;
  remissionTotalRepayment: number;
}

export interface PlusSimulationResponse extends PlusSimulationData {
  firstInstallmentPaymentAverage: Date;
  pitRequired: boolean;
}

export interface TotalSimulationData {
  minDate: Date;
  maxDate: Date;
  maxDaysAmount: number;
}

export interface SimulationResponse {
  data: Simulation;
  status: number;
  success: boolean;
}

export interface RecalculateSimulationChangeItem {
  key: ProductParametersKey;
  value: string | number;
  valueChanged: boolean;
}

export interface RecalculateParameters {
  agreementId: number;
  productType: ProductType;
  date: string;
  valueChanged:
    | DebtValueChanged
    | PrerequisitePaymentValueChanged
    | InstallmentsPaymentValueChanged;
  debt?: Debt;
  t?: TFunction<'translation'>;
  currencyType?: CurrencyType;
}

export interface RecalculateSimulationResponse {
  data: SimulationParameter[];
  status: number;
}

export interface RecalculateDynamicRemissionParameters
  extends RecalculateParameters {
  prerequisitePaymentAmount: number;
  valueChanged:
    | PrerequisitePaymentValueChanged
    | InstallmentsPaymentValueChanged;
  firstInstallmentDate?: string;
  installmentsCount?: number;
  installmentsAmount?: number;
  t?: TFunction<'translation'>;
  currencyType?: CurrencyType;
  initialCalculate?: boolean;
}

export interface RecalculatePlusSettlementParameters
  extends RecalculateParameters {
  currencyType?: CurrencyType;
  initialCalculate?: boolean;
  installmentsAmount: number;
  t?: TFunction<'translation'>;
  valueChanged: DebtValueChanged;
}

export interface RecalculateZeroWithPrerequisiteParameters
  extends RecalculateParameters {
  valueChanged:
    | PrerequisitePaymentValueChanged
    | InstallmentsPaymentValueChanged;
  firstInstallmentDate?: string;
  installmentsCount?: number;
  installmentsAmount?: number;
  t?: TFunction<'translation'>;
  currencyType?: CurrencyType;
  initialCalculate?: boolean;
}

export interface Debt {
  sum: number;
  amount: number;
  lastInstallment: number;
  endOfDebtDate: string;
}

export interface PrerequisitePaymentData {
  averageDate: Date;
  averagePrerequisitePaymentAmount: number;
  maxDate: Date;
  maxPrerequisitePaymentAmount: Date;
  minDate: Date;
  minPrerequisitePaymentAmount: number;
  prerequisitePaymentDebtAmount: number;
  remissionAmount: number;
  remissionInstalmentRepaymentAmount: number;
  firstInstallmentPaymentMin: Date;
  firstInstallmentPaymentMax: Date;
  firstInstallmentPaymentAverage: Date;
  installmentsCountMin: number;
  installmentsCountMax: number;
  installmentsCountAverage: number;
  installmentsAmountMin: number;
  installmentsAmountMax: number;
  installmentsAmountAverage: number;
}

export enum DynamicRemissionValueChanged {
  prerequisiteDate = 'prerequisiteDate',
  prerequisitePaymentAmount = 'prerequisitePaymentAmount',
  installmentsCount = 'installmentsCount',
  installmentsAmount = 'installmentsAmount'
}

export enum SettlementZeroWithPrerequisiteValueChanged {
  prerequisiteDate = 'prerequisiteDate',
  installmentsCount = 'installmentsCount',
  installmentsAmount = 'installmentsAmount'
}

export type PrerequisitePaymentValueChanged =
  | 'prerequisitePaymentAmount'
  | 'prerequisiteDate';

export type InstallmentsPaymentValueChanged =
  | 'installmentsAmount'
  | 'installmentsCount';

export enum DebtValueChanged {
  sum = 'sum',
  amount = 'amount',
  date = 'date'
}

export interface ZeroSimulationParameters {
  debtValueChanged: DebtValueChanged;
  agreementId: number;
  productType: ProductType;
  addressId: number;
  emailAddressId: number;
  date: Date;
  debt: Debt;
}

export interface ConfirmSimulationResponse {
  status: number;
  success?: boolean;
}

export interface ConfirmSimulationRequest {
  productTypeId: ProductType;
  productParameters: RecalculateSimulationChangeItem[];
  documentDeliveryType: string;
}

export interface ConfirmZeroSimulationRequest extends ConfirmSimulationRequest {
  addressId: number;
  emailAddressId: number;
}

export interface ConfirmRemissionSimulationRequest
  extends ConfirmSimulationRequest {
  addressId: number;
  pitAddressId: number | null;
  isRemittedDebtRelatedToBusiness: boolean;
}

export interface ConfirmDynamicRemissionSimulationRequest
  extends ConfirmSimulationRequest {
  addressId: number;
  pitAddressId: number | null;
  emailAddressId: number;
  isRemittedDebtRelatedToBusiness: boolean;
}

export interface TotalSimulationParameters {
  agreementId: number;
  date: Date;
  productType: ProductType;
}

export interface RemissionSimulationParameters {
  agreementId: number;
  date: Date;
  productType: ProductType;
  addressId: number;
  pitAddressId: number | null;
  isRemittedDebtRelatedToBusiness: boolean;
}

export interface DynamicRemissionSimulationParameters {
  isRemittedDebtRelatedToBusiness: boolean;
  prerequisiteDate: Date;
  agreementId: number;
  firstInstallmentDate: Date;
  prerequisitePaymentAmount: number;
  valueChanged:
    | PrerequisitePaymentValueChanged
    | InstallmentsPaymentValueChanged;
  productType: ProductType;
  addressId: number;
  pitAddressId: number | null;
  installments: {
    amount: number;
    sum: number;
  };
  emailAddressId: number;
}

export interface ZeroWithPrerequisiteSimulationParameters {
  agreementId: number;
  productType: ProductType;
  prerequisiteDate: Date;
  firstInstallmentDate: Date;
  prerequisitePaymentAmount: number;
  addressId: number;
  emailAddressId: number;
  installments: {
    amount: number;
    sum: number;
  };
  valueChanged:
    | PrerequisitePaymentValueChanged
    | InstallmentsPaymentValueChanged;
}

export interface ConfirmZeroWithPrerequisiteSimulationRequest
  extends ConfirmSimulationRequest {
  addressId: number;
  emailAddressId: number;
}

export interface ConfirmPlusSimulationRequest extends ConfirmSimulationRequest {
  addressId: number;
  emailAddressId: number;
  isRemittedDebtRelatedToBusiness: boolean;
  pitAddressId: number | null;
}

export interface PlusSimulationParameters {
  addressId: number;
  agreementId: number;
  date: Date;
  emailAddressId: number;
  installmentsAmount: number;
  isRemittedDebtRelatedToBusiness: boolean;
  pitAddressId: number | null;
  productType: ProductType;
  valueChanged: DebtValueChanged;
}
