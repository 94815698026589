import React, { useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootDispatch, RootState } from 'storage/store';
import useInstallPrompt from 'hooks/useInstallPrompt';
import HowInstallPWA from 'components/Modal/modals/HowInstallPWA';
import { Icon } from 'components/Icon';

enum ButtonType {
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

const PWAButton = () => {
  const { t } = useTranslation();
  const [PWAModalVisible, setPWAModalVisible] = useState(false);
  const dispatch = useDispatch<RootDispatch>();
  const randomButton = useSelector((state: RootState) => state.pwa.buttonType);
  const { isIphone } = useSelector((state: RootState) => state.pwa);
  const [promptToInstall] = useInstallPrompt();

  const onPWAButtonClick = (
    buttonType: string,
    event: MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    dispatch.pwa.recordButtonClick(buttonType);
    if (isIphone) {
      return setPWAModalVisible(true);
    }
    return promptToInstall();
  };

  const getPWAButton = () => {
    switch (randomButton) {
      case ButtonType.B:
        return (
          <Button
            variant="outlined"
            size="medium"
            data-link
            onClick={(event) => {
              onPWAButtonClick(ButtonType.B, event);
            }}
            sx={{
              textAlign: 'left',
              color: 'inherit',
              borderColor: 'primary.main',
              padding: '5px',
              paddingRight: '20px',
              paddingLeft: '10px'
            }}
          >
            <Icon
              icon="mobile"
              fontSize="large"
              sx={{
                height: '60px',
                width: '40px',
                paddingLeft: '0',
                paddingTop: '10px'
              }}
            />
            {t('PWA_APP_BUTTON_1')}
          </Button>
        );
      case ButtonType.C:
        return (
          <Button
            variant="contained"
            size="medium"
            data-link
            onClick={(event) => onPWAButtonClick(ButtonType.C, event)}
            sx={{
              textAlign: 'left',
              padding: '1px',
              paddingRight: '20px',
              paddingLeft: '10px'
            }}
          >
            <Icon
              icon="mobile"
              fontSize="large"
              sx={{
                height: '80px',
                width: '50px',
                paddingLeft: '2px',
                paddingTop: '10px'
              }}
            />
            {t('PWA_APP_BUTTON_1')}
          </Button>
        );
      case ButtonType.D:
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={(event) => onPWAButtonClick(ButtonType.D, event)}
            size="medium"
            sx={{
              textAlign: 'left',
              padding: '14px 25px 14px 17px',
              color: 'inherit',
              minWidth: '100%',
              justifyContent: 'left',
              borderColor: 'primary.main',
              width: '100%'
            }}
          >
            <Icon
              icon="cursor"
              color="primary"
              fontSize="medium"
              sx={{
                height: '16px',
                width: '16px',
                marginRight: '15px'
              }}
            />
            {t('PWA_APP_BUTTON_2')}
          </Button>
        );
      case ButtonType.E:
        return (
          <Button
            id="pwaBtn"
            variant="contained"
            onClick={(event) => onPWAButtonClick(ButtonType.E, event)}
            size="medium"
            sx={{
              textAlign: 'left',
              padding: '14px 25px 15px 17px',
              justifyContent: 'left',
              width: '328px'
            }}
          >
            <Icon
              icon="cursor"
              fontSize="medium"
              sx={{
                height: '16px',
                width: '16px',
                marginRight: '15px'
              }}
            />
            {t('PWA_APP_BUTTON_2')}
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <HowInstallPWA
        open={PWAModalVisible}
        onClose={() => setPWAModalVisible(false)}
      />
      {getPWAButton()}
    </>
  );
};

export default PWAButton;
