import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  Link,
  Typography
} from '@mui/material';
import theme from 'App.theme';
import { Icon } from 'components/Icon';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from 'storage/store';
import { IsLoggedIn } from 'utils/user';
import { menuClickEvent } from 'utils/events';
import sendEvent from 'utils/sendEvent';
import { Index } from 'utils/consts';
import { UserInfo } from '../styled/UserInfo';
import { getMenuFooterList, getMenuList } from '../utils';
import AppBarButtons from './AppBarButtons';
import PWAButton from './PWAButton';
import MenuItemNavLink from '../styled/MenuItemNavLink';
import { MenuVisibilityContext } from '../utils/menuVisibilityContext';
import { colors } from '../../Colors';

interface IMobileMenu {
  username: string;
}

const MobileMenu = ({ username }: IMobileMenu) => {
  const { t } = useTranslation();
  const { menuVisible, setVisible } = useContext(MenuVisibilityContext);
  const { modals } = useDispatch<RootDispatch>();
  const menuList = getMenuList(t);
  const menuFooterList = getMenuFooterList(t, setVisible!, modals);
  const { showButton } = useSelector((state: RootState) => state.pwa);

  const mobileMenuAction = (isMenuVisible: boolean, text: string) => {
    setVisible!(isMenuVisible);
    sendEvent({
      labelText: text,
      ...menuClickEvent
    });
  };

  return (
    <Grid item>
      <IconButton onClick={() => mobileMenuAction(!menuVisible, 'mobile menu')}>
        <Icon icon={menuVisible ? 'close' : 'menu'} color="primary" />
      </IconButton>
      <SwipeableDrawer
        disableBackdropTransition
        anchor="top"
        open={menuVisible}
        onClose={() => setVisible!(false)}
        onOpen={() => setVisible!(true)}
        BackdropProps={{
          invisible: true
        }}
      >
        <Box
          padding={{
            xs: '24px 24px 70px',
            md: '36px 49px'
          }}
        >
          {username && (
            <UserInfo
              variant="body1"
              fontWeight="700"
              color="secondary"
              margin="12px 0"
            >
              <Icon icon="user" color="secondary" />
              {username}
            </UserInfo>
          )}
          {[menuList.primary, menuList.secondary].map(
            (group, groupIndex, list) => (
              <List
                sx={{
                  marginTop: '5px',
                  marginBottom:
                    groupIndex === Index.zero && IsLoggedIn() ? '20px' : ''
                }}
                key={group.id}
              >
                {group.items
                  .filter((item) => (IsLoggedIn() ? true : !item.loggedOnly))
                  .map(({ href, text }, index) => (
                    <Fragment key={text}>
                      <ListItem button disableGutters disablePadding>
                        <Typography
                          component={MenuItemNavLink}
                          to={href}
                          onClick={() => mobileMenuAction(false, text)}
                          variant="h4"
                          color="primary"
                          fontWeight="900"
                        >
                          {text}
                        </Typography>
                      </ListItem>
                      {index < list.length && IsLoggedIn() && (
                        <Divider
                          sx={{
                            borderWidth: '1px',
                            borderColor: colors.green.midTeal.value
                          }}
                        />
                      )}
                    </Fragment>
                  ))}
              </List>
            )
          )}
          <Grid
            container
            width="190px"
            marginTop={IsLoggedIn() ? '19px' : '7px'}
          >
            <AppBarButtons />
          </Grid>
          <Grid
            container
            marginTop="35px"
            sx={{ maxWidth: '400px', width: { xs: 'auto' } }}
          >
            {showButton && <PWAButton />}
          </Grid>
          <Grid
            container
            flexDirection="column"
            marginTop={IsLoggedIn() ? '38px' : '34px'}
          >
            {menuFooterList.map(({ text, href, onClick }, index, list) => (
              <Grid
                item
                marginBottom={index < list.length ? '29px' : '0'}
                key={text}
              >
                {href ? (
                  <Typography
                    variant="body1"
                    fontWeight="700"
                    color="secondary"
                    component={MenuItemNavLink}
                    to={href}
                    onClick={() => mobileMenuAction(false, text)}
                  >
                    {text}
                  </Typography>
                ) : (
                  <Link
                    href={href}
                    onClick={onClick}
                    sx={{
                      color: theme.palette.secondary.main,
                      cursor: 'pointer'
                    }}
                  >
                    {text}
                  </Link>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </SwipeableDrawer>
    </Grid>
  );
};

export default MobileMenu;
