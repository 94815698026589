import { TFunction } from 'react-i18next';
import { Constant } from './consts';

const getWordVariation = (value: number, t: TFunction<'translation'>) => {
  if (value === Constant.one) {
    return t('SETTLEMENT_ZERO_OFFER_ONE_INSTALLMENT_NAME');
  }
  if (
    value % Constant.ten >= Constant.two &&
    value % Constant.ten <= Constant.four &&
    (value % Constant.oneHundred < Constant.ten ||
      value % Constant.oneHundred >= 20)
  ) {
    return t('SETTLEMENT_ZERO_OFFER_TWO_TO_FOUR_INSTALLMENT_NAME');
  }
  return t('SETTLEMENT_ZERO_OFFER_OTHER_INSTALLMENT_NAME');
};

export default getWordVariation;
