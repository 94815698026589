import store from 'storage/store';

export const getUserId = async () =>
  new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const userId = store.getState().user.id;
      if (userId) {
        resolve(userId);
        unsubscribe();
      }
    });
  });
