import styled from 'styled-components/macro';
import { Container } from '@mui/material';

export const IllustrationWrapper = styled(Container)`
  position: absolute;
  left: -46%;
  top: 31%;
  &&& {
    width: 154%;
  }
`;

export default IllustrationWrapper;
