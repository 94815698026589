import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import reducers from './liveChat.reducers';
import { LiveChatState } from './liveChat.model';

const liveChatModel = createModel<RootModel>()({
  state: {
    visibility: undefined
  } as LiveChatState,
  reducers
});

export default liveChatModel;
