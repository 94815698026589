import { differenceInCalendarDays, parse } from 'date-fns';
import { PATTERNS } from 'validations/common';

const getDifferenceInDays = (date: any) => {
  if (typeof date === 'string' && !date.match(PATTERNS.ddMmYyyy)) {
    throw new Error(`Value / ${date} / does not match date format`);
  }

  return differenceInCalendarDays(
    parse(date, 'd.MM.yyyy', new Date()),
    new Date()
  );
};

export default getDifferenceInDays;
