import { Typography, Link } from '@mui/material';
import { List } from '../styled/List';
import { ListItem } from '../styled/ListItem';
import { linkFb } from '../utils/urls';

const InformationAboutSocialPlugins = () => (
  <>
    <Typography variant="h5" fontWeight="700">
      INFORMACJE O WTYCZKACH SPOŁECZNOŚCIOWYCH
    </Typography>
    <Typography variant="body1">
      Wtyczki społecznościowe to małe fragmenty kodu, łączące wizytę Użytkownika
      w Serwisie z daną platformą mediów społecznościowych Zewnętrznego dostawcy
      (np. kliknięcie w „Lubię to” na Facebooku). Wtyczka społecznościowa
      powiadomi Zewnętrznego dostawcę, że odwiedziłeś Serwis, może także
      pozwolić na odczyt informacji z plików Cookies, jakie wcześniej przesłał
      na Twój komputer.
    </Typography>
    <Typography variant="body1" sx={{ marginTop: '10px' }}>
      Stosujemy wtyczki społecznościowe niżej wskazanych Zewnętrznych dostawców
      (obok Zewnętrznego dostawcy dodano link do polityki prywatności):
    </Typography>
    <List>
      <ListItem dense>
        {' '}
        <Link
          sx={{ fontWeight: 700, lineHeight: '125%' }}
          color="primary.light"
          href={linkFb}
          target="_blank"
          rel="nofollow"
          data-link-no-alert
        >
          Facebook (Meta Platforms Ireland Limited)
        </Link>
      </ListItem>
    </List>
  </>
);

export default InformationAboutSocialPlugins;
