import { IIllustration } from '../Illustration.model';

const InformationMark = ({ width, height }: IIllustration) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_861_2124)">
      <path
        d="M6.76561 48.2195L4.39258 41.0768C4.39258 41.0768 7.00506 43.6798 7.00506 45.8164L6.53693 40.8726C6.53693 40.8726 7.87662 42.6749 7.74286 45.4112C7.60911 48.1475 6.76561 48.2195 6.76561 48.2195Z"
        fill="#E6E6E6"
      />
      <path
        d="M43.841 51.5147C43.841 56.2134 34.4923 54.7679 23.0435 54.7776C11.5948 54.7873 2.38312 56.2489 2.37988 51.5534C2.37665 46.8578 11.7274 45.8282 23.173 45.8132C34.6185 45.7981 43.8367 46.817 43.841 51.5147Z"
        fill="#E6E6E6"
      />
      <path
        d="M10.6759 15.2495C10.3301 13.7655 10.5366 12.4135 11.2916 11.1771C12.0466 9.9407 13.2467 9.12215 14.8791 8.74177C16.5116 8.36139 17.9499 8.56519 19.1736 9.34052C20.3974 10.1158 21.1841 11.2538 21.526 12.7213C21.8718 14.2054 21.6653 15.5574 20.9103 16.7938C20.1553 18.0302 18.9553 18.8487 17.3228 19.2291C15.6903 19.6095 14.2521 19.4057 13.0283 18.6303C11.8046 17.855 11.0217 16.7336 10.6759 15.2495ZM29.4941 49.0069C29.0846 49.1892 28.4811 49.4342 27.6708 49.762C26.8606 50.0899 26.035 50.3518 25.1776 50.5516C24.3201 50.7514 23.5297 50.866 22.8268 50.9081C22.1238 50.9502 21.4929 50.8539 20.9505 50.6152C20.4081 50.3765 19.9339 49.9828 19.5444 49.4303C19.1549 48.8779 18.8424 48.1336 18.6157 47.1607L13.0829 23.4158C13.4924 23.2335 14.096 22.9885 14.9062 22.6607C15.7164 22.3328 16.542 22.0709 17.3995 21.8711C18.2569 21.6713 19.0308 21.5605 19.7503 21.5146C20.4532 21.4725 21.0842 21.5688 21.6266 21.8075C22.1689 22.0462 22.6431 22.4399 23.0326 22.9924C23.4221 23.5449 23.7346 24.2891 23.9613 25.262L29.4941 49.0069Z"
        fill="#58A765"
      />
    </g>
    <defs>
      <clipPath id="clip0_861_2124">
        <rect width="43.8406" height="55" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InformationMark;
