import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Grid, useMediaQuery } from '@mui/material';
import appTheme from 'App.theme';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import PrivacyPolicyContent from 'components/PrivacyPolicy/components/PrivacyPolicyContent';
import { ScrollableContent } from 'components/ScrollableContent';

interface ICookiesModal {
  open: boolean;
  onClose: () => void;
}

const ADDITIONAL_HEIGHT_TO_CUT_MOBILE = 42;
const ADDITIONAL_HEIGHT_TO_CUT_NOT_MOBILE = 110;

const Cookies = ({ open, onClose }: ICookiesModal) => {
  const { t } = useTranslation();
  const [isPrivacyPolisyContentToggled, setIsPrivacyPolisyContentToggled] =
    useState(false);
  const mobileBreakpoint = useMediaQuery(appTheme.breakpoints.down('md'));
  const tabletBreakpoint = useMediaQuery(
    appTheme.breakpoints.between('md', 'lg')
  );
  const additionalHeightToCut = mobileBreakpoint
    ? ADDITIONAL_HEIGHT_TO_CUT_MOBILE
    : ADDITIONAL_HEIGHT_TO_CUT_NOT_MOBILE;
  const topPosition = mobileBreakpoint
    ? '0'
    : tabletBreakpoint
    ? '20px'
    : '30px';
  return (
    <Modal
      open={open}
      withoutCloseIcon
      fullWidth={mobileBreakpoint}
      sx={{
        top: topPosition,
        bottom: 'unset',
        width: `${mobileBreakpoint ? '100%' : 'auto'}`,
        maxWidth: '672px',
        margin: '0 auto',
        '.MuiDialog-paper': {
          width: `${mobileBreakpoint ? '100%' : 'auto'}`,
          padding: '19px 23px 30px',
          overflow: 'hidden auto'
        },
        '.MuiDialogContent-root': {
          padding: '5px 0 15px'
        }
      }}
    >
      <ScrollableContent
        scrollAmount={80}
        buttonBarHeight={80}
        additionalHeightToCut={additionalHeightToCut}
        description={t('PRIVACY_POLICY_SCROLLBUTTON')}
        contentContainerSx={{ padding: 0 }}
        isStaticPosition
      >
        <Grid
          container
          padding={{ xs: '0 5px 20px', md: '0 15px', lg: '0 15px' }}
        >
          <Grid
            item
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '-6px',
              marginBottom: '10px'
            }}
          >
            <Typography variant="h4">
              <Trans i18nKey="COOKIES_TITLE" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Trans i18nKey="COOKIES_MESSAGE" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              label={t('BUTTON_AGREED')}
              size="large"
              sx={{ marginTop: '25px', marginBottom: '15px' }}
              onClick={onClose}
            />
          </Grid>
          <Grid
            item
            padding="10px 0 5px"
            sx={{
              color: 'primary.light',
              '&:hover': { color: 'primary.main' }
            }}
            onClick={() =>
              setIsPrivacyPolisyContentToggled(!isPrivacyPolisyContentToggled)
            }
          >
            <Typography
              variant="body1"
              sx={{
                display: 'inline',
                fontWeight: 700,
                color: 'inherit',
                userSelect: 'none',
                cursor: 'pointer'
              }}
            >
              {t('COOKIES_TOGGLE_CONTENT')}
            </Typography>

            <Icon
              icon={isPrivacyPolisyContentToggled ? 'chevronUp' : 'chevronDown'}
              fontSize="16"
              sx={{
                margin: '5px 5px 0',
                color: 'inherit',
                cursor: 'pointer',
                verticalAlign: 'text-bottom'
              }}
            />
          </Grid>
          {isPrivacyPolisyContentToggled && (
            <>
              <Grid item xs={12}>
                <PrivacyPolicyContent />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  label={t('BUTTON_AGREED')}
                  size="large"
                  sx={{ marginTop: '20px', marginBottom: '5px' }}
                  onClick={onClose}
                />
              </Grid>
            </>
          )}
        </Grid>
      </ScrollableContent>
    </Modal>
  );
};

export default Cookies;
