import { Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Url } from 'utils/urls';
import { RootDispatch } from 'storage/store';
import { ExitProtectionModalOptions } from 'pages/Dashboard/pages/Case/pages/Offer/interfaces/ExitModal.model';

function useOutsideAlerter(
  ref: any,
  callback: (args: ExitProtectionModalOptions) => void,
  setMenuVisibility?: Dispatch<SetStateAction<boolean>> | null
) {
  const navigate = useNavigate();
  const { user } = useDispatch<RootDispatch>();

  const handleClick = (event: Event) => {
    const { target } = event;
    event.preventDefault();
    if ((target as HTMLElement).tagName === 'BUTTON') {
      event.stopPropagation();
      if (setMenuVisibility) setMenuVisibility(false);
      callback({
        isOpen: true,
        func: () => {
          user.logoutUser();
          navigate(Url.login);
        }
      });
    } else {
      const path =
        (target as HTMLAnchorElement).getAttribute('href') ||
        (target as HTMLElement).closest('a[data-link]')!.getAttribute('href');
      callback({ isOpen: true, func: () => navigate(path ?? '') });
    }
  };

  function handleClickOutside(event: Event) {
    const { target } = event;
    const hasLinkAttribute = (target as HTMLElement).hasAttribute('data-link');
    const isNoAlertLink =
      (target as HTMLElement).hasAttribute('data-link-no-alert') ||
      (target as HTMLElement).hasAttribute('download');
    const isValidLinkElement =
      (target as HTMLElement).tagName === 'A' &&
      (target as HTMLElement).hasAttribute('href');
    const isValidNavigationClick =
      isValidLinkElement ||
      hasLinkAttribute ||
      !!(target as HTMLElement).closest('a[data-link]');

    if (
      ref.current &&
      !ref.current.contains(target) &&
      isValidNavigationClick &&
      !isNoAlertLink
    ) {
      handleClick(event);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref]);
}

export default useOutsideAlerter;
