import {
  CountryCode,
  PhoneCountryCode
} from 'providers/TranslationProvider/TranslationProvider.model';

export interface TempPasswordResponseData {
  token: string;
}

export interface TempPasswordResponse {
  success: boolean;
  message?: string;
  data?: TempPasswordResponseData;
  status: number;
}

export enum MessageTypes {
  ok = 'ok',
  blocked = 'Account is blocked',
  notActivated = 'Account is not active',
  invalidPassword = 'invalidPassword',
  sessionExpired = 'sessionExpired'
}

export enum TokenType {
  REGISTER_ACCOUNT = 'REGISTER_ACCOUNT',
  RESET_PASSWORD = 'RESET_PASSWORD',
  UNBLOCK_ACCOUNT = 'UNBLOCK_ACCOUNT'
}

export enum TokenStatus {
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  USED = 'USED',
  VALID = 'VALID'
}

export enum VerifyClientMessage {
  FAILED = 'User verification failed',
  LIMIT_REACHED = 'User verification failed - limit reached',
  UNAUTHORIZED = 'Full authentication is required to access this resource',
  EMAIL_INCORRECT = 'email: Email address is incorrect',
  PASSWORD_INCORRECT = 'Password is invalid',
  EMAIL_EMPTY = 'email: must not be empty',
  PASSWORD_EMPTY = 'password: must not be empty'
}

export enum CreateAccountMessage {
  SUCCESS = 'User create success',
  EMAIL_EXISTS = 'User create failed - email exists'
}

export enum ChangePasswordMessage {
  SAME_PASSWORDS = 'Passwords are the same',
  INVALID_OLD_PASSWORD = 'Old password is invalid'
}

export enum ResetPasswordMessage {
  PESEL_INCORRECT = 'Given PESEL is incorrect',
  PESEL_LIMIT_REACHED = 'User verification failed - limit reached'
}

export enum GetDocumentMessage {
  NO_DOCUMENT_AVAILABLE = 'No document to download on given agreement for given user'
}

export type UserStatus =
  | MessageTypes.blocked
  | MessageTypes.notActivated
  | MessageTypes.invalidPassword
  | MessageTypes
  | null;

export interface UserCredentials {
  login: string;
  password: string;
}

export interface UserData {
  email: string;
  firstName: string;
  lastName: string;
}
export interface UserPayload {
  data: UserData;
}

export interface UserLoginData {
  token: string;
  type: string;
}

export interface UserLoginPayload {
  data?: UserLoginData;
  status: number;
  message?: string;
}

export interface User {
  id: number | null;
  language: string;
  firstName: string;
  firstNameVocative: string;
  lastName: string;
  email: string;
  peselGender: 'M' | 'F';
  token: string | null;
  userStatus: UserStatus;
  testGroup: TestGroupTypes;
  showContactDetailsAcceptance: boolean;
  impersonation: boolean;
}

export interface UserEmail {
  email: string;
}

export interface UserPassword {
  password: string;
}

export interface UnblockAccount {
  password: string;
  pesel: string;
}

export interface ResetUserPassword {
  pesel: string;
  password: string;
  conditionsAccepted: boolean;
}

export interface ResetEmailResponse {
  status: number;
}

export interface ResetPassword extends UserPassword {
  token: string;
  pesel: string;
}

export interface ChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface ActivateAccount {
  token: string;
}
export interface ResendActivateAccount {
  activationToken: string;
}
export interface ActivateAccountResponse {
  status: number;
}

export interface ResetPasswordResponse {
  status: number;
  success?: boolean;
  message?: string;
}

export interface ChangePasswordResponse {
  status: number;
  message?: string;
  success?: boolean;
}

export interface ValidateToken {
  token: string;
  type: TokenType;
}

export interface ValidateTokenData {
  status: TokenStatus;
  login: string | null;
}

export interface ValidateTokenResponse {
  success: boolean;
  data: ValidateTokenData;
}

export interface UnblockAccountResponse {
  status: number;
}

export interface VerifyClient {
  agreementId: string;
  pesel: string;
}

export interface VerifyClientResponse {
  success?: boolean;
  message?: string;
  status: number;
}

export interface CreateAccount {
  email: string;
  password: string;
  agreementId: string;
  pesel: string;
}

export interface CreateAccountFromACP {
  email: string;
  password: string;
}

export interface CreateAccountFromACPResponse {
  message?: CreateAccountMessage | VerifyClientMessage;
  status: number;
  success?: boolean;
}

export interface CreateAccountResponse {
  message?: CreateAccountMessage | VerifyClientMessage;
  status: number;
  success?: boolean;
}

export interface ContactFormPayload {
  email: string;
  phone: string;
  message: string;
}

export interface UserResponse<T> {
  data: T;
  success: boolean;
}

export enum AddressType {
  CONSTANT = 'CONSTANT',
  CORRESPONDENCE = 'CORRESPONDENCE',
  TEMPORARILY = 'TEMPORARILY'
}

export interface Address {
  addressId: number;
  addressType: AddressType | null;
  apartmentNumber: string | null;
  buildingNumber: string | null;
  street: string | null;
  city: string | null;
  postalCode: string | null;
  postCity: string | null;
  countryCode: CountryCode | null;
  clientDataId: number;
}

export interface AddressesData {
  addresses: Address[];
}

export enum EmailType {
  PRIVATE = 'PRIVATE',
  BUSINESS = 'BUSINESS',
  COURTESY = 'COURTESY'
}

export interface Email {
  clientDataId: number;
  emailAddressId: number;
  emailAddress: string | null;
  emailAddressType: EmailType | null;
  loginEmail: boolean | null;
}

export interface EmailsData {
  emails: Email[];
}

export interface AddEmailAddressPayload {
  emailAddress: string;
  emailAddressType: EmailType;
}

export interface EditEmailAddressPayload extends AddEmailAddressPayload {
  emailAddressId: number;
}

export interface UpdateTaxOfficePayload {
  agreementId: number;
  taxOfficeId: number;
}

export interface EmailResponse {
  success?: boolean;
  status: number;
}

export enum PhoneNumberType {
  MOBILE = 'MOBILE',
  HOME = 'HOME'
}

export interface Phone {
  phoneNumberId: number;
  phoneNumber: string | null;
  phoneNumberCodePrefix: string | null;
  phoneNumberCodeCountry: PhoneCountryCode | null;
  phoneNumberType: PhoneNumberType | null;
}
export interface CreatePhone {
  phoneNumberCodeCountry: PhoneCountryCode;
  phoneNumberType: PhoneNumberType;
  phoneNumber: string;
}

export interface UpdatePhone extends CreatePhone {
  phoneNumberId: number;
}

export interface DeletePhone {
  phoneNumberId: number;
  phoneNumber: string;
  phoneNumberType: string;
  phoneNumberCountryCode: string;
}

export interface PhoneResponse {
  status: number;
  success?: boolean;
}

export interface PhonesData {
  phones: Phone[];
}

export interface UserInfo {
  identityNumber: string;
}

export interface CreateAddress {
  addressType: AddressType;
  buildingNumber: string;
  city: string;
  countryCode: CountryCode;
  flatNumber?: string;
  postName?: string;
  street: string;
  zipCode: string;
}

export interface DeleteAddress {
  addressId: number;
  clientDataId: number;
}

export interface AddressResponse {
  success: boolean;
  status: number;
}

export interface UpdateAddress extends CreateAddress {
  addressId: number;
  clientDataId: number;
}

export interface TestGroupData {
  testGroup: TestGroupTypes;
}

export interface TestingDataResponse {
  data: TestGroupData;
  status: number;
  success: boolean;
}

export interface UserAcceptanceDataResponse {
  data: number;
  success: boolean;
}

export type TestGroupTypes = 'A' | 'B' | null;

export enum TestGroupVariant {
  A = 'A',
  B = 'B'
}
