import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import { models, RootModel } from './root';

const store = init({
  models,
  redux: {
    rootReducers: {
      RESET: () => undefined
    }
  }
});

export type RootState = RematchRootState<RootModel>;
export type RootDispatch = RematchDispatch<RootModel>;

export default store;
